<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Patient list</h5>
          </div>
          <div class="headerButtons" *ngIf="newbuttondie">
            <a style="float: right;margin-left: 5px;" *ngIf="show_disbut == false" (click)="openDiscahrgeTimeLinePage()">
              <img src="../../../assets/ui_icons/buttons/timeline_button.svg" width="85px"  /></a>
            <!-- <a style="float: right;" (click)="create_patient()">
              <img src="../../../assets/ui_icons/buttons/new_button.svg" class="imgbtn">
            </a> -->
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" *ngIf="hospitalFlag">
            <mat-label class="matlabel">
              <img src='../../../assets/ui_icons/Bill_list/Hospital_icon.svg' width="20px" height="auto" />&nbsp;
              Hospital
              <select required class="ipcss" [(ngModel)]="hospital_id" (change)="changePatientType('self')"
                style="height: 28px;">
                <option *ngFor="let hosp of hospital_array" value={{hosp.hptl_clinic_id}}>{{hosp.hptl_name}}
                </option>
              </select>
            </mat-label>
          </div>
          <div class="col-12" style="margin-bottom: 15px;margin-top: 5px;">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4 col-sm-6 col-xl-3 select_bottom">
                <div class="row">
                  <div class="col-10" *ngIf="!is_admin">
                    <div class="switch-field" *ngIf="ynopt1 != undefined">
                      <input type="radio" id="radio-three" (click)="change_type(1,'yes')" />
                      <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                        <div class="tab_cover">
                          <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Self.svg" class="iocinStyle"
                              alt=""></div>
                          <div class="lableStyle">Self</div>
                        </div>
                      </label>
                      <input type="radio" id="radio-four" (click)="change_type(1,'no')" />
                      <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                        <div class="tab_cover">
                          <div class="tab_icon_cover"><img src="../../../assets/ui_icons/others.svg" class="iocinStyle"
                              alt=""></div>
                          <div class="lableStyle">Others</div>
                        </div>
                      </label>  
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                  <mat-label class="matlabel">View<br>
                    <select disableOptionCentering class="ipcss widthappt" (change)="viewChange(view_type)"
                      [(ngModel)]="view_type">
                      <option value="graph">Graphical</option>
                      <option value="tabular">Tabular</option>
                    </select>
                  </mat-label>
                </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3" style="margin-top: 0px !important;">
                <mat-label class="matlabel">
                  <div class="flex-container">
                    <div class="label">Floor</div>
                    <select class="ipcss widthappt" (ngModelChange)="changefloorvalue($event)" [(ngModel)]="floorvalue">
                      <option *ngFor="let floorcheck of floorarray" [value]="floorcheck">{{ floorcheck }}</option>
                    </select>
                  </div>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                <mat-label class="matlabel">Filter by<br>
                  <select disableOptionCentering class="ipcss widthappt" (change)="Filterbydata(selectvalue)"
                    [(ngModel)]="selectvalue" >
                    <option value="mrno">Mr no</option>
                    <option value="name">Name</option>
                    <option value="location">Location</option>
                    <option value="date">Date</option>
                  </select>
                </mat-label>
              </div>
           
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="mrnoflag">
                <mat-label class="matlabel">MR No<br>
                  <input type="text" class="ipcss inpat_width" [(ngModel)]="mrno" matInput (keyup)="getdatavaluemrno($event)"
                     />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="nameflag">
                <mat-label class="matlabel">Name<br>
                  <input type="text" class="ipcss inpat_width" [(ngModel)]="name" matInput (blur)="nambebased()"  (keyup)="getdatavalue($event)"
                     />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="locationflag">
                <mat-label class="matlabel">Location<br>
                  <input type="text" class="ipcss inpat_width" [(ngModel)]="location"  matInput  (keyup)="getdatavaluelocation($event)"
                     />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style="width: fit-content;margin-top: -23px;" *ngIf="fdateflag">
                <mat-label class="matlabel"><br></mat-label>
                <div class="full-input">
                  <mat-label class="matlabel">From : &nbsp;</mat-label>
                  <input type="date"  (change)="selectFromDate(fromDate)" class="ipcss" id="appt_date" [(ngModel)]="fromDate" #matInput
                    max="{{currentMaxDate}}"  >
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 padding_right" style="width: fit-content;margin-top: -23px;" *ngIf="todateflag">
                <mat-label class="matlabel"><br></mat-label>
                <div class="full-input">
                  <mat-label class="matlabel">To : &nbsp;</mat-label>
                  <input type="date" class="ipcss" id="appt_date" (change)="selectToDate(toDate)" [(ngModel)]="toDate"
                    max="{{currentMaxDate}}"  matInput >
                </div>
              </div>
              <div class="col-1 d-flex justify-content-center my-auto" style="margin-top: 26px !important;margin-left: -33px !important"  *ngIf="nameflag || locationflag || todateflag">
                <mat-label class="matlabel"><br></mat-label>
                <a (click)="filtercheck()">
                  <img src="../../../assets/ui_icons/search_icon_new.svg" width='18px' height="auto" class="topvalue" /></a>
              </div>
             
              <div class="col-12 col-sm-6 col-md-2 col-lg-1 col-xl-1" style="text-align: right;margin-top: 24px !important;">
                <p class="textformat"> Count: <strong style="font-weight: bold;font-size: 18px;">{{this.count}}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tabular_format">
          <p class="nodata" *ngIf="patientList.length == 0">No appointment(s) found</p>
          <table *ngIf="patientList.length" mdbTable datatable [dtOptions]="dtOptions"
            class="table table-nowrap table-sm dataTable">
            <thead>
              <tr>
                <th>Image</th>
                <th>Inpatient id</th>
                <th style="text-align: center !important;">Patient name</th>
                <th>MR no</th>
                <th>Admitted on</th>
                <th>Admitted time</th>
                <th>Ward</th>
                <th>MLC case</th>
                <th style="width: 5%;">                  
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let person of patientList; let i = index"
                (click)='view_patient(person.inpatient_id,person.mobile,person.client_reg_id,person,person.first_name,person.middle_name,person.last_name,person.relation_id,person.relationship_name)'>
                <td><img src="{{person.profile_image}}" class="img-fluid z-depth-1 rounded-circle mr-1 profileimage"></td>
                <td style="font-size: 12px;">{{ person.hosp_inp_id }}</td>
                <td style="font-size: 12px;text-align: left !important;">{{ person.patient_name }}</td>
                <td style="font-size: 12px;">{{ person.patient_id }}</td>
                <td style="font-size: 12px;">{{ person.admit_date }}</td>
                <td style="font-size: 12px;">{{ person.admit_time }}</td>
                <td style="font-size: 12px;text-align: left;padding-left: 50px;">{{ person.ward_text }}</td>
                <td style="font-size: 12px;text-align: left;padding-left: 50px;">{{ person.mlc }}</td>    
                <td style="font-size: 12px;width: 5%;">          
                  <mdb-checkbox [default]="true" [(ngModel)]="person.checked" name="list_name"
                    value="{{person.inpatient_id}}" (change)="isInpatSelected(person)" [disabled]="person.disflag">   
                  </mdb-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="graphicalformat">
          <div class="row">
            <div class="col-2 col-md-3 col-sm-4 col-lg-3 col-xl-2" *ngFor="let person of patientList" style="text-align: center;">
              <div
                (click)='view_patient(person.inpatient_id,person.mobile,person.client_reg_id,person,person.first_name,person.middle_name,person.last_name,person.relation_id,person.relationship_name)'>
                <div class="bed_bed_no">{{person.patient_name}}</div>
                <div class="bed_bed_no1">{{person.bed_no}}</div>
                <div><img src="{{person.bed_img}}" class="bed_img">
                </div>
                <div class="bed_bed_name d-flex justify-content-center">
                  {{person.ward_text}}</div>
                <div class="bed_bed_name1 d-flex justify-content-center">
                  {{person.hosp_inp_id}}</div>
                <div class="bed_bed_name2 d-flex justify-content-center">
                  {{person.admit_date}}</div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="graph_defaultimg">
          <div class="row content_box">
            <div class="col-2 col-md-3 col-sm-4 col-lg-2 col-xl-2" *ngFor="let person of patientList" style="text-align: center;padding:5px;width:20%;">
              <div class="container">
                <div class="card_box">
                    <div class="card-inner" style="--clr:#fff;">
                        <div class="box">
                            <div class="imgBox">
                              <div class="detailbox">
                                <mdb-checkbox [default]="true" [(ngModel)]="person.checked" name="list_name" style="position: absolute;top:3px;right:5px;" 
                                  value="{{person.inpatient_id}}" (change)="isInpatSelected(person)" [disabled]="person.disflag">
                                </mdb-checkbox>
                                <div class="row">
                                  <div class="col-4">
                                    <div>
                                      <img src="{{person.profile_image}}" class="cycling" />
                                    </div>
                                  </div>
                                  <div class="col-8" >
                                    <p style="font-weight: 600;text-align:left;display:flex">{{person.patient_name}}
                                    </p>
                                    <p style="text-align:left">{{person.patient_id}}</p>
                                   </div>
                                </div>
                                <div class="row">
                                  <div class="col-12">
                                    <div class="row">
                                      <div class="col-6" style="text-align: left;margin-left: 2px !important;">{{person.gender_age}}</div>
                                      <div class="col-5" style="text-align: right;margin-left: 2px !important;">{{person.hosp_inp_id}}</div>
                                    </div>
                                  </div>
                                </div>
                                <div style="text-align: left;padding-left:3px;">
                                  <p>{{person.locationcheck}}, {{person.districtcheck}}</p>  
                                </div>
                                <div style="text-align: left;padding-left:3px">
                                  {{person.doctor_name}}
                                </div>

                                <div>
                                <div style="font-weight: 600;text-align: left;padding-left:3px">
                                  {{person.ward_text}}
                                  </div>
                                </div>
                              </div>
                             
                              <div style="text-align: left;margin-left:25px">
                                <table>
                                  <tr>
                                    <td>ADT:</td>
                                    <td>{{person.admit_date}} <br> {{person.admit_time}}</td>
                                  </tr>
                                </table>
                               <!-- <p> 
                                ADT: {{person.admit_date}}<br>
                                <span>{{person.admit_time}}</span>
                               </p> -->
                               
                              </div>
                            </div>
                            <div class="icon" (click)='view_patient(person.inpatient_id,person.mobile,person.client_reg_id,person,person.first_name,person.middle_name,person.last_name,person.relation_id,person.relationship_name)'>
                                <a class="iconBox" >
                                   <span class="material-symbols-outlined" >
                                    <img src="../../../assets/img/Page_icon/visibility.svg" style="width:100%">
                                   </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
