import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { Date_Formate, Time_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { CommonDataService } from '../../providers/common-data.service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-diag-test-count-report',
  templateUrl: './diag-test-count-report.component.html',
  styleUrls: ['./diag-test-count-report.component.scss']
})
export class DiagTestCountReportComponent implements OnInit {
  @ViewChild('tbltest') tbltest: ElementRef;
  @ViewChild('tblcount') tblcount: ElementRef;
  @ViewChild('tblrevenue') tblrevenue: ElementRef;
  @ViewChild('hospitalwithnamelogo') hospitalwithnamelogo: ElementRef;
  public Pagetype:string;
  public forcoverdiv: boolean = true;
  public backbutton: boolean = false;
  public selectedDuration:string;
  public fromDate:string;
  public toDate:string;
  public currentDate:string;
  public ishide: boolean = false;
  public hasData: boolean;
  public hasDatacoount:boolean;
  public hasDatarev: boolean;
  public print_template;
  public hospital_logo;
  public printlogostyle;
  public hosp_name;
  public hosp_addr;
  public hosp_csz;
  public hosp_tel;
  public header = [];
  public userInfo;
  public currenttime;
  public presentdate;
  public reportType;
  public reporttype;
  public url:string;
  public diag_center_id;
  public test_data=[];
  public count_data=[];
  public hsp_id;
  public categoryarray=[];
  public cat_desc="All";
  public revenue_data=[];
  public revenuetotal:any=0.00

  constructor(public gservice: CommonDataService, public http: Http,public toastr: ToastrService) {
    if(this.reporttype == 'DTR'){
      this.hasData = true;
    }else if(this.reporttype == 'DTCR'){
      this.hasDatacoount=true;
    }else if(this.reporttype == 'revenue'){
      this.hasDatarev=true
    }
     
  }

  ngOnInit(): void {
    this.header=["Id","Pay type id","MR NO","Name","Refund amount","Bank","IFC code","Account no","Created date","Reason","Type","Transation id ","Status"]
    this.userInfo = Helper_Class.getInfo();
    this.hospital_logo = ipaddress.Ip_with_img_address + this.userInfo.bill_print_logo;
    this.print_template = this.userInfo.bill_print_template;
    this.printlogostyle = this.userInfo.bill_print_logo_style;
    this.reportType = Helper_Class.getReportType();
    this.diag_center_id=Helper_Class.getInfo().hospitals[0].diag_centre_id;
    this.selectedDuration="0"
    // if(this.userInfo.user_type == "diagnosis"){
    //   this.hsp_id= Helper_Class.getInfo().hospitals[0].hosp_id
    // }else{
    //   this.hsp_id=  Helper_Class.getInfo().hptl_clinic_id
    // }
    this.hsp_id= Helper_Class.getInfo().hospitals[0].hptl_clinic_id

    if(this.reportType =="DTR"){
      this.Pagetype="Diagnosis test report";
      this.reporttype="Diagnosis test";
      this.url="misr/gdtr";
      this.test_data=[];
      this.header=[
       "Name",
       "Test name",
       "Fee",
       "Quantity",
      "Amount",
       "Concession",
       " After concesstion"
      ]
    } else if(this.reportType =="DTCR"){
      this.Pagetype="Diagnosis test count report";
      this.reporttype="Diagnosis test count";
      this.url="misr/gdtcr";
      this.count_data=[];
      this.header=[
        "Test name","Count",
      ]

    } else if(this.reportType == "revenue"){
      this.Pagetype="Revenue report";
      this.reporttype="Revenue report";
      this.url='misr/ghrr';
      this.revenue_data=[];
      this.header=[
        "Bill no",
        "Finalized Date",
            "Charge Head",
              "Description",
               " Fee",
                "Discount",
              " Amount",
      ]
    }
    this.get_category();
    var admdata = Helper_Class.getDurationData();
    if(admdata!= undefined && admdata.from_date != undefined) {
      this.currentDate = admdata.from_date;
      this.fromDate = admdata.from_date;
      this.toDate = admdata.to_date;
      if(admdata.duration == "Weekly") {
        this.selectedDuration = "7";
      }
      
      this.get_data();

    } else {
      this.getCurrentDate();
    }
    // this.getCurrentDate();
  }

  get_category(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/ghcc', { hptl_clinic_id:this.hsp_id

    },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("category_data ="+JSON.stringify(obj))
          this.categoryarray.push({
            category_id:"All",
            category_desc:"All"
          })
          obj.charge_categories.forEach(categoryarray =>{
            this.categoryarray.push({
              category_id:categoryarray.category_id,
              category_desc:categoryarray.category_desc
            })
          }
          )
          
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log(JSON.stringify(obj))
          this.currentDate = obj.current_date;
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          this.currenttime = currenttime1[0]
          this.presentdate = obj.current_date;
          console.log('CURRENT_DATE', this.presentdate);
           this.get_data();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  todisplaycoverdiv(){
    this.forcoverdiv = true;
    // this.amountcard = false;
    this.backbutton = false;
    this.ishide = false;
    // this.test_data = [];
    // this.count_data = [];
    // this.reve
    if(this.reporttype == 'DTR'){
      this.hasData = true;
    }else if(this.reporttype == 'DTCR'){
      this.hasDatacoount=true;
    }else if(this.reporttype == 'revenue'){
      this.hasDatarev=true
    }
  }

  updateFromDate() {
    const today = new Date();
    const selectedDuration = parseInt(this.selectedDuration);

    if (!isNaN(selectedDuration)) {
      const fromDate = new Date(today);
      fromDate.setDate(today.getDate() - selectedDuration);
      this.fromDate = fromDate.toISOString().split('T')[0];
      this.toDate = today.toISOString().split('T')[0];
    }
   this.get_data()
  }

  get_data(){
    var send_data;
    if(this.reportType =="DTR"){
      send_data={
        diag_centre_id:this.diag_center_id,
        from_date:this.fromDate,
        to_date:this.toDate
      }
    }
    else if(this.reportType =="DTCR"){
      send_data={
        diag_centre_id:this.diag_center_id,
        from_date:this.fromDate,
        to_date:this.toDate
      }
    }else if(this.reportType =="revenue"){
      send_data={
        hptl_clinic_id:this.hsp_id,
        from_date:this.fromDate,
        to_date:this.toDate,
        category_desc: this.cat_desc,
      }

    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.url, send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj;
          if(this.reportType =="DTR"){
            obj=response.json();
            console.log("sendata = ",JSON.stringify(send_data));
            console.log("DTR =",JSON.stringify(obj));
          if(obj.test_details != undefined){
            if(obj.test_details.length != 0){
              obj.test_details.forEach(test=>{
                var name,test_type,test_name,fee;
                if(test.middle_name != undefined && test.middle_name !=''){
                  name= encrypt_decript.Decript(test.first_name)+" "+encrypt_decript.Decript(test.middle_name)+" "+encrypt_decript.Decript(test.last_name)
                }else if(test.first_name != undefined && test.first_name !='' && test.last_name != undefined && test.last_name !=""){
                  name= encrypt_decript.Decript(test.first_name)+" "+encrypt_decript.Decript(test.last_name)
                }else{
                  name= encrypt_decript.Decript(test.first_name)
                }
                test_type= test.diag_test_type != undefined && test.diag_test_type != ''? test.diag_test_type : "";
                test_name= test.test_name != undefined && test.test_name != ''? test.test_name : "";
                test.fee= test.fee != undefined && test.fee != ''? test.fee : 0.00;
                test.quantity= test.quantity != undefined && test.quantity != ''? test.quantity : 0;
                test.amount= test.amount != undefined && test.amount != ''? test.amount : 0;
                test.concession= test.concession != undefined && test.concession != ''? test.concession : 0.00;
                test.amt_aft_conc= test.amt_aft_conc != undefined && test.amt_aft_conc != ''? test.fee : 0.00;
                this.test_data.push({
                  client_reg_id:test.client_reg_id,
                  name:name,
                  test_type:test_type,
                  test_name:test_name,
                  fee:test.fee,
                  quantity:test.quantity,
                  amount:test.amount,
                  concession:test.concession,
                  amt_aft_conc:test.amt_aft_conc

                })
              })
              this.forcoverdiv = false;
              this.backbutton = true; 
              this.ishide = true;
              this.hasData = false;
            }else{
              this.test_data=[];
              this.hasData = true;
            }
          }
          }
          else if(this.reportType =="DTCR"){
            obj= response.json();
            console.log("sendata = ",JSON.stringify(send_data));
            console.log("DTCR =",JSON.stringify(obj));
            this.count_data=[];
            if(obj.test_count != undefined){
              if(obj.test_count.length != 0){
                obj.test_count.forEach(count=>{
                  // {"test_name":"Blood Glucose - R","count":"2381"}
                  var test_name,count;
                  test_name= count.test_name != undefined && count.test_name != '' ? count.test_name: '';
                  count= count.count != undefined && count.count != '' ? count.count: 0;
                  this.count_data.push({
                    test_name:test_name,
                    count:count
                  })
                })
                this.forcoverdiv = false;
                this.backbutton = true; 
                this.ishide = true;
                this.hasDatacoount = false;
                console.log("DTCR =",JSON.stringify(this.count_data));
              }else{
                this.count_data=[];
                this.hasDatacoount = true;
              }
            }

          }else if(this.reportType =="revenue"){
            obj=response.json();
            console.log("sendata = ",JSON.stringify(send_data));
            console.log("revenue =",JSON.stringify(obj));
            this.revenue_data=[];
            if(obj.total_revenue != undefined){
              this.revenuetotal=obj.total_revenue != undefined && obj.total_revenue != '' ? parseFloat(obj.total_revenue).toFixed(2): 0.00;
            }
            if(obj.revenue_details != undefined){
              if(obj.revenue_details.length != 0){
                obj.revenue_details.forEach(revenue=>{
                  // {"test_name":"Blood Glucose - R","count":"2381"}
                  
                  revenue.settled_date= revenue.settled_date != undefined && revenue.settled_date != '' ? Date_Formate(revenue.settled_date): '';
                  revenue.settled_time= revenue.settled_time != undefined && revenue.settled_time != '' ? Time_Formate(revenue.settled_time): '';
                  revenue.concession= revenue.concession != undefined && revenue.concession != '' ? revenue.concession: 0.00;
                  revenue.fee= revenue.fee != undefined && revenue.fee != '' ?  revenue.fee: 0.00;
                  revenue.fee_before_concession= revenue.fee_before_concession != undefined && revenue.fee_before_concession != '' ?  revenue.fee_before_concession: 0.00;
                  
                  this.revenue_data.push({
                    billing_id: revenue.billing_id,
                    settled_date: revenue.settled_date +" "+revenue.settled_time,
                    charge_desc: revenue.charge_desc,
                    fee_before_concession: parseFloat(revenue.fee_before_concession).toFixed(2),
                    concession: parseFloat(revenue.concession).toFixed(2),
                    fee: parseFloat(revenue.fee).toFixed(2),
                    category_desc: revenue.category_desc,
                  })
                })
                this.forcoverdiv = false;
                this.backbutton = true; 
                this.ishide = true;
                this.hasDatarev = false;
                console.log("DTCR =",JSON.stringify(this.count_data));
              }else{
                this.revenue_data=[];
                this.hasDatarev = true;
              }
            }


          }
        },error=>{
        })

  }

  print_area(){
    let tbl,printContents,popupWin,logo,hospitaldetails;
    if(this.reportType == 'DTR'){
      printContents = this.tbltest.nativeElement.innerHTML;
    }
    else if(this.reportType == 'DTCR'){
      printContents = this.tblcount.nativeElement.innerHTML;
    }
    else if(this.reportType == 'revenue'){
      printContents = this.tblrevenue.nativeElement.innerHTML;
    } 
    logo = document.getElementById('printimage').innerHTML;
    hospitaldetails = this.hospitalwithnamelogo.nativeElement.innerHTML;

    if(this.print_template != undefined && this.print_template == "banner"){
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`

  <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
         width: 90%;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:2% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
     
      <table style="border:'0';width: 95%;margin-left: auto;margin-right: auto;">  
      <thead>
      <tr>
      ${logo}
      </tr>
      </thead>             
         <tfoot> 
          <tr>  

           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 

         <tbody>  
           <tr>  
             <td width="100%"> 
            
           
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
   </body>
    </html>`
      );

      popupWin.document.close();
    }else if(this.print_template != undefined && this.print_template == "logowithname"){
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();


      popupWin.document.write(`
  
      <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 90%;;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
         width: 100%;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:2% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
      <table style="border:'0';width: 95%;margin-left: auto;margin-right: auto;">  
      <thead>
      <tr>
      ${logo}
      </tr>
      <div>
      ${hospitaldetails}
      </div>
      </thead>             
         <tfoot> 
          <tr>  

           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 

         <tbody>  
           <tr>  
             <td width="100%"> 
            
           
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
   </body>
    </html>
   

    `);

      popupWin.document.close();
    }else{
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();


      popupWin.document.write(`

  <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 90%;;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
        width: 100%;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:2% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
     
      <table style="border:'0';width: 95%;margin-left: auto;margin-right: auto;">  
      <thead>
      <tr>
      <td><div style="height: 130px;"></div></td>    
      </tr>
      </thead>             
         <tfoot> 
          <tr>  

           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 

         <tbody>  
           <tr>  
             <td width="100%"> 
            
           
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
      
   </body>
    </html>`
      );

      popupWin.document.close();

    }
  }

  getExcelData(type) {
    if (this.test_data.length != 0 && this.reportType == 'DTR') {
      var test_array=[];
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Test report');
      worksheet.getColumn(1).width = 10;
      worksheet.getColumn(2).width = 30;
      worksheet.getColumn(3).width = 20;
      worksheet.getColumn(4).width = 15;  
      worksheet.getColumn(5).width = 20;
      let headerRow = worksheet.addRow(this.header);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
      })
      this.test_data.forEach(test=>{
        test_array.push({
                  name:test.name,
                  test_name:test.test_name,
                  fee:test.fee,
                  quantity:test.quantity,
                  amount:test.amount,
                  concession:test.concession,
                  amt_aft_conc:test.amt_aft_conc
        })
      })
      for (let x1 of test_array) {
        let x2 = Object.keys(x1);
        let temp = []
        for (let y of x2) {
          temp.push(x1[y])
        }
        let chilrow = worksheet.addRow(temp)
        let salesamt = chilrow.getCell(5);
        salesamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
      }
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, "Test report" + '-' + new Date().valueOf() + '.xlsx');
      });
  }else if(this.count_data.length != 0 && this.reportType == 'DTCR'){
    var count_array=[];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Test count report');
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 15;  
    worksheet.getColumn(5).width = 20;
    let headerRow = worksheet.addRow(this.header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })
    this.count_data.forEach(count=>{
      count_array.push({
        test_name:count.test_name,
        count:count.count
      })
    })
    for (let x1 of count_array) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      let chilrow = worksheet.addRow(temp)
      let salesamt = chilrow.getCell(5);
      salesamt.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "Test count" + '-' + new Date().valueOf() + '.xlsx');
    });

  }else if(this.revenue_data.length != 0 && this.reportType == 'revenue'){
    var revenuearray=[];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Revenue report');
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 15;  
    worksheet.getColumn(5).width = 20;
    let headerRow = worksheet.addRow(this.header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })
    this.revenue_data.forEach(revenue=>{
      revenuearray.push({
      billing_id: revenue.billing_id,
      settled_date: revenue.settled_date,
      charge_desc: revenue.charge_desc,
      category_desc: revenue.category_desc,
      fee_before_concession: parseFloat(revenue.fee_before_concession).toFixed(2),
      concession: parseFloat(revenue.concession).toFixed(2),
      fee: parseFloat(revenue.fee).toFixed(2),
      })
    })
    for (let x1 of revenuearray) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      let chilrow = worksheet.addRow(temp)
      let salesamt = chilrow.getCell(5);
      salesamt.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
    }

    var revenuedata=['','','','','','Total',this.revenuetotal]
    let finalrow = worksheet.addRow(revenuedata);
    let totalval = finalrow.getCell(8);
    totalval.font = {
      bold:true,
      color:{argb: '000000'},
      size:12
    }
    let revenuamt = finalrow.getCell(9);
    revenuamt.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }
    revenuamt.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }


    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "Revenue report" + '-' + new Date().valueOf() + '.xlsx');
    });

  }
  }


}
