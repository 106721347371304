import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDataService } from '../../../app/providers/common-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from '../../../assets/js/Message_data';
import { Helper_Class } from '../../helper_class';
import { Date_Formate } from '../../../assets/js/common.js';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import moment from 'moment';

import { ipaddress } from '../../ipaddress'; import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
declare var $: any;
import { ReceptAppCreatePageService } from './recept-app-create-page.service';
// import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
// import { ScanDetected } from 'ngx-scanner-detection';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-recept-app-create-page',
  templateUrl: './recept-app-create-page.component.html',
  styleUrls: ['./recept-app-create-page.component.css']
})
export class ReceptAppCreatePageComponent implements OnInit {
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef;
  @ViewChild('printtoken') printtoken: ElementRef;
  @ViewChild('idprint') idprint: ElementRef;
  public temp_read;
  public mrno;
  public cbg_txt;
  public rr_txt;
  public bmr;
  public diocolorstyle;
  public siscolorstyle;
  public colorstyle;
  public test_flag: boolean = false;
  public nextvisittestarray = [];
  public center_type;
  public clntSymptomHtml;
  public basic_detailflag: boolean = false;
  public previousvisits;
  public address;
  public spo2colorstyle:boolean;
  public previousflag: boolean = false;
  public ref_source;
  pulsecolorstyle:boolean;
  rrcolorstyle:boolean;
  cbgcolorstyle:boolean;
  public ref_sourcearray = [];
  public booktime = [];
  public somePlaceholder;
  public purposeofvisit;
  public visit_purpose;

  public timeslotflag: boolean;
  public clientname;
  public clntSal_id;
  public covidFlag;
  public updateFlag: boolean;
  public filterType;
  public patientFName;
  public clientList = [];
  public clntFirstName;
  public clntMiddleName;
  public clntLastName;
  public clientId;
  public userTypeFlag: boolean;
  public existUserFlag: boolean;
  public searchFlag: boolean;
  public bookFlag: boolean;
  public availableFlag: boolean;
  public nonAvailableFlag: boolean;
  public appType;
  public filter;
  public referredDoctorListData;
  public sendAppType;
  public readonly: boolean;
  public clntLocationList: any = [];
  public clntCityList: any = [];
  public clntStateList: any = [];
  public clntCountryList: any = [];
  public referredDoctorList: any = [];
  public clntSalList: any = [];
  public splList: any = [];
  public doctorListData: any = [];
  public doctorList: any = [];
  public bookTime: any = [];
  public doctorDetailData: any = [];
  public docUnavailableData: any = [];
  public hospitalListData: any = [];
  public visitingSessionData: any = [];
  public homecareTimeList: any = [];
  public homeSessionTableData: any = [];
  public homecareTableData: any = [];
  public homecareSessionData: any = [];
  public homeApps: any = [];
  public billAddList: any = [];

  public patientList: any = [];
  public filteredArr: any = [];
  public ipaddress;
  public sendMiddleName;
  public patientName;

  public sendDoctorDetailData;
  public timeOfAppt;
  public dataForApptBook;
  public sendUpdateData;
  public clntAddress1: string = null;
  public clntAddress2: string = null;
  public clntLocation: string = null;
  public clntCity: string = null;
  public clntState: string = null;
  public clntCountry: string = null;
  public clntLocationId: string = null;
  public clntCityId: string = null;
  public clntStateId: string = null;
  public clntCountryId: string = null;
  public clntZipcode: string = null;
  public referredDoctor: string = null;
  public clntContNumber: string;
  public clntEmergNumber: number;
  public clntEmergNumber1: number;
  public clntSymptom: string;
  public clntDOB = null;
  public relId: string;
  public clntSal: string;
  public BP01: string;
  public BP02: string;
  public pulse: string;
  public spo2: string;
  public locationUrl: string;
  public locationNameByUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public doctorDetailUrl: string;
  public currentDateUrl: string;
  public apptBookUrl: string;
  public salUrl: string;
  public splUrl: string;
  public doctorUrl: string;

  public hospId: string;
  public clientRegId: string;
  public relationId: string;
  public subRelId: string;
  public clntGender: string;
  public clntAge;
  public clntFatherOrGuardName: string;
  public clientType: string;
  public doctorProfileImg: string;
  public tempFee: string;
  public typeorTime: string;
  public bloodPressureFlag;
  public booktimeMorn: any = [];
  public booktimeNoon: any = [];
  public booktimeEve: any = [];
  public hospitalId: string;
  public hosptlId: string;
  public appTypeTokenFlag: Boolean;
  public appTypeTimeFlag: Boolean;
  public apptType: string;
  public hospSltLocation: string;
  public hospSltFee: string;
  public currentDate: string;
  public currentTime: string;
  public homecare: string;
  public visitSession;
  public visitTime;
  public tokenorTime;
  public timeList;
  public homecareAppDate;
  public appointmentDate;
  public hospitalName;
  public height;
  public hmeasure: string;
  public weight;
  public wmeasure: string;
  public temperature: string;
  public bmi;
  public encheight;
  public encweight;
  public hospName;
  public splName;
  public splId;
  public doctorId;
  public currentDatetime;
  public getDate;
  public currentYear;
  public currentMaxDate;
  public appCurrentDate
  public userInfo;
  public ageRead: boolean = false;
  public ageUrl;
  public timeFlag: boolean;
  public symptomListData: any = [];
  public symptomList: any = [];
  public symptomsMasterList: any = [];
  public selectedSymptoms: any = [];
  public dobDisabledFlag;
  public maxLength: number;
  public retrvDisabledFlag: boolean;
  public newSymptoms: any = [];
  public insurerList: any = [];
  public payTypeList: any = [];
  public ageDisabledFlag: boolean;
  public clientTypeFlag: boolean;
  public apptTypeFlag: boolean;
  public zipcode_url;
  public book_flag: boolean = false;
  public pay_flag: boolean = true;
  public insurFlag: boolean = true;
  public transactionFlag: boolean = true;
  public cardFlag: boolean = true;
  public chequeFlag: boolean = true;
  public payType: string;
  public paydesc: string;
  public transactionId: string;
  public insurerId: string;
  public cardNumber: string;
  public cardHolderName: string;
  public bankName: string;
  private billUrl: string;
  public appclass: boolean = false;
  public appdisable: boolean = false;
  public searchTypeFlag: boolean;
  private nodob: boolean = false;
  public searchby: string;
  public searchtext: string;

  public mrNumber: string;
  public styleOne: boolean = true;
  public profileImg;

  public patientid: boolean;
  public searchTypeFlagid: boolean;
  public searchTypeFlagmoblile: boolean;
  public name = "1";
  public mobile = "1";
  public patientidflag = "1";
  public typeflag;
  public dateformate
  public aadharNumber;
  public fileType: string;
  public camImage;
  public base64;

  public imgUrl = "../../assets/img/default.jpg";

  public camImage2;
  public base64_2;
  public imgUrl_2: string;
  private barcodeimage: string;

  public cvs_txt;
  public rstxt;
  public cns_txt;
  public abd_txt;
  public pr_txt;

  dtOptions: DataTables.Settings = {};

  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';

  elementType: 'svg';
  lineColor = '#000000';
  width = 2;
  barcodeheight = 50;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;
  whatsappNumber: any;
  imgStr: any;
  public currentLimit = 1;
  public hidePattype: boolean;
  heightForm: FormGroup;
  myForm: FormGroup;
  public time_flag: boolean;
  public saluationflag:boolean;
  public visitdisable: boolean = false;
  public visitTypeFlag:boolean;
  public visitType;
  public docType:string;
  public physioUrl:string;
  public tockennumber;
  public doctorName;

  constructor(public frontdeskservice: MenuViewService, private formBuilder: FormBuilder, public datepipe: DatePipe, public gservice: CommonDataService,
    public http: HttpClient, public toastr: ToastrService, private receptAppService: ReceptAppCreatePageService, private cdRef: ChangeDetectorRef) {
    this.ageUrl = ipaddress.getIp + "adm/ddif";
    localStorage.setItem("searchbyhospital", "search_hospital");
    this.filterType = "0";
    this.searchFlag = false;
    this.appType = "New";
    this.ageRead = false;
    this.userTypeFlag = false;
    this.clientType = "Existing";
    this.existUserFlag = true;
    this.appTypeTokenFlag = true;
    this.hmeasure = "cms";
    this.wmeasure = "kgs";
    this.locationUrl = ipaddress.getIp + "usercontroller/loc";
    this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.cityUrl = ipaddress.getIp + "gen/city";
    this.stateUrl = ipaddress.getIp + "gen/state";
    this.countryUrl = ipaddress.getIp + "gen/count";
    this.salUrl = ipaddress.getIp + "gen/sal";
    this.splUrl = ipaddress.getIp + "adm/gsplbyhptl";
    this.doctorUrl = ipaddress.getIp + "search/docbyhs";
    this.physioUrl = ipaddress.getIp + "search/npbyhs";
    this.apptBookUrl = ipaddress.getIp + "appointment/rapp";
    this.billUrl = ipaddress.getIp + "bill/savebill/";
    this.doctorDetailUrl = ipaddress.getIp + "search/doctorbyid/";
    this.currentDateUrl = ipaddress.getIp + "adm/curdate";
    this.zipcode_url = ipaddress.getIp + "usercontroller/locbyzipcode";
    this.ipaddress = Helper_Class.getIPAddress();
  }
  @ViewChild('myInputField') myInputField!: ElementRef;

  ngAfterViewInit() {
    this.myInputField.nativeElement.focus();
  }
  ngOnInit() {
    this.temp_read="F"
    console.log("helper --" + JSON.stringify(Helper_Class.getmangamentlist_doctor()))
    this.hmeasure = 'cms';
    this.wmeasure = 'kgs';
    this.profileImg = "../../assets/img/default.jpg";
    this.hmeasure = "cms";
    this.wmeasure = "kgs";
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.userInfo = Helper_Class.getInfo()
    this.filter == "patientid";
    if (this.userInfo.doc_app_bill_create == "1") {
      this.pay_flag = false;
    }
    if (this.userInfo.reg_clnt_app_booking == "1") {
      this.readonly = false;
      this.hidePattype = false;
      this.ageRead = false;

    } else {
      this.readonly = true;
      this.hidePattype = true;
      this.ageRead = true;
    }
    this.docType = FrontDesk_Helper.getreceptionistcreatepage().docType;

    if(this.docType =='physio') {
      this.getPhysiotherapists();
    } else if(this.docType == 'doctor') {
      this.getSpecializations();
    }
    this.clientType = "Existing";
    this.bookFlag = true;
    this.searchTypeFlag = true;
    this.clntCountryId = this.userInfo.hospitals[0].country;
    this.clntStateId = this.userInfo.hospitals[0].state;
    this.clntCityId = this.userInfo.hospitals[0].city;
    this.clntLocationId = this.userInfo.hospitals[0].location;
    this.hospitalId = this.userInfo.hptl_clinic_id;
    this.appclass = true;

    console.log("chck data------------" + JSON.stringify(FrontDesk_Helper.getreceptionistcreatepage()))
    if (FrontDesk_Helper.getreceptionistcreatepage() != undefined) {
      if (FrontDesk_Helper.getreceptionistcreatepage().appType != undefined) {
        if (FrontDesk_Helper.getreceptionistcreatepage().appType == "hospital") {
          this.center_type = FrontDesk_Helper.getreceptionistcreatepage().appType
        } else if (FrontDesk_Helper.getreceptionistcreatepage().appType == "diagnosis") {
          this.center_type = FrontDesk_Helper.getreceptionistcreatepage().appType
        }
      }
    }
    this.getpurposeofvisit();
    this.searchType('patientid');

    //this.changeType('client', 1, 'no');
    this.changeType('app', 1, 'yes');
    this.changeType('visit', 1, 'yes');
    // this.getSpecializations();
    this.getCurrentDate();
    this.getSalutations();
    this.getPaymentType();
    //this.getPatientList();   


    this.tableFilter(this.patientName); 
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 500,
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",

        searchPlaceholder: "Search patients with Name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 mb-3 ">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.heightForm = this.formBuilder.group({
      height: ['', [Validators.pattern(/^\d+(\.\d{0,1})?$/)]],
    });
    this.saluationflag=false;
  }

  validate(event: any) {
    const inputValue = event.target.value;
    const charCode = event.key.charCodeAt(0);

    // Allow digits, a single dot, backspace, and delete
    if (
      (charCode >= 48 && charCode <= 57) || // Digits
      charCode === 46 || // Dot
      event.key === 'Backspace' || // Backspace key
      event.key === 'Delete' // Delete key
    ) {
      // Check if a dot already exists
      const dotIndex = inputValue.indexOf('.');

      if (charCode === 46) {
        if (dotIndex !== -1 || inputValue === '') {
          event.preventDefault(); // Prevent typing an additional dot or starting with a dot
        }
      } else if (dotIndex !== -1) {
        // If there's a dot, allow backspace and delete to remove the digit after the dot
        if (event.key === 'Backspace' || event.key === 'Delete') {
          const decimalPart = inputValue.substr(dotIndex + 2);
          if (decimalPart.length === 1) {
            event.preventDefault();
            event.target.value = inputValue.substr(0, dotIndex); // Remove the digit after the dot
          }
        } else if (inputValue.substr(dotIndex + 2).length >= 1) {
          event.preventDefault(); // Prevent typing more than one digit after the dot
        }
      }
    } else {
      event.preventDefault(); // Prevent typing non-digit and non-dot characters
    }
  }

  getPhysiotherapists(){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.physioUrl, { hptl_clinic_id: this.userInfo.hptl_clinic_id,type:'physio' },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data)).physio_nurses;
          this.doctorList=[];
          for (var j = 0; j < dataval.length; j++) {
            var docname = "";
            docname = dataval[j].middle_name != undefined ? dataval[j].first_name + " " + dataval[j].middle_name + " " + dataval[j].last_name : dataval[j].first_name + " " + dataval[j].last_name;

            this.doctorList.push({
              docname: docname,
              prov_id: dataval[j].prov_id
            });
          }

          if(this.doctorList.length !=0) {
            this.doctorId = this.doctorList[0].prov_id;
            this.getDoctorDetails();
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }

  tempcolor(value) {
    if (value > 100) {
      this.colorstyle = true;
    } else {
      this.colorstyle = false;
    }
  }


  diostol(value) {
    if (value > 90 || value < 70) {
      this.diocolorstyle = true;
    } else {
      this.diocolorstyle = false;
    }
  }

  sistol(value) {
    if (value > 160 || value < 110) {
      this.siscolorstyle = true;
    } else {
      this.siscolorstyle = false;
    }
  }

  focusInput() {
    this.patientName = "";
    document.getElementById("filterInput").focus()
  }

  searchType(data) {
    this.searchby = data;
    if (data == "name") {
      this.typeflag = "name";
      this.patientName = "";
      this.searchtext = "search by Name"
      this.getPatientListByName(this.patientName)

    } else if (data == "patientid") {
      this.typeflag = "patientid";
      this.patientName = "";
      this.searchtext = "search by MR No"
      this.patientid = true;
      this.getPatientListByName(this.patientName)

    } else {
      this.typeflag = "mobile";
      this.patientName = "";
      this.searchtext = "search by Mobile";
      this.getPatientListByName(this.patientName)
    }
  }

  changePayType() {
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "2") {
      this.transactionFlag = false;
      this.cardFlag = false;

    }
    else if (this.payType == "3")
      this.insurFlag = false;
    else if (this.payType == "4")
      this.transactionFlag = false;
    else if (this.payType == "5")
      this.transactionFlag = false;
    else if (this.payType == "6")
      this.transactionFlag = false;
    else if (this.payType == "7")
      this.chequeFlag = false;
    else
      this.insurFlag = true;

    if (this.payTypeList.length != 0) {
      for (var i = 0; i < this.payTypeList.length; i++) {
        if (this.payTypeList[i].pay_id == this.payType) {
          this.paydesc = this.payTypeList[i].pay_desc;
        }
      }
    }
  }

  getInsurers() {//Get payment type
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.insurerList = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getPaymentType() {//Get payment type
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.payTypeList = obj.payments;
          this.payType = this.payTypeList[0].pay_id;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  back() {
    this.frontdeskservice.sendMessage("manageappointments");
  }

  fnameToUpper() {
    if (this.clntFirstName != null)
      this.clntFirstName = this.clntFirstName.toLocaleUpperCase();
  }

  mnameToUpper() {
    if (this.clntMiddleName != undefined)
      this.clntMiddleName = this.clntMiddleName.toLocaleUpperCase();
  }

  lnameToUpper() {
    if (this.clntLastName != undefined)
      this.clntLastName = this.clntLastName.toLocaleUpperCase();
  }

  filterByPatients() {//based client select placeholder will be change
    if (this.filter == "name") {
      this.maxLength = 50;
      this.somePlaceholder = "Search by first name";
      this.filterType = "0";
      this.patientFName = "";
      this.clientList = [];
      this.clntFirstName = "";
      this.clntMiddleName = "";
      this.clntLastName = "";

    } else if (this.filter == "mobile") {
      this.maxLength = 10;
      this.somePlaceholder = "Search by mobile number";
      this.filterType = "1";
      this.patientFName = "";
      this.clientList = [];
      this.clntFirstName = "";
      this.clntMiddleName = "";
      this.clntLastName = "";

    } else if (this.filter == "patient_id") {
      this.maxLength = 20;
      this.somePlaceholder = "Search by patient id";
      this.filterType = "2";
      this.patientFName = "";
      this.clientList = [];
      this.clntFirstName = "";
      this.clntMiddleName = "";
      this.clntLastName = "";
    }
  }

  changeName() {
    this.clientList = [];
    this.patientFName = this.patientFName.toLocaleUpperCase();
  }

  patientNameToUpper() {
    if (this.patientFName != undefined) {
      if (this.filter == "name")
        this.patientFName = this.patientFName.toLocaleUpperCase();
    }
  }

  changePatientName(name, flag) {//based client search filters patient  names
    this.clntGender = "Male";
    this.clntFirstName = null;
    this.clntMiddleName = null;
    this.clntLastName = null;
    this.clntAge = "";
    this.clntGender = "";
    this.clntFatherOrGuardName = "";
    this.clntAddress1 = "NA";
    this.clntAddress2 = null;
    this.clntContNumber = null;
    this.aadharNumber = null;
    this.whatsappNumber = null;
    this.clntEmergNumber = null;
    this.clntEmergNumber1 = null;
    this.clntSymptom = "";
    this.clntDOB = null;

    this.height = "";
    this.hmeasure = "cms";
    this.weight = "";
    this.wmeasure = "kgs";
    this.bmi = ""
    this.temperature = "";
    this.bmi;
    this.encheight;
    this.encweight;
    this.hospName = "";
    this.referredDoctor = "";
    var nameval;
    if (flag == true) {
      this.filter = "name";
      nameval = name.first_name;
      this.clntContNumber = name.mobile;

    } else {
      nameval = name;
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/cid', {
      first_name: nameval,
      type: this.filter,
      hptl_clinic_id: Helper_Class.getHospitalId(),
    },
      { headers: headers })
      .subscribe(
        response => {
          this.clientList = [];
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.clients != null && obj.clients.length != 0)
            this.clientList = obj.clients;
          else
            this.toastr.error(Message_data.clientNotFound);

          if (obj.clients.length != 0) {
            this.clientId = this.clientList[0].patient;
            this.changeClient(this.clientList[0].patient);
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });

  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, { country: "IN" }, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        this.currentDate = Date_Formate(dataval.current_date);
        this.homecareAppDate = this.currentDate;
        this.appointmentDate = dataval.current_date;
        this.currentTime = dataval.current_time;//time basiiss
        this.currentDatetime = dataval.current_date;
        this.getDate = dataval.current_date.split('-');
        this.currentYear = this.getDate[0];
        this.currentMaxDate = dataval.current_date;
     
        //this.currentDate = dataval.current_date;
        this.appCurrentDate = this.currentDate;
      },
      error => { });
  }

  changeClient(client) {// get the the client detials based on patient id
    this.clntFirstName = "";
    this.clntMiddleName = undefined;
    this.clntLastName = "";
    for (var i = 0; i < this.clientList.length; i++) {
      if (this.clientList[i].patient == client) {
        this.clntFirstName = (this.clientList[i].first_name);
        this.clntMiddleName = this.clientList[i].middle_name;
        this.clntLastName = this.clientList[i].last_name;
        this.clientRegId = this.clientList[i].client_reg_id;
        this.relationId = this.clientList[i].relation_id;
        this.subRelId = this.clientList[i].sub_rel_id;
      }
    }
    //this.retrieveClient();
  }

  getSpecializations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.splUrl, { hptl_clinic_id: this.userInfo.hptl_clinic_id },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          this.splList = [];
          if (dataval.specializations != null) {
            this.splList = dataval.specializations;
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }

  getDoctorList(e) {
    this.appTypeTokenFlag = true;
    if (e != null)
      document.getElementById("mandatory_specfication").style.borderColor = "#2ca8fe";

    this.splName = e;
    var splname = this.splName;
    for (var i = 0; i < this.splList.length; i++) {
      if (this.splName == this.splList[i].spl_name)
        this.splId = this.splList[i].spl_id;
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.doctorUrl,
      {
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        spl_name: splname,
      },
      { headers: headers }).subscribe(
        data => {
          this.doctorListData = [];
          this.doctorList = [];

          var dataobj = JSON.parse(JSON.stringify(data));
          this.doctorListData = dataobj.doctors;
          if (this.doctorListData != undefined) {
            for (var i = 0; i < this.doctorListData.length; i++) {
              var docname = "";
              docname = this.doctorListData[i].middle_name != undefined ? this.doctorListData[i].first_name + " " + this.doctorListData[i].middle_name + " " + this.doctorListData[i].last_name : this.doctorListData[i].first_name + " " + this.doctorListData[i].last_name;

              this.doctorList.push({
                docname: docname,
                prov_id: this.doctorListData[i].prov_id
              });
              if(this.appType == "Follow-up"){
                var docid = this.doctorListData.filter(doctor => doctor.prov_id === this.doctorId);
                this.doctorId=docid[0].prov_id;
                if(this.previousvisits.length != 0){
                  this.doctorId = this.previousvisits[this.previousvisits.length - 1].doc_reg_id;
                  this.doctorName = this.previousvisits[this.previousvisits.length - 1].doc_name;
                }

              }else{
                this.doctorId = this.doctorList[0].prov_id;
                this.doctorName = this.doctorList[0].docname;
              }

              this.appTypeTokenFlag = true;
              this.getDoctorDetails()
            }
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }

  getCountries(stateid, flag) {
    this.clntCountryList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.countryUrl, { state_id: stateid }, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.countries != null) {
          this.clntCountryList = dataval.countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == this.clntCountryId) {
              this.clntCountry = this.clntCountryList[c].country_desc;
              this.clntCountryId = this.clntCountryList[c].country_id;
              //this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  getStates(city, flag) {
    this.clntStateList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl, { city_id: city },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.states != null) {
            this.clntStateList = dataval.states;
            if (this.clntStateList.length == 1) {
              for (var i = 0; i < this.clntStateList.length; i++) {
                if (this.clntStateList[i].state_id == this.clntStateId) {
                  this.clntStateId = this.clntStateList[i].state_id;
                  this.clntState = this.clntStateList[i].state_desc;
                  this.getCountries(this.clntStateId, '0');
                  break;
                } else {
                  this.clntStateId = this.clntStateList[0].state_id;
                  this.clntState = this.clntStateList[0].state_desc;
                  this.getCountries(this.clntStateId, '0');
                }
              }

            } else {
              this.clntStateId = this.clntStateList[0].state_id;
              this.clntState = this.clntStateList[0].state_desc;
              this.getCountries(this.clntStateId, '0');
            }

          }
        });
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].stage_desc == clnt_state) {
        this.clntStateId = this.clntStateList[i].state_id;
        this.clntState = this.clntStateList[i].stage_desc;
        this.getCountries(this.clntStateId, '0');
      }
    }
  }

  getCities(state, flag) {
    this.clntCityList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, { loc_name: this.clntLocation, zipcode: this.clntZipcode },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.cities != null) {
            this.clntCityList = dataval.cities;
            if (this.clntCityList.length == 1) {
              for (var i = 0; i < this.clntCityList.length; i++) {
                if (this.clntCityList[i].city_id == this.clntCityId) {
                  this.clntCityId = this.clntCityList[i].city_id;
                  this.clntCity = this.clntCityList[i].city_desc;
                  this.getStates(this.clntCityId, 0)

                  break;
                } else {
                  this.clntCityId = this.clntCityList[0].city_id;
                  this.clntCity = this.clntCityList[0].city_desc;
                  this.getStates(this.clntCityId, 0)
                }
              }

            } else {
              this.clntCityId = this.clntCityList[0].city_id;
              this.clntCity = this.clntCityList[0].city_desc;
              this.getStates(this.clntCityId, 0)
            }
          }
        });
  }

  changeCity(clnt_city, flag) {
    this.clntLocationList = [];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].city_desc == clnt_city) {
        this.clntCityId = this.clntCityList[i].district_id;
        this.clntCity = this.clntCityList[i].city_desc;
        this.getStates(this.clntCityId, 0)
      }
    }
  }

  getLocations(city) {
    this.clntLocationList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.locationUrl, { city_id: city },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.locations != null && dataval.locations.length != 0) {
            this.clntLocationList = dataval.locations;
            for (var i = 0; i < this.clntLocationList.length; i++) {
              if (this.clntLocationList[i].location_id == this.clntLocationId) {
                this.clntLocationId = this.clntLocationList[i].location_id;
                this.clntLocation = this.clntLocationList[i].description;
                this.clntZipcode = this.clntLocationList[i].pincode;
                this.clntLocationList = [];
                break;
              }
            }
          }
        });
  }

  zipcode_change() {
    if (this.clntZipcode.length > 4) {
      this.clntLocationList = [];
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.zipcode_url, {
        zipcode: this.clntZipcode,
      }, { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data))
          if (obj.locations != null && obj.locations.length != 0) {
            if (obj.locations.length == 1) {
              this.clntLocationId = obj.locations[0].location_id;
              this.clntLocation = obj.locations[0].description;
              this.clntZipcode = obj.locations[0].pincode;
              this.getCities(this.clntLocation, 0);
            } else {
              this.clntLocationList = obj.locations;
              console.log("../location" + JSON.stringify(this.clntLocationList))
            }
          }
        },
        error => {
          this.toastr.error("Unable to fetch location details, please try later");
        }
      )
    } else {
      this.clntLocationList = [];
    }
  }

  changeLocation(locat_desc) {
    this.clntLocation = locat_desc;
    this.clntLocationList = [];

    if (this.clntLocation != null && this.clntLocation.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameByUrl, {
        name: this.clntLocation,
        //city_id: this.clntCityId,
      }, { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.locations != null && dataval.locations.length != 0)
            this.clntLocationList = dataval.locations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    } else {
      this.clntLocationList = [];
    }
  }

  selectLocation(location) {
    this.clntLocation = location;
    for (var i = 0; i < this.clntLocationList.length; i++) {
      if (this.clntLocationList[i].location_id == location.location_id) {
        this.clntLocationId = this.clntLocationList[i].location_id;
        this.clntLocation = this.clntLocationList[i].description;
        this.clntZipcode = this.clntLocationList[i].pincode;
      }
    }
    this.clntLocationList = [];
    this.getCities(this.clntLocation, '0');
    if (this.clientType == "Existing") {
      this.updateFlag = true;
    }
  }

  getReferral(e) {// Refer doctor list
    this.referredDoctor = e.target.value.toString();
    this.referredDoctorList = [];
    this.referredDoctorListData = [];
    var dname = this.checkData(this.referredDoctor);

    if (dname != "" && dname.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        {
          hospital_id: this.hospId,
          doc_name: dname
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            this.referredDoctorListData = obj.doctors;

            if (this.referredDoctorListData != undefined) {
              for (var i = 0; i < this.referredDoctorListData.length; i++) {
                var docname = "";
                docname = this.referredDoctorListData[i].middle_name != undefined ? this.referredDoctorListData[i].first_name + " " + this.referredDoctorListData[i].middle_name + " " + this.referredDoctorListData[i].last_name : this.referredDoctorListData[i].first_name + " " + this.referredDoctorListData[i].last_name;
                this.referredDoctorList.push(docname);
              }
            }
            this.referredDoctorList = this.referredDoctorList.filter(function (el) {
              return el.toLowerCase().indexOf(dname.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.referredDoctorList = [];
    }
  }

  selectReferredDoctor(item) {
    this.referredDoctor = item;
    this.referredDoctorList = [];
  }

  selectAppointmentDate(appdate) {  
    this.appointmentDate = appdate;
    this.timeList = [];
    if (this.currentDate != Date_Formate(appdate)) {
      var gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
      var get_date = appdate.split('-');
      var year = get_date[2];
      var month = get_date[1];
      var dayval = get_date[0];
      var result = new Date(year + "-" + (month - 1) + "-" + dayval);
      var dayName = gsDayNames[result.getDay()];
      console.log("day num000000000000 "+result.getDay())
      var get_sel_day_num = this.gservice.get_Day_num(dayName);
      console.log("day num000000000000 "+get_sel_day_num+"????????????"+dayName)
    
      var Available_flag_1 = false;
      for (var i = 0; i < this.doctorDetailData.length; i++) {
        if (this.doctorDetailData[i].hospital_id == this.hospitalId) {
          if (this.doctorDetailData[i].day_num == "8" || (get_sel_day_num != 1 && this.doctorDetailData[i].day_num == "9")) {
            Available_flag_1 = true;
          } else {
            if (((new Date(appdate)).getDay() + 1) == this.doctorDetailData[i].day_num) {
              Available_flag_1 = true;
            }
          }
        }
      }
      if (Available_flag_1 == false) {
        this.availableFlag = false;
        this.nonAvailableFlag = true;
      } else {
        this.availableFlag = true;
        this.nonAvailableFlag = false;
      }
      this.addSession(this.hospitalId);
      this.changeVisitSession(this.visitSession, appdate);
      this.availableFunction((appdate), this.currentTime);
    } else {
      this.addSession(this.hospitalId);
      this.changeVisitSession(this.visitSession, appdate);
      this.availableFunction((appdate), this.currentTime);
    }



    this.getDoctorDetails();
  }

  userTypeSelect(e) {
    this.filterByPatients();
    this.filter = "name";
    if (this.clientType == 'New') {
      this.retrvDisabledFlag = false;
      this.readonly = true;
      this.userTypeFlag = true;
      this.existUserFlag = false;
      this.searchFlag = true;
      this.clientRegId = "0";
      this.clntGender = "Male";
      this.clntFirstName = null;
      this.clntMiddleName = null;
      this.clntLastName = null;
      this.clntAge = "";
      this.clntGender = "";
      this.clntFatherOrGuardName = "";
      this.clntAddress1 = "NA";
      this.clntAddress2 = null;
      this.clntContNumber = null;
      this.aadharNumber = null;
      this.whatsappNumber = null;
      this.clntEmergNumber = null;
      this.clntEmergNumber1 = null;
      this.clntSymptom = "";
      this.clntDOB = null;

      this.height = "";
      this.hmeasure = "cms";
      this.weight = "";
      this.wmeasure = "kgs";
      this.bmi = ""
      this.temperature = "";
      this.bmi;
      this.encheight;
      this.encweight;
      this.hospName = "";
      this.referredDoctor = "";
      this.dobDisabledFlag = false;
      this.ageDisabledFlag = false;
      this.clntSal = "select";

      this.clntZipcode = this.userInfo.hospitals[0].zipcode;
      this.clntLocationId = this.userInfo.hospitals[0].location;
      this.clntLocation = this.userInfo.hospitals[0].location_desc;
      this.selectLocation(this.clntLocation)
      this.clntCityId = this.userInfo.hospitals[0].city;
      this.clntCity = this.userInfo.hospitals[0].city_desc;
      this.clntStateId = this.userInfo.hospitals[0].state;
      this.clntState = this.userInfo.hospitals[0].state_desc;
      this.clntCountryId = this.userInfo.hospitals[0].country;
      this.clntCountry = this.userInfo.hospitals[0].country_desc;
    }

    if (this.clientType == "Existing" || this.appType == "Follow-up") {
      this.userTypeFlag = false;
      this.existUserFlag = true;
      this.searchFlag = false;
      this.clntFirstName = null;
      this.clntMiddleName = null;
      this.clntLastName = null;
      this.clntFatherOrGuardName = "";      
      this.clntSymptom = "";
      this.retrvDisabledFlag = false;
      this.dobDisabledFlag = false;
      this.ageDisabledFlag = false;

      this.clntCountryId = this.userInfo.hospitals[0].country;
      this.clntStateId = this.userInfo.hospitals[0].state;
      this.clntCityId = this.userInfo.hospitals[0].city;
      this.clntLocationId = this.userInfo.hospitals[0].location;
      this.clntSal = "select";

    }

    if (this.appType == "Follow-up") {
      this.splId = this.previousvisits[this.previousvisits.length - 1].spl_id;
      this.splName = this.previousvisits[this.previousvisits.length - 1].specialization_name;
    
      //(" 2222222222222222222"+this.doctorId)
      this.getDoctorList(this.splName)
      //

    }
  }

  getSalutations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.salUrl, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj != undefined) {
          for (var i = 0; i < obj.salutations.length; i++) {
            this.clntSalList.push({
              sal_id: obj.salutations[i].salutation_id,
              sal_desc: obj.salutations[i].salutation_desc,
            });
          }
          this.clntSal = "select";
          this.changeSalutation(this.clntSal);
        }
      });
  }

  // retrieveClient(person) {
  //   var get_data;
  //   if (person.sub_rel_id != undefined) {
  //     get_data = {
  //       user_id: person.client_reg_id,
  //       relation_id: person.relation_id,
  //       sub_rel_id: person.sub_rel_id,
  //       country: ipaddress.country_code
  //     };
  //   } else {
  //     get_data = {
  //       user_id: person.client_reg_id,
  //       relation_id: person.relation_id,
  //       country: ipaddress.country_code
  //     };
  //   }

  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp.toString() + 'usercontroller/grclnt/',
  //     get_data,
  //     { headers: headers })
  //     .subscribe(
  //       response => {
  //         FrontDesk_Helper.setClientId(null);
  //         var obj = JSON.parse(JSON.stringify(response));

  //         if (obj.client_id != null) {
  //           FrontDesk_Helper.setClientId(obj.client_id);
  //           // Editable Fields
  //           if (obj.salutation != null) {
  //             for (var i = 0; i < this.clntSalList.length; i++) {
  //               if (this.clntSalList[i].sal_desc == obj.salutation) {
  //                 this.clntSal = this.clntSalList[i].sal_id;
  //               }
  //             }
  //           }
  //           this.clientRegId=person.client_reg_id;
  //           this.relationId=person.relation_id;
  //           this.subRelId=person.sub_rel_id;
  //           this.clntFirstName = obj.first_name != null && (encrypt_decript.Decript(obj.first_name) != "") ? encrypt_decript.Decript(obj.first_name) : "";
  //           this.clntMiddleName = obj.middle_name != null && (encrypt_decript.Decript(obj.middle_name) != "") ? encrypt_decript.Decript(obj.middle_name) : "";
  //           this.clntLastName = obj.last_name != null && (encrypt_decript.Decript(obj.last_name) != "") ? encrypt_decript.Decript(obj.last_name) : "";
  //           this.clntGender = obj.gender != null && (encrypt_decript.Decript(obj.gender) != "") ? encrypt_decript.Decript(obj.gender) : "";
  //           this.clntContNumber = obj.mobile != null && obj.mobile != "" && obj.mobile != undefined && (encrypt_decript.Decript(obj.mobile) != "") ? encrypt_decript.Decript(obj.mobile) : "";
  //           this.clntEmergNumber = obj.emerg_contact1 != null && obj.emerg_contact1 != "" && obj.emerg_contact1 != undefined && (encrypt_decript.Decript(obj.emerg_contact1) != "") ? encrypt_decript.Decript(obj.emerg_contact1) : "";
  //           this.clntEmergNumber1 = obj.emerg_contact2 != null && obj.emerg_contact2 != "" && obj.emerg_contact2 != undefined && (encrypt_decript.Decript(obj.emerg_contact2) != "") ? encrypt_decript.Decript(obj.emerg_contact2) : "";

  //           if (obj.dob != 'null') {
  //             this.clntDOB = obj.dob;
  //             this.selectDob(this.clntDOB)

  //           } else {
  //             this.clntDOB = null;
  //             this.dobDisabledFlag = true;
  //             this.ageRead = false;
  //           }

  //           if (obj.age != undefined) {
  //             this.clntAge = obj.age;

  //           } else {
  //             this.clntAge="";
  //           }

  //           if (this.appType != "New") {
  //             this.clntSymptom = obj.symptom;
  //           }

  //           this.clntFatherOrGuardName = this.checkData(obj.guardian);
  //           if (obj.address1 != null && obj.address1 != "") {
  //             this.clntAddress1 = encrypt_decript.Decript(obj.address1);
  //           }

  //           if (obj.address2 != null && obj.address2 != "") {
  //             this.clntAddress2 = encrypt_decript.Decript(obj.address2);
  //           }
  //           this.clntLocation = this.checkData(obj.location_desc);
  //           this.clntLocationId = this.checkData(obj.location);
  //           this.clntCountry = this.checkData(obj.country_desc);
  //           this.clntCountryId = this.checkData(obj.country);
  //           this.clntState = this.checkData(obj.state_desc);
  //           this.clntStateId = this.checkData(obj.state);
  //           this.clntCity = this.checkData(obj.city_desc);
  //           if (obj.address2 != null && obj.address2 != "") {
  //             this.clntZipcode = encrypt_decript.Decript(obj.zipcode);
  //           }
  //         }
  //         this.retrvDisabledFlag = true;
  //       },
  //       error => {
  //         this.toastr.error(Message_data.defaultErr);
  //       });
  // }

  retrieveClient(person) {
    if (this.searchby == "patientid") {
      var get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    } else {
      var get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/grclnt/',
      get_data,
      { headers: headers })
      .subscribe(
        response => {
          FrontDesk_Helper.setClientId(null);
          var obj = JSON.parse(JSON.stringify(response));
          console.log("check patientid" + JSON.stringify(obj))

          this.previousvisits = [];
          this.nextvisittestarray=[];
          if (obj.client_id != null) {
            this.clientId = obj.client_id;
            this.basic_detailflag = true
            FrontDesk_Helper.setClientId(obj);
            // Editable Fields
            if (obj.salutation != null) {
              for (var i = 0; i < this.clntSalList.length; i++) {
                if (this.clntSalList[i].sal_desc == obj.salutation) {
                  this.clntSal = this.clntSalList[i].sal_desc;
                  this.clntSal_id = this.clntSalList[i].sal_id;
                }
              }
            }
            if (obj.ref_source_desc != undefined) {

              // for (var i = 0; i < this.ref_sourcearray.length; i++) {
              //if (this.ref_sourcearray[i].lead_desc == obj.ref_source_desc) {
              this.ref_source = obj.ref_source_desc;
              //  }

              //  }
            }
            if (obj.prev_visits != undefined) {

              this.previousflag = true;
              for (var k = 0; k < obj.prev_visits.length; k++) {
                if (obj.prev_visits[k].visit_date != undefined) {
                  this.previousvisits.push({
                    visit_date: Date_Formate(obj.prev_visits[k].visit_date),
                    doc_name: obj.prev_visits[k].doc_name,
                    visit_purpose: obj.prev_visits[k].visit_purpose != undefined ? obj.prev_visits[k].visit_purpose : "",
                    specialization_name: obj.prev_visits[k].specialization_name,
                    app_type: obj.prev_visits[k].app_type,
                    spl_id: obj.prev_visits[k].spl_id,
                    doc_reg_id: obj.prev_visits[k].doc_reg_id,
                  })
                }
              }
              console.log("check this.previousvisits---" + JSON.stringify(this.previousvisits))


            } else {
              this.previousflag = false;
            }
            if (obj.prev_visits != undefined) {
              for (var i = 0; i < this.purposeofvisit.length; i++) {
                for (var k = 0; k < obj.prev_visits.length; k++) {
                  console.log("check data &&7---" + JSON.stringify(this.purposeofvisit[i].description))
                  console.log("check data previosu---" + JSON.stringify(obj.prev_visits[k].visit_purpose))
                  if (this.purposeofvisit[i].description == obj.prev_visits[k].visit_purpose) {
                    this.visit_purpose = this.purposeofvisit[i].prov_id;
                  }
                }
              }
            }
            this.profileImg = ipaddress.Ip_with_img_address + obj.profile_image;

            if (this.searchby == "patientid") {
              this.clientRegId = person.client_reg_id;
              this.relationId = person.relation_id;
              this.subRelId = person.sub_rel_id;

            } else {
              this.clientRegId = person.client_reg_id;
              this.relationId = person.relation_id;
              this.subRelId = person.sub_rel_id;
            }

            if (obj.tests != undefined) {
              this.test_flag = true;
              for (var j = 0; j < obj.tests.length; j++) {
                if (obj.tests[j].test_id != undefined) {
                  this.nextvisittestarray.push({
                    testname: (obj.tests[j].test_name),
                    department: obj.tests[j].department,
                    category: obj.tests[j].test_cat,
                    price: obj.tests[j].price,
                  })
                }
              }

            } else {
              this.test_flag = false;
            }

            this.clntFirstName = obj.first_name != null && (encrypt_decript.Decript(obj.first_name) != "") ? encrypt_decript.Decript(obj.first_name) : "";
            this.clntMiddleName = obj.middle_name != null && (encrypt_decript.Decript(obj.middle_name) != "") ? encrypt_decript.Decript(obj.middle_name) : "";
            this.clntLastName = obj.last_name != null && (encrypt_decript.Decript(obj.last_name) != "") ? encrypt_decript.Decript(obj.last_name) : "";
            this.clntGender = obj.gender != null && (encrypt_decript.Decript(obj.gender) != "") ? encrypt_decript.Decript(obj.gender) : "";
            this.clntContNumber = obj.mobile != null && obj.mobile != "" && obj.mobile != undefined && (encrypt_decript.Decript(obj.mobile) != "") ? encrypt_decript.Decript(obj.mobile) : "";
            this.clntEmergNumber = obj.emerg_contact1 != null && obj.emerg_contact1 != "" && obj.emerg_contact1 != undefined && (encrypt_decript.Decript(obj.emerg_contact1) != "") ? encrypt_decript.Decript(obj.emerg_contact1) : "";
            this.clntEmergNumber1 = obj.emerg_contact2 != null && obj.emerg_contact2 != "" && obj.emerg_contact2 != undefined && (encrypt_decript.Decript(obj.emerg_contact2) != "") ? encrypt_decript.Decript(obj.emerg_contact2) : "";

            if (this.clntMiddleName != "" && this.clntMiddleName != undefined) {
              this.clientname = this.clntSal + "." + this.clntFirstName + " " + this.clntMiddleName + " " + this.clntLastName;
            } else {
              this.clientname = this.clntSal + "." + this.clntFirstName + " " + this.clntLastName;
            }

            if (obj.patient_id != undefined) {
              this.mrno = obj.patient_id
            }

            if (obj.dob != undefined ) {
              this.clntDOB = Date_Formate(obj.dob);
              if(this.clntDOB =='undefined-undefined-'){
                this.clntDOB=null;
              }
              // if(this.clntDOB !='undefined-undefined-'){
              //   this.selectDob(this.clntDOB)
              // } else {
              //   this.clntDOB=null;
              // }
              
            } else {
              this.clntDOB = null;
              this.dobDisabledFlag = true;
            }

            if (obj.age != undefined) {
              this.clntAge = obj.age;

            } else {
              this.clntAge = "";
            }

            if (this.appType != "New") {
              this.clntSymptom = obj.symptom != undefined ? obj.symptom.replace(/<\/?p>/g, '') : "";
            }

            this.clntFatherOrGuardName = this.checkData(obj.guardian);
            this.barcodeimage = obj.barcode;

            if (obj.address1 != null && obj.address1 != "") {
              this.clntAddress1 = encrypt_decript.Decript(obj.address1);
            }
            if (obj.SSN_PAN_number != undefined) {
              this.aadharNumber = encrypt_decript.Decript(obj.SSN_PAN_number);
            }
            //whatsapp_number
            if (obj.whatsapp_number != undefined) {
              this.whatsappNumber = encrypt_decript.Decript(obj.whatsapp_number);
            }
            if (obj.referred_by != undefined && obj.referred_by != 'null' && obj.referred_by != null && obj.referred_by != '') {
              this.referredDoctor = (obj.referred_by);
              this.saluationflag=true
            }else{
              this.referredDoctor='';
              this.saluationflag=false
            }
            // if (obj.emerg_contact1 != undefined) {
            //   this.clntEmergNumber = encrypt_decript.Decript(obj.emerg_contact1);
            // }
            // if (obj.emerg_contact2 != undefined) {
            //   this.clntEmergNumber1 = encrypt_decript.Decript(obj.emerg_contact2);
            // }
            //emerg_contact1,emerg_contact2
            if (obj.address2 != null && obj.address2 != "") {
              this.clntAddress2 = encrypt_decript.Decript(obj.address2);

              this.address = this.clntAddress1 + "" + this.clntAddress2
            } else {
              this.address = this.clntAddress1
            }
            this.clntLocation = this.checkData(obj.location_desc);
            this.clntLocationId = this.checkData(obj.location);
            this.clntCountry = this.checkData(obj.country_desc);
            this.clntCountryId = this.checkData(obj.country);
            this.clntState = this.checkData(obj.state_desc);
            this.clntStateId = this.checkData(obj.state);
            this.clntCity = this.checkData(obj.city_desc);
            if (obj.address2 != null && obj.address2 != "") {
              this.clntZipcode = encrypt_decript.Decript(obj.zipcode);
            }
            this.bookFlag = false;
          }
          this.retrvDisabledFlag = true;

          console.log("check doc--"+JSON.stringify(this.previousvisits))
          if (this.appType == "Follow-up") {
            this.splId = this.previousvisits[this.previousvisits.length - 1].spl_id;
            this.splName = this.previousvisits[this.previousvisits.length - 1].specialization_name;
            this.getDoctorList(this.splName)
            console.log("check ***--"+JSON.stringify(this.splId)+this.splName)
            this.doctorId = this.previousvisits[this.previousvisits.length - 1].doc_reg_id;
            console.log("check doc--"+JSON.stringify(this.doctorId))
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getDoctorDetails() {
    this.appTypeTokenFlag = true;
    // this.sendDoctorDetailData = {
    //   doc_reg_id: this.doctorId,
    //   location: this.userInfo.location,
    //   country: ipaddress.country_code,
    //   home_care: "0",
    // }
    if(this.docType =='doctor') {
      this.doctorDetailUrl = ipaddress.getIp + "search/doctorbyid/";
      this.sendDoctorDetailData = {
        doc_reg_id: this.doctorId,
        location: this.userInfo.location,
        country: ipaddress.country_code,
        home_care: "0",
      }

    } else {
      this.doctorDetailUrl = ipaddress.getIp + "search/npsbyid/";
      this.sendDoctorDetailData = {
        prov_id: this.doctorId,
        type:"physio",
        location: this.userInfo.location,
        country: ipaddress.country_code,
        home_care: "0",
      }
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.doctorDetailUrl, this.sendDoctorDetailData, { headers: headers }).subscribe(
      data => {
        this.doctorDetailData = [];
        var dataval = JSON.parse(JSON.stringify(data));

        if (dataval.prov_id != null) {
          this.doctorId = dataval.prov_id;
          if (dataval.non_avail != null)
            this.docUnavailableData = dataval.non_avail;
          this.doctorProfileImg = ipaddress.Ip_with_img_address + dataval.profile_image;
        }

        if (dataval.consultation != null) {// Normal Consultation
          this.doctorDetailData = [];
          this.hospitalListData = [];

          for (var l = 0; l < dataval.consultation.length; l++) {
            this.tempFee = dataval.consultation[l].fees != "0" ? dataval.consultation[l].fees : "NA";

            var temp_address;
            if (this.gservice.get_Array_Contains(this.hospitalListData, "hospital_id", dataval.consultation[l].hospital_id) == false) {
              if (dataval.consultation[l].address2 != null && dataval.consultation[l].address2 != "") {
                temp_address = dataval.consultation[l].address + ", " + dataval.consultation[l].address2;
              } else {
                temp_address = dataval.consultation[l].address;
              }
              this.hospitalListData.push({
                hospital_id: dataval.consultation[l].hospital_id,
                Hospname: dataval.consultation[l].hospital,
                Location: dataval.consultation[l].location,
                Address_1: temp_address,
                City: dataval.consultation[l].city,
                fees: this.tempFee,
              });
            }

            if (this.hospitalListData.length == 1)
              this.hosptlId = this.hospitalListData[0].hospital_id;

            this.doctorDetailData.push({
              hospitalid: dataval.consultation[l].hospital_id,
              hospital: dataval.consultation[l].hospital,
              location: dataval.consultation[l].location,
              day: this.gservice.get_Day(dataval.consultation[l].day),
              day_num: dataval.consultation[l].day,
              session: this.gservice.get_Session(dataval.consultation[l].session),
              available_from: this.gservice.get_Timeformate(dataval.consultation[l].available_from),
              available_to: this.gservice.get_Timeformate(dataval.consultation[l].available_to),
              fees: this.tempFee,
              TokenorTime: dataval.consultation[l].token_flag,
              Time_Duration: dataval.consultation[l].time_duration,
              Avail_from: dataval.consultation[l].available_from,
              Avail_to: dataval.consultation[l].available_to
            });
          }

          console.log("this.doctorDetailData[0].TokenorTime " + JSON.stringify(this.doctorDetailData))
          console.log("this.doctorDetailData[0].TokenorTime " + this.doctorDetailData[0].TokenorTime)
          if (this.doctorDetailData[0].TokenorTime == "true") {
            this.typeorTime = "type";
            this.appTypeTokenFlag = true;
            this.appTypeTimeFlag = false;
            this.apptType = "Token";
          } else {
            this.typeorTime = "time";
            this.appTypeTokenFlag = false;
            this.appTypeTimeFlag = true;
          }

          if (localStorage.getItem('app_flow') == "search_hospital") {
            for (var o = 0; o < this.hospitalListData.length; o++) {
              if (this.hospitalListData[o].Hospname == localStorage.getItem('doc_search')) {
                //this.hospitalId = this.hospitalListData[0].hospital_id;
                this.hospitalName = this.hospitalListData[o].Hospname;
                this.hospSltLocation = this.hospitalListData[o].Location;
                this.hospSltFee = this.hospitalListData[o].fees;
                break;
              }
            }
          } else {
            //this.hospitalId = this.hospitalListData[0].hospital_id;
            this.hospitalName = this.hospitalListData[0].Hospname;
            this.hospSltLocation = this.hospitalListData[0].Location;
            this.hospSltFee = this.hospitalListData[0].fees;
          }

          this.addSession(this.hospitalId);
          var parts = this.currentDate.split('-');
          this.availableFunction(parts[2] + "-" + parts[1] + "-" + parts[0], this.currentTime); // Available or Unavailable for today
        }

        if (dataval.home_consult != null) {// HomeCare Consult
          this.homecareTableData = [];
          this.homecareSessionData = [];

          for (var i = 0; i < dataval.home_consult.length; i++) {
            this.homecareTableData.push({
              Session: this.gservice.get_Session(dataval.home_consult[i].session),
              Avail_From: this.gservice.get_Timeformate(dataval.home_consult[i].available_from),
              Avail_To: this.gservice.get_Timeformate(dataval.home_consult[i].available_to),
              Fees: dataval.home_consult[i].fees,
              Time_Inter: dataval.home_consult[i].time_interval,
              avail_from: dataval.home_consult[i].available_from,
              avail_to: dataval.home_consult[i].available_to
            });
            this.homecareSessionData.push(this.gservice.get_Session(dataval.home_consult[i].session));
            this.addSession("")
          }
        }

        console.log("this.doctorList[0].prov_id" + this.doctorId)

      },
      error => { });
  }

  appDateChange(appdate) {
    this.timeList = []; // clear app time list

    if (this.currentDate != appdate) {
      var gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];

      var get_date = appdate.split('-');
      var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
      var dayName = gsDayNames[result.getDay()];
      var get_sel_day_num = this.gservice.get_Day_num(dayName);
      var Available_flag_1 = false;

      for (var i = 0; i < this.doctorDetailData.length; i++) {
        if (this.doctorDetailData[i].hospital_id == this.hospitalId) {
          if (this.doctorDetailData[i].day_num == "8" || (get_sel_day_num != 1 && this.doctorDetailData[i].day_num == "9")) {
            Available_flag_1 = true;
          } else {
            if (((new Date(appdate)).getDay() + 1) == this.doctorDetailData[i].day_num) {
              Available_flag_1 = true;
            }
          }
        }
      }

      if (Available_flag_1 == false) {
        this.availableFlag = false;
        this.nonAvailableFlag = true;
      } else {
        this.availableFlag = true;
        this.nonAvailableFlag = false;
      }

      this.addSession(this.hospitalId);
      this.changeVisitSession(this.visitSession, appdate);
      this.availableFunction((appdate), this.currentTime);
    } else {
      this.addSession(this.hospitalId);
      this.changeVisitSession(this.visitSession, appdate);
      this.availableFunction((appdate), this.currentTime);
    }
  }

  addSession(Hospital) {
    this.visitingSessionData = [];
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];

    if (Hospital == "") {
      var get_date = Date_Formate(this.homecareAppDate);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";

      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }

      for (var a = 0; a < this.homecareSessionData.length; a++) {
        if (this.currentDate.trim() == get_date.trim()) { // Today 
          if (this.homecareSessionData[a] == "Morning" && cuur_mor_sess == "Morning") {
            this.visitingSessionData.push({
              description: "Morning",
              id: "1"
            });
          }

          if (this.homecareSessionData[a] == "Afternoon" && cuur_afetr_sess == "Afternoon") {
            this.visitingSessionData.push({
              description: "Afternoon",
              id: "2"
            });
          }

          if (this.homecareSessionData[a] == "Evening" && cuur_eve_sess == "Evening") {
            this.visitingSessionData.push({
              description: "Evening",
              id: "3"
            });
          }
        } else {
          if (this.homecareSessionData[a] == "Morning") {
            this.visitingSessionData.push({
              description: "Morning",
              id: "1"
            });
          }

          if (this.homecareSessionData[a] == "Afternoon") {
            this.visitingSessionData.push({
              description: "Afternoon",
              id: "2"
            });
          }

          if (this.homecareSessionData[a] == "Evening") {
            this.visitingSessionData.push({
              description: "Evening",
              id: "3"
            });
          }
        }
      }

    } else {
      var get_date=''
      this.appCurrentDate = this.appointmentDate;
     
        get_date =this.appointmentDate;
      var curr_datetime = new Date((get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";

      }
      console.log("this.doctorDetailData >>>>>>"+JSON.stringify(this.doctorDetailData))
      for (var a = 0; a < this.doctorDetailData.length; a++) {
        var dayName = gsDayNames[curr_datetime.getDay()];
        var sel_day_num = this.gservice.get_Day_num(dayName);
        var day_num = this.doctorDetailData[a].day_num;

        let dayflag = false;
        if (dayName == this.doctorDetailData[a].day)
          dayflag = true;
        else if (this.doctorDetailData[a].day == "All Days")
          dayflag = true;
        else if (sel_day_num != 1 && day_num == 8)
          dayflag = true;
        if (this.doctorDetailData[a].hospitalid == Hospital && dayflag == true) {
          console.log("********** >>>>>>"+JSON.stringify(this.doctorDetailData[a].session)+cuur_mor_sess)
          if (Date_Formate(this.currentDate).trim() == (get_date.trim())) { // Today 
            if (this.doctorDetailData[a].session == "Morning" && cuur_mor_sess == "Morning") {
              this.visitingSessionData.push({
                description: "Morning",
                id: "1"
              });
            }

            if (this.doctorDetailData[a].session == "Afternoon" && cuur_afetr_sess == "Afternoon") {
              this.visitingSessionData.push({
                description: "Afternoon",
                id: "2"
              });
            }

            if (this.doctorDetailData[a].session == "Evening" && cuur_eve_sess == "Evening") {
              this.visitingSessionData.push({
                description: "Evening",
                id: "3"
              });
            }
          } else {
            if (this.doctorDetailData[a].session == "Morning") {
              this.visitingSessionData.push({
                description: "Morning",
                id: "1"
              });
            }

            if (this.doctorDetailData[a].session == "Afternoon") {
              this.visitingSessionData.push({
                description: "Afternoon",
                id: "2"
              });
            }

            if (this.doctorDetailData[a].session == "Evening") {
              this.visitingSessionData.push({
                description: "Evening",
                id: "3"
              });
            }
          }
        }else{
          
        }
      }
    }

  
   this.visitSession = this.visitingSessionData[0].description;
   this.changeVisitSession(this.visitSession, get_date);

  }

  checkAvailabilty(get_sel_date, sess, f_time, t_time) {
    var Available_flag = true;
    var check_date = new Date(get_sel_date);
    if (this.docUnavailableData.length != 0) {
      for (var i = 0; i < this.docUnavailableData.length; i++) {
        if (this.docUnavailableData[i].full_day != null) {
          if (check_date >= new Date(this.docUnavailableData[i].from_date)
            && check_date <= new Date(this.docUnavailableData[i].to_date)) {
            Available_flag = false;
            break;
          }
        } else {
          if (sess != null) {
            // get unavialable date
            var From_Split = this.docUnavailableData[i].from_time.split(':');
            var To_Split = this.docUnavailableData[i].to_time.split(':');

            var t1 = new Date(check_date);
            t1.setHours(From_Split[0]);
            t1.setMinutes(From_Split[1]);

            var t2 = new Date(check_date); // t_time
            t2.setHours(To_Split[0]);
            t2.setMinutes(To_Split[1]);

            var get_f_time = this.gservice.get_time_form_session(f_time, this.gservice.get_seesion_ampm(sess));
            var get_t_time = this.gservice.get_time_form_session(t_time, this.gservice.get_seesion_ampm(sess));

            var get_time1 = this.convertTimeformat("24", get_f_time);
            var get_time2 = this.convertTimeformat("24", get_t_time);

            // get available date
            var From_Split1 = get_time1.split(':');
            var To_Split1 = get_time2.split(':');

            var get_f_time1 = get_time1.split(':');
            var get_f_time2 = get_time2.split(':');

            var f_time1 = new Date(check_date);
            f_time1.setHours(parseInt(get_f_time1[0]));
            f_time1.setMinutes(parseInt(get_f_time1[1]));

            var get_chk_date = this.getDateMatePicker1(check_date);
          
            // var get_f_date = this.docUnavailableData[i].from_date;
            // var get_t_date = this.docUnavailableData[i].from_date;
            var get_chk_date = this.datepipe.transform(check_date, 'dd-MM-yyyy');
            var get_f_date = this.datepipe.transform(this.docUnavailableData[i].from_date, 'dd-MM-yyyy');
            var get_t_date = this.datepipe.transform(this.docUnavailableData[i].from_date, 'dd-MM-yyyy');
            if (get_chk_date.toString() == get_f_date.toString()
              && sess == this.docUnavailableData[i].session
              || get_chk_date.toString() == get_t_date.toString()
              && sess == this.docUnavailableData[i].session) {
              if (f_time1 > t1 && f_time1 < t2)
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 <= t2))
                Available_flag = false;

              if ((f_time1 >= t1 && f_time1 == t2))
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 == t2))
                Available_flag = false;
            }

            if (get_chk_date.toString() == get_f_date.toString()
              && get_chk_date.toString() == get_t_date.toString()
              && sess == this.docUnavailableData[i].session) {

              if (f_time1 > t1 && f_time1 < t2)
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 < t2))
                Available_flag = false;

              if ((f_time1 > t1 && f_time1 == t2))
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 == t2))
                Available_flag = false;
            }
          }
        }
      }
    }
    return Available_flag;
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  convertTimeformat(format, str) {
    var time = str;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return (sHours + ":" + sMinutes);
  }

  book(flow) {// App Booking
    var valid_flag = false;
    var vsession, vtime;
    var vmgmtcheck: any
    var errortext = "Fill "
    if (flow == "booking") {
      if (this.clientId == "0" && this.apptTypeFlag) {
        this.toastr.error("Please select the user before updating");
      } else {
        if (this.splName == "Pediatrics") {
          let str = this.clntAge.toString();
          let check = str.includes("d");
          if (check == false && 16 < parseInt(this.clntAge)) {
            this.toastr.error(Message_data.splOnlyForChild);
            valid_flag = true;
          }

        } else if (this.splName == "Gynecology") {
          if (this.clntGender != "Female") {
            this.toastr.error(Message_data.splOnlyForwomen);
            valid_flag = true;
          }
        }
       
        if (this.appType =="doctor" && this.splName == undefined) {
          errortext += " Specality"
          valid_flag = true;
        }
        if (this.visitSession != "" && this.visitSession != undefined) {
          vsession = this.visitSession;
          this.visitSession = encrypt_decript.Encript(this.visitSession).toString();

        } else {
          errortext += " Session"
          valid_flag = true;
        }

        if (this.appTypeTokenFlag == false) {
          if (this.visitTime != "" && this.visitTime != undefined) {
            vtime = this.visitTime;
            this.visitTime = (this.visitTime);

          } else {
            errortext += "Time"
            valid_flag = true;
          }
        }
      }
    } 

    if (this.clntSymptom != undefined && this.clntSymptom != "") {
      var new_symptoms: any = [];
      if (this.clntSymptom.indexOf(',') > -1) {
        var dataval = this.clntSymptom.trim().split(",");
        for (var i = 0; i < dataval.length; i++) {
          if (this.newSymptoms.length != 0) {
            for (var j = 0; j < this.newSymptoms.length; j++) {
              if (this.newSymptoms[j] != dataval[i]) {
                new_symptoms.push(dataval[i]);
              }
            }
          }
        }

      } else {
        if (this.newSymptoms.length != 0) {
          for (var j = 0; j < this.newSymptoms.length; j++) {
            if (this.newSymptoms[j] != this.clntSymptom.trim()) {
              new_symptoms.push(this.clntSymptom.trim());
            }
          }
        } else {
          new_symptoms.push(this.clntSymptom.trim());
        }
      }
    }

    if (valid_flag == true) {
      this.toastr.error(errortext);

    } else if (valid_flag == false) {
      var Provider = "doctor";
      if (localStorage.getItem('app_flow') == "nurse_physio_app") {
        Provider = localStorage.getItem('type');
      }
      if(this.docType != undefined) {
        Provider = this.docType;
      }
      if (this.visitTime != undefined) {
        var app_time = this.visitTime.toString().split(" ");
        this.tokenorTime = this.visitTime.toString();
        if (this.tokenorTime != "true") {
          if (this.tokenorTime != undefined) {
            this.timeOfAppt = app_time[0] + ":00";
          }
        } else
          this.timeOfAppt = "1";
      } else
        this.timeOfAppt = "1";

      this.sendAppType = this.appType == "New" ? "1" : "2";

      if (this.clntAge != null)
        var client_app_age = this.clntAge;

      if (this.clntSymptom != null)
        this.clntSymptomHtml = `<p>${this.clntSymptom}</p>`;
      if (this.clntMiddleName != null)
        var clnt_middle_name = encrypt_decript.Encript(this.clntMiddleName).toString();

      if (this.clntDOB != null) {
        var cdob = this.clntDOB;
        var client_send_dob = cdob;
      }

      if (this.subRelId != null)
        var client_sub_rel_id = this.subRelId;

      if (this.homeSessionTableData.length != 0) {
        this.homeApps = [];
        for (var i = 0; i < this.homeSessionTableData.length; i++) {
          var dateval = this.homeSessionTableData[i].Date.split('-');
          this.homeApps.push({
            session: encrypt_decript.Encript(this.homeSessionTableData[i].Session).toString(),
            date: dateval[2] + "-" + dateval[1] + "-" + dateval[0],
            app_time: this.homeSessionTableData[i].app_time,
          });
        }
      }

      var token_flag;
      token_flag = this.typeorTime == "time" ? false : true;

      var apptype;
      apptype = this.appType == "New" ? "1" : "2";

      var addr1;
      addr1 = this.clntAddress1 != null ? encrypt_decript.Encript(this.clntAddress1).toString() : null;

      var addr2;
      addr2 = this.clntAddress2 != null ? encrypt_decript.Encript(this.clntAddress2).toString() : null;

      var address: any = [];
      if (flow == "booking") {
        address.push({
          address1: addr1,
          address2: addr2,
          location: this.clntLocationId,
          city: this.clntCityId,
          state: this.clntStateId,
          country: this.clntCountryId,
          zipcode: encrypt_decript.Encript(this.clntZipcode).toString(),
          type: "pres"
        });

      } else {   // update client details
        address.push({
          address1: addr1,
          address2: addr2,
          location: this.clntLocationId,
          city: this.clntCityId,
          state: this.clntStateId,
          country: this.clntCountryId,
          zipcode: encrypt_decript.Encript(this.clntZipcode).toString(),
          type: "pres",
          is_permanent: "1"
        });
      }

      var relid;
      relid = this.relationId != undefined ? this.relationId : "1";

      if (client_sub_rel_id != undefined && client_sub_rel_id != null) {
        client_sub_rel_id = client_sub_rel_id;
        this.subRelId = client_sub_rel_id;
      }

      var bpval;
      bpval = this.BP01 != null ? this.BP01 + "/" + this.BP02 : "";

      var clientFName, lastName, gender, contactNo, emgContNo, emgContNo1, aadharNo, whatsappNo;
      if (this.clntFirstName != null && this.clntFirstName != undefined)
        clientFName = encrypt_decript.Encript(this.clntFirstName).toString();

      if (this.clntLastName != null && this.clntLastName != undefined)
        lastName = encrypt_decript.Encript(this.clntLastName).toString();

      if (this.clntGender != null && this.clntGender != undefined)
        gender = encrypt_decript.Encript(this.clntGender).toString();

      if (this.clntContNumber != null && this.clntContNumber != undefined)
        contactNo = encrypt_decript.Encript(this.clntContNumber).toString();

      if (this.aadharNumber != null && this.aadharNumber != undefined)
        aadharNo = encrypt_decript.Encript(this.aadharNumber).toString();

      if (this.whatsappNumber != null && this.whatsappNumber != undefined)
        whatsappNo = encrypt_decript.Encript(this.whatsappNumber).toString();

      if (this.clntEmergNumber != null && this.clntEmergNumber != undefined)
        emgContNo = encrypt_decript.Encript(this.clntEmergNumber).toString();

      if (this.clntEmergNumber1 != null && this.clntEmergNumber1 != undefined)
        emgContNo1 = encrypt_decript.Encript(this.clntEmergNumber1).toString();

      this.dataForApptBook = {
        profile_image: this.base64,
        prov_id: this.doctorId,
        visit_purpose: this.visit_purpose,
        provider: Provider,
        client_id: this.clientRegId,
        relation_id: relid,
        sub_relation_id: client_sub_rel_id,
        salutation: this.clntSal_id,
        // Client
        first_name: clientFName,
        middle_name: clnt_middle_name,
        last_name: lastName,
        gender: gender,
        age: client_app_age,
        dob: client_send_dob,
        mem_mobile: contactNo,
        SSN_PAN_number: aadharNo,
        whatsapp_number: whatsappNo,
        emerg_contact1: emgContNo,
        emerg_contact2: emgContNo1,
        symptom: this.clntSymptomHtml,
        app_type: apptype,
        address: address,
        guardian: this.clntFatherOrGuardName,
        medicare_id: "1",
        specialization_id: this.splId,
        session: this.visitSession,
        date: (this.appointmentDate),
        token: token_flag,
        time: this.timeOfAppt,
        hptl_id: this.hosptlId,
        home_care: this.homecare,
        payment_type: this.paydesc,
        ref_by: this.referredDoctor,
        height: this.height,
        height_measure: this.hmeasure,
        weight: this.weight,
        weight_measure: this.wmeasure,
        bmi: this.bmi,
        bmr:this.bmr,
        pulse: this.pulse,
        temparature: this.temperature+" "+this.temp_read,       
        rr_txt:this.rr_txt,
        blood_pressure: bpval,
        spo2: this.spo2,
        cns_txt: this.cns_txt,
        abd_txt: this.abd_txt,
        cvs: this.cvs_txt,
        rs_txt: this.rstxt,       
        pr_txt: this.pr_txt,
        cbg_txt:this.cbg_txt,
        newsymptoms: new_symptoms,
        country: ipaddress.country_code,
        token_on_arrival: this.userInfo.token_on_arrival,
        barcode: this.barcodeimage,
        patient_id: this.mrno,
        app_source:this.visitType,
      };

      this.sendUpdateData = {
        client_id: this.clientRegId,
        relation_id: relid,
        sub_rel_id: client_sub_rel_id,
        salutation: this.clntSal_id,
        // Client

        visit_purpose: this.visit_purpose,
        first_name: clientFName,
        middle_name: clnt_middle_name,
        last_name: lastName,
        gender: gender,
        age: client_app_age,
        dob: client_send_dob,
        mobile: contactNo,
        SSN_PAN_number: aadharNo,
        whatsapp_number: whatsappNo,
        emerg_contact1: emgContNo,
        emerg_contact2: emgContNo1,
        address1: addr1,
        address2: addr2,
        location: this.clntLocationId,
        city: this.clntCityId,
        state: this.clntStateId,
        country: this.clntCountryId,
        zipcode: encrypt_decript.Encript(this.clntZipcode).toString(),
        guardian: this.clntFatherOrGuardName,
        newsymptoms: new_symptoms,
        rep: true,
        token_on_arrival: this.userInfo.token_on_arrival,
        barcode: this.barcodeimage,
        patient_id: this.mrno,
        app_source:this.visitType,
      };

      if (client_sub_rel_id != undefined && client_sub_rel_id != null && client_sub_rel_id != "")
        this.sendUpdateData.sub_rel_id = client_sub_rel_id;

      if (flow == "booking") {
        this.bookingConfirm();

      } else { // update client details
        this.updateUserDetails();
      }
    } else {
      this.visitSession = vsession;
    }
  }

  bookingConfirm() {
    var bookflag = true;
    var timeforfmat;
    if(Helper_Class.getmangamentlist_doctor() != null){
      var filteredArray = Helper_Class.getmangamentlist_doctor().filter(obj => obj.client_reg_id == this.clientId);
      if (filteredArray.length != 0) {
        for (var i = 0; i < filteredArray.length; i++) {
          if (filteredArray[i].client_reg_id == this.clientId && filteredArray[i].session == encrypt_decript.Decript(this.visitSession) && filteredArray[i].app_date == this.appointmentDate) {
            if (this.appTypeTokenFlag == false) {
              this.visitTime = this.visitTime
            } else {
              this.visitTime = undefined
            }
            if (filteredArray[i].apptimecheck != undefined && this.visitTime != undefined) {
              timeforfmat = 'h:mm A';
              var time1 = moment(filteredArray[i].app_time, timeforfmat);
              var time2 = moment(this.visitTime, timeforfmat);
  
              if (time1.isBefore(time2)) {
                if (filteredArray[i].doc_reg_id == this.doctorId) {
                  bookflag = false;
                  this.toastr.error("Already You have Appoinments")
                } else {
                  bookflag = true;
                }
                
              } else if (time1.isAfter(time2)) {
                console.log('Time 1 is after Time 2');
                if (filteredArray[i].doc_reg_id == this.doctorId) {
                  bookflag = false;
                  this.toastr.error("Already You have Appoinments")
                } else {
                  bookflag = true;
                }
              } else {
                if (filteredArray[i].doc_reg_id == this.doctorId) {
                  bookflag = false;
                  this.toastr.error("Already You have Appoinments")
                } else {
                  bookflag = true;
                }
                // bookflag = false;
              }
              if (filteredArray[i].doc_reg_id == this.doctorId) {
                this.toastr.error("Already You have Appoinments")
                bookflag = false;
              }
            } else if (filteredArray[i].apptimecheck != undefined && this.visitTime == undefined) {
              if (filteredArray[i].doc_reg_id == this.doctorId) {
                bookflag = false;
                this.toastr.error("Already You have Appoinments")
              } else {
                bookflag = true;
              }
            }
            else if (filteredArray[i].apptimecheck == undefined && this.visitTime != undefined) {
              console.log(filteredArray[i].doc_reg_id + this.doctorId)
              if (filteredArray[i].doc_reg_id == this.doctorId) {
                bookflag = false;
                this.toastr.error("Already You have Appoinments")
              } else {
                bookflag = true;
              }
            } else if (filteredArray[i].apptimecheck == undefined && this.visitTime == undefined) {
              if (filteredArray[i].doc_reg_id == this.doctorId) {
                bookflag = false;
                this.toastr.error("Already You have Appoinments")
              } else {
                bookflag = true;
              }

            } else {
              if (filteredArray[i].doc_reg_id == this.doctorId) {
                this.toastr.error("Already You have Appoinments")
                bookflag = false;
              }
            }
            break;

          } else {
            if (filteredArray[i].doc_reg_id == this.doctorId) {
              bookflag = false;
              this.toastr.error("Already You have Appoinments")
            } else {
              bookflag = true;
            }
          }
          break;
        }

      } else {
        bookflag = true;

      } if (bookflag == false) {
        this.updateFlag = true;
      }

    }else{
      bookflag = true;
      this.updateFlag = true;
    }
  
    if (bookflag == true) {
      this.receptAppService.postData(this.apptBookUrl, this.dataForApptBook).subscribe(
        (response) => {
          var dataval = response;

          if (dataval.status == "1") {
            if (this.clientRegId == "0") {
              this.clientRegId = dataval.client_id;
            }
            this.toastr.success(dataval.result);
            var billData;
            this.mrNumber = dataval.patient_id;
            this.profileImg = ipaddress.Ip_with_img_address + dataval.profile_image;

            if(this.visitType =="walkin") {
              this.printToken(dataval.token)
            }
            this.cdRef.detectChanges();
            setTimeout(() => {
              this.printIdCard();
            }, 1000);

            // ID Print
            if (this.userInfo.doc_app_bill_create == "1") {
              var apptype = this.appType == "New" ? "1" : "2";
              var paid_amount = 0;
              if (this.clientType == "Existing") {
                for (var t = 0; t < this.userInfo.charges.length; t++) {
                  if (this.userInfo.charges[t].charge_desc.substring(0, 3) == "REG") {
                    this.userInfo.charges.splice(t, 1)
                  }
                }
              }

              for (var b = 0; b < this.userInfo.charges.length; b++) {
                paid_amount += parseInt(this.userInfo.charges[b].amount)
                this.billAddList.push({
                  biill_type: this.userInfo.charges[b].charge_desc,
                  amount_txt: parseFloat(this.userInfo.charges[b].amount).toFixed(2),
                  concession: "0.00",
                  fee: parseFloat(this.userInfo.charges[b].amount).toFixed(2),
                  charge_type: this.userInfo.charges[b].charge_id,
                  pay_type: this.paydesc,
                  paytype: this.payType,
                  insurer: this.insurerId,
                  ref_code: this.transactionId,
                  quantity: "1",
                });
              }

              billData = {
                hptl_clinic_id: this.userInfo.hptl_clinic_id,
                appointment_id: dataval.app_id,
                app_type: "doctor",
                doc_reg_id: this.doctorId, visit_purpose: this.visit_purpose,
                bill_amount: encrypt_decript.Encript(paid_amount.toString()).toString(),
                paid_amount: paid_amount,
                paid_flag: "Fully paid",
                created_by: this.userInfo.user_id,
                bills: this.billAddList,
                country: ipaddress.country_code,
                client_reg_id: this.clientRegId,
                relation_id: this.relationId,
                sub_rel_id: this.subRelId,
                balance: "0.00",
                round_off: "0.00",
                pay_type: this.payType,
                insurer: this.insurerId,
                card_no: this.cardNumber,
                card_holder_name: this.cardHolderName,
                transaction_no: this.transactionId,
                bank_name: this.bankName,
                userid: this.userInfo.user_id,
              };

              var headers = new HttpHeaders();
              this.http.post(this.billUrl, billData, { headers: headers }).subscribe(
                data => {
                  var billresult = JSON.parse(JSON.stringify(data));
                  if (billresult.key == "1") {
                    var getBillDetails = {
                      bill_view_id: billresult.bill_no,
                      fin_year: billresult.fin_year,
                      provider_type: "doctor",
                      doc_reg_id: this.doctorId,
                    }
                    FrontDesk_Helper.setreceptbilldetailview(getBillDetails);
                    this.frontdeskservice.sendMessage("receptbilldetail");
                    
                  } else {
                    this.toastr.error(dataval.result);
                  }
                });

            } else {
              this.frontdeskservice.sendMessage("manageappointments");
            }

          } else {
            this.toastr.error(dataval.result);
          }
        },
        (error) => {
          this.toastr.error(error.json().result);
          this.bookFlag = false;
        }
      );
    }
  }

  appbooking() {

  }

  printIdCard() {
    if (this.clientType == "New") {
      let printContents, popupWin;
      // printContents = document.getElementById('idprint').innerHTML;
      printContents = this.idprint.nativeElement.innerHTML;

      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
          <head>
            <title>Bill</title>
            <link rel="stylesheet" media="screen" href="">
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
            <style>
            @page { size: auto;  margin: 0mm; }
            .table_class {
              background-color: rgb(108, 180, 248);
            }
            
            </style>
          </head>
              <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
            </html>`
      );
      popupWin.document.close();
    }
    else {
      console.log("ID card alredy Printed");
    }
  }

  printToken(tockennumber) {
    this.tockennumber = tockennumber;
    // console.log("check number--" + tocknumber + this.tocknumber)
    let printContents, popupWin
    printContents = this.printtoken.nativeElement.innerHTML;
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <head>
      <title>Reports</title>
      
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      <style>
      @page { size: landscape || potrait; } 
      @media print and (orientation: landscape) {
        
        body{
          padding-top:10%;
        }
        .txtcenter{
          text-align:center;
        }
        .algncheck{
      
        }
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
      <div style="border:1px solid black;width:30%;border-radius:5px;margin: 10px !important">
      ${printContents}  
      </div> 


      </body>
    </html>`
    );
    popupWin.document.close();
  }

  calculateCalories() {
    if ((this.height != undefined && this.height.length != 0)
      || (this.weight != undefined && this.weight.length != 0)) {

      if (this.height != undefined && this.height.length != 0)
        this.encheight = encrypt_decript.Encript(this.height);

      if (this.weight != undefined && this.weight.length != 0)
        this.encweight = encrypt_decript.Encript(this.weight);
    }

    if (this.height == undefined || this.height == "" || this.weight == undefined || this.weight == "") {
      this.bmi = "";

    } else {
      // if (this.height != undefined) {
      //   if (this.hmeasure == "cms")
      //     this.height = this.height.replace(/[^0-9]/g, '');
      //   else {
      //     this.height = this.height.replace('/[^-0-9\.]/g', '');

      //     // if (this.height.indexOf('.') !== -1) {
      //     //   if (this.height.split('.').length > 2)
      //     //     this.height = this.height.height.slice(0, -1);
      //     // }
      //   }

      //   if (this.hmeasure == "cms") {
      //     if (parseFloat(this.height) > 300)
      //       this.height = this.height.slice(0, -1);
      //   } else {
      //     if (parseFloat(this.height) > 100)
      //       this.height = this.height.slice(0, -1);
      //   }
      // }

      if (this.wmeasure == "kgs" && this.hmeasure == "inch") {

        var pound = this.weight * 2.20462;
        var Height_txt = parseFloat(this.height)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);
        if (this.clntGender == "Male") {
          var get_bmr = (10 * this.weight) + (6.25 * (this.height * 2.54)) - (5 * this.clntAge) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.clntGender == "Female") {
          var get_bmr = (10 * this.weight) + (6.25 * (this.height * 2.54)) - (5 * this.clntAge) - 161;
          this.bmr = Math.round(get_bmr);
        }
      } else if (this.wmeasure == "kgs" && this.hmeasure == "cms") {

        
        var meter = parseFloat(this.height) / 100.00;
        var to = parseFloat(this.weight) / (meter * meter);
        this.bmi = Math.round(to);
        if (this.clntGender == "Male") {
          var get_bmr = (10 * this.weight) + (6.25 * this.height) - (5 * this.clntAge) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.clntGender == "Female") {
          var get_bmr = (10 * this.weight) + (6.25 * this.height) - (5 * this.clntAge) - 161;
          this.bmr = Math.round(get_bmr);
        }
      } else if (this.wmeasure == "lbs" && this.hmeasure == "inch") {

        var Height_txt = parseFloat(this.height);
        var dat = this.weight / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);
        if (this.clntGender == "Male") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * (this.height * 2.54)) - (5 * this.clntAge) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.clntGender == "Female") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * (this.height * 2.54)) - (5 * this.clntAge) - 161;
          this.bmr = Math.round(get_bmr);
        }
      } else if (this.wmeasure == "lbs" && this.hmeasure == "cms") {

        var Height_txt = parseFloat(this.height);
        var inch = Height_txt * 0.393701;
        var dat = this.weight / (inch * inch);
        var to = dat * 703;
        this.bmi = Math.round(to);
        if (this.clntGender == "Male") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * this.height) - (5 * this.clntAge) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.clntGender == "Female") {
          var get_bmr = (10 * (this.weight * 0.453592)) + (6.25 * this.height) - (5 * this.clntAge) - 161;
          this.bmr = Math.round(get_bmr);
        }
      }

    }
  }

  getDateMatePickerCalendar(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  validateMobile() {
    if (this.clntContNumber.length != 10) {
      this.toastr.error("Enter valid mobile number")

    } else {
      this.bookFlag = false;
    }

    if (this.clientType == "Existing") {
      this.updateFlag = true;
    } else {
      this.updateFlag = false;
    }
  }

  validateAadhar() {
    if (this.aadharNumber.length != 12) {
      this.toastr.error("Enter valid Aadhar number")
      this.aadharNumber = undefined;
    }
    if (this.clientType == "Existing") {
      this.updateFlag = true;
    }
  }

  validatewhatsapp() {
    if (this.whatsappNumber.length != 10) {
      this.toastr.error("Enter valid whatsapp number")
      this.whatsappNumber = undefined;
    }
    if (this.clientType == "Existing") {
      this.updateFlag = true;
    }
  }


  // updateUserprofile() {
  //   if (this.clientType == "Existing") {
  //     this.updateFlag = true;
  //   }
  //   this.nodob = true;
  //   // this.height = this.height.replace(/(\.\d*[^.]*)\./g, '$1');
  // }

  onHeightInput(event: any) {

    // Allow only digits and one decimal point
    this.height = event.target.value.replace(/[^0-9.]/g, '');

    // Allow only one digit after the decimal point
    const decimalIndex = this.height.indexOf('.');
    if (decimalIndex !== -1) {
      const digitsAfterDecimal = this.height.substring(decimalIndex + 1);
      if (digitsAfterDecimal.length > 1) {
        this.height = this.height.substring(0, decimalIndex + 2);
      }
    }
  }

  updateUserDetails() {
    var update_url;
    if (this.subRelId != "" && this.subRelId != null && this.subRelId != undefined)
      update_url = ipaddress.getIp + "usercontroller/ureld/";
    else
      update_url = ipaddress.getIp + "usercontroller/updclnt";

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(update_url, this.sendUpdateData, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj.key == "1") {
          this.updateFlag = false;
          this.toastr.success(obj.result);
        } else {
          this.updateFlag = false;
          this.toastr.error(obj.result);
        }
      },
      error => {
        this.updateFlag = false;
        this.toastr.error(error.json().result);
      })
  }

  availableFunction(curtdate, curttime) {
    var Available_flag = false;
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    var get_date = (curtdate).split('-');
    var get_sel_date = new Date(get_date[0] + "-" + get_date[1] + "-" + get_date[2]);
    var get_date = (curtdate).split('-');
    var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
    var dayName = gsDayNames[result.getDay()];
    var get_sel_day_num = this.gservice.get_Day_num(dayName);

    for (var i = 0; i < this.doctorDetailData.length; i++) {
      var get_day_num = this.gservice.get_Day_num(this.doctorDetailData[i].day);

      if (this.doctorDetailData[i].hospital == this.hospitalName) {
        var Cur_timesplit = [];
        Cur_timesplit = curttime.split(':');
        var Curre_time = new Date(get_sel_date);
        Curre_time.setHours(Cur_timesplit[0]);
        Curre_time.setMinutes(Cur_timesplit[1]);
        var Avtotimesplit = [];
        Avtotimesplit = this.doctorDetailData[i].Avail_to.split(':');
        var Retrivel_time = new Date(get_sel_date);
        Retrivel_time.setHours(Avtotimesplit[0]);
        Retrivel_time.setMinutes(Avtotimesplit[1]);
        if (this.doctorDetailData[i].day_num == "9" || (get_sel_day_num != 1 && get_day_num == 8)) {
          if (this.currentDate == (curtdate)) {
            if (Retrivel_time > Curre_time)
              Available_flag = true;
          } else {
            Available_flag = true;
          }
        } else {
          if ((new Date(curtdate)).getDay() + 1 == this.doctorDetailData[i].day_num) {
            if (this.currentDate == Date_Formate(curtdate)) {
              if (Retrivel_time > Curre_time && this.currentDate == this.gservice.get_Date_Formate(curtdate)) {
                Available_flag = true;
              }
            } else {
              Available_flag = true;
            }
          }
        }
      }
    }

    Available_flag = this.checkAvailabilty(curtdate, null, null, null);
    if (this.visitingSessionData.length == 0) {
      Available_flag = false;
    }
    if (Available_flag == false) {
      this.availableFlag = false;
      this.nonAvailableFlag = true;
    } else {
      this.availableFlag = true;
      this.nonAvailableFlag = false;
    }
  }

  changeVisitSession(session, appdate) {
    this.booktime = []
    var url = ipaddress.getIp + "appointment/dbt/"
    this.updateFlag = false;
    this.timeList = [];
    if (this.typeorTime == "time") {
      var dateval = appdate.split("-");
      var send_data = {
        type: "doctor",
        doc_reg_id: this.doctorId,
        app_date: dateval[2] + "-" + dateval[1] + "-" + dateval[0],
        session: session
      }

      console.log("send_data ---" + JSON.stringify(send_data))
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "appointment/dbt/", (send_data), { headers: headers }).subscribe(
        data => {
          if (session == "Morning") {
            this.booktime = JSON.parse(JSON.stringify(data)).morning;
          } else if (session == "Afternoon") {
            this.booktime = JSON.parse(JSON.stringify(data)).afternoon;
          } else if (session == "Evening") {
            this.booktime = JSON.parse(JSON.stringify(data)).evening;
          }

         this.setTimeSlots(session, Date_Formate(appdate))
        })
    }
  }

  
  setTimeSlots(session, appdate) {
    console.log("doctor_detail_table_data ==" + JSON.stringify(this.doctorDetailData))   
    console.log("appdate ==" + JSON.stringify(appdate))
    this.time_flag = false;
    var parts = appdate.split('-');
    var year = parts[2];
    var month = parts[1];
    var date = parts[0];
    var dateval = new Date(year + "-" + (month - 1) + "-" + date).getDay() + 1;
    var availfrom, availto, timedur;
    for (var i = 0; i < this.doctorDetailData.length; i++) {
      if (session == this.doctorDetailData[i].session
        && (dateval == this.doctorDetailData[i].day_num
          || (dateval < 9 && this.doctorDetailData[i].day_num == 8)
          || this.doctorDetailData[i].day_num == 9)) {
        var From_Split = this.doctorDetailData[i].Avail_from.split(':');
        var To_Split = this.doctorDetailData[i].Avail_to.split(':');
        availfrom = this.doctorDetailData[i].Avail_from;
        availto = this.doctorDetailData[i].Avail_to;
        timedur = this.doctorDetailData[i].Time_Duration;
     
        this.time_flag = true;
        break;
      }
    }
    if (this.time_flag == true) {
      var fromday = new Date();
      var datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.currentTime);
      var curr_datetime = new Date(datetime + " " + this.currentTime);
      var curr_get_ftime = new Date(datetime + " " + availfrom);
      fromday.setHours(From_Split[0]);
      fromday.setMinutes(From_Split[1]);
      var today = new Date();
      today.setHours(To_Split[0]);
      today.setMinutes(To_Split[1]);
      console.log("fromday ----"+fromday+today)
      this.timeList = [];
      var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
      var chk_time = this.datepipe.transform(fromday, 'hh:mm');
      var end_time = this.datepipe.transform(today, 'hh:mm');
    
      var Available_flag = this.checkAvailabilty(appdate, session, chk_time, end_time);
      console.log("curr_get_ftime"+JSON.stringify(Available_flag)+this.currentDate+appdate)
      console.log("curr_get_ftime"+JSON.stringify(Available_flag)+curr_get_ftime+curr_datetime)
      if (Available_flag == true && this.currentDate == (appdate) && curr_get_ftime > curr_datetime) {
        console.log("if 777777777777777777777777")
        console.log("curr_get_fti 999me"+JSON.stringify(curr_get_ftime)+curr_datetime)
        this.timeList.push({
          Css: false,
          time:New_time1
        }
         );
      } else {
        console.log("else 777777777777777777777777")
        if (Available_flag == true && this.currentDate != (appdate)) {
          this.timeList.push({
            Css: false,
            time:New_time1
          });
        }
      }
      console.log("while"+fromday+today)
      while (fromday < today) {
        console.log("while ----"+fromday+today)
        var Day_Now = new Date();
        console.log("while ---current_date-"+this.currentDate+appdate)
        if (this.currentDate == (appdate)) {
          var Now_Split = this.currentTime.split(':');
          Day_Now.setHours(parseInt(Now_Split[0]));
          Day_Now.setMinutes(parseInt(Now_Split[1]));
        } else {
          console.log("ok else")
          Day_Now.setHours(From_Split[0]);
          Day_Now.setMinutes(From_Split[1]);
        }
        fromday.setMinutes(fromday.getMinutes() + parseInt(timedur));
        var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
        var Avail_flag = this.checkAvailabilty(appdate, session, chk_time1, end_time);
        if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
          console.log("ok ---------------")
          var New_time = this.datepipe.transform(fromday, 'hh:mm a');
          if (New_time != this.gservice.get_Timeformate(availto)) {
            this.timeList.push({
              Css: false,
              time:New_time
            }
            );
          }
        }
      }
      console.log("time slots -- "+JSON.stringify(this.timeList))
    }
    if (this.booktime != null && this.booktime.length != 0) {
      for (var i = 0; i < this.booktime.length; i++) {
        for (var j = 0; j < this.timeList.length; j++) {
          if (this.booktime[i] == this.timeList[j].time) {
            this.timeList.splice(j, 1);
          }
        }
      }
    }
    if (this.timeList.length == 0) {
      this.timeslotflag = true;

    } else {
      this.timeslotflag = false;
      this.visitTime = this.timeList[0].time;
    }
  }

  selectDob(e) {
    if (this.clntDOB != undefined) {
      this.ageRead = true;
      this.clntDOB = e;
      this.dateformate = Date_Formate(this.clntDOB)
      this.ageDisabledFlag = true;

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.ageUrl, {
        dobdate: (this.clntDOB),
        curdate: Date_Formate(this.currentDatetime),
        spl: this.splId,
      }, { headers: headers }).subscribe(
        data => {
          console.log
          var obj = JSON.parse(JSON.stringify(data));
          if (obj != undefined) {
            this.clntAge = obj.value;
          }
        });
    } else {
      this.ageRead = false;
    }
  }

  getSymptoms() {
    var searchString;
    if (this.clntSymptom.indexOf(',') > -1) {
      var dataval = this.clntSymptom.split(",");
      searchString = dataval[dataval.length - 1].trim();
    } else {
      searchString = this.clntSymptom;
    }

    if (searchString.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/', {
        symp_name: searchString
      },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.symptoms != null && obj.symptoms != undefined && obj.symptoms != "" && obj.symptoms.length != 0) {
              this.symptomsMasterList = [];
              this.symptomList = [];
              this.symptomListData = [];
              this.symptomsMasterList = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.symptomListData.push(obj.symptoms[i].description);
              }

              this.symptomList = this.symptomListData.filter(function (el) {
                return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
              }.bind(this));
            } else {
              this.symptomList = [];
            }
          },
          error => { });
    }
  }

  selectSymptom(data) {
    this.newSymptoms.push(data);
    for (var j = 0; j < this.symptomsMasterList.length; j++) {
      if (this.symptomsMasterList[j].description == data) {
        this.selectedSymptoms.push(data);
        break;
      }
    }

    if (this.clntSymptom.indexOf(',') > -1) {
      var dataval = this.clntSymptom.split(",");
      dataval.pop();
      this.clntSymptom = dataval.join(",");
      this.clntSymptom = this.clntSymptom + "," + data;
    } else {
      this.clntSymptom = data;
    }
    this.symptomList = [];
  }

  changeAge() {
    if (this.clntAge != "") {
      this.dobDisabledFlag = true;
      this.clntDOB = null;
      var dateval = this.appCurrentDate.split("-");
      if (this.clntDOB == null) {
        this.clntDOB = (parseInt(dateval[0]) - parseInt(this.clntAge)) + "-01-01";
      }

    } else {
      this.dobDisabledFlag = false;
    }
  }

  changeSalutation(e) {
    if (e == "1" || e == "6")
      this.clntGender = "Male";
    else if (e == "2" || e == "3" || e == "4" || e == "7")
      this.clntGender = "Female";
    else if (e == "5")
      this.clntGender = "Transgender";
    else if (e == "8")
      this.clntGender = "Female";
    else if (e == "10")
      this.clntGender = "Male";
    else
      this.clntGender = "";
  }

  changeGender(e) {
    if (this.clntAge != undefined && this.clntAge != null && this.clntAge != "") {
      if (e == "Male") {
        if (this.clntAge <= 15)
          this.clntSal = "6";
        else
          this.clntSal = "1";
      } else if (e == "Female") {
        if (this.clntAge <= 15)
          this.clntSal = "7";
        else
          this.clntSal = "2";
      } else if (e == "Transgender") {
        this.clntSal = "5";
      } else {
        this.clntSal = "";
      }

    } else {
      if (e == "Male") {
        this.clntSal = "1";
      } else if (e == "Female") {
        this.clntSal = "2";
      } else if (e == "Transgender") {
        this.clntSal = "5";
      } else {
        this.clntSal = "";
      }
    }
  }

  changeType(type, id, value) {
    if (type == "app") {
      if (value == "yes") {
        this.apptTypeFlag = true;
        this.appType = "New";
        this.userTypeSelect('New');

      } else {
        this.apptTypeFlag = false;
        this.appType = "Follow-up";
        this.userTypeSelect('Follow-up');
      }
 
    } else if (type == "visit") {
      if (value == "yes") {
        this.visitTypeFlag = true;
        this.visitType = "walkin";

      } else {
        this.visitTypeFlag = false;
        this.visitType = "telephonic";
      }

    } else {
      if (value == "yes") {
        this.clientTypeFlag = true;
        this.clientType = "New";
        this.userTypeSelect('New');
        this.relationId = "1";
        this.appclass = false;
        this.appdisable = true;
        this.appType = "New";
        this.searchFlag = true;
        this.apptTypeFlag = true;
        this.styleOne = false;
        this.bookFlag = true;
        if (this.userInfo.reg_clnt_app_booking == "1") {
          this.readonly = false;
        }

      } else {
        this.clientTypeFlag = false;
        this.clientType = "Existing";
        this.appclass = true;
        this.userTypeSelect('Existing');
        this.appdisable = false;
        this.styleOne = true;
        this.bookFlag = true;
      }
    }
  }

  changeAppType(id, value) {
    if (value == "walkin") {
      this.visitType = "walkin";

    } else if (value == "telephonic") {
      this.visitType = "telephonic";    

    } else if (value == "hotline") {
      this.visitType = "hotline";
    }
  }

  checkData(value) {
    var data = "";
    if (value != undefined && value != null && value != "" && value != "undefined" && value != "null")
      data = value;
    else
      data = "";
    return data;
  }

  getPatientListByName(name) {
    var send_data;
    if (name != "") {
      if (this.searchby == "patientid" && name.length > 8) {
        send_data = {
          hptl_clinic_id: this.userInfo.hptl_clinic_id,
          country: ipaddress.country_code,
          type: "patientid",
          name: this.patientName,
          prov_id: this.userInfo.user_id,
          location: "front-desk",
          center_type: this.center_type
        }

        console.log("name.length  --" + JSON.stringify(send_data))
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(JSON.stringify(response));
              console.log("verify this **************" + JSON.stringify(obj))
              this.patientList = [];
              this.filteredArr = [];
              if (obj.clients != null) {
                for (var i = 0; i < obj.clients.length; i++) {
                  var name;
                  if (obj.clients[i].middle_name != undefined) {
                    name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                  } else {
                    name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                  }
                  this.patientList.push({
                    client_reg_id: obj.clients[i].client_reg_id,
                    patient_id: obj.clients[i].patient_id,
                    relation_id: obj.clients[i].relation_id,
                    sub_rel_id: obj.clients[i].sub_rel_id,
                    first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                    full_name: name,
                    mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                  });

                  this.filteredArr.push({
                    client_reg_id: obj.clients[i].client_reg_id,
                    patient_id: obj.clients[i].patient_id,
                    relation_id: obj.clients[i].relation_id,
                    sub_rel_id: obj.clients[i].sub_rel_id,
                    first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                    full_name: name,
                    mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                  });
                }
                this.retrieveClient(this.filteredArr[0])
              }
            },
            error => { });

      } else if (name.length > 2 && this.searchby != "patientid") {
        this.patientList = [];
        this.filteredArr = [];
        send_data = {
          hptl_clinic_id: this.userInfo.hptl_clinic_id,
          country: ipaddress.country_code,
          type: this.searchby,
          name: encrypt_decript.Encript(this.patientName.toLocaleUpperCase()).toString(),
          prov_id: this.userInfo.user_id,
          location: "front-desk",
          center_type: this.center_type
        }

        console.log("name.length  --" + JSON.stringify(send_data))

        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(JSON.stringify(response));
              console.log("verify this " + JSON.stringify(obj))
              this.patientList = [];
              this.filteredArr = [];
              if (obj.clients != null) {
                for (var i = 0; i < obj.clients.length; i++) {
                  var name;
                  if (obj.clients[i].middle_name != undefined) {
                    name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                  } else {
                    name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                  }

                  this.patientList.push({
                    client_reg_id: obj.clients[i].client_reg_id,
                    patient_id: obj.clients[i].patient_id,
                    relation_id: obj.clients[i].relation_id,
                    sub_rel_id: obj.clients[i].sub_rel_id,
                    first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                    full_name: name,
                    mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                  });

                  this.filteredArr.push({
                    client_reg_id: obj.clients[i].client_reg_id,
                    patient_id: obj.clients[i].patient_id,
                    relation_id: obj.clients[i].relation_id,
                    sub_rel_id: obj.clients[i].sub_rel_id,
                    first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                    full_name: name,
                    mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                  });
                }
              }
            },
            error => { });

      } else {
        this.patientList = [];
        this.filteredArr = [];
        this.patientList = Helper_Class.getPatientList();
        this.filteredArr = Helper_Class.getPatientList();
      }
    } else {
      this.patientList = [];
      this.filteredArr = [];
      this.getPatientList()
    }
  }

  getPatientList() {//get patient list
    var send_data = {
      hptl_clinic_id: this.userInfo.hptl_clinic_id,
      country: ipaddress.country_code,
      imei: this.ipaddress,
      limit: 200,
      filterby: "Patient Id",
      type: "front-desk",
      start_no: this.currentLimit,
      prov_id: this.userInfo.user_id,
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gac', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("check cleitns--" + JSON.stringify(obj))
          if (obj.clients != null) {
            for (var i = 0; i < obj.clients.length; i++) {
              var name;
              if (obj.clients[i].middle_name != undefined) {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
              } else {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
              }
              this.patientList.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                sub_rel_id: obj.clients[i].sub_rel_id
              });

              this.filteredArr.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                sub_rel_id: obj.clients[i].sub_rel_id
              });
            }
            Helper_Class.setPatientList(this.patientList);
          }
        },
        error => { });
  }

  tableFilter(e) {
    this.patientName = e;
    if (this.patientName != undefined) {
      this.filteredArr = this.patientList.filter(item => item.full_name.toLowerCase().includes(this.patientName.toLowerCase()));
    }
  }

  // public webcamImage: WebcamImage = null;
  // private trigger: Subject<void> = new Subject<void>();
  // triggerSnapshot(): void {
  //   this.trigger.next();
  // }
  // handleImage(webcamImage: WebcamImage): void {
  //   console.info('Saved webcam image', webcamImage);
  //   this.webcamImage = webcamImage;
  //   this.camImage = new Image();
  //   this.camImage.src = this.webcamImage.imageAsDataUrl;

  //   console.log('url', this.webcamImage.imageAsBase64)
  //   this.base64 = this.webcamImage.imageAsBase64;
  //   this.imgUrl = this.webcamImage.imageAsDataUrl;
  // }

  // public get triggerObservable(): Observable<void> {
  //   return this.trigger.asObservable();
  // }
  // // ----------------------------
  // public webcamImage2: WebcamImage = null;
  // private trigger2: Subject<void> = new Subject<void>();
  // triggerSnapshot2(): void {
  //   this.trigger2.next();
  // }
  // handleImage2(webcamImage2: WebcamImage): void {
  //   console.info('Saved webcam image', webcamImage2);
  //   this.webcamImage2 = webcamImage2;
  //   this.camImage2 = new Image();
  //   this.camImage2.src = this.webcamImage2.imageAsDataUrl;

  //   console.log('url', this.webcamImage2.imageAsBase64)
  //   this.base64_2 = this.webcamImage2.imageAsBase64;
  //   this.imgUrl_2 = this.webcamImage2.imageAsDataUrl;
  // }

  // public get triggerObservable2(): Observable<void> {
  //   return this.trigger2.asObservable();
  // }




  // fileChange($event): void {
  //   this.readThis($event.target);
  // }

  // readThis(inputValue: any): void {
  //   var file = inputValue.files[0];
  //   var myReader: FileReader = new FileReader();

  //   var fileval = file.type.split('/');
  //   this.fileType = fileval[1] == "jpeg" ? "jpg" : fileval[1];

  //   if (file.type == "image/jpeg" || file.type == "image/png") {
  //     myReader.onloadend = (e) => {
  //       var fileSize = Math.round(inputValue.files[0].size / 1024);
  //       if (fileSize >= 1024) {
  //         this.toastr.error(Message_data.maxOnembImg);
  //         $("#file").val('');
  //         this.imgStr = null;
  //       } else {
  //         this.imgStr = myReader.result;
  //         $('#profile_img').attr('src', myReader.result as string);
  //       }
  //     }
  //     myReader.readAsDataURL(inputValue.files[0]);
  //   } else {
  //     this.toastr.error(Message_data.appSupportsJPGorPng);
  //   }
  // }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const element = this.scrollContainer.nativeElement;
    var stop = element.scrollTop;
    var oset = element.offsetHeight;
    var cval = stop + oset + 2;

    if (cval >= element.scrollHeight) {
      this.currentLimit += 200;
      this.getPatientList();
      console.log("reached end of scroll ", this.currentLimit)
    }
  }
  public datacheck

  getpurposeofvisit() {
    console.log("purpose of visir" + JSON.stringify({
      hptl_clinic_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id, prov_type: 'doctor'
    }))


    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp + 'mas/gvpp',
      { hptl_clinic_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id, prov_type: 'hospital' },
      { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));
          this.purposeofvisit = [];
          this.datacheck = obj.visit_purposes;
          //  Master_Helper.setmasterpurposeofvisit(obj);
          console.log("purpose of visir" + JSON.stringify(obj))
          for (var i = 0; i < this.datacheck.length; i++) {
            this.purposeofvisit.push({
              description: this.datacheck[i].visit_purpose_desc,
              prov_id: this.datacheck[i].visit_purpose_id
            });
          }

        },
        error => { });

  }

  changevisit(visit_purpose) {
    this.visit_purpose = visit_purpose
  }

  getreferencesorce() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "gen/glt", { headers: headers }).subscribe(
      data => {
        this.ref_sourcearray = [];
        var obj = JSON.parse(JSON.stringify(data));

        console.log("get ref source --" + JSON.stringify(obj))
        if (obj != undefined) {
          for (var i = 0; i < obj.lead_types.length; i++) {
            this.ref_sourcearray.push({
              lead_type_id: obj.lead_types[i].lead_type_id,
              lead_desc: obj.lead_types[i].lead_desc,
            });
          }

          //  this.changeref(this.ref_source);

        }
      });
  }

  changeref(e) {
    this.ref_source = e
  }

  spo2value(value) {
    if (value < 95) {
      this.spo2colorstyle = true;
    } else {
      this.spo2colorstyle = false;
    }
  }
  pulsevalue(value){ 
    if (value < 60 ||  value >100) {
      this.pulsecolorstyle = true;
    } else {
      this.pulsecolorstyle = false;
    }
  }
  rrvalue(value){ 
    if (value < 14 ||  value > 20) {
      this.rrcolorstyle = true;
    } else {
      this.rrcolorstyle = false;
    }
  }
  cbgvalue(value){ 
    if (value < 90 ||  value > 250) {
      this.cbgcolorstyle = true;
    } else {
      this.cbgcolorstyle = false;
    }
  }
  chnagetemp(e){
    this.temp_read=e;
  }
}

