import { Component, OnInit, ElementRef, Input, ViewEncapsulation, OnDestroy, Inject } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr'
import { CommonDataService } from '../../../app/providers/common-data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';
import { ipaddress } from '../../ipaddress';
@Component({
  selector: 'app-viewmedprespage',
  templateUrl: './viewmedprespage.component.html',
  styleUrls: ['./viewmedprespage.component.css']
})
export class ViewmedprespageComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  public pharcy_details: any = [];
  public instr_flag;
  public instructions;
  public intake_txt: string;
  public everySix: string;
  public dureWrite: string;
  public listProducts = [];
  public afterfood_txt: string;
  public tot_address;
  datePrescription: string;
  diseaseName: string;
  disease_describtion: string;
  drTotalName: string;
  totalName: string;
  hospName: any;
  hossLocation: any;
  hossCitypin: any;
  stateTXT: any;
  contactNO: any;
  hospitalView: boolean;
  public orderID;
  public workTime;
  public dure_flag: boolean;
  public pharmacy_id;
  constructor(public comm_Data: CommonDataService, private el: ElementRef, toastr: ToastrService, public router: Router, public http: Http, public dialog: MatDialog, public dialogRef: MatDialogRef<ViewmedprespageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    this.orderID = data.order;
    this.pharmacy_id = data.pharmacy_id;
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
  }
  ngOnInit() {
    this.instr_flag=true
    this.hospitalView = true;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'prescription/medpresdetpur/',
      JSON.stringify({
        order_id: this.orderID,
        pharma_id: this.pharmacy_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("object = "+JSON.stringify(obj))
          if (obj.first_name != null) {
            this.drTotalName = obj.middle_name != null && obj.middle_name != "" ? "Dr. "+obj.first_name + ' ' + obj.middle_name + ' ' + obj.last_name : "Dr. "+obj.first_name + ' ' + obj.last_name;
            this.totalName = obj.cl_middle_name != null && obj.cl_middle_name != "" ? encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_middle_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name) : encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
            if(obj.salutation_desc != undefined){
            this.totalName= obj.cl_middle_name != null && obj.cl_middle_name != "" ? obj.salutation_desc+". "+encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_middle_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name) : obj.salutation_desc+". "+encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
            }
            if (obj.hptl_name != undefined) {
              this.hospName = obj.hptl_name;
              this.hossLocation = obj.hosp_location;
              this.hossCitypin = obj.city + " - " + obj.zipcode
              this.stateTXT = obj.state + " - " + obj.country;
              this.contactNO = obj.telephone;
              this.hospitalView = false;
            }
            var PresDate = obj.date.toString().split('-');
            this.datePrescription = PresDate[2] + "-" + PresDate[1] + "-" + PresDate[0];
            this.diseaseName = obj.disease;
            if(obj.text_comment != undefined){
              this.instr_flag=false;
              this.instructions=obj.text_comment;
            }


            this.disease_describtion = obj.dis_desc;
            for (var i = 0; i < obj.drug_list.length; i++) {
              var master_timeDuration = "";
              if (obj.drug_list[i].show_short_form == "1") {
                var shortform = obj.drug_list[i].short_form;
                if (obj.drug_list[i].show_intake == "0"){
                  this.everySix = obj.drug_list[i].morning;
                }else{
                  var shortform = obj.drug_list[i].short_form;
                  if (obj.drug_list[i].intake == "1") {
                    // //=false;
                    this.everySix =  obj.drug_list[i].morning + shortform  + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  + " - 0";
                  } else if (obj.drug_list[i].intake == "2") {
                    // //=false;
                    this.everySix =  obj.drug_list[i].morning   + " - " + obj.drug_list[i].afternoon+shortform + " - "  + obj.drug_list[i].evening  + " - 0";
  
                  } else if (obj.drug_list[i].intake == "3") {
                    // //=false;
                    this.everySix =  obj.drug_list[i].morning   + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening+ " - " + obj.drug_list[i].night + shortform;
  
                    // this.everySix = "0 - 0 - "+ obj.drug_list[i].evening + shortform  + obj.drug_list[i].night + shortform;
  
                  } else if (obj.drug_list[i].intake == "4") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " +obj.drug_list[i].evening   + " - " + obj.drug_list[i].night;
                    // //=false;
  
                  } else if (obj.drug_list[i].intake == "5") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + " - " +obj.drug_list[i].evening   + " - " + obj.drug_list[i].night+ shortform;                    
                    // //=false;
  
                  } else if (obj.drug_list[i].intake == "6") {
                    this.everySix = obj.drug_list[i].morning  + " - " + obj.drug_list[i].afternoon + shortform + " - " +obj.drug_list[i].evening   + " - " + obj.drug_list[i].night+ shortform;                    
                    // //=false;
  
                  } else if (obj.drug_list[i].intake == "7") {
                    // //=false;
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform ;
  
                  } else if (obj.drug_list[i].intake == "8") {
                    // //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform;
  
                  } else if (obj.drug_list[i].intake == "9") {
                    // //this.param_four=true;
                    this.everySix = obj.drug_list[i].morning + shortform + " - Every 6 hours";
  
                  } else if (obj.drug_list[i].intake == "10") {
                    // //this.param_four=true;
                    this.everySix = obj.drug_list[i].morning + shortform + " - Every 8 hours";
  
                  } else if (obj.drug_list[i].intake == "11") {
                    // //this.param_four=true;
                    this.everySix = obj.drug_list[i].morning + shortform + " - Every 12 hours ";
  
                  } else if (obj.drug_list[i].intake == "12") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - Once a day";
                    // //this.param_four=true;
  
                  } else if (obj.drug_list[i].intake == "13") {
                    this.everySix = obj.drug_list[i].morning + shortform + " SOS - if required";
                    // //this.param_four=true;
  
                  } else if (obj.drug_list[i].intake == "14") {
                    // //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform;
  
                  } else if (obj.drug_list[i].intake == "15") {
                    // //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform ;
  
                  } else if (obj.drug_list[i].intake == "16") {
                    // //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform ;
  
                  } else if (obj.drug_list[i].intake == "17") {
                    // //this.param_four=true;
                    this.everySix = obj.drug_list[i].morning + shortform + " STAT";
                  }
                  else if (obj.drug_list[i].intake == "18") {
                    // //this.param_four=true;
                    this.everySix = obj.drug_list[i].morning + shortform + "Once a month";
                  }
                  else if (obj.drug_list[i].intake == "19") {
                    // //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning  + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night  ;
  
                  }
                  else if (obj.drug_list[i].intake == "20") {
                    // //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " + obj.drug_list[i].evening + shortform + " - " + obj.drug_list[i].night + shortform ;
  
                  }
                  else if (obj.drug_list[i].intake == "21") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " +obj.drug_list[i].evening   + " - " + obj.drug_list[i].night;
                    // //this.param_four=false;
  
                  }
                  else if (obj.drug_list[i].intake == "22") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " +obj.drug_list[i].evening   + " - " + obj.drug_list[i].night;
                    // //this.param_four=false;
  
                  }
                  else if (obj.drug_list[i].intake == "23") {
                    this.everySix = obj.drug_list[i].morning + shortform + " - " + obj.drug_list[i].afternoon + shortform + " - " +obj.drug_list[i].evening   + " - " + obj.drug_list[i].night;
                    // //this.param_four=false;
  
                  }
                  
                }
                
                if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                  this.dureWrite = obj.drug_list[i].time_duration + " " + "mins";
                } else {
                  this.dureWrite = "";
                  this.dure_flag = true;
                }
                if (this.afterfood_txt == undefined) {
                  this.afterfood_txt = "";
                }
                var frequency;
                if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks") {
                  frequency = this.intake_txt;
                } else {
                  frequency = "Daily";
                }
                this.listProducts.push({
                  med_typetxt: obj.drug_list[i].drug_type_name,
                  drug_id: obj.drug_list[i].drug_id,
                  drug_name: obj.drug_list[i].drug_name,
                  days: obj.drug_list[i].days,
                  period: obj.drug_list[i].period,
                  drug_intake: obj.drug_list[i].drug_intake,
                  intake: obj.drug_list[i].intake,
                  every_six: this.everySix,
                  dure_txt_table: this.dureWrite,
                  time_duration: obj.drug_list[i].time_duration,
                  morning: obj.drug_list[i].morning,
                  afternoon: obj.drug_list[i].afternoon,
                  evening: obj.drug_list[i].evening,
                  drug_type_id: obj.drug_list[i].drug_type_id,
                  frequency: frequency,
                  show_intake: obj.drug_list[i].show_intake,
                  
                });
              } else {
                if (obj.drug_list[i].show_intake == "0") {
                  this.everySix = obj.drug_list[i].morning;
                }else{
                  if (obj.drug_list[i].intake == "1") {
                    //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  } else if (obj.drug_list[i].intake == "2") {
                     //this.param_four=false;
                     this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening   +  " - "  + obj.drug_list[i].night;
  
                  } else if (obj.drug_list[i].intake == "3") {
                    //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  } else if (obj.drug_list[i].intake == "4") {
                    //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  } else if (obj.drug_list[i].intake == "5") {
                    //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - " + obj.drug_list[i].evening+ " - "   + obj.drug_list[i].night;
  
                  } else if (obj.drug_list[i].intake == "6") {
                    //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - " + obj.drug_list[i].evening+ " - "   + obj.drug_list[i].night;
  
                  } else if (obj.drug_list[i].intake == "7") {
                    //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening  + " - " + obj.drug_list[i].night  ;
  
                  } else if (obj.drug_list[i].intake == "8") {
                    //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
  
                  } else if (obj.drug_list[i].intake == "9") {
                    this.everySix = obj.drug_list[i].morning + " - Every 6 hours";
                    //this.param_four=true;
  
                  } else if (obj.drug_list[i].intake == "10") {
                    this.everySix = obj.drug_list[i].morning + " - Every 8 hours";
                    //this.param_four=true;
  
                  } else if (obj.drug_list[i].intake == "11") {
                    this.everySix = obj.drug_list[i].morning + " - Every 12 hours";
                    //this.param_four=true;
  
                  } else if (obj.drug_list[i].intake == "12") {
                    this.everySix = obj.drug_list[i].morning + " - Once a day";
                    //this.param_four=true;
  
                  } else if (obj.drug_list[i].intake == "13") {
                    this.everySix = obj.drug_list[i].morning + " SOS - if required";
                    //this.param_four=true;
  
                  } else if (obj.drug_list[i].intake == "14") {
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night ;
                    //this.param_four=true;
                  } else if (obj.drug_list[i].intake == "15") {
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                    //this.param_four=true;
                  } else if (obj.drug_list[i].intake == "16") {
                    //this.param_four=true;
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night ;
  
                  } else if (obj.drug_list[i].intake == "17") {
                    //this.param_four=true;
                    this.everySix = obj.drug_list[i].morning + " STAT";
                  }
                  else if (obj.drug_list[i].intake == "18") {
                    //this.param_four=true;
                    this.everySix = obj.drug_list[i].morning + " Once a month";
                  }
                  else if (obj.drug_list[i].intake == "19") {
                    //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning  + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening  + " - " + obj.drug_list[i].night  ;
  
                  }
                  else if (obj.drug_list[i].intake == "20") {
                    //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning  + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening  + " - " + obj.drug_list[i].night  ;
  
                  }
                  else if (obj.drug_list[i].intake == "21") {
                    //this.param_four=false;
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  }
                  else if (obj.drug_list[i].intake == "22") {
                    // =false;
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  }
                  else if (obj.drug_list[i].intake == "23") {
                    // //=false;
                    this.everySix = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  }
                }
                
                if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "") {
                  this.dureWrite = obj.drug_list[i].time_duration + " " + "mins";
                } else {
                  this.dureWrite = "";
                  this.dure_flag = true;
                }
                if (this.afterfood_txt == undefined) {
                  this.afterfood_txt = "";
                }
                var frequency;
                if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks") {
                  frequency = this.intake_txt;
                } else {
                  frequency = "Daily";
                }
                this.listProducts.push({
                  med_typetxt: obj.drug_list[i].drug_type_name,
                  drug_id: obj.drug_list[i].drug_id,
                  drug_name: obj.drug_list[i].drug_name,
                  days: obj.drug_list[i].days,
                  period: obj.drug_list[i].period,
                  drug_intake: obj.drug_list[i].drug_intake,
                  intake: obj.drug_list[i].intake,
                  every_six: this.everySix,
                  dure_txt_table: this.dureWrite,
                  time_duration: obj.drug_list[i].time_duration,
                  morning: obj.drug_list[i].morning,
                  afternoon: obj.drug_list[i].afternoon,
                  evening: obj.drug_list[i].evening,
                  night:obj.drug_list[i].night,
                  drug_type_id: obj.drug_list[i].drug_type_id,
                  frequency: frequency,
                  show_intake: obj.drug_list[i].show_intake,
                });
              }
            }
          }
        });
  }
  closePopup() {
    this.dialogRef.close();
  }
  onNoClick() {
    this.dialogRef.close();
  }
}
