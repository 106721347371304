<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Create advances</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="close()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="icd_content">
  <div class="Details" style="padding:5px 15px; min-width: 530px; width: 1090px;">
    <div class="row">
      <div class="col-12" *ngIf="createpage_flag">
        <div class="cover_div" >
          <div class="header_lable">Patient details</div>
            <div class="content_cover">             
              <div class="row">
                <div class="col-2" style="text-align: center;">
                  <img src="{{this.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                    class="profileimage">
                </div>
                <div class="col-10">
                  <div class="row">                  
                    <div class="col-3">
                      <table style="width: 100%;" class="head-table">
                        <tbody>
                          <tr class="head-tr">
                            <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                              <b>MR No</b></td>
                            <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{patientId}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-3">
                      <table style="width: 100%;" class="head-table">
                        <tbody>
                          <tr class="head-tr">
                            <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                              <b>Patient name</b></td>
                            <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                              {{salutation_desc}}{{clntFirstName}} <span *ngIf="clntMiddleName != undefined">{{clntMiddleName}}</span> {{clntLastName}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-3">
                      <table style="width: 100%;" class="head-table">
                        <tbody>
                          <tr class="head-tr">
                            <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                              <b>Age/Gender</b></td>
                            <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                              {{clntAge}} <span *ngIf="clntGenShort != '' && clntGenShort != undefined">/</span> {{clntGenShort}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-3" *ngIf="mobile_no != undefined && mobile_no != ''">
                      <table style="width: 100%;" class="head-table">
                        <tbody>
                          <tr class="head-tr">
                            <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                              <b>Mobile</b></td>
                            <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{mobile_no}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>      
                    <div class="col-3">
                      <table style="width: 100%;" class="head-table">
                        <tbody>
                          <tr class="head-tr">
                            <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                              <b>Doctor</b></td>
                            <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{docName}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>   
                    <div class="col-3">
                      <table style="width: 100%;" class="head-table">
                        <tbody>
                          <tr class="head-tr">
                            <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                              <b>DOA</b></td>
                            <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                              {{admitted_date | date : "dd-MM-yyyy"}} {{admitted_time}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>  
                    <div class="col-3" *ngIf="em_contact != '' && em_contact != undefined">
                      <table style="width: 100%;" class="head-table">
                        <tbody>
                          <tr class="head-tr">
                            <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                              <b>Emerg contact</b></td>
                            <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{em_contact}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>                                                          
                    <div class="col-6">
                      <table style="width: 100%;" class="head-table">
                         <tbody>
                            <tr class="head-tr">
                               <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Address</b></td>
                               <td class="head-td" style="width: 10px; font-weight: 500;">:&nbsp;</td>
                               <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{clntAddress1}} {{clntAddress2}} <span *ngIf="clntLocation != undefined">{{clntLocation}}</span> {{clntCity}} {{clntZipcode}} {{clntState}} <span *ngIf="clntCountry != undefined">{{clntCountry}}</span></td>
                            </tr>                 
                         </tbody>
                      </table>
                    </div>
                  </div>      
                </div>
              </div>               
            </div>
        </div>
      </div> 

      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
        <mat-label class="matlabel">Filter by<br>
          <select required class="ipcss" [(ngModel)]="filter" required (change)="changeFilter()"
                  disableOptionCentering>                                        
            <option value="Date">Date</option>
            <option value="Name">Name</option>
            <option value="Patient_ID">MR.No</option>
            <option value="Mobile_No">Mobile No</option>
          </select>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">   

        <mat-label class="matlabel" [hidden]="toFlag">Date<br>
          <input type="date" class="ipcss" style="height: 25px;" (change)="OnDateChangedto(date)"
            [hidden]="fromFlag" [(ngModel)]="date" #matInput max="{{currentDate}}">
        </mat-label>
      
        <mat-label class="matlabel" [hidden]="firstNameFlag">First name<br>
          <input  #focusFName id="focusFName" matInput  class="ipcss" style="height: 25px !important;" type="text" [hidden]="firstNameFlag" required
            [(ngModel)]="firstName" (blur)="fnameToUpper()" />
        </mat-label>
        
        <mat-label class="matlabel" [hidden]="uhidFlag">MR.No<br>
          <input #focusMRNo id="focusMRNo" matInput class="ipcss" type="text" style="height: 25px;" [hidden]="uhidFlag" required
            [(ngModel)]="patientId" (change)="getPatientListByMRNo(patientId)" />
        </mat-label>

        <mat-label class="matlabel" [hidden]="mobileFlag">Mobile No<br>
          <input #focusMobileNo id="focusMobileNo" matInput class="ipcss" style="height: 25px;" type="text" [hidden]="mobileFlag" required
            [(ngModel)]="mobileNo" (change)="getPatientListByMobile_no(mobileNo)"/>
        </mat-label>                            
      </div>   

      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="firstNameFlag"> 
        <mat-label class="matlabel" [hidden]="firstNameFlag">Last name<br>
          <input matInput class="ipcss" style="height: 25px;" type="text" [hidden]="firstNameFlag" required
            [(ngModel)]="lastName" (blur)="lnameToUpper()" />
        </mat-label>
      </div>
      <div class="col-1  p-0" [hidden]="!mr_no_flag"> 
        <mat-label class="matlabel">
          <a (click)="searchPatient()" ><img src="../../../assets/ui_icons/search_icon.svg"
              class="searchButton" /></a> 
        </mat-label>
      </div>     
      
      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2">
        <mat-label class="matlabel">Patient Name<br>
          <select required class="ipcss" [(ngModel)]="clientName" disableOptionCentering    
            (change)="changeAppointment(clientName)">
            <option *ngFor="let location of appointmentListArray" value="{{location.docid_name}}">
              {{location.docid_name}}
            </option>
          </select>
        </mat-label>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 select_bottom">
        <mat-label class="matlabel">Payment type<br>
          <select class="ipcss " [(ngModel)]="payType" (change)="changePayType()" disableOptionCentering>
            <option *ngFor="let pay of paytypelist" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
          </select>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 select_bottom" [hidden]="cardFlag">
        <mat-label class="matlabel">Card No <br>
          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" [required]="cardreqflag">
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 select_bottom" [hidden]="cardFlag">
        <mat-label class="matlabel">Holder name<br>
          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 select_bottom" [hidden]="transactionFlag">
        <mat-label class="matlabel">Transaction ID<br>
          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionId" [required]="transreqflag"/>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 select_bottom" [hidden]="chequeFlag">
        <mat-label class="matlabel">Cheque No <br>
          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" [required]="chequereqflag"/>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 select_bottom" [hidden]="chequeFlag">
        <mat-label class="matlabel">Bank Name <br>
          <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" [required]="chequereqflag"/>
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" *ngIf="advanceFlag">
        <mat-label class="matlabel">Advance<br>
          <input matInput class="ipcss widthbilllist" type="text" required [(ngModel)]="advance"
            style="margin-bottom: 10px;" maxlength="9"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
        </mat-label>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" style="padding-right: 85px;"> <!--col-1  p-0-->
        <mat-label class="matlabel"><br>
          <a (click)="addAdvance()">
             <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo add_align btn-outline-success rounded"
             style="float: right;cursor: pointer;"/>
          </a>                
        </mat-label>
      </div>
      <div class="col-12">
        <p *ngIf="advanceArray.length == 0" class="nodata"> No advance found </p>
        <div class="dig_table_overflow" *ngIf="advanceArray.length != 0">
          <table style="width:100%;" class="card_tbl table-dynamic table-hover">
            <thead>
              <tr>
                <th> S.No </th>
                <th> Payment type </th>
                <th> Advance </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let advance of advanceArray; let i = index">
                <td style="text-align: center;font-family: Arial, Helvetica, sans-serif;font-size: 0.9rem;"> {{ i + 1 }} </td>
                <td style="text-align: center;font-family: Arial, Helvetica, sans-serif;font-size: 0.9rem;"> {{ advance.pay_desc }} </td>
                <td style="text-align: right;font-family: Arial, Helvetica, sans-serif;font-size: 0.9rem;"> {{ advance.amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div [hidden]="true"  #printbanner id="printbanner">
  <div class="bill_border">       
    <table style="width: 100%;">
      <thead>
        <tr>
          <th style="width:100%;">                                 
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
              <div style="width: 100%; height: 205px !important;"> 
                <div style="width: 100%;">
                  <img alt="image" src={{hospitalLogo}}  [style]="printlogostyle">
                </div>                                                             
                <table style="width: 100%;font-size: 12px;border: 1px solid black;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;height: 95px;">                
                  <tbody>
                    <tr style="height: 14px;">
                      <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                      <td>: {{salutation_desc}}{{clntFirstName}} <span *ngIf="clntMiddleName != ''">{{clntMiddleName}}</span> 
                            {{clntLastName}} </td>
                      <td style="padding-left: 5px;"><strong>Address</strong></td>
                      <td>: {{clntAddress1}} {{clntAddress2}}</td>
                    </tr>
                    <tr style="height: 14px;">
                      <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                      <td>: {{clntAge}} / {{clntGenShort}}</td>
                      <td style="padding-left: 5px;"><strong></strong></td>
                      <td><span class="address_visibility">:</span> {{clntLocation}} {{ clntCity}} {{clntZipcode}}</td>
                    </tr> 
                    <tr style="height: 14px;">
                      <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                      <td>: {{docName}}</td>
                      <td style="padding-left: 5px;"><strong></strong></td>
                      <td><span class="address_visibility">:</span> {{clntState}} {{clntCountry}}</td>
                    </tr> 
                    <tr>
                      <td style="padding-left: 5px;"><strong>Advance Bill No</strong></td>
                      <td>: {{billId}}</td>
                    </tr>
                    <tr style="height: 14px;">
                      <td style="padding-left: 5px;"><strong>Advance Date/Time</strong></td>
                      <td>: {{currentDate | date : "dd-MM-yyyy"}}&nbsp;{{timevalue}}</td>
                    </tr>                                   
                    <tr>
                      <td style="padding-left: 5px;"><strong></strong></td>
                      <td>
                      </td> 
                      <td style="padding-left: 5px;"><strong></strong></td>
                      <td *ngIf="barcode_flag">
                        <!-- <ngx-barcode class="barcode" [bc-value]="patientId" [bc-display-value]="displayValue"
                          [bc-line-color]="lineColor" [element-type]="elementType" [format]="format" [bc-width]="width"
                          [bc-height]="barcodeheight" [bc-font-options]="fontOptions" [bc-font]="font"
                          [bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin"
                          [bc-font-size]="fontSize" [bc-background]="background" [bc-margin]="margin"
                          [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft"
                          [bc-margin-right]="marginRight"></ngx-barcode> -->

                        <img style="width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;"
                          src={{barcode_url}} /></td> 
                    </tr>                         
                  </tbody>
                </table>
              </div>
            </div>               
          </th>
        </tr>
      </thead>
      <tbody> 
        <tr>
          <td class="report-content-cell">
            <div class="main">   
              <p style="text-align: center;font-size: 16px; margin-top: 0px; margin-bottom: 5px;"><b>Advance bill</b></p>
              <table *ngIf="advanceArray.length != 0" style="margin-left: 20px;border: 1px solid black;width: 93%;border-collapse: collapse; text-align:center;">
                <thead>
                  <tr style="border: 1px solid black;border-collapse: collapse; text-align:center; ">
                    <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> S.No </th>
                    <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Payment type </th>
                    <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Advance </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let advance of advanceArray; let i = index" style="border: 1px solid black;border-collapse: collapse; text-align:center; ">
                    <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{ i + 1 }} </td>
                    <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{ advance.pay_desc }} </td>
                    <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{ advance.amount }}</td>
                  </tr>
                  <tr style="border: 1px solid black;border-collapse: collapse; text-align:center; "> <td colspan="2" style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Total amount </td > <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{totalAdvance}} </td> </tr>
                </tbody>
              </table>  
              <table style="margin-left: 20px;width: 93%;border-collapse: collapse; text-align:left;margin-top: 5px;" *ngIf="numToWords != undefined && totalAdvance != 0">
                <tr>
                  <td style="vertical-align: top;"><strong>In Words:</strong>Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot style="margin-bottom: 10px;"> 
        <tr>  
          <td width="100%">  
            <table style="width: 100%;">  
              <tr>  
                <td style="vertical-align: top;width:50%;">
                  <table style="float: left !important;margin-left: 15px;">
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </table> 
                </td>  
                <td style="vertical-align: top;width:50%;">
                  <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                    <tr>
                      <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                        <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                        <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                      </div>                       
                    </tr>
                  </table>                      
                </td>
              </tr>  
            </table> 
          </td>
        </tr> 
      </tfoot>  
    </table>   
  </div>
</div>

<div [hidden]="true" #printnoheader id="printnoheader" >
  <div class="bill_border">       
    <table style="width: 100%;">
      <thead>
        <tr>
          <th style="width:100%;">
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
              <div style="width: 100%; height: 95px;">
                <div style="height: 5px !important;"></div>
                <table style="width: 100%;font-size: 12px;border: 1px solid black;border-radius: 10px;height: 85px;"> 
                  <tbody>
                    <tr style="height: 14px;">
                      <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                      <td>: {{salutation_desc}}{{clntFirstName}} <span *ngIf="clntMiddleName != ''">{{clntMiddleName}}</span> 
                            {{clntLastName}} </td>
                      <td style="padding-left: 5px;"><strong>Address</strong></td>
                      <td>: {{clntAddress1}} {{clntAddress2}}</td>
                    </tr>
                    <tr style="height: 14px;">
                      <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                      <td>: {{clntAge}} / {{clntGenShort}}</td>
                      <td style="padding-left: 5px;"><strong></strong></td>
                      <td><span class="address_visibility">:</span> {{clntLocation}} {{ clntCity}} {{clntZipcode}}</td>
                    </tr> 
                    <tr style="height: 14px;">
                      <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                      <td>: {{docName}}</td>
                      <td style="padding-left: 5px;"><strong></strong></td>
                      <td><span class="address_visibility">:</span> {{clntState}} {{clntCountry}}</td>
                    </tr> 
                    <tr>
                      <td style="padding-left: 5px;"><strong>Advance Bill No</strong></td>
                      <td>: {{billId}}</td>
                    </tr>
                    <tr style="height: 14px;">
                      <td style="padding-left: 5px;"><strong>Advance Date/Time</strong></td>
                      <td>: {{currentDate | date : "dd-MM-yyyy"}}&nbsp;{{timevalue}}</td>
                    </tr>                                   
                    <tr>
                      <td style="padding-left: 5px;"><strong></strong></td>
                      <td>
                      </td> 
                      <td style="padding-left: 5px;"><strong></strong></td>
                      <td *ngIf="barcode_flag">
                        <img style="width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;"
                          src={{barcode_url}} /></td> 
                    </tr>                                            
                  </tbody>
                </table>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody> 
        <tr>
          <td class="report-content-cell">
            <div class="main">   
              <p style="text-align: center;font-size: 16px; margin-top: 0px; margin-bottom: 5px;"><b>Advance bill</b></p>
              <table *ngIf="advanceArray.length != 0" style="margin-left: 20px;border: 1px solid black;width: 93%;border-collapse: collapse; text-align:center;">
                <thead>
                  <tr style="border: 1px solid black;border-collapse: collapse; text-align:center; ">
                    <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> S.No </th>
                    <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Payment type </th>
                    <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Advance </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let advance of advanceArray; let i = index" style="border: 1px solid black;border-collapse: collapse; text-align:center; ">
                    <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{ i + 1 }} </td>
                    <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{ advance.pay_desc }} </td>
                    <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{ advance.amount }}</td>
                  </tr>
                  <tr style="border: 1px solid black;border-collapse: collapse; text-align:center; "> <td colspan="2" style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Total amount </td > <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{totalAdvance}} </td> </tr>
                </tbody>
              </table>  
              <table style="margin-left: 20px;width: 93%;border-collapse: collapse; text-align:left;margin-top: 5px;" *ngIf="numToWords != undefined && totalAdvance != 0">
                <tr>
                  <td style="vertical-align: top;"><strong>In Words:</strong>Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot style="margin-bottom: 10px;"> 
        <tr>  
          <td width="100%">  
            <table style="width: 100%;">  
              <tr>  
                <td style="vertical-align: top;width:50%;">
                  <table style="float: left !important;margin-left: 15px;">
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </table> 
                </td>  
                <td style="vertical-align: top;width:50%;">
                  <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                    <tr>
                      <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                        <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                        <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                      </div>                       
                    </tr>
                  </table>                      
                </td>
              </tr>  
            </table> 
          </td>
        </tr> 
      </tfoot> 
    </table>   
  </div>
</div> 

<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  <button id="add_btn" mat-button *ngIf="advanceFlag">
    <img src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save()" class="addimgbtn_icd" />
  </button>
</div>