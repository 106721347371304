import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import * as $ from 'jquery/dist/jquery.min.js';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MenuViewService } from '../../common-module/menu-view/menu-view.service';
import { Time_Formate, ConvertTimeformat, Nurse_Day_Return } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Chart } from 'chart.js';
import { Date_Formate } from '../../../assets/js/common';
import { Master_Helper } from '../../Doctor_module/Master_Helper';
import * as HighCharts from 'highcharts';
import { response } from 'express';
import { send } from 'process';
import { FrontDesk_Helper } from "../../../app/FrontDesk_module/FrontDesk_Helper";

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  tabtype: string;
  // public ModeOfPayCanvas: any;
  public modeOfPayCanvas: any;
  public modeOfPayCanvasIp: any;
  public modeOfPayCanvasIpAdm: any;
  public billEstCompletedIpAdm: any;
  billCountIpAdm: any;
  IndentsIpAdm: any;
  billAmtIpAdm: any;
  currentDate: any;
  from_date: any;
  to_date: any;
  f_date: string;
  t_date: string;
  appointment_type: string;
  interval: string;
  range: string;
  is_admin: boolean;
  user_id: string;
  hosp_id: any;
  admin: any;

  public op_estimate: any;
  public ip_estimate: any;
  pending_indents: any;
  complete_pos_indents: any;
  total_indents: number;
  op_comp_estimate: any;
  op_pending_estimate: any;
  total_Op_estimate: number;
  ip_comp_estimate: any;
  ip_pending_estimate: any;
  total_Ip_estimate: number;
  op_bill_count: number;
  op_bill_amount: any;
  ip_bill_count: any;
  ip_bill_amount: any;
  userInfo: any;
  hospital_details: any;
  hospBillsOpAdm: any;
  pharmBillsOpAdm: any;
  diagBillsOpAdm: any;
  pharmabillAmtIpAdm: any;
  pharmabillAmtOpAdm: any;
  diagbillAmtOpAdm: any;
  doc_completedApp: any;
  doc_openApp: any;
  doc_confirmedApp: any;
  doc_inpatApp: any;
  pharmBillsIpAdm: any;
  hospBillsIpAdm: any;
  diagBillsIpAdm: any;
  diagbillAmtIpAdm: any;
  hospbillAmtOpAdm: any;
  hospbillAmtIpAdm: any;
  hosp_totalOpBillAmt: any;
  diag_totalOpBillAmt: any;
  diag_totalIpBillAmt: number;
  hosp_totalIpBillAmt: number;
  total_docApps: number;
  diag_completedApp: any;
  diag_openApp: any;
  total_diagApps: number;
  isBar_pharma: boolean;
  isBar_hosp: boolean;
  pharmaBillsPaytypeOpAdm: any;
  hospBillsPaytypeOpAdm: any;
  hospBillsPaytypeOpAdm_Bar: any;
  isBar_hosp_Ip: boolean;
  pharmaBillsPaytypeIpAdm: any;
  hospBillsPaytypeIpAdm: any;
  hospBillsPaytypeIpAdm_Bar: any;
  pharmaBillsPaytypeIpAdm_Bar: any;
  isBar_pharma_Ip: boolean;
  isBar_diag: boolean;
  diagBillsPaytypeOpAdm: any;
  diagBillsPaytypeOpAdm_Bar: any;
  diagBillsPaytypeIpAdm_Bar: any;
  diagBillsPaytypeIpAdm: any;
  //new
  appointments_chart: any;
  diagnosticorder_chart: any;
  public diagnostic_total: any;
  public diagnostic_complete: any;
  public diagnostic_pending: any;
  revenue_chart: any;
  public billing_chart: any;
  public payment_chart: any;
  inpatients_chart: any;
  public medicinearray: any[] = [];
  public Revenue_Total: any;
  public Revenue_Expenses: any;
  public Revenue_Earnings: any;
  public inpatients: any;
  public inpatient_noninsure: any;
  public inpatient_insure: any;
  public inpatient_mlc: any;
  public appoint_total_app: any;
  public appoint_new_app: any;
  public appoint_followup_app: any;
  public appoint_completed: any;
  public appoint_not_visited: any;
  public appoint_pending: any;
  public appoint_non_discount: any;
  public appoint_discounted: any;
  public appoint_walkin: any;
  public appoint_telephonic: any;
  public bill_dept_type: string = 'hospital';
  public hosp_dates_array: any = [];
  public hosp_data_array: any = [];
  public pharma_dates_array: any = [];
  public pharma_data_array: any = [];
  public diag_dates_array: any = [];
  public diag_data_array: any = [];
  public dept_payment: string = 'hospital';
  public hosp_payment_array: any = [];
  public diag_payment_array: any = [];
  public pharma_payment_array: any = [];
  public stockval_stock: string;
  public stockval_purchase: string;
  public stockval_sale: string;
  public stockvalue_chart: any;
  public ambulance_chart: any;
  public ambulancevalue_total: string;
  public ambulancevalue_completed: string;
  public ambulancevalue_pending: string;
  public currentLimit = 1;
  public totalemp: any = 0;
  public emppresent: any = 0;



  constructor(public http: Http, public httpp: HttpClient, private Menuservice: MenuViewService, public gservice: CommonDataService) {
    this.appointment_type = 'Weekly';
    this.tabtype = 'outpatient';
    this.interval = '7';
    this.range = 'weekly';
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.user_id = this.userInfo.user_id;

    // setTimeout(() => {
    //   // this.getModeOfPayments();
    //   // this.getModeOfPayments_Ip();
    //   this.getModeOfPayments_IpAdm();
    //   this.getbillEstCompleted_IpAdm();
    //   this.getbillCount_IpAdm();
    //   this.getIndents_IpAdm();
    //   this.getbillAmt_IpAdm();
    // }, 500);

    if (this.userInfo.admin == '1' || this.userInfo.user_type == 'Admin') {
      this.is_admin = true;
      this.user_id = 'All';
    } else {
      this.user_id = this.userInfo.user_id;
      this.is_admin = false;
    }

    this.hospital_details = Helper_Class.getHospitalInfo();
    this.hosp_id = this.hospital_details.hptl_clinic_id;
    this.admin = this.userInfo.admin;
    this.get_curr_date();
    this.getmedicine_details()
    // this.hospital_name = this.hospital_details.hosp_name;   
    // this.hospital_logo = ipaddress.Ip_with_img_address + this.hospital_details.hptl_logo; 
    // this.hosp_id = this.userInfo.hosp_id;
  }

  // ----------------------------Pharma Dashboard
  selectTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    if (tab == 'inpatient') {
      this.tabtype = 'inpatient';
      // this.getModeOfPayments_Ip();
      // this.getModeOfPayments_IpAdm();
      // this.getbillEstCompleted_IpAdm();
      // this.getbillCount_IpAdm();
      // this.getIndents_IpAdm();
      // this.getbillAmt_IpAdm();

      // this.user_base();
      this.getFrontdeskDashdata();
      this.Appointment_type_change();
    } else {
      this.tabtype = 'outpatient';
      // this.getModeOfPayments();

      // this.user_base();
      this.getFrontdeskDashdata();
      this.Appointment_type_change();
    }
  }

  get_curr_date() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code,
    }),
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);

          if (this.tabtype == 'outpatient') {
            if (obj != undefined) {
              this.currentDate = obj.current_date;
              // this.curr_date = obj.current_date;
              this.from_date = obj.current_date;
              this.to_date = obj.current_date;
              this.f_date = Date_Formate(this.currentDate);
              this.t_date = Date_Formate(this.currentDate);
              this.Appointment_type_change();
              this.getFrontdeskDashdata();
            }
          } else {
            if (obj != undefined) {
              // this.curr_date = obj.current_date;
              this.currentDate = obj.current_date;
              this.f_date = Date_Formate(this.currentDate);
              this.t_date = Date_Formate(this.currentDate);
              this.Appointment_type_change();
              this.getFrontdeskDashdata();
            }
          }


        },
      )

  }

  getDateFormate(d) {
    var month = (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + '-' + month + '-' + day;
    return temp;
  }

  Appointment_type_change() {
    var e;

    if (this.appointment_type == 'Today') {
      e = this.currentDate;
      this.from_date = e;
      this.interval = '0';
      this.range = 'today';
    } else if (this.appointment_type == 'Weekly') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 7);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '7';
      this.range = 'weekly';
    } else if (this.appointment_type == 'Fort Night') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 14);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '14';
      this.range = 'fort night';
    } else if (this.appointment_type == 'Monthly') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 30);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '30';
      this.range = 'monthly';
    } else if (this.appointment_type == '3 Months') {
      var d = new Date();
      d.setDate(d.getDate() - 92);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '92';
      this.range = '3 months';
    } else if (this.appointment_type == '6 Months') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 183);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '183';
      this.range = '6 months';
    } else if (this.appointment_type == 'Yearly') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 365);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '365';
      this.range = '12 months';
    } else {
    }

    if (this.tabtype == 'outpatient') {
      this.f_date = Date_Formate(e);
      this.user_base();
      this.getFrontdeskDashdata();

    } else if (this.tabtype == 'inpatient') {
      this.f_date = Date_Formate(e);
      this.user_base();
      this.getFrontdeskDashdata();

    } else {
      console.log('allrt');
    }
  }

  getHospBills_OpAdm(hosp_bills, hosp_data, hosp_dates) {
    // var data = ["1500", "2000", "2500", "5500", "5000"];
    // var labels = ["Total", "Completed", "Pending"]
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    console.log("getHospBills_OpAdm------", hosp_bills, hosp_data, hosp_dates)

    var dataset_array = [];
    if (hosp_data != undefined) {
      for (var i = 0; i < hosp_data.length; i++) {
        dataset_array.push({
          data: hosp_data[i].op_bill_amount,
          fill: false,
          backgroundColor: backgroundColors[i],
          borderColor: backgroundColors[i],
          spanGaps: true,
          label: hosp_data[i].created_by
        })
      }

      var hospital_dates = [];
      for (var l = 0; l < hosp_dates.length; l++) {
        hospital_dates.push(
          Date_Formate(hosp_dates[l])
        )
      }
    }

    console.log("Datasetarray------", dataset_array)


    // console.log(hospital_dates);

    if (this.hospBillsOpAdm) {
      this.hospBillsOpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("hospBillsOpAdm");
    ctx.height = 245;
    ctx.width = 515;
    this.hospBillsOpAdm = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: hospital_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });

    // Hospital Total Amount Graph data 

    var data_array = [];
    var labels = [];
    this.hosp_totalOpBillAmt = 0;

    if (hosp_data != undefined) {
      for (var j = 0; j < hosp_data.length; j++) {
        var total_op_amt = 0;
        for (var k = 0; k < hosp_data[j].op_bill_amount.length; k++) {
          total_op_amt += parseFloat(hosp_data[j].op_bill_amount[k])
        }
        data_array.push(total_op_amt)
        labels.push(hosp_data[j].created_by)
        this.hosp_totalOpBillAmt += total_op_amt
      }
    }

    // var hosptotalOpBillAmt = this.hosp_totalOpBillAmt.toString();
    this.hosp_totalOpBillAmt = this.hosp_totalOpBillAmt.toFixed(2);

    if (this.hospbillAmtOpAdm) {
      this.hospbillAmtOpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("hospbillAmtOpAdm");
    ctx.height = 260;
    // ctx.width = 515;
    this.hospbillAmtOpAdm = new Chart(ctx, {
      type: "polarArea",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data_array,
            fill: false,
            backgroundColor: ['rgb(255, 99, 132, 0.4)',
              'rgb(75, 192, 192, 0.4)',
              'rgb(255, 205, 86, 0.4)',
              'rgb(201, 203, 207, 0.4)',
              'rgb(54, 162, 235), 0.4'],
          }
        ]
      },
      options: {
      }
    });
  }

  getPharmBills_OpAdm(pharm_bills, pharm_data, pharm_dates) {
    // var data = ["1500", "2000", "2500", "5500", "5000"];
    // var labels = ["Total", "Completed", "Pending"]
    var backgroundColors = ['rgb(255, 99, 132)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(201, 203, 207)',
      'rgb(54, 162, 235)']

    console.log("getPharmBills_OpAdm------", pharm_bills, pharm_data, pharm_dates)

    var dataset_array = [];
    if (pharm_data != undefined) {
      for (var i = 0; i < pharm_data.length; i++) {
        dataset_array.push({
          data: pharm_data[i].op_bill_amount,
          fill: false,
          backgroundColor: backgroundColors[i],
          // borderColor: ["rgba(255, 99, 132, 1)"],
          spanGaps: true,
          label: pharm_data[i].created_by
        })
      }

      var pharmacy_dates = [];
      for (var l = 0; l < pharm_dates.length; l++) {
        pharmacy_dates.push(
          Date_Formate(pharm_dates[l])
        )
      }
    }

    console.log("Datasetarray------", dataset_array)

    if (this.pharmBillsOpAdm) {
      this.pharmBillsOpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("pharmBillsOpAdm");
    ctx.height = 245;
    ctx.width = 515;
    this.pharmBillsOpAdm = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: pharmacy_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });

    // Pharmacy Total Amount Graph data 

    var data_array = [];
    var labels = [];

    if (pharm_data != undefined) {
      for (var j = 0; j < pharm_data.length; j++) {
        var total_op_amt = 0;
        for (var k = 0; k < pharm_data[j].op_bill_amount.length; k++) {
          total_op_amt += parseFloat(pharm_data[j].op_bill_amount[k])
        }
        data_array.push(total_op_amt)
        labels.push(pharm_data[j].created_by)
      }
    }

    if (this.pharmabillAmtOpAdm) {
      this.pharmabillAmtOpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("pharmabillAmtOpAdm");
    ctx.height = 260;
    // ctx.width = 515;
    this.pharmabillAmtOpAdm = new Chart(ctx, {
      type: "polarArea",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data_array,
            fill: false,
            backgroundColor: ['rgb(255, 99, 132, 0.4)',
              'rgb(75, 192, 192, 0.4)',
              'rgb(255, 205, 86, 0.4)',
              'rgb(201, 203, 207, 0.4)',
              'rgb(54, 162, 235), 0.4'],
          }
        ]
      },
      options: {
      }
    });
  }

  getDiagBills_OpAdm(diag_bills, diag_data, diag_dates) {
    // var data = ["1500", "2000", "2500", "5500", "5000"];
    // var labels = ["Total", "Completed", "Pending"]
    var backgroundColors = ['rgb(255, 99, 132)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(201, 203, 207)',
      'rgb(54, 162, 235)']

    // console.log("getPharmBills_OpAdm------", pharm_bills, pharm_data, pharm_dates)

    var dataset_array = [];
    if (diag_data != undefined) {
      for (var i = 0; i < diag_data.length; i++) {
        var created_by = '';
        if (diag_data[i].created_by == "null null") {
          created_by = "Sudharshan"
        } else {
          created_by = diag_data[i].created_by;
        }

        dataset_array.push({
          data: diag_data[i].op_bill_amount,
          fill: false,
          backgroundColor: backgroundColors[i],
          // borderColor: ["rgba(255, 99, 132, 1)"],
          spanGaps: true,
          label: created_by
        })
      }

      var diagnosis_dates = [];
      for (var l = 0; l < diag_dates.length; l++) {
        diagnosis_dates.push(
          Date_Formate(diag_dates[l])
        )
      }
    }

    console.log("Datasetarray--getDiagBills_OpAdm------", dataset_array)

    if (this.diagBillsOpAdm) {
      this.diagBillsOpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("diagBillsOpAdm");
    ctx.height = 245;
    ctx.width = 515;
    this.diagBillsOpAdm = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: diagnosis_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });

    // Diagnosis Total Amount Graph data 

    var data_array = [];
    var labels = [];
    this.diag_totalOpBillAmt = 0;

    if (diag_data != undefined) {
      for (var j = 0; j < diag_data.length; j++) {
        var total_op_amt = 0;
        for (var k = 0; k < diag_data[j].op_bill_amount.length; k++) {
          total_op_amt += parseFloat(diag_data[j].op_bill_amount[k])
        }

        var created_by = '';
        if (diag_data[j].created_by == "null null") {
          created_by = "Sudharshan"
        } else {
          created_by = diag_data[j].created_by;
        }

        data_array.push(total_op_amt)
        labels.push(created_by)
        this.diag_totalOpBillAmt += total_op_amt
      }

      this.diag_totalOpBillAmt = this.diag_totalOpBillAmt.toFixed(2);
    }

    if (this.diagbillAmtOpAdm) {
      this.diagbillAmtOpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("diagbillAmtOpAdm");
    ctx.height = 260;
    // ctx.width = 515;
    this.diagbillAmtOpAdm = new Chart(ctx, {
      type: "polarArea",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data_array,
            fill: false,
            backgroundColor: ['rgb(255, 99, 132, 0.4)',
              'rgb(75, 192, 192, 0.4)',
              'rgb(255, 205, 86, 0.4)',
              'rgb(201, 203, 207, 0.4)',
              'rgb(54, 162, 235), 0.4'],
          }
        ]
      },
      options: {
      }
    });
  }

  getHospBills_IpAdm(hosp_bills, hosp_data, hosp_dates) {
    // var data = ["1500", "2000", "2500", "5500", "5000"];
    // var labels = ["Total", "Completed", "Pending"]
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    console.log("getHospBills_IpAdm------", hosp_bills, hosp_data, hosp_dates)

    var dataset_array = [];
    if (hosp_data != undefined) {
      for (var i = 0; i < hosp_data.length; i++) {
        dataset_array.push({
          data: hosp_data[i].ip_bill_amount,
          fill: false,
          backgroundColor: backgroundColors[i],
          borderColor: backgroundColors[i],
          spanGaps: true,
          label: hosp_data[i].created_by
        })
      }
      var hospital_dates = [];
      for (var l = 0; l < hosp_dates.length; l++) {
        hospital_dates.push(
          Date_Formate(hosp_dates[l])
        )
      }
    }

    console.log("Datasetarray------", dataset_array)

    if (this.hospBillsIpAdm) {
      this.hospBillsIpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("hospBillsIpAdm");
    ctx.height = 245;
    ctx.width = 515;
    this.hospBillsIpAdm = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: hospital_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });

    // Hospital Total Amount Graph data 

    var data_array = [];
    var labels = [];
    this.hosp_totalIpBillAmt = 0;

    if (hosp_data != undefined) {
      for (var j = 0; j < hosp_data.length; j++) {
        var total_op_amt = 0;
        for (var k = 0; k < hosp_data[j].ip_bill_amount.length; k++) {
          total_op_amt += parseFloat(hosp_data[j].ip_bill_amount[k])
        }
        data_array.push(total_op_amt)
        labels.push(hosp_data[j].created_by)
        this.hosp_totalIpBillAmt += total_op_amt
      }
    }

    if (this.hospbillAmtIpAdm) {
      this.hospbillAmtIpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("hospbillAmtIpAdm");
    ctx.height = 260;
    // ctx.width = 515;
    this.hospbillAmtIpAdm = new Chart(ctx, {
      type: "polarArea",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data_array,
            fill: false,
            backgroundColor: ['rgb(255, 99, 132, 0.4)',
              'rgb(75, 192, 192, 0.4)',
              'rgb(255, 205, 86, 0.4)',
              'rgb(201, 203, 207, 0.4)',
              'rgb(54, 162, 235), 0.4'],
          }
        ]
      },
      options: {
      }
    });
  }

  getPharmBills_IpAdm(pharm_bills, pharm_data, pharm_dates) {
    // var data = ["1500", "2000", "2500", "5500", "5000"];
    // var labels = ["Total", "Completed", "Pending"]
    var backgroundColors = ['rgb(255, 99, 132)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(201, 203, 207)',
      'rgb(54, 162, 235)']

    console.log("getPharmBills_OpAdm------", pharm_bills, pharm_data, pharm_dates)

    var dataset_array = [];
    if (pharm_data != undefined) {
      for (var i = 0; i < pharm_data.length; i++) {
        dataset_array.push({
          data: pharm_data[i].ip_bill_amount,
          fill: false,
          backgroundColor: backgroundColors[i],
          // borderColor: ["rgba(255, 99, 132, 1)"],
          spanGaps: true,
          label: pharm_data[i].created_by
        })
      }

      var pharmacy_dates = [];
      for (var l = 0; l < pharm_dates.length; l++) {
        pharmacy_dates.push(
          Date_Formate(pharm_dates[l])
        )
      }
    }

    console.log("Datasetarray------", dataset_array)



    if (this.pharmBillsIpAdm) {
      this.pharmBillsIpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("pharmBillsIpAdm");
    ctx.height = 245;
    ctx.width = 515;
    this.pharmBillsIpAdm = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: pharm_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });

    // Pharmacy Total Amount Graph data 

    var data_array = [];
    var labels = [];

    if (pharm_data != undefined) {
      for (var j = 0; j < pharm_data.length; j++) {
        var total_op_amt = 0;
        for (var k = 0; k < pharm_data[j].ip_bill_amount.length; k++) {
          total_op_amt += parseFloat(pharm_data[j].ip_bill_amount[k])
        }
        data_array.push(total_op_amt)
        labels.push(pharm_data[j].created_by)
      }
    }

    if (this.pharmabillAmtIpAdm) {
      this.pharmabillAmtIpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("pharmabillAmtIpAdm");
    ctx.height = 260;
    // ctx.width = 515;
    this.pharmabillAmtIpAdm = new Chart(ctx, {
      type: "polarArea",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data_array,
            fill: false,
            backgroundColor: ['rgb(255, 99, 132, 0.4)',
              'rgb(75, 192, 192, 0.4)',
              'rgb(255, 205, 86, 0.4)',
              'rgb(201, 203, 207, 0.4)',
              'rgb(54, 162, 235), 0.4'],
          }
        ]
      },
      options: {
      }
    });
  }

  getDiagBills_IpAdm(diag_bills, diag_data, diag_dates) {
    // var data = ["1500", "2000", "2500", "5500", "5000"];
    // var labels = ["Total", "Completed", "Pending"]
    var backgroundColors = ['rgb(255, 99, 132)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(201, 203, 207)',
      'rgb(54, 162, 235)']

    // console.log("getPharmBills_OpAdm------", pharm_bills, pharm_data, pharm_dates)

    var dataset_array = [];
    if (diag_data != undefined) {
      for (var i = 0; i < diag_data.length; i++) {
        var created_by = '';
        if (diag_data[i].created_by == "null null") {
          created_by = "Sudharshan"
        } else {
          created_by = diag_data[i].created_by;
        }

        dataset_array.push({
          data: diag_data[i].ip_bill_amount,
          fill: false,
          backgroundColor: backgroundColors[i],
          // borderColor: ["rgba(255, 99, 132, 1)"],
          spanGaps: true,
          label: created_by
        })
      }

      var diagnosis_dates = [];
      for (var l = 0; l < diag_dates.length; l++) {
        diagnosis_dates.push(
          Date_Formate(diag_dates[l])
        )
      }
    }

    console.log("Datasetarray--getDiagBills_OpAdm------", dataset_array)

    if (this.diagBillsIpAdm) {
      this.diagBillsIpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("diagBillsIpAdm");
    ctx.height = 245;
    ctx.width = 515;
    this.diagBillsIpAdm = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: diagnosis_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });

    // Diagnosis Total Amount Graph data 

    var data_array = [];
    var labels = [];
    this.diag_totalIpBillAmt = 0;

    if (diag_data != undefined) {
      for (var j = 0; j < diag_data.length; j++) {
        var total_op_amt = 0;
        for (var k = 0; k < diag_data[j].ip_bill_amount.length; k++) {
          total_op_amt += parseFloat(diag_data[j].ip_bill_amount[k])
        }

        var created_by = '';
        if (diag_data[j].created_by == "null null") {
          created_by = "Sudharshan"
        } else {
          created_by = diag_data[j].created_by;
        }

        data_array.push(total_op_amt)
        labels.push(created_by)
        this.diag_totalIpBillAmt += total_op_amt
      }
    }

    if (this.diagbillAmtIpAdm) {
      this.diagbillAmtIpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("diagbillAmtIpAdm");
    ctx.height = 260;
    // ctx.width = 515;
    this.diagbillAmtIpAdm = new Chart(ctx, {
      type: "polarArea",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data_array,
            fill: false,
            backgroundColor: ['rgb(255, 99, 132, 0.4)',
              'rgb(75, 192, 192, 0.4)',
              'rgb(255, 205, 86, 0.4)',
              'rgb(201, 203, 207, 0.4)',
              'rgb(54, 162, 235), 0.4'],
          }
        ]
      },
      options: {
      }
    });
  }

  getPharmPaytype_OpAdm(pharma_paytypes) {
    var backgroundColors = [
      'rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(232, 141, 103)',
      'rgb(192, 199, 140)'
      // 'rgb(54, 162, 235, 1)'
    ]

    if (pharma_paytypes != undefined) {
      if (pharma_paytypes.length > 1) {
        var dates = [];
        var cash = [];
        var card = [];
        var upi = [];
        var cheque = [];
        var emi = [];
        var insurance = [];

        for (var i = 0; i < pharma_paytypes.length; i++) {
          dates.push(Date_Formate(pharma_paytypes[i].created_date))
          cash.push(pharma_paytypes[i].op_cash)
          card.push(pharma_paytypes[i].op_card)
          upi.push(pharma_paytypes[i].op_upi)
          cheque.push(pharma_paytypes[i].op_cheque)
          emi.push(pharma_paytypes[i].op_emi)
          insurance.push(pharma_paytypes[i].op_insurance)
        }

        if (this.pharmaBillsPaytypeOpAdm) {
          this.pharmaBillsPaytypeOpAdm.destroy();
        }

        var ctx = <HTMLCanvasElement>document.getElementById("pharmaBillsPaytypeOpAdm");
        ctx.height = 245;
        ctx.width = 515;
        this.pharmaBillsPaytypeOpAdm = new Chart(ctx, {
          type: "line",
          labels: ["Stepcount"],
          data: {
            labels: dates,
            datasets: [{
              data: cash,
              fill: false,
              backgroundColor: backgroundColors[0],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cash"
            }, {
              data: card,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Card"
            }, {
              data: upi,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "UPI"
            }, {
              data: cheque,
              fill: false,
              backgroundColor: backgroundColors[3],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cheque"
            }, {
              data: emi,
              fill: false,
              backgroundColor: backgroundColors[4],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "EMI"
            }, {
              data: insurance,
              fill: false,
              backgroundColor: backgroundColors[5],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Insurance"
            }],
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 90,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Payment Methods'
                }
              }]
            },
            legend: {
              display: true
            },
          }
        });
      } else {

      }
    }
  }

  getHospPaytype_OpAdm(hosp_paytypes) {
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    if (hosp_paytypes != undefined) {
      if (hosp_paytypes.length > 1) {
        var dates = [];
        var cash = [];
        var card = [];
        var upi = [];
        var cheque = [];
        var emi = [];
        var insurance = [];

        for (var i = 0; i < hosp_paytypes.length; i++) {
          dates.push(Date_Formate(hosp_paytypes[i].created_date))
          cash.push(hosp_paytypes[i].op_cash)
          card.push(hosp_paytypes[i].op_card)
          upi.push(hosp_paytypes[i].op_upi)
          cheque.push(hosp_paytypes[i].op_cheque)
          emi.push(hosp_paytypes[i].op_emi)
          insurance.push(hosp_paytypes[i].op_insurance)
        }

        if (this.hospBillsPaytypeOpAdm) {
          this.hospBillsPaytypeOpAdm.destroy();
        }

        var ctx = <HTMLCanvasElement>document.getElementById("hospBillsPaytypeOpAdm");
        ctx.height = 245;
        ctx.width = 515;
        this.hospBillsPaytypeOpAdm = new Chart(ctx, {
          type: "line",
          labels: ["Stepcount"],
          data: {
            labels: dates,
            datasets: [{
              data: cash,
              fill: false,
              backgroundColor: backgroundColors[0],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cash"
            }, {
              data: card,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Card"
            }, {
              data: upi,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "UPI"
            }, {
              data: cheque,
              fill: false,
              backgroundColor: backgroundColors[3],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cheque"
            }, {
              data: emi,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "EMI"
            }, {
              data: insurance,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Insurance"
            }],
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 90,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Payment Methods'
                }
              }]
            },
            legend: {
              display: true
            },
          }
        });
      } else {

      }
    }
  }

  getHospPaytype_OpAdm_bar(hosp_paytypes) {
    // this.isBar_hosp = true;
    var data = [hosp_paytypes[0].op_cash, hosp_paytypes[0].op_card, hosp_paytypes[0].op_upi, hosp_paytypes[0].op_cheque, hosp_paytypes[0].op_emi, hosp_paytypes[0].op_insurance];
    var labels = ["Cash", "Card", "UPI", "Cheque", "EMI", "Insurance"]

    if (this.hospBillsPaytypeOpAdm_Bar) {
      this.hospBillsPaytypeOpAdm_Bar.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("hospBillsPaytypeOpAdm_Bar");
    ctx.height = 245;
    ctx.width = 515;
    this.hospBillsPaytypeOpAdm_Bar = new Chart(ctx, {
      type: "bar",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)"
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)"
            ],
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Payment Modes"
          },
        ]
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: false
        },
      }
    });
  }

  getPharmPaytype_IpAdm(pharma_paytypes) {
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    if (pharma_paytypes != undefined) {
      if (pharma_paytypes.length > 1) {
        var dates = [];
        var cash = [];
        var card = [];
        var upi = [];
        var cheque = [];
        var emi = [];
        var insurance = [];

        for (var i = 0; i < pharma_paytypes.length; i++) {
          dates.push(Date_Formate(pharma_paytypes[i].created_date))
          cash.push(pharma_paytypes[i].ip_cash)
          card.push(pharma_paytypes[i].ip_card)
          upi.push(pharma_paytypes[i].ip_upi)
          cheque.push(pharma_paytypes[i].ip_cheque)
          emi.push(pharma_paytypes[i].ip_emi)
          insurance.push(pharma_paytypes[i].ip_insurance)
        }

        if (this.pharmaBillsPaytypeIpAdm) {
          this.pharmaBillsPaytypeIpAdm.destroy();
        }

        var ctx = <HTMLCanvasElement>document.getElementById("pharmaBillsPaytypeIpAdm");
        ctx.height = 245;
        ctx.width = 515;
        this.pharmaBillsPaytypeIpAdm = new Chart(ctx, {
          type: "line",
          labels: ["Stepcount"],
          data: {
            labels: dates,
            datasets: [{
              data: cash,
              fill: false,
              backgroundColor: backgroundColors[0],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cash"
            }, {
              data: card,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Card"
            }, {
              data: upi,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "UPI"
            }, {
              data: cheque,
              fill: false,
              backgroundColor: backgroundColors[3],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cheque"
            }, {
              data: emi,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "EMI"
            }, {
              data: insurance,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Insurance"
            }],
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 90,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Payment Methods'
                }
              }]
            },
            legend: {
              display: true
            },
          }
        });
      } else {

      }
    }
  }

  getPharmPaytype_IpAdm_bar(pharma_paytypes) {
    // this.isBar_hosp = true;
    var data = [pharma_paytypes[0].ip_cash, pharma_paytypes[0].ip_card, pharma_paytypes[0].ip_upi, pharma_paytypes[0].ip_cheque, pharma_paytypes[0].ip_emi, pharma_paytypes[0].ip_insurance];
    var labels = ["Cash", "Card", "UPI", "Cheque", "EMI", "Insurance"]

    if (this.pharmaBillsPaytypeIpAdm_Bar) {
      this.pharmaBillsPaytypeIpAdm_Bar.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("pharmaBillsPaytypeIpAdm_Bar");
    ctx.height = 245;
    ctx.width = 515;
    this.pharmaBillsPaytypeIpAdm_Bar = new Chart(ctx, {
      type: "bar",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)"
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)"
            ],
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Payment Modes"
          },
        ]
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: false
        },
      }
    });
  }

  getHospPaytype_IpAdm(hosp_paytypes) {
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    if (hosp_paytypes != undefined) {
      if (hosp_paytypes.length > 1) {
        var dates = [];
        var cash = [];
        var card = [];
        var upi = [];
        var cheque = [];
        var emi = [];
        var insurance = [];

        for (var i = 0; i < hosp_paytypes.length; i++) {
          dates.push(Date_Formate(hosp_paytypes[i].created_date))
          cash.push(hosp_paytypes[i].ip_cash)
          card.push(hosp_paytypes[i].ip_card)
          upi.push(hosp_paytypes[i].ip_upi)
          cheque.push(hosp_paytypes[i].ip_cheque)
          emi.push(hosp_paytypes[i].ip_emi)
          insurance.push(hosp_paytypes[i].ip_insurance)
        }

        if (this.hospBillsPaytypeIpAdm) {
          this.hospBillsPaytypeIpAdm.destroy();
        }

        var ctx = <HTMLCanvasElement>document.getElementById("hospBillsPaytypeIpAdm");
        ctx.height = 245;
        ctx.width = 515;
        this.hospBillsPaytypeIpAdm = new Chart(ctx, {
          type: "line",
          labels: ["Stepcount"],
          data: {
            labels: dates,
            datasets: [{
              data: cash,
              fill: false,
              backgroundColor: backgroundColors[0],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cash"
            }, {
              data: card,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Card"
            }, {
              data: upi,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "UPI"
            }, {
              data: cheque,
              fill: false,
              backgroundColor: backgroundColors[3],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cheque"
            }, {
              data: emi,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "EMI"
            }, {
              data: insurance,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Insurance"
            }],
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 90,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Payment Methods'
                }
              }]
            },
            legend: {
              display: true
            },
          }
        });
      } else {

      }
    }
  }

  getHospPaytype_IpAdm_bar(hosp_paytypes) {
    // this.isBar_hosp = true;
    var data = [hosp_paytypes[0].ip_cash, hosp_paytypes[0].ip_card, hosp_paytypes[0].ip_upi, hosp_paytypes[0].ip_cheque, hosp_paytypes[0].ip_emi, hosp_paytypes[0].ip_insurance];
    var labels = ["Cash", "Card", "UPI", "Cheque", "EMI", "Insurance"]

    if (this.hospBillsPaytypeIpAdm_Bar) {
      this.hospBillsPaytypeIpAdm_Bar.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("hospBillsPaytypeIpAdm_Bar");
    ctx.height = 245;
    ctx.width = 515;
    this.hospBillsPaytypeIpAdm_Bar = new Chart(ctx, {
      type: "bar",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)"
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)"
            ],
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Payment Modes"
          },
        ]
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: false
        },
      }
    });
  }

  getDiagPaytype_OpAdm(diag_paytypes) {
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    if (diag_paytypes != undefined) {
      if (diag_paytypes.length > 1) {
        var dates = [];
        var cash = [];
        var card = [];
        var upi = [];
        var cheque = [];
        var emi = [];
        var insurance = [];

        for (var i = 0; i < diag_paytypes.length; i++) {
          dates.push(Date_Formate(diag_paytypes[i].created_date))
          cash.push(diag_paytypes[i].op_cash)
          card.push(diag_paytypes[i].op_card)
          upi.push(diag_paytypes[i].op_upi)
          cheque.push(diag_paytypes[i].op_cheque)
          emi.push(diag_paytypes[i].op_emi)
          insurance.push(diag_paytypes[i].op_insurance)
        }

        if (this.diagBillsPaytypeOpAdm) {
          this.diagBillsPaytypeOpAdm.destroy();
        }

        var ctx = <HTMLCanvasElement>document.getElementById("diagBillsPaytypeOpAdm");
        ctx.height = 245;
        ctx.width = 515;
        this.diagBillsPaytypeOpAdm = new Chart(ctx, {
          type: "line",
          labels: ["Stepcount"],
          data: {
            labels: dates,
            datasets: [{
              data: cash,
              fill: false,
              backgroundColor: backgroundColors[0],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cash"
            }, {
              data: card,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Card"
            }, {
              data: upi,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "UPI"
            }, {
              data: cheque,
              fill: false,
              backgroundColor: backgroundColors[3],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cheque"
            }, {
              data: emi,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "EMI"
            }, {
              data: insurance,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Insurance"
            }],
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 90,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Payment Methods'
                }
              }]
            },
            legend: {
              display: true
            },
          }
        });
      } else {

      }
    }
  }

  getDiagPaytype_OpAdm_bar(diag_paytypes) {
    // this.isBar_hosp = true;
    var data = [diag_paytypes[0].op_cash, diag_paytypes[0].op_card, diag_paytypes[0].op_upi, diag_paytypes[0].op_cheque, diag_paytypes[0].op_emi, diag_paytypes[0].op_insurance];
    var labels = ["Cash", "Card", "UPI", "Cheque", "EMI", "Insurance"]

    if (this.diagBillsPaytypeOpAdm_Bar) {
      this.diagBillsPaytypeOpAdm_Bar.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("diagBillsPaytypeOpAdm_Bar");
    ctx.height = 245;
    ctx.width = 515;
    this.diagBillsPaytypeOpAdm_Bar = new Chart(ctx, {
      type: "bar",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)"
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)"
            ],
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Payment Modes"
          },
        ]
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: false
        },
      }
    });
  }

  getDiagPaytype_IpAdm(diag_paytypes) {
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    if (diag_paytypes != undefined) {
      if (diag_paytypes.length > 1) {
        var dates = [];
        var cash = [];
        var card = [];
        var upi = [];
        var cheque = [];
        var emi = [];
        var insurance = [];

        for (var i = 0; i < diag_paytypes.length; i++) {
          dates.push(Date_Formate(diag_paytypes[i].created_date))
          cash.push(diag_paytypes[i].ip_cash)
          card.push(diag_paytypes[i].ip_card)
          upi.push(diag_paytypes[i].ip_upi)
          cheque.push(diag_paytypes[i].ip_cheque)
          emi.push(diag_paytypes[i].ip_emi)
          insurance.push(diag_paytypes[i].ip_insurance)
        }

        if (this.diagBillsPaytypeIpAdm) {
          this.diagBillsPaytypeIpAdm.destroy();
        }

        var ctx = <HTMLCanvasElement>document.getElementById("diagBillsPaytypeIpAdm");
        ctx.height = 245;
        ctx.width = 515;
        this.diagBillsPaytypeIpAdm = new Chart(ctx, {
          type: "line",
          labels: ["Stepcount"],
          data: {
            labels: dates,
            datasets: [{
              data: cash,
              fill: false,
              backgroundColor: backgroundColors[0],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cash"
            }, {
              data: card,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Card"
            }, {
              data: upi,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "UPI"
            }, {
              data: cheque,
              fill: false,
              backgroundColor: backgroundColors[3],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cheque"
            }, {
              data: emi,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "EMI"
            }, {
              data: insurance,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Insurance"
            }],
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 90,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Payment Methods'
                }
              }]
            },
            legend: {
              display: true
            },
          }
        });
      } else {

      }
    }
  }

  getDiagPaytype_IpAdm_bar(diag_paytypes) {
    // this.isBar_hosp = true;
    var data = [diag_paytypes[0].ip_cash, diag_paytypes[0].ip_card, diag_paytypes[0].ip_upi, diag_paytypes[0].ip_cheque, diag_paytypes[0].ip_emi, diag_paytypes[0].ip_insurance];
    var labels = ["Cash", "Card", "UPI", "Cheque", "EMI", "Insurance"]

    if (this.diagBillsPaytypeIpAdm_Bar) {
      this.diagBillsPaytypeIpAdm_Bar.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("diagBillsPaytypeIpAdm_Bar");
    ctx.height = 245;
    ctx.width = 515;
    this.diagBillsPaytypeIpAdm_Bar = new Chart(ctx, {
      type: "bar",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)"
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)"
            ],
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Payment Modes"
          },
        ]
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: false
        },
      }
    });
  }

  user_base() {
    var send_data: any;
    send_data = {
      reg_id: this.user_id,
      fdate: Date_Formate(this.f_date),
      tdate: Date_Formate(this.t_date),
      hosp_id: this.hosp_id,
      createdby: this.user_id,
      range: this.range,
    };
    if (this.userInfo.user_type == 'dietician') {
      send_data.type = 'dietician';
    } else if (this.userInfo.user_type == 'Admin') {
      send_data.reg_id = 'All';
      send_data.admin = '1';
    } else if (this.userInfo.user_type == 'doctor') {
      send_data.reg_id = this.user_id;
    }
    console.log('send_data' + JSON.stringify(send_data));
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "dash/grbhd", send_data, { headers: headers }).subscribe(
      response => {
        var obj = JSON.parse(response["_body"]);
        console.log('gender based  GRAPH' + JSON.stringify(obj));
        if (obj != undefined) {
          if (this.is_admin == true) {
            if (this.tabtype == "outpatient") {
              let diag_payment = [];
              let hospital_payment_array = [];
              let pharma_payment_array = [];
              this.hosp_dates_array = [];
              this.hosp_data_array = [];
              this.pharma_dates_array = [];
              this.pharma_data_array = [];
              this.diag_dates_array = [];
              this.diag_data_array = [];
              this.hosp_payment_array = [];
              this.diag_payment_array = [];
              this.pharma_payment_array = [];
              this.getHospBills_OpAdm(obj.hospital_bills, obj.hosp_data, obj.hosp_dates);
              this.getPharmBills_OpAdm(obj.pharma_bills, obj.pharma_data, obj.pharma_dates);
              this.getDiagBills_OpAdm(obj.diagnosis_bills, obj.diag_data, obj.diag_dates);
              this.getappointments(obj.total_apps, obj.new_app, obj.followup_app, obj.completed, obj.not_visited, obj.pending, obj.non_discount, obj.discounted, obj.walkin, obj.telephonic);
              this.getdiagnosticorder_chart(obj.diag_apps, obj.comp_diag_apps, obj.pending_diag_apps);
              this.getrevenue_chart(obj.revenue, obj.expenses, obj.net_revenue);
              this.totalemp = obj.total_emp;
              this.emppresent = obj.present;
              this.hosp_dates_array = obj.hosp_dates;
              this.hosp_data_array = obj.hosp_data;
              this.pharma_dates_array = obj.pharma_dates;
              this.pharma_data_array = obj.pharma_data;
              this.diag_dates_array = obj.diag_dates;
              this.diag_data_array = obj.diag_data;
              hospital_payment_array.push({
                cash: obj.hosp_cash,
                card: obj.hosp_card,
                upi: obj.hosp_upi,
                neft: obj.hosp_neft,
                ins: obj.hosp_ins,
                emi: obj.hosp_emi
              });
              diag_payment.push({
                cash: obj.diag_cash,
                card: obj.diag_card,
                upi: obj.diag_upi,
                neft: obj.diag_neft,
                ins: obj.diag_ins,
                emi: obj.diag_emi,
              });
              pharma_payment_array.push({
                cash: obj.pharma_cash,
                card: obj.pharma_card,
                upi: obj.pharma_upi,
                neft: obj.pharma_neft,
                ins: obj.pharma_ins,
                emi: obj.pharma_emi,
              })
              this.hosp_payment_array = hospital_payment_array;
              this.diag_payment_array = diag_payment;
              this.pharma_payment_array = pharma_payment_array;
              // this.pharma_payment_array=obj.pharma_paytypes;

              if (this.bill_dept_type == 'hospital') {
                this.getbilling_chart(obj.hosp_dates, obj.hosp_data);
                console.log("hosp_dates_array " + JSON.stringify(this.hosp_dates_array) + "-------" + JSON.stringify(this.hosp_data_array))
              } else if (this.bill_dept_type == 'pharmacy') {
                this.getbilling_chart(obj.pharma_dates, obj.pharma_data);
              } else {
                this.getbilling_chart(obj.diag_dates, obj.diag_data);
              }
              if (this.dept_payment == 'hospital') {
                this.getpaymentchart(hospital_payment_array);
              } else if (this.dept_payment == 'pharmacy') {
                this.getpaymentchart(pharma_payment_array);
              } else {
                this.getpaymentchart(diag_payment);
              }
              this.getinpatients_chart(obj.inpatients, obj.ins_patients, obj.other_patients, obj.mlc_patients);
              this.getPharmPaytype_OpAdm(obj.pharma_paytypes);
              this.getStockvalue_chart(obj.stock_value, obj.stock_pruchased, obj.stock_sales);
              this.getambulancedata_chart(obj.ambulance_total, obj.ambulance_completed, obj.ambulance_pending);
              this.getmedicine_details()

              // if (obj.hosp_paytypes != undefined) {
              //   if (obj.hosp_paytypes.length == 1) {
              //     this.isBar_hosp = true;
              //     setTimeout(() => {
              //       this.getHospPaytype_OpAdm_bar(obj.hosp_paytypes);
              //     }, 500);
              //   } else {
              //     this.isBar_hosp = false;
              //     setTimeout(() => {
              //       this.getHospPaytype_OpAdm(obj.hosp_paytypes);
              //     }, 500);                  
              //   }
              // } 

              // if (obj.diag_paytypes != undefined) {
              //   if (obj.diag_paytypes.length == 1) {
              //     this.isBar_diag = true;
              //     setTimeout(() => {
              //       this.getDiagPaytype_OpAdm_bar(obj.diag_paytypes);
              //     }, 500);
              //   } else {
              //     this.isBar_diag = false;
              //     setTimeout(() => {
              //       this.getDiagPaytype_OpAdm(obj.diag_paytypes);
              //     }, 500);                  
              //   }
              // }
            } else {
              this.getHospBills_IpAdm(obj.hospital_bills, obj.hosp_data, obj.hosp_dates);
              this.getPharmBills_IpAdm(obj.pharma_bills, obj.pharma_data, obj.pharma_dates);
              this.getDiagBills_IpAdm(obj.diagnosis_bills, obj.diag_data, obj.diag_dates);

              if (obj.hosp_paytypes != undefined) {
                if (obj.hosp_paytypes.length == 1) {
                  this.isBar_hosp_Ip = true;
                  setTimeout(() => {
                    this.getHospPaytype_IpAdm_bar(obj.hosp_paytypes);
                  }, 500);
                } else {
                  this.isBar_hosp_Ip = false;
                  setTimeout(() => {
                    this.getHospPaytype_IpAdm(obj.hosp_paytypes);
                  }, 500);
                }
              }

              if (obj.pharma_paytypes != undefined) {
                if (obj.pharma_paytypes.length == 1) {
                  this.isBar_pharma_Ip = true;
                  setTimeout(() => {
                    this.getPharmPaytype_IpAdm_bar(obj.pharma_paytypes);
                  }, 500);
                } else {
                  this.isBar_pharma_Ip = false;
                  setTimeout(() => {
                    this.getPharmPaytype_IpAdm(obj.pharma_paytypes);
                  }, 500);
                }
              }

              if (obj.diag_paytypes != undefined) {
                if (obj.diag_paytypes.length == 1) {
                  this.isBar_pharma_Ip = true;
                  setTimeout(() => {
                    this.getDiagPaytype_IpAdm_bar(obj.diag_paytypes);
                  }, 500);
                } else {
                  this.isBar_pharma_Ip = false;
                  setTimeout(() => {
                    this.getDiagPaytype_IpAdm(obj.diag_paytypes);
                  }, 500);
                }
              }
            }

            this.pending_indents = obj.pending_pos != undefined ? obj.pending_pos : "0";
            this.complete_pos_indents = obj.complete_pos != undefined ? obj.complete_pos : "0";
            this.total_indents = parseInt(this.pending_indents) + parseInt(this.complete_pos_indents);
            // this.bills_chart(obj);
          } else {

            // Estimates
            this.op_comp_estimate = obj.op_comp_estimate != undefined ? obj.op_comp_estimate : "0";
            this.op_pending_estimate = obj.op_pending_estimate != undefined ? obj.op_pending_estimate : "0"
            this.total_Op_estimate = parseInt(this.op_comp_estimate) + parseInt(this.op_pending_estimate);

            this.ip_comp_estimate = obj.ip_comp_estimate != undefined ? obj.ip_comp_estimate : "0";
            this.ip_pending_estimate = obj.ip_pending_estimate != undefined ? obj.ip_pending_estimate : "0";
            this.total_Ip_estimate = parseInt(this.ip_comp_estimate) + parseInt(this.ip_pending_estimate);

            // Indents
            this.pending_indents = obj.pending_pos != undefined ? obj.pending_pos : "0";
            this.complete_pos_indents = obj.complete_pos != undefined ? obj.complete_pos : "0";
            this.total_indents = parseInt(this.pending_indents) + parseInt(this.complete_pos_indents);

            // Bill count and Bill amount
            this.op_bill_count = 0;
            this.op_bill_amount = 0;
            this.ip_bill_count = 0;
            this.ip_bill_amount = 0;
            if (obj.pharma_bills != undefined) {
              for (var i = 0; i < obj.pharma_bills.length; i++) {
                this.op_bill_count = obj.pharma_bills[i].op_bill_count != undefined ? this.op_bill_count + parseInt(obj.pharma_bills[i].op_bill_count) : this.op_bill_count;

                this.op_bill_amount = (obj.pharma_bills[i].op_bill_amount != undefined ? this.op_bill_amount + parseFloat(obj.pharma_bills[i].op_bill_amount) : this.op_bill_amount).toFixed(2);

                this.ip_bill_count = obj.pharma_bills[i].ip_bill_count != undefined ? this.ip_bill_count + parseInt(obj.pharma_bills[i].ip_bill_count) : this.ip_bill_count;

                this.ip_bill_amount = (obj.pharma_bills[i].ip_bill_amount != undefined ? this.ip_bill_amount + parseFloat(obj.pharma_bills[i].ip_bill_amount) : this.ip_bill_amount).toFixed(2);
              }
            }
          }
        }
      },
      (error) => { }
    );
  }

  getFrontdeskDashdata() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "dash/gabfd", JSON.stringify({
      reg_id: this.user_id,
      fdate: Date_Formate(this.f_date),
      tdate: Date_Formate(this.t_date),
      hosp_id: this.hosp_id,
      createdby: this.user_id,
      // admin: this.admin,
      // pat_type: 'outpat',
      // interval: this.interval,
      range: this.range,
    }), { headers: headers }).subscribe(
      response => {
        var obj = JSON.parse(response["_body"]);
        console.log('frontdesk dash data' + JSON.stringify(obj));

        if (obj != undefined) {
          this.doc_completedApp = obj.completed != undefined ? obj.completed : 0;
          this.doc_openApp = obj.open_app != undefined ? obj.open_app : 0;
          this.doc_confirmedApp = obj.confirmed != undefined ? obj.confirmed : 0;
          this.doc_inpatApp = obj.inpat != undefined ? obj.inpat : 0;
          this.total_docApps = parseInt(this.doc_completedApp) + parseInt(this.doc_openApp) + parseInt(this.doc_confirmedApp);

          this.diag_completedApp = obj.diag_completed != undefined ? obj.diag_completed : 0;
          this.diag_openApp = obj.diag_open_app != undefined ? obj.diag_open_app : 0;
          this.total_diagApps = parseInt(this.diag_completedApp) + parseInt(this.diag_openApp);
        }
      },
      (error) => { });

  }

  getappointments(total_apps, new_app, followup_app, completed, not_visited, pending, non_discount, discounted, walkin, telephonic) {
    let app_total_apps, app_new_app, app_followup_app, app_completed, app_not_visited, app_pending, app_non_discount, app_discounted, app_walkin, app_telephonic;
    app_total_apps = total_apps != undefined && total_apps != '' ? total_apps : 0;
    app_new_app = new_app != undefined && new_app != '' ? new_app : 0;
    app_followup_app = followup_app != undefined && followup_app != '' ? followup_app : 0;
    app_completed = completed != undefined && completed != '' ? completed : 0;
    app_not_visited = not_visited != undefined && not_visited != '' ? not_visited : 0;
    app_pending = pending != undefined && pending != '' ? pending : 0;
    app_non_discount = non_discount != undefined && non_discount != '' ? non_discount : 0;
    app_discounted = discounted != undefined && discounted != '' ? discounted : 0;
    app_walkin = walkin != undefined && walkin != '' ? walkin : 0;
    app_telephonic = telephonic != undefined && telephonic != '' ? telephonic : 0;

    this.appoint_total_app = app_total_apps;
    this.appoint_new_app = app_new_app;
    this.appoint_followup_app = app_followup_app;
    this.appoint_completed = app_completed;
    this.appoint_not_visited = app_not_visited;
    this.appoint_pending = app_pending;
    this.appoint_non_discount = app_non_discount;
    this.appoint_discounted = app_discounted;
    this.appoint_walkin = app_walkin;
    this.appoint_telephonic = app_telephonic;
    console.log("getHospBills_OpAdm------", new_app, followup_app, completed, not_visited, pending, non_discount, discounted)
    // console.log(hospital_dates);
    if (this.appointments_chart) {
      this.appointments_chart.destroy();
    }
    var ctx = <HTMLCanvasElement>document.getElementById("appointments_chart");
    ctx.height = 245;
    ctx.width = 270;
    this.appointments_chart = new Chart(ctx, {
      type: "polarArea",
      data: {
        labels: ['New', 'Follow up', 'Completed', 'Not visited', 'Pending', 'Discounted', 'Non-Discounted', 'Walkin', 'Telephone'],
        datasets: [{
          label: 'My First Dataset',
          data: [app_new_app, app_followup_app, app_completed, app_not_visited, app_pending, app_non_discount, app_discounted, app_walkin, app_telephonic],
          backgroundColor: ['#129c6e', '#8b75d7', '#3d9736', '#ff6178', '#febc3a', '#1f0f57', '#0081e2', '#DEAA79', '#1D5B79']
        }]
      },
      options: {
        responsive: true,
        legend: {
          display: false
        },
      }
    });
  }

  // diagnosticorder_chart
  getdiagnosticorder_chart(total, complete, pending) {
    console.log("getHospBills_OpAdm------", total, complete, pending)
    let diagnosis_total, diagnosis_complete, diagnosis_pending;
    diagnosis_total = total != undefined && total != '' ? total : 0;
    diagnosis_complete = complete != undefined && complete != '' ? complete : 0;
    diagnosis_pending = pending != undefined && pending != '' ? pending : 0;
    this.diagnostic_total = diagnosis_total;
    this.diagnostic_complete = diagnosis_complete;
    this.diagnostic_pending = diagnosis_pending;
    var dataset_array = [];
    console.log("Datasetarray------", dataset_array)
    // console.log(hospital_dates);
    if (this.diagnosticorder_chart) {
      this.diagnosticorder_chart.destroy();
    }
    var ctx = <HTMLCanvasElement>document.getElementById("diagnosticorder_chart");
    ctx.height = 245;
    ctx.width = 270;
    //  var labels = Utils.months({count: 7});
    this.diagnosticorder_chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ['Total', 'Completed', 'Pending'],
        // datasets: dataset_array,
        datasets: [{
          label: 'My First Dataset',
          data: [diagnosis_total, diagnosis_complete, diagnosis_pending],
          backgroundColor: [
            '#4b70f5',
            "#3e9837",
            "#f54b4b"
          ],
          borderColor: [
            "#4b70f5",
            "#3e9837",
            "#f54b4b"
          ],
          borderWidth: 1
        }]
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: false,
              labelString: 'Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
          }]
        },
        legend: {
          display: false
        },
      }
    });

  }

  //revenue_chart
  getrevenue_chart(total, expense, earning) {
    console.log("getrevenue_chart------", total, expense, earning)
    let rev_total, rev_expense, rev_earning;
    rev_total = total != undefined && total != '' ? total : 0;
    rev_expense = expense != undefined && expense != '' ? expense : 0;
    rev_earning = earning != undefined && earning != '' ? earning : 0;
    this.Revenue_Total = rev_total;
    this.Revenue_Expenses = rev_expense;
    this.Revenue_Earnings = rev_earning;
    console.log("revnue data = " + rev_total, rev_expense, rev_earning)
    if (this.revenue_chart) {
      this.revenue_chart.destroy();
    }
    var ctx = <HTMLCanvasElement>document.getElementById("revenue_chart");
    ctx.height = 245;
    ctx.width = 270;
    this.revenue_chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ["Total", "Expense", "Earnings"],
        datasets: [
          {
            label: ["Total", "Expense", "Earnings"],
            data: [rev_total, rev_expense, rev_earning],
            backgroundColor: ['#6a4ef6', '#c697ff', '#e0d0ff'],
            borderWidth: 3,
            hoverOffset: 10,
            // cutout: '90%', // Inner circle size
            weight: 1, // Weight of dataset when stacked
          }
        ]
      },
      options: {
        // Adjust this value to control the donut width (e.g., '50%' or a pixel value like 50)
        responsive: true,
        maintainAspectRatio: false, // Allow custom size
        plugins: {
          legend: {
            position: 'top',
          },
        },
        legend: {
          display: false // Optionally hide the legend
        },
        cutoutPercentage: 70,
      }
    });
  }

  getbilling_chart(dates: string[], data: any[]) {
    console.log("bill_chart_date = " + JSON.stringify(dates));
    console.log("bill_chart_data = " + JSON.stringify(data));
    const chart_dates: string[] = Array.isArray(dates) ? dates.map(date => this.formatDate(date)) : [];
    const chart_data: number[] = [];
    if (data && Array.isArray(data)) {
      data.forEach(item => {
        if (item.bill_amount && Array.isArray(item.bill_amount)) {
          item.bill_amount.forEach((amount: string) => {
            chart_data.push(parseFloat(amount));
          });
        }
      });
    }
    console.log("Processed chart data:", JSON.stringify(chart_dates), JSON.stringify(chart_data));
    if (this.billing_chart) {
      this.billing_chart.destroy();
    }
    var ctx = <HTMLCanvasElement>document.getElementById('billing_chart');
    ctx.height = 245;
    ctx.width = 270;
    if (!ctx) {
      console.error('Canvas element not found!');
      return;
    }
    // Create the chart
    this.billing_chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: chart_dates, // X-axis labels
        datasets: [
          {
            label: 'Bill Amount',
            data: chart_data, // Y-axis data
            fill: false,
            backgroundColor: '#95a826',
            borderColor: '#95a826', // Line color
            tension: 0.1, // Curve tension
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Dates',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Bill Amount',
            },
          },
        },
      },
    });
  }

  formatDate(date: string): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  getpaymentchart(paytypes) {
    console.log("paytypes = " + JSON.stringify(paytypes))
    if (!paytypes || paytypes.length === 0) {
      console.error("No data available to plot the chart");
      return;
    }
    // Extract data from the 0th array
    const paymentData = paytypes[0];
    // Prepare labels and corresponding data for the pie chart
    // const labels = ["Cash", "Card", "Insurance", "UPI", "Cheque", "EMI"];
    const chartData = [
      parseFloat(paymentData.cash || "0"),
      parseFloat(paymentData.card || "0"),
      parseFloat(paymentData.upi || "0"),
      parseFloat(paymentData.neft || "0"),
      parseFloat(paymentData.emi || "0"),
      parseFloat(paymentData.insurance || "0"),
    ];
    if (this.payment_chart) {
      this.payment_chart.destroy();
    }
    var ctx = <HTMLCanvasElement>document.getElementById("payment_chart");
    ctx.height = 245;
    ctx.width = 270;
    this.payment_chart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: ['Cash', 'Card', 'UPI', 'Cheque', 'EMI', 'Insurance'],
        datasets: [{
          label: 'My First Dataset',
          data: chartData,
          backgroundColor: ['#f8cf66', '#f85e5e', '#3d97f2', '#2bcc9f', '#6f54d7', '#e65498'],
          // #f8cf66;
          // #f85e5e;
          // #3d97f2;
          // #2bcc9f;
          // #6f54d7;
          // #e65498;
          hoverOffset: 4
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false,
        },
      }
    })
  }

  getinpatients_chart(inpatients, ins_patients, other_patients, mlc_patients) {
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']
    console.log("getHospBills_OpAdm------", inpatients, ins_patients, other_patients, mlc_patients)
    let in_inpatients, in_ins_patients, in_other_patients, in_mlc_patients;
    in_inpatients = inpatients != undefined && inpatients != '' ? inpatients : 0;
    in_ins_patients = ins_patients != undefined && ins_patients != '' ? ins_patients : 0;
    in_other_patients = other_patients != undefined && other_patients != '' ? other_patients : 0;
    in_mlc_patients = mlc_patients != undefined && mlc_patients != '' ? mlc_patients : 0;
    this.inpatients = in_inpatients;
    this.inpatient_noninsure = in_ins_patients;
    this.inpatient_insure = in_other_patients;
    this.inpatient_mlc = in_mlc_patients;
    if (this.inpatients_chart) {
      this.inpatients_chart.destroy();
    }
    var ctx = <HTMLCanvasElement>document.getElementById("inpatients_chart");
    ctx.height = 245;
    ctx.width = 270;
    this.inpatients_chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['In-patients', 'Non-Insurance', 'Insurance', 'MLC'],
        datasets: [
          {
            label: 'Dataset 1',
            data: [inpatients, ins_patients, other_patients, mlc_patients],
            backgroundColor: ["#16C47F", "#6a4ef6", "#ffb059", "#f54b4b",],
            borderWidth: 2,
            hoverOffset: 10,
            weight: 1, // Weight of dataset when stacked
          }
        ]
      },
      options: {
        // Adjust this value to control the donut width (e.g., '50%' or a pixel value like 50)
        responsive: true,
        maintainAspectRatio: false, // Allow custom size
        plugins: {
          legend: {
            position: 'top',
          },
        },
        legend: {
          display: false // Optionally hide the legend
        },
        cutoutPercentage: 70,
      }
    });
  }

  select_dept_type() {
    if (this.bill_dept_type == 'hospital') {
      this.getbilling_chart(this.hosp_dates_array, this.hosp_data_array);
    } else if (this.bill_dept_type == 'pharmacy') {
      this.getbilling_chart(this.pharma_dates_array, this.pharma_data_array);
    } else {
      this.getbilling_chart(this.diag_dates_array, this.diag_data_array);
    }
  }

  select_dept_payment() {
    if (this.dept_payment == 'hospital') {
      this.getpaymentchart(this.hosp_payment_array);
    } else if (this.dept_payment == 'pharmacy') {
      this.getpaymentchart(this.pharma_payment_array);
    } else {
      this.getpaymentchart(this.diag_payment_array);
    }
  }

  getStockvalue_chart(stock, purchase, sale) {
    console.log("getrevenue_chart------", stock, purchase, sale)
    let sto_stock, sto_purchase, sto_sale;
    sto_stock = stock != undefined && stock != '' ? stock : 0;
    sto_purchase = purchase != undefined && purchase != '' ? purchase : 0;
    sto_sale = sale != undefined && sale != '' ? sale : 0;
    this.stockval_stock = sto_stock;
    this.stockval_purchase = sto_purchase;
    this.stockval_sale = sto_sale;
    if (this.stockvalue_chart) {
      this.stockvalue_chart.destroy();
    }
    var ctx = <HTMLCanvasElement>document.getElementById("stockvalue_chart");
    ctx.height = 245;
    ctx.width = 270;
    const DATA_COUNT = 5;
    const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };
    this.stockvalue_chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ["Stock", "Purchased", "Sales"],
        datasets: [
          {
            label: 'Dataset 1',
            data: [sto_stock, sto_purchase, sto_sale],
            backgroundColor: ["#99c84e", "#6d9c40", "#365126"],
            borderWidth: 2,
            hoverOffset: 10,
            // cutout: '90%', // Inner circle size
            weight: 1, // Weight of dataset when stacked
          }
        ]
      },
      options: {
        // Adjust this value to control the donut width (e.g., '50%' or a pixel value like 50)
        responsive: true,
        maintainAspectRatio: false, // Allow custom size
        plugins: {
          legend: {
            position: 'top',
          },
        },
        legend: {
          display: false // Optionally hide the legend
        },
        cutoutPercentage: 70,
      }
    });
  }


  getambulancedata_chart(total, completed, pending) {
    console.log("getambulance_chart------", total, completed, pending)
    let ambulance_total, ambulance_completed, ambulance_pending;
    ambulance_total = total != undefined && total != '' ? total : 0
    ambulance_completed = completed != undefined && completed != '' ? completed : 0
    ambulance_pending = pending != undefined && pending != '' ? pending : 0
    // ambulance_total=ambulance_total != undefined && ambulance_total != '' ? ambulance_total:0; 
    // ambulance_completed=ambulance_completed != undefined && ambulance_completed != '' ? ambulance_completed:0;
    // ambulance_pending=ambulance_pending != undefined && ambulance_pending != '' ? ambulance_pending:0;
    this.ambulancevalue_total = ambulance_total;
    this.ambulancevalue_completed = ambulance_completed;
    this.ambulancevalue_pending = ambulance_pending;
    if (this.ambulance_chart) {
      this.ambulance_chart.destroy();
    }
    var ctx = <HTMLCanvasElement>document.getElementById("ambulance_chart");
    ctx.height = 245;
    ctx.width = 270;
    const DATA_COUNT = 5;
    const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };
    this.ambulance_chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ["Total", "Completed", "Pending"],
        datasets: [
          {
            label: 'Dataset 1',
            data: [ambulance_total, ambulance_completed, ambulance_pending],
            backgroundColor: ["#6a4ef6", "#c595ff", "#a275ff"],
            borderWidth: 2,
            hoverOffset: 10,
            // cutout: '90%', // Inner circle size
            weight: 1, // Weight of dataset when stacked
          }
        ]
      },
      options: {
        // Adjust this value to control the donut width (e.g., '50%' or a pixel value like 50)
        responsive: true,
        maintainAspectRatio: false, // Allow custom size
        plugins: {
          legend: {
            position: 'top',
          },
        },
        legend: {
          display: false // Optionally hide the legend
        },
        cutoutPercentage: 70,
      }
    });
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const element = this.scrollContainer.nativeElement;
    var stop = element.scrollTop;
    var oset = element.offsetHeight;
    var cval = stop + oset + 2;

    if (cval >= element.scrollHeight) {
      this.currentLimit += 100;
      this.getmedicine_details();
      console.log("reached end of scroll ", this.currentLimit)
    }
  }

  getmedicine_details() {
    var send_data;
    send_data = {
      hptl_clinic_id: this.hosp_id,
      sno: this.currentLimit,
      limit: 100,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.httpp.post(ipaddress.getIp + 'dash/gdndn', send_data, { headers: headers }).subscribe(response => {
      var obj = JSON.parse(JSON.stringify(response));
      console.log("getmedicine_details_send" + JSON.stringify(send_data))
      console.log("getmedicine_details" + JSON.stringify(obj))
      if (obj.products != undefined) {
        if (obj.products.length != 0) {
          obj.products.forEach((product, index) => {
            product.expiry_date = product.expiry_date != undefined && product.expiry_date != '' ? Date_Formate(product.expiry_date) : '';
            this.medicinearray.push({
              sno: index + 1,
              name: product.product_name,
              batch: product.batch_no,
              expiryDate: product.expiry_date,
              expiry_color: product.expiry_color,
              receivedQty: product.receive_qty,
              soldQty: product.qty_sold,
              returnToSupplier: product.returned_qty,
              availableQty: product.available_qty
            })
          })
        }
      }
    },
      (error) => {

      });
  }

  re_direct(value) {
    var durval = {
      'duration':this.appointment_type,
      'from_date': this.from_date,
      'to_date': this.to_date
    }
    Helper_Class.setDurationData(undefined);
    Helper_Class.setDurationData(durval);
    
    if (value == 'appointments') {
      FrontDesk_Helper.setdoctor(undefined);
      FrontDesk_Helper.setDietician(undefined);
      FrontDesk_Helper.setDate(this.currentDate);
      this.Menuservice.sendMessage('manageappointments')

    } else if (value == 'diagnostic_order') {

    } else if (value == 'medicine') {
      this.Menuservice.sendMessage('product');

    } else if (value == 'revenue') {
      Helper_Class.setReportType('revenue');
      this.Menuservice.sendMessage('revenue_rep');

    } else if (value == 'inpatients') {
      this.Menuservice.sendMessage('Patients')

    } else if (value == 'stock') {
      Helper_Class.setReportType('stock_ledger');
      this.Menuservice.sendMessage('stock_status');

    } else if (value == 'billing') {
      this.Menuservice.sendMessage('frontdeskbilling');

    } else if (value == 'payments') {
      this.Menuservice.sendMessage('Day_book');
    }
  }
}
