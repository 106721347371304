import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers, JSONPConnection } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ConvertTimeformat, Date_Formate, tConv24, Time_Formate } from '../../../assets/js/common';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { MatDialog } from '@angular/material/dialog';
import { InpatMedprescViewComponent } from '../inpat-medpresc-view/inpat-medpresc-view.component';
import { ipaddress } from '../../ipaddress'; import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { GynaObestricPopSelectionComponent } from '../gyna-obestric-pop-selection/gyna-obestric-pop-selection.component';
import { Master_Helper } from '../Master_Helper';
import { MasterHelperService } from '../MasterHelper.service';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Chart } from 'chart.js';
import moment from 'moment';
import { ServerApi } from '../../../app/server-api';
import { TranslateService } from '@ngx-translate/core';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';

@Component({
  selector: 'app-emergencycare',
  templateUrl: './emergencycare.component.html',
  styleUrls: ['./emergencycare.component.scss']
})
export class EmergencycareComponent implements OnInit {
  @ViewChild('idprint') idprint: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  //printgraph
  public billtypeflag: boolean;
  public approved_amountflag: boolean;
  public doc_qualification;
  public random;
  public bill_inc_pharmavalue;
  public temp_read;
  public cbg_txt;
  public Gender_data;
  public Age_data;
  public rs_text;
  public rr_txt;
  public pr_txt;
  public bmr;
  public cbgcolorstyle;
  public pulsecolorstyle;
  public rrcolorstyle;
  @ViewChild('printgraph') printgraph: ElementRef;
  public nursingnotes;
  public hospitalinfoarray:any = [];
  public pharmaratecrad;
  public hospitalratecard;
  public pharma_id;
  public bill_inc_pharma;
  public nursing_notes_id;
  public bloodglucoseflag: boolean = false;
  public nurselogin;
  public adm_headerdata: boolean;
  public doctorflag: boolean = false
  public nurseflag: boolean = true;
  public nursenotesarray: any = [];
  public gluprnt_graph: boolean = false;
  public barcode_flag: boolean = false;
  public frontdeskuser: boolean = false;
  public barcode_url;
  public billinguser: boolean;
  public barcode;
  public bloodpressureky;
  public currentTimeAMPM;
  public currentTime24Hr;
  public bill_noconsultant: boolean = false;
  public surgeon_list1: any = [];
  public pulseflag: boolean = false;
  public spo2graph;
  public showGraph: boolean = false;
  public bpgraphflag: boolean = false;
  public spo2graphflag: boolean = false;
  public bpgraph;
  public systolearray;
  public diastolearray;
  public bparray_xaxis;
  public pulsegrpah;
  public spo2array: any = [];
  public pulsearray: any = [];
  public spo2_xaxis: any = [];
  public pulse_xaxis: any = [];
  public pulsexaxis: any = [];
  public diastole;
  public systole;
  public charge_desc;
  public f_date_flag: boolean;
  public t_date_flag: boolean;
  public datacheck;
  public randomlevel: any = []
  public chargebase;
  public timerequired: boolean = false;
  public viewpres: boolean = false;
  public medicalprescription: boolean;
  public print_template = "template";
  public randomflag: boolean = false;
  public showgraph = "6month";
  public diaburl;
  public bgdate;
  public energyGraph: any;
  public energyGraph1: any;
  public xAxis: any = [];
  public fastinglevel: any = [];
  public postprandiallevel: any = [];
  public Daygraph;
  public beforefood_flag: boolean = true;
  public options;
  public gluselect;
  public totalHours;
  public bloodgluarray: any = [];
  public currentDatevalue;
  public visitSession;
  public getVisitingSessionArray: any = [];
  public f_date;
  public t_date;
  public dateval;
  enddate: any;
  endtime: any;
  HalfDayHour: number;
  DayHour: number;
  HourlyHour: number;

  public totalMinutes;
  public startMoment;
  public endMoment;
  public duration;
  public End_from_array: any = [];
  public selectedchargelistvalue: any = [];
  public chargedescription
  public sesionselction1;
  public f_time1;
  public totalHours1;
  public endTime1;
  public startTime1;
  public data1;
  public data2;
  public to_time1;
  public ends_at_array: any = [];
  public start_from_array: any = [];
  public start_at_array: any = [];
  public idproofcheck;
  public bpvalue;
  public clnt_maritalvalue;
  public print_wardbed: boolean = false;
  public bed_novalue;
  public gendervalue;
  public doc_reg_id;
  public modeOfPaymentvalue;
  public insure_flagheader: boolean = true;
  public tpanamevalue;
  public insuranceNamevalue;
  public approved_amountvalue;
  public insCardNovalue;
  public policyNumbervalue;
  public ccnNumbervalue;
  public billflag: boolean = false;
  public chargeListOthers: any;
  public selectedchargelist: any = [];
  public chargeid;
  public fasting;
  public ogit_txt;
  public Fasting_Halfhour;
  public Fasting_Onehour;
  public Fasting_Onehalfhour;
  public Postprandial;
  public fast_one_disable;
  public fast_onehalf_disable;
  public HbA1c;
  public clnt_address1;
  public clnt_address2;
  public clnt_location;
  public clnt_city;
  public clnt_state;
  public clnt_country;
  public clnt_location_id;
  public clnt_city_id;
  public clnt_state_id;
  public clnt_country_id;
  public clnt_zipcode;
  public allocatedbedflag: boolean;
  public printbutton: boolean = false;
  public previousflag: boolean = false;
  public pre_hospflag = false;
  public approved_amount;
  public inpat_id;
  public surgery_time;
  public previousvisits;
  public clnt_zipcodevalue;
  colorstyle: boolean;
  // BP01: any;
  // BP02: any;
  siscolorstyle: boolean;
  diocolorstyle: boolean;
  spo2colorstyle: boolean;

  public temperature: string;
  tempcolorstyle: boolean;
  public doctornamevalue;
  public department_id;
  @Input() pattern: string | RegExp;
  editorStyle = {
    height: '210px;',
  };
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
    ],
  };
  public updatebutton: boolean = false;
  public dichargebutton: boolean = false;

  public casesheetbutton: boolean = false;
  public approval_amount;
  // public updatebutton:boolean=false;

  public patient_name;
  public userinfo: any;
  public user_id: any;
  public first_name;
  public last_name;
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public messages;
  public msgflag: boolean = false;
  public notifications;
  public notifyflag: boolean = false;
  public profile_image;
  public covid_type: string;
  public header_footer_flag: Boolean;
  public isMobile: boolean;
  public fabButtonOpened: boolean;

  public treate_list_data: any = [];
  public data;
  public clnt_subrel_id;
  public clnt_sal;
  public clnt_saldesc;
  public clnt_fname;
  public clnt_mname;
  public clnt_lname;
  public clnt_age: any = '';
  public clnt_gender;
  public clnt_blood;
  public clnt_blood_id;
  public clnt_fg_name;
  public clnt_income;
  public clnt_occupation;

  public clnt_cont_number;
  public clnt_symptom;
  public clnt_visitpurpose;
  public clnt_dob = null;
  public ward_id;
  public bed_no;
  public send_bed_no;
  public emer_contact;
  public bp_txt;
  public surgery_name = "";
  public surgery_name1 = "";
  public treatement_type;
  public treatement_type_obj;
  public relationship_obj;
  public opiniontype_obj;
  public clnt_location_list: any = [];
  public clnt_city_list: any = [];
  public clnt_state_list: any = [];
  public clnt_country_list: any = [];
  public ward_list: any = [];
  public clnt_sal_list: any = [];

  public App_type;
  public Relationship;
  public client_data_list: any = [];
  public doctor_search_list;
  public appoint_client_data;

  public doctor_search_div: Boolean;
  public opinion_type_Div: Boolean;
  public appoint_Type_Div: Boolean;
  public search_Opinion_div: Boolean;
  public homecarevalue;

  public clnt_url;
  public user_type: boolean;
  public mobile_type: boolean;
  public new_user: boolean;
  public exist_user: boolean;
  public Client_type;
  public search_hide: boolean;

  public height_txt;
  public hmeasure: string;
  public weight_txt;
  public wmeasure: string;
  public temp_txt;
  public bmi_txt;
  public encheight;
  public encweight;
  public chief_comp;
  public line_mgmt;
  public pres_ill;
  public med_hist;
  public family_hist;
  public physical_exam;
  public client_reg_id;
  public Confirm_btn: boolean;

  public CurrentDatetime;
  public get_date;
  public tokenumber;
  public spl_id;
  public spl_array: any = [];
  public splvalue;
  public spl;
  public app_id;
  public admission_id;
  public hospital_id;
  public life_style_id;
  public get_locname_url;
  public update_btn: boolean;

  public refer_txt;
  public Refered_doctor: any = [];
  public Refered_name: any = [];
  public submenu_flag: boolean = true;
  public submenu_flag1: boolean = false;

  public dob_flag: boolean;
  public currentYear;
  public currentTime;
  public currentDate;
  public pastadmdate;
  public covid_flag: boolean;
  public readonlyFlag: boolean;

  public observList: any = [];
  public covidSympList: any = [];
  public comorbList: any = [];
  public finalcovidsymp: any = [];
  public finalcomorbList: any = [];
  public BP_01: string;
  public BP_02: string;
  public spo_txt: string;
  public pulse_txt: string;
  public resp_txt: string;
  public cvs_txt: string;
  public rstxt: string;
  public cns_txt: string;
  public abd_txt: string;
  public covid_duration: string;

  public observetable: boolean;
  public scan_01: string;
  public scan_02: string;
  public rtpcr_result: string;
  public bmr_txt: string;
  public blood_disabled: boolean;
  public rs_hidden: boolean;
  public cns_hidden: boolean;
  public abd_hidden: boolean;
  public cvs_hidden: boolean;

  public h_location;
  public h_address1;
  public h_address2;
  public h_city;
  public h_country;
  public h_state;
  public h_zipcode;

  public blood_preshidden: boolean;

  public symptom_data: any = [];
  public symptomlist: any = [];
  public symptoms_list: any = [];
  public selected_symptoms: any = [];

  public newsymptoms_flag: boolean = false;
  public newsymptoms: any = [];
  public quantity;
  public f_time;
  public to_time;
  public sesionselction;
  public discharge_date;
  public discharge_time1;
  public discharge_time2;
  public discharge_time3 = 'AM';
  public dischage_min: any = [];
  public dischage_hrs: any = [];

  public disable_dob: boolean;
  public disable_age: boolean;
  public require_flag: boolean;
  public h_city_id;
  public h_state_id;
  public h_country_id;

  public clnt_marital;
  public pre_hosp = "no";
  public pre_hospital_details;
  public allergy;
  public allergyFlag;
  public drugDetailsFlag: boolean = false;
  public engDetailsFlag: boolean = false;
  public foodDetailsFlag: boolean = false;
  public drug_value;
  public drug_details;
  public env_details;
  public env_value;
  public food_details;
  public food_value;
  public bedList: any = [];
  public bedOccList: any = [];
  public selectBed;
  public chargetypevalue;

  public printFlag;
  public hosp_addr;
  public hosp_csz;
  public hosp_count;
  public hosp_tel;
  public inpatientId;
  public curr_date;
  public coviedSytemmsText = '';
  public comorb_desc = '';
  public doctor_name;
  public wardName;
  public disPrint;
  public timePrint;

  public preHospFlag;
  public drugdetailsFlag;
  public envDetailsFlag;

  public hospitalAddress;
  public Hospital_location;
  public non_inpatient_flow: boolean = false;
  public doc_url;
  public doctor_list: any = [];
  public docname_list: any = [];
  public doc_id;
  public nurse_flow: boolean = false;
  public casesheet: boolean = false;

  public visiting_doctor;
  public visiting_doc_name;
  public docName;
  public visiting_remarks;
  public is_consultent = '0';
  public visiting_doc_id;
  public visitignArrray: any = [];
  public maritalStatusArray: any = [];
  public module_data: any = [];
  public both_modul_list: any = [];
  public submenu_drawer_modul_list: any = [];

  //casesheet
  public Gynecology_flag;
  public Is_specilization;
  public Pediatrics_flag;
  public Cardio_flag;
  public Nephrology_flag;
  public Dental_flag;
  public Diabetes_flag;
  public Others_flag;
  public spl_url;
  public stroke1: boolean = true;
  public doc_billable: string = "1";
  public admission_date;
  public admission_time;
  public admission_time1;
  public admission_time2;
  public admission_time3;

  public ynopt1: boolean = false;
  public ynopt2: boolean = false;
  public ynopt3: boolean = false;
  public ynopt4: boolean = false;
  public ynopt5: boolean = false;
  public itemsInRow = 4;
  public itemInRowRange: any = [];
  public show_bed_details: boolean = false;
  public show_bednos: boolean = false;
  public idProofType;
  public idProofTypeList: any = [];
  public purposeofvisit: any = []
  public idProofId;
  public idProofDesc;
  public idProofLabel;
  public idProofNo;
  public idProofNoFlag: boolean = false;
  public select = 'Select';
  public income;
  public unit;
  public mlcCase = 'No';
  public mlcCaseNo;
  public mlcCaseNoFlag: boolean = false;
  public admDisReason;
  public modeOfPayment;
  public inpatId: string;
  public vitalsFlag: boolean = true;
  public observationFlag: boolean = true;
  public disReason;
  public req_surgery = 'No';
  public req_sur_flag: boolean = true;
  public surgery_date: string;
  public surgery_time1: string;
  public surgery_time2: string;
  public surgery_time3: string;
  public surgery_proc: string;
  public allergies: string;
  public inp_smoke: string;
  public inp_alcohol: string;
  public show_doc: boolean = true;
  public medication: string;
  public med_details: string;
  public medication_flag: boolean = false;
  public med_requed: boolean = false;

  public anaest_types: any = [];
  public anaest_effects: any = [];
  public anaest_doctors: any = [];
  public anaest_procedure: string;
  public anaesttype: string;
  public anaest_effect: string;
  public anaest_doc: string[] = [];
  public surg_doc: string[] = [];
  public consult_doc: string[] = [];
  public surgeon_list: any = [];
  public surgery_list: any = [];
  public surg_diagosis: string;
  public inp_inv: string;
  public anaest_findings: string;
  public surgery_id: string = "0";

  public insuranceName: string;
  public insCardNo: string;
  public policyNumber: string;
  public ccnNumber: string;
  public insure_flag: boolean = true;
  private relation_id: string;
  private relation_name: string;
  public locationUrl: string;
  public locationNameByUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public zipcode_url: string;
  public userTypeFlag: boolean;
  public existUserFlag: boolean;
  public tpa_name: string;
  private nodob: boolean = false;
  public user_details: any;
  public full_details: any;
  public gender: string;
  public mobile_no: string;
  public surgery_time_full: string;
  public admission_time_full: string;
  //added
  public department: string;
  public departmentList: any = [];
  public hospId: string;
  public prooflength: string;
  public isDisabled: boolean = true;
  public ot_bookingFlag: boolean = false;
  public createpage_flag: boolean = true;
  public adm_datetime_flag: boolean = true;
  public doc_flag: boolean = true;
  public profileImg;
  public mrno;
  public chargeList: any = [];
  public chargetype;
  public is_charge_id;
  //Senthil Modified
  displayValue = true;
  lineColor = '#000000';
  elementType: 'svg';
  format:
    | ''
    | 'CODE128'
    | 'CODE128A'
    | 'CODE128B'
    | 'CODE128C'
    | 'EAN'
    | 'UPC'
    | 'EAN8'
    | 'EAN5'
    | 'EAN2'
    | 'CODE39'
    | 'ITF14'
    | 'MSI'
    | 'MSI10'
    | 'MSI11'
    | 'MSI1010'
    | 'MSI1110'
    | 'pharmacode'
    | 'codabar';
  width = 2;
  barcodeheight = 20;
  width1 = 0.8;
  barcodeheight1 = 20;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;

  fontSize = 15;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;
  vitals_List: any = [];
  ward: any;
  public adm_date: string;
  public mr_no: string;
  public dept_desc: string;
  public doctorviewflag: boolean = false;
  public pay_flag: boolean = false;
  public billPayment: string;
  public showandhideflag: boolean;
  public front_showinsuranceflag: boolean = false;
  public front_inp_show_vitalsflag: boolean = false;
  public bill_inp_show_insflag: boolean = false;
  public bill_inp_show_vitalsflag: boolean = false;
  public adm_date_edit;
  public currentTime_charge;
  public glucose_charge_id: string;
  public discountURl;
  public discountDatas: any = [];
  public discType;
  public discURL;
  public discountDatasArray:any = [];
  public rateplaneflag: boolean = false;
  public RateCardArray:any = [];
  public InsNameList:any = [];
  public rate_card;
  //

  public height_txt_case;
  public hmeasure_case;
  public weight_txt_case;
  public wmeasure_case;
  public bmi_txt_case;
  public bmr_txt_case;
  public temperature_case;
  public bloodPressure_case;
  public SPO2_case;
  public pulse_case;
  public RR_case;
  public Cvs_case;
  public Rs_case;
  public Cns_case;
  public ABD_case;
  public bpsys_case;
  public bpdia_case;
  public PR_case;
  public cbg_txt_case;
  public Medicne_data:any = [];
  public medicine_nametxt: string;
  public new_med: boolean;
  public Medicinenamelist;
  public Medicine_list:any = [];
  public medicinecolorarray:any = [];
  public intake_txt: string;
  public dure_require: boolean;
  public Get_Intake_array:any = [];
  public intakemodes: any = [];
  public intake_mode;
  public remark_txt: string;
  public soundalike;
  public lookalike;
  highRiskPresent: boolean = false;
  public med_typetxt: string;
  public print_med: boolean;
  public medicineaddbtn: boolean;
  public subtestname: any = [];
  public subtestuname: any = [];
  public subtestsname: any = [];
  public subtestxname: any = [];
  public subtestbname: any = [];
  public subtesturname: any = [];
  public subtestfname: any = [];
  public subtestcname: any = [];
  public print_medidiag: boolean = false;
  public painLevels: { number: number; active: string; selected: boolean; activeEmoji: boolean; blink: boolean; emoji: string; }[];
  diabetes: string;
  hyper: string;
  cadvalue: string;
  cvdvalue: string;

  tuber: string;
  ba: string;
  ptb: string;
  copd: string;
  apd: string;
  seizure: string;
  thyroid: string;
  jaundice: string;
  surgical: string;
  ckdvalue: string;
  ynopt10: boolean = false;
  ynopt11: boolean = false;
  ynopt12: boolean = false;
  ynopt13: boolean = false;
  ynopt14: boolean = false;
  ynopt15: boolean = false;
  ynopt16: boolean = false;

  ynopt17: boolean = false;
  ynopt18: boolean = false;
  ynopt19: boolean = false;
  ynopt20: boolean = false;
  ynopt21: boolean = false;
  ynopt22: boolean = false;
  ynopt23: boolean = false;
  ynopt24: boolean = false;
  ynoptsmoke: boolean=false;
  ynoptalc: boolean=false;
  public ynoptapp1: boolean = false;
  public inpatientflag: boolean = false;

  othervalue: string;
  diabflag: boolean = false;
  hyperflag: boolean = false;
  cadflag: boolean = false;
  cvdflag: boolean = false;
  tuberflag: boolean = false;
  ckdflag: boolean = false;
  baflag: boolean = false;
  ptbflag: boolean = false;
  copdflag: boolean = false;
  apdflag: boolean = false;
  seizureflag: boolean = false;
  thyroidflag: boolean = false;
  jaundiceflag: boolean = false;
  surgicalflag: boolean = false;

  otherflag: boolean = false;
  public diabdetials;
  public hype_details;
  public ckddetails;
  public caddetails;
  public cvd_details;
  public tuber_details;
  public ba_details;
  public ptb_details;
  public copd_details;
  public apd_details;
  public seizure_details;
  public thyroid_details;
  public jaundice_details;
  public surgical_details;
  public Others_details;

  public rate_card_disable: boolean = false;
  public high_risk;
  public look_alike;
  public sound_alike;
  public ward_only;
  public highrisk;
  public drug_typetxt: string;
  public medtypetxt;
  public ganericName: string;
  public short_form;
  public show;
  public show_intake;
  private med_id;
  public short_name;
  public default_quantity;
  public mix_show: boolean = true;
  public no_med: boolean = false;
  public intakesession: boolean = false;
  public quan_lable: boolean = true;
  public morning_req: boolean;
  public morning_dis: boolean;
  public afternoon_req: boolean;
  public even_dis: boolean;
  public afternoon_dis: boolean;
  public evening_req: boolean;
  public night_req: boolean;
  public night_dis: boolean;
  public mor_id: boolean;
  public after_id: boolean;
  public eve_id: boolean;
  public intake_show: boolean;
  public dure_show: boolean = false;
  public eve_eventxt: boolean = false;
  public night_eventxt: boolean = false;
  public daydur_show: boolean;
  public mor_lable: boolean = true;
  public after_eventxt: boolean = true;
  public morn_eventxt: boolean;
  public personalinfo;
  public viewpreslist_array = [];
  public intake_id;
  public mor: string;
  public aft: string;
  public eve: string;
  public ngt: string;
  public client_age;
  public ngt_dis: boolean;
  public afterfood_txt: string;
  public dure_txt: string;
  public daydur_txt;
  public day_txt: string = '1';
  public saveredirect_medical: boolean = true;
  public saveredirect_diagnosis: boolean = false;
  public printerflag: boolean = true;
  public ward_onlycolor;
  public high_riskcolor;
  public sound_alikecolor;
  public look_alikecolor;
  public genericnameflag: boolean = false;
  public listProducts: any = [];
  public Medical_prescription_array = [];
  public found;
  public newmed_flag: boolean;
  public saveflag: boolean = false;
  public print_view_flag: boolean = true;
  public Medicine_table: boolean = true;
  public every_six: string;
  public param_four: boolean;
  public dure_write: string;
  public dure_flag: boolean;
  public rowid: string;
  public mixing_txt: string;
  public saveproducts = [];
  public advance: string;
  public inpatientlist: any = [];
  public fromdatetime;
  public doc_visit_chargeList: any[] = [];
  public transactionFlag: boolean = true;
  public cardFlag: boolean = true;
  public chequeFlag: boolean = true;
  public cardreqflag: boolean = false;
  public transreqflag: boolean = false;
  public chequereqflag: boolean = false;
  public insreqflag: boolean = false;
  public showPaytype: boolean = true;
  public showadv: boolean = true;
  public insurFlag: boolean;
  public payType: string;
  public paytypelist: any = [];
  public payDesc: string;
  public transactionId;
  public cardNumber;
  public cardHolderName;
  public bankName;
  public Medicine_detailsflag: boolean = false;
  public pain_scaleflag: boolean = false;
  public Blood_glucoseflag: boolean = false;
  public Nursing_notesflag: boolean = false;
  public gen_exam;
  public local_exam;

  // Family hist new variables

  public togg_CKD_text: string = "No";
  public togg_CKD: boolean;
  public togg_CAD_text: string = "No";
  public togg_CAD: boolean;
  public togg_CVD_text: string = "No";
  public togg_CVD: boolean;
  public togg_BA_text: string = "No";
  public togg_BA: boolean;
  public togg_COPD_text: string = "No";
  public togg_COPD: boolean;
  public togg_APD_text: string = "No";
  public togg_APD: boolean;
  public togg_Seizure_text: string = "No";
  public togg_Seizure: boolean;
  public togg_Thyroid_text: string = "No";
  public togg_Thyroid: boolean;
  public togg_Jaundice_text: string = "No";
  public togg_Jaundice: boolean;
  public togg_Surgical_text: string = "No";
  public togg_Surgical: boolean;
  public togg_Other_text: string = "No";
  public togg_Other: boolean;

  public diab_details: string = "";
  public CKD_details: string = "";
  public CAD_details: string = "";
  public CVD_details: string = "";
  public BA_details: string = "";
  public TB_details: string = "";
  public COPD_details: string = "";
  public APD_details: string = "";
  public Seizure_details: string = "";
  public Thyroid_details: string = "";
  public hyper_details: string = "";
  public Jaundice_details: string = "";
  public Surgical_details: string = "";
  public Other_details: string = "";

  public relation_datas = [];
  public relation: any;
  public family_hist_data: any = [];

  public familyhist;
  public familyHistFlag = "min";
  public togg_heart_att1;
  public stroke2;
  public togg_endo1;
  public togg_hyper1;
  public togg_tuber1;
  public togg_hepat1;
  public togg_arter1;
  public togg_auto1;
  public togg_hemo1;
  public togg_hiv1;
  public togg_gla1;

  public togg_diab1;
  public togg_diab: string = "No";
  public togg_storke: string;
  public togg_endo: string;
  public togg_hyper: string = "No";
  public togg_tuber: string = "No";
  public family_other: string = "";
  public client: boolean = false;

  public tot_coll_adv: string="0.00";
  public advanceList: any = [];
  public show_adv_info: boolean = false;
  public wardflag:boolean=true;
  public dischargeflag:boolean=true;
  public diet_rec;
  public prov_diag;
  public emerg_admn;
  public emerg_treat;
  public emerg_dis;
  public ward_type;
  public emerg_type;

  constructor(private translate: TranslateService, public serviceAPI: ServerApi, public dialog: MatDialog, public http: Http, public https: HttpClient, public doctormessageservice: MenuViewService,
    public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
    public messageservice: MenuViewService, public cashsheet: CasesheetService,
    public frontservice: MenuViewService, public nurseservice: MenuViewService,
    public masterData: MasterHelperService, public masterCSdata: MasterCSHelperService, private cdRef: ChangeDetectorRef) {
    Doc_Helper.setMedForDischarge(undefined);
    Helper_Class.setAsg(undefined);
    this.get_locname_url = ipaddress.getIp + 'usercontroller/locbyname';
    this.doc_url = ipaddress.getIp + 'search/docbyhs';
    this.discountURl = ipaddress.getIp.toString() + "pharmacontrol/gdis";
    this.discURL = ipaddress.getIp.toString() + "pharmacontrol/gdis";
    this.maritalStatusArray = [
      'Single',
      'Bachelor',
      'Spinster',
      'Married',
      'Divorced',
      'Seperated',
      'Widowed',
    ];
    this.homecarevalue = '0';
    this.user_type = false;
    this.mobile_type = true;
    this.exist_user = false;
    this.new_user = true;
    this.update_btn = true;
    this.hmeasure = 'cms';
    this.wmeasure = 'kgs';
    this.Client_type = 'Existing';
    this.userTypeFlag = false;
    this.existUserFlag = true;

    this.search_hide = false;
    this.covid_type = 'no';
    this.scan_02 = '25';
    this.covid_flag = false;
    this.observetable = true;
    this.temp_txt = '';
    this.BP_01 = '';
    this.BP_02 = '';
    this.spo_txt = '';
    this.resp_txt = '';
    this.scan_01 = '';
    this.rtpcr_result = 'Positive';
    this.covid_flag = false;
    this.observetable = true;
    this.require_flag = true;
    this.clnt_address1 = 'NA';

    this.discharge_time1 = '';
    this.discharge_time2 = '00';
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.dischage_hrs.push('0' + i);
      } else {
        this.dischage_hrs.push(i);
      }
    }

    for (var i = 0; i <= 59; i += 1) {
      if (i < 10) {
        this.dischage_min.push('0' + i);
      } else {
        this.dischage_min.push(i);
      }
    }

    this.locationUrl = ipaddress.getIp + 'usercontroller/loc';
    this.locationNameByUrl = ipaddress.getIp + 'usercontroller/locbyname';
    this.cityUrl = ipaddress.getIp + 'gen/city';
    this.stateUrl = ipaddress.getIp + 'gen/state';
    this.countryUrl = ipaddress.getIp + 'gen/count';
    this.zipcode_url = ipaddress.getIp + 'usercontroller/locbyzipcode';

    this.treatement_type = Doc_Helper.getMedicare_name();
    var hosp: any = Helper_Class.getHospital();
    // this.hospital_id = hosp[0].hptl_clinic_id;
    Helper_Class.setapp_flow('0');
    Doc_Helper.setAppFlow('Inpatient');
    Helper_Class.setapp_flowinpa(null)
    Doc_Helper.setClient_Info(null);
    this.itemInRowRange = Array.from(Array(this.itemsInRow).keys());
  }

  async ngOnInit() {
    this.advance = '0.00';
    this.personalinfo = Helper_Class.getInfo();
    this.temp_read = "F";
    this.currentTime_charge = moment().format('hh:mm A');
    this.nursingnotes = "";
    this.nursing_notes_id = "";
    this.nurselogin = "";
    
    if (Doc_Helper.getInpatientId() == null) {
      this.createpage_flag = true;

    } else {
      this.createpage_flag = false;
    }
    this.Fasting_Halfhour = "";
    this.Postprandial = ""
    Doc_Helper.setAppFlow('Inpatient');
    Helper_Class.setapp_flowinpa('Inpatient')
    if (this.energyGraph) {
      this.energyGraph.destroy();
    }
    if (this.energyGraph1) {
      this.energyGraph1.destroy();
    }
    if (this.spo2graph) {
      this.spo2graph.destroy();
    }
    if (this.bpgraph) {
      this.bpgraph.destroy();
    }
    if (this.pulsegrpah) {
      this.pulsegrpah.destroy();
    }
    this.getSpecializations();
    this.painLevels = [
      { number: 0, active: "No pain", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/No_pain.svg" },
      { number: 1, active: "Very mild", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Very_mild.svg" },
      { number: 2, active: "Discomforting", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Discomforting.svg" },
      { number: 3, active: "Tolerable", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Tolerable.svg" },
      { number: 4, active: "Distressing", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Distressing.svg" },
      { number: 5, active: "Very distressing", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Very_distressing.svg" },
      { number: 6, active: "Intense", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Intense.svg" },
      { number: 7, active: "Very_intense", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Very_intense.svg" },
      { number: 8, active: "Horrible", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Horrible.svg" },
      { number: 9, active: "Unbearable", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Unbearable.svg" },
      { number: 10, active: "Unspeakable", selected: false, activeEmoji: false, blink: false, emoji: "../../../assets/ui_icons/Emojiicon/Unspeakable.svg" },
    ];

    this.languageChange();
    this.gluselect = "beforefood"
    this.f_time1 = ""
    this.to_time1 = ""
    this.sesionselction1 = ""
    this.f_time = ""
    this.to_time = ""
    this.sesionselction = ""
    this.quantity = 1;
    this.emerg_type="observation";
    //this.reading_Graph();
    this.profileImg = "../../assets/img/default.jpg";
    $(document).ready(function () {
      $('input').attr('autocomplete', 'off');
    });
    if (Helper_Class.getInfo() != undefined) {
      if (Helper_Class.getInfo().user_type == "Billing") {
        this.billinguser = true;

      } else {
        this.billinguser = false
      }
    }

    if (Helper_Class.getmodulelist() != undefined) {
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "1") {
          if (Helper_Class.getmodulelist()[i].print == "1") {
            this.printbutton = false;
          }
        }
      }

    } else {
      this.printbutton = false;
    }

    if (Helper_Class.getInpatientFlow() == 'doctor') {
      if (this.createpage_flag == false) {
        this.nurse_flow = false;
        this.doctorviewflag = false;

      } else {
        this.nurse_flow = true;
      }

      if (Helper_Class.getInfo().both_modules != undefined) {
        if (Helper_Class.getInfo().both_modules.length != 0) {
          var Moduleidlist
          Moduleidlist = Helper_Class.getInfo().both_modules;
          for (var i = 0; i < Moduleidlist.length; i++) {
            if (Moduleidlist[i].module_id == "6" || Moduleidlist[i].module_id == "119") {
              if (this.createpage_flag == false) {
                this.medicalprescription = false;
              }
            }

            if (Helper_Class.getmodulelist()[i].module_id == "147") {
              if (Helper_Class.getmodulelist()[i].create == "1") {
                this.dichargebutton = true
              }
            }
          }
        }
      }

    } else if (Helper_Class.getInpatientFlow() == 'nurse') {
      this.nurseflag = false;
      this.nurselogin = "nurse";
      if (Helper_Class.getInfo().both_modules.length != 0) {
        var Moduleidlist
        Moduleidlist = Helper_Class.getInfo().both_modules;
        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i].module_id == "6" || Moduleidlist[i].module_id == "119") {
            if (this.createpage_flag == false) {
              this.medicalprescription = false;
            }
          }

          if (Moduleidlist[i].module_id == "157") {
            if (this.createpage_flag == false) {
              this.viewpres = false;
            }
          }

          if (Helper_Class.getmodulelist()[i].module_id == "147") {
            if (Helper_Class.getmodulelist()[i].create == "1") {
              this.dichargebutton = true
            }
          }
        }
      }

      if (this.createpage_flag == false) {
        this.nurse_flow = false;
        this.doctorviewflag = true;
      } else {
        this.nurse_flow = true;
      }

      this.non_inpatient_flow = true;
      this.nurse_flow = false;
      this.casesheet = false;
      if (this.createpage_flag == false) {
        this.nurse_flow = false;
        this.casesheet = false;

      } else {
        this.nurse_flow = true;
        this.casesheet = true;
      }

    } else if (Helper_Class.getInpatientFlow() == 'front-desk') {
      this.frontdeskuser = true;
      this.non_inpatient_flow = true;
      if (this.createpage_flag == false) {
        this.nurse_flow = true;
        this.casesheet = true;
        this.doctorviewflag = false;
        this.medicalprescription = false;
      }

      if (Helper_Class.getmodulelist() != undefined) {
        for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
          if (Helper_Class.getmodulelist()[i].module_id == "1") {
            if (Helper_Class.getmodulelist()[i].edit == "1") {
              this.updatebutton = true;
            }
          }

          if (Helper_Class.getmodulelist()[i].module_id == "3") {
            if (Helper_Class.getmodulelist()[i].create == "1") {
              this.dichargebutton = true
            }
          }

          if (Helper_Class.getmodulelist()[i].module_id == "85") {
            this.casesheetbutton = true
          }

          if (Helper_Class.getmodulelist()[i].module_id == "147") {
            if (Helper_Class.getmodulelist()[i].create == "1") {
              this.dichargebutton = true
            }
          }
        }
      }

    } else {
      this.frontdeskuser = false;
      this.non_inpatient_flow = false;
      if (this.createpage_flag == false) {
        this.nurse_flow = false;
        this.casesheet = false;

      } else {
        this.casesheet = true;
      }

      if (Helper_Class.getmodulelist() != undefined) {
        for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
          if (Helper_Class.getmodulelist()[i].module_id == "1") {
            if (Helper_Class.getmodulelist()[i].edit == "1") {
              this.updatebutton = true;
            }
          }

          if (Helper_Class.getmodulelist()[i].module_id == "147") {
            if (Helper_Class.getmodulelist()[i].create == "1") {
              this.dichargebutton = true
            }
          }

          if (Helper_Class.getmodulelist()[i].module_id == "85") {
            this.casesheetbutton = true
          }
        }
      }
    }

    let currentMinute = new Date().getMinutes();
    for (var i = 0; i <= 55; i += 1) {
      if (i < 10)
        this.ends_at_array.push("0" + i);
      else
        this.ends_at_array.push(i.toString());
    }

    let currentTime = new Date();
    let currentHour = currentTime.getHours();

    for (var i = 1; i <= 12; i++) {
      if (i < 10)
        this.End_from_array.push("0" + i);
      else
        this.End_from_array.push(i);
    }

    for (var i = 0; i <= 55; i += 1) {
      if (i < 10)
        this.start_at_array.push("0" + i);
      else
        this.start_at_array.push(i);
    }

    for (var i = 1; i <= 12; i++) {
      if (i < 10)
        this.start_from_array.push("0" + i);
      else
        this.start_from_array.push(i);
    }

    if (Doc_Helper.getClient_type() == "Existing") {
      this.createpage_flag = false;

    } else {
      this.createpage_flag = true;
    }
    this.clnt_url = Doc_Helper.getInpatientFlow() == 'list'
      ? ipaddress.getIp + 'inpat/gpd/'
      : ipaddress.getIp + 'usercontroller/cdetnoapph/';

    this.getSalutations();
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;

    if (this.userinfo.user_type == 'doctor') {
      this.doc_flag = true;
      this.spl_id = this.userinfo.specializations[0].spl_id;

    } else {
      this.doc_flag = false;
    }

    if (this.userinfo.user_type == "Billing") {
      this.getPaymentType();
      this.showadv = false;
    }

    this.hospital_details = this.userinfo.hospitals[0];
    this.hospital_name = this.hospital_details.hptl_name;
    this.hospId = this.hospital_details.hptl_clinic_id;
    this.hospital_id = this.hospital_details.hptl_clinic_id;
    this.clnt_location_id = this.hospital_details.location;
    this.clnt_city_id = this.hospital_details.city;
    this.clnt_state_id = this.hospital_details.state;
    this.clnt_country_id = this.hospital_details.country;

    if (Helper_Class.getInpatientFlow() == 'front-desk') {
      if (this.userinfo.adm_datetime_edit == "1") {
        this.adm_datetime_flag = true;
      } else {
        this.adm_datetime_flag = false;
      }

    } else {
      if (this.hospital_details.adm_datetime_edit == "1") {
        this.adm_datetime_flag = true;
      } else {
        this.adm_datetime_flag = false;
      }
    }

    this.Confirm_btn = false;
    this.clnt_marital = 'single';
    this.clnt_maritalvalue = '';

    this.pre_hosp = 'no';
    this.changestroke(true);
    this.userTypeSelect('Existing');

    if (this.userinfo.middle_name != undefined) {
      this.docName = this.userinfo.first_name + ' ' + this.userinfo.middle_name + ' ' + this.userinfo.last_name;
    } else {
      if (this.userinfo.last_name != undefined) {
        this.docName = this.userinfo.first_name + ' ' + this.userinfo.last_name;
      } else {
        this.docName = this.userinfo.first_name;
      }
    }

    if (Helper_Class.getInpatientFlow() == 'front-desk') {
      this.show_doc = false;
      if (Helper_Class.getInfo().dis_summ_create == '1') {
        this.non_inpatient_flow = true;

      } else {
        this.non_inpatient_flow = false;
      }

    } else {
      this.show_doc = true;
      if (Helper_Class.getInpatientFlow() != 'nurse') {
        this.visiting_doc_name = this.docName;
      }
      this.visiting_doc_id = this.user_id;
    }

    if (Helper_Class.getInpatientFlow() == 'doctor') {
      this.hospital_id = this.hospital_details.hptl_clinic_id;
      if (this.userinfo.specializations != undefined) {
        this.spl_array = this.userinfo.specializations;
      }
    }

    if (Master_Helper.getMasterWardData() != undefined) {
      this.ward_list = Master_Helper.getMasterWardData().wards;
      this.ward_list.forEach(element => {
        if(element.ward_name =="Emergency") {
          this.ward_id = element.ward_id;
          this.getBedList(this.ward_id);
        }
      });
      
    } else {
      await this.masterData.getWardData();
      this.ward_list = Master_Helper.getMasterWardData().wards;
      this.ward_list.forEach(element => {
        if(element.ward_name =="Emergency") {
          this.ward_id = element.ward_id;
          this.getBedList(this.ward_id);
        }
      });
    }

    if (this.userinfo.user_type == "front-desk") {
      if (this.userinfo.front_inp_show_ins == "1") {
        this.front_showinsuranceflag = true;
        this.bill_inp_show_insflag = false;

      } else {
        this.front_showinsuranceflag = false;
        this.bill_inp_show_vitalsflag = false;
        this.bill_inp_show_insflag = false;
      }

      if (this.userinfo.front_inp_show_vitals == "1") {
        this.front_inp_show_vitalsflag = true;
        this.bill_inp_show_insflag = false;
        this.Medicine_detailsflag = true;
        this.pain_scaleflag = true;
        this.Blood_glucoseflag = true
        this.Nursing_notesflag = true;

      } else if (this.userinfo.front_inp_show_vitals == "0") {
        this.front_inp_show_vitalsflag = false;
        this.bill_inp_show_insflag = false;
        this.Medicine_detailsflag = false;
        this.pain_scaleflag = false;
        this.Blood_glucoseflag = false;
        this.Nursing_notesflag = false;
      }

    } else if (this.userinfo.user_type == "Billing") {
      this.doctorviewflag = false;
      this.billflag = true;
      if (this.userinfo.bill_inp_show_ins == "1") {
        this.bill_inp_show_insflag = true;
        this.front_inp_show_vitalsflag = false;

      } else {
        this.bill_inp_show_insflag = false;
        this.front_inp_show_vitalsflag = false;
      }

      if (this.userinfo.bill_inp_show_vitals == "1") {
        this.bill_inp_show_vitalsflag = true;
        this.front_inp_show_vitalsflag = true;
        this.Medicine_detailsflag = true;
        this.pain_scaleflag = true;
        this.Blood_glucoseflag = true;
        this.Nursing_notesflag = true;

      } else {
        this.bill_inp_show_vitalsflag = false;
        this.front_inp_show_vitalsflag = false;
        this.Medicine_detailsflag = false;
        this.pain_scaleflag = false;
        this.Blood_glucoseflag = false;
        this.Nursing_notesflag = false;
      }
      this.hospitalinfoarray = Helper_Class.getHospital();
      this.pharma_id = this.hospitalinfoarray[0].pharma_id;

    } else {
      this.front_showinsuranceflag = true;
      this.front_inp_show_vitalsflag = true;
      this.Medicine_detailsflag = true;
      this.pain_scaleflag = true;
      this.Blood_glucoseflag = true
      this.Nursing_notesflag = true;
      this.billflag = false;
      this.hospitalinfoarray = Helper_Class.getHospital();
      this.pharma_id = this.hospitalinfoarray[0].pharma_id;
    }

    this.getCurrentDate();
    this.getCurrentDate1()
    this.getDepartment(this.hospital_id);
    this.getCurrentToken();
    this.getHospitalDetails();
    this.getCovidSymptoms();
    this.getComorbidities();
    this.getIdProofType();
    this.changeAllery('main', 1, 'no');
    this.changeAllery('drug', 1, 'no');
    this.changeAllery('evn', 1, 'no');
    this.changeAllery('food', 1, 'no');
    this.getAnaestesiaTypes();
    this.getAnaestisiaEffects();
    this.getAnaestisiaDoctors();
    this.getRetrivalothercharges();
    this.getRelations();

    if (Doc_Helper.getClient_type() != undefined && Doc_Helper.getClient_type() == 'New') {
      this.Client_type = 'Existing';
      this.user_type = true;
      this.mobile_type = false;
      this.new_user = false;
      this.exist_user = true;
      this.clnt_gender = 'Male';
      this.search_hide = false;
      this.dob_flag = false;
      this.ynopt1 = true;
      this.change_type(1, 'no');

    } else {
      this.new_user = true;
      this.mobile_type = true;
      this.dob_flag = true;
      this.Client_type = 'Existing';
      this.exist_user = false;
      this.search_hide = true;
      this.ynopt1 = false;
    }

    if (Doc_Helper.getMobile_no() != null) {
      this.clnt_cont_number = encrypt_decript.Decript(Doc_Helper.getMobile_no());
    }

    if (Doc_Helper.getFirstname() != null) {
      this.clnt_fname = Doc_Helper.getFirstname();
    }

    if (Doc_Helper.getMiddlename() != null) {
      this.clnt_mname = Doc_Helper.getMiddlename();
    } else {
      this.clnt_mname = undefined;
    }

    if (Doc_Helper.getLastname() != null) {
      this.clnt_lname = Doc_Helper.getLastname();
    }

    if (Doc_Helper.getRel_id() != null) {
      this.relation_id = Doc_Helper.getRel_id();
    }

    if (Doc_Helper.getrelationName() != null) {
      this.relation_name = Doc_Helper.getrelationName();
    }

    if (this.userinfo.user_type != "Billing") {
      this.bill_noconsultant = true;

    } else {
      this.RateCardData();
      this.getDiscTypes();
    }

    if (this.clnt_cont_number != undefined || this.clnt_cont_number != null) {
      this.getUserDetails();
    }

    if (this.createpage_flag == true) {
      this.changeglucoselevel(this.gluselect)
    }

    this.search_hide = Boolean(Doc_Helper.getSearchFlag());
    this.medication = 'no';
    this.medication_flag = true;
    this.is_charge_id = "0";
    if (Helper_Class.getInpatientFlow() == 'doctor') {
      this.doctorflag = true;

    } else {
      this.doctorflag = false;
    }
    
    if (masterCSData_Helper.getMasterIntakeSession != undefined) {
      await this.masterCSdata.getIntakeSession();
      this.Get_Intake_session();

    } else {
      this.Get_Intake_session();
    }
    this.inpatientlist = Helper_Class.getinpatientfilterdata()
  }

  showPayType() {
    if (this.advance != "0.00") {
      this.showPaytype = false;
    } else {
      this.showPaytype = true;
    }
  }

  changeAdvPayType() {
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "2") {
      this.transactionFlag = false;
      this.cardFlag = false;
      this.cardreqflag = true;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if (this.payType == "3") {
      this.cardreqflag = false;
      this.insurFlag = false;
      this.cardreqflag = false;
      this.transreqflag = false;
      this.chequereqflag = false;
      this.insreqflag = true;

    } else if (this.payType == "4") {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if (this.payType == "5") {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if (this.payType == "6") {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;

    } else if (this.payType == "7") {
      this.chequeFlag = false;
      this.cardreqflag = false;
      this.transreqflag = false;
      this.chequereqflag = true;
      this.insreqflag = false;
    }
    var res = this.paytypelist.filter(o => o.pay_id == this.payType);
    this.payDesc = res[0].pay_desc;
  }

  paymentTypeChange(data) {
    this.modeOfPayment = data;
    if (this.modeOfPayment == 'Insurance') {
      this.insure_flag = false;
      this.pay_flag = false;
      this.insure_flagheader = false;
    } else {

      this.insure_flag = true;
      this.pay_flag = true;
      this.insure_flagheader = true;
    }
  }

  changepayment(data) {
    this.billPayment = data;
    this.bill_inc_pharma = this.billPayment
  }

  getPaymentType() { //Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response['_body']);
          this.paytypelist = obj.payments;
          for (var i = 0; i < this.paytypelist.length; i++) {
            if (this.paytypelist[i].pay_desc == "Insurance")
              this.paytypelist.splice(i, 1);
          }
          this.payType = this.paytypelist[0].pay_id;
          this.payDesc = this.paytypelist[0].pay_desc;
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeInvest = (event) => {
    this.inp_inv = event.html;
    if (this.inp_inv.length > 250) {
      this.inp_inv = this.inp_inv.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };

  changeGeneral = (event) => {
    this.gen_exam = event.html;
    if (this.gen_exam.length > 250) {
      this.gen_exam = this.gen_exam.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };

  changeLocalExam = (event) => {
    this.local_exam = event.html;
    if (this.local_exam.length > 250) {
      this.local_exam = this.local_exam.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };

  changeDiet = (event) => {
    this.diet_rec = event.html;
    if (this.local_exam.length > 250) {
      this.diet_rec = this.diet_rec.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };

  changeProvDiagnosis = (event) => {
    this.prov_diag = event.html;
    if (this.prov_diag.length > 250) {
      this.prov_diag = this.prov_diag.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };

  changeDiagosis = (event) => {
    this.surg_diagosis = event.html;
    if (this.surg_diagosis.length > 250) {
      this.surg_diagosis = this.surg_diagosis.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };

  changeChiefComp = (event) => {
    this.chief_comp = event.html;
    if (this.chief_comp.length > 250) {
      this.chief_comp = this.chief_comp.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };

  changeLineMgmt = (event) => {
    this.line_mgmt = event.html;
    if (this.line_mgmt.length > 250) {
      this.line_mgmt = this.line_mgmt.substring(0, 250);
    }
  };

  changePresIllness = (event) => {
    this.pres_ill = event.html;
  };

  changeMedicalHistory = (event) => {
    this.med_hist = event.html;
  };

  changeFamilyHistory = (event) => {
    this.family_hist = event.html;
  };

  changePhysicalExam = (event) => {
    this.physical_exam = event.html;
  };

  concentForm() {

  }

  getAnaestesiaTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .get(ipaddress.getIp + 'inpat/gat', { headers: headers })
      .subscribe(
        (data) => {
          this.anaest_types = [];
          this.anaest_types = JSON.parse(data['_body']).anaesthesia_types;
          console.log('ANAESTHESIA TYPES2 ' + JSON.stringify(this.anaest_types));
        },
        (error) => {
          this.toastr.error(Message_data.network);
        }
      );
  }

  getAnaestisiaEffects() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .get(ipaddress.getIp + 'inpat/gase', { headers: headers })
      .subscribe(
        (data) => {
          this.anaest_effects = [];
          this.anaest_effects = JSON.parse(data['_body']).anaes_effects;
          console.log(
            'ANAESTESIA EFFECTS1 ' + JSON.stringify(this.anaest_effects)
          );
        },
        (error) => {
          this.toastr.error(Message_data.network);
        }
      );
  }

  getAnaestisiaDoctors() {
    console.log('ANAESTESIA EFFECTS' + JSON.stringify(this.hospital_details.hptl_clinic_id));
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp + 'inpat/gad',
        JSON.stringify({ hptl_clinic_id: this.hospital_details.hptl_clinic_id }),
        { headers: headers }
      )
      .subscribe(
        (data) => {
          var obj = data.json();
          this.anaest_doctors = [];
          console.log('ANAESTESIA EFFECTS **' + JSON.stringify(obj));
          // Parse and filter the response
          var doctors = JSON.parse(data['_body']).anaesthesia_doctors;
          this.anaest_doctors = doctors.filter(doctor => doctor.name && doctor.doc_reg_id);

          console.log('ANAESTESIA EFFECTS **' + JSON.stringify(this.anaest_doctors));
        },
        (error) => {
          this.toastr.error(Message_data.network);
        }
      );
  }


  getSpecializations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp + 'adm/gsplbyhptl',
        JSON.stringify({ hptl_clinic_id: this.hospital_id }),
        { headers: headers }
      )
      .subscribe(
        (data) => {
          this.spl_array = [];
          var obj = JSON.parse(data['_body']);
          if (obj.specializations != null) {
            this.spl_array = obj.specializations;
            this.getDoctorList(this.spl_id);
            this.getSurgeons();
          }
        },
        (error) => {
          // this.toastr.error(Message_data.network);
        }
      );
  }

  surgerySelect(data) {
    this.surgery_id = data.surgery_id;
    this.surgery_name = data.surgery_name;
  }

  surgeryRequired() {
    if (this.req_surgery == 'No') {
      this.req_sur_flag = true;
      this.ot_bookingFlag = false;

    } else {
      this.req_sur_flag = false;
      this.ot_bookingFlag = true;
    }
  }

  medicationChange() {
    if (this.medication == 'no') {
      this.medication_flag = true;
      this.med_requed = false;

    } else {
      this.medication_flag = false;
      this.med_requed = true;
    }
  }

  addObservations() {
    if (
      this.temp_txt.length != 0 &&
      this.BP_01.length != 0 &&
      this.BP_02.length != 0 &&
      this.spo_txt.length != 0 &&
      this.resp_txt.length != 0 &&
      this.scan_01.length != 0
    ) {
      this.BP_01 = this.BP_01 != null ? this.BP_01 : '00';
      this.BP_02 = this.BP_02 != null ? this.BP_02 : '00';

      this.observList.push({
        temp: this.temp_txt,
        bp: this.BP_01 + '/' + this.BP_02,
        sop2: this.spo_txt,
        pulse: this.pulse_txt,
        resprate: this.resp_txt,
        ct_scan: this.scan_01 + '/' + this.scan_02,
        rtpcr: this.rtpcr_result,
        status: '1',
      });
      this.observetable = false;

    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  getDoctorList(e) {
    if (Helper_Class.getInpatientFlow() == 'front-desk' ||
      Helper_Class.getInpatientFlow() == 'nurse') {
      for (var i = 0; i < this.spl_array.length; i++) {
        if (e == this.spl_array[i].spl_id) {
          var splname = this.spl_array[i].spl_name;
        }
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.doc_url,JSON.stringify({
            hptl_clinic_id: this.hospital_id,
            spl_name: splname,
          }),
          { headers: headers }
        )
        .subscribe(
          (data) => {
            console.log('dataobj ' + JSON.stringify({
              hptl_clinic_id: this.hospital_id,
              spl_name: splname,
            }));
            this.doctor_list = [];
            this.docname_list = [];
            var dataobj = JSON.parse(data['_body']);
            if (dataobj.doctors != undefined && dataobj.doctors.length != 0) {
              this.doctor_list = dataobj.doctors;
              if (this.doctor_list != undefined) {
                for (var i = 0; i < this.doctor_list.length; i++) {
                  var docname;
                  if (this.doctor_list[i].middle_name != undefined) {
                    docname = this.doctor_list[i].first_name +' ' +
                      this.doctor_list[i].middle_name +' ' +this.doctor_list[i].last_name;

                  } else {
                    docname =this.doctor_list[i].first_name +' ' +this.doctor_list[i].last_name;
                  }
                  this.docname_list.push({
                    docname: docname,
                    prov_id: this.doctor_list[i].prov_id,
                  });
                }

                if (Helper_Class.getInpatientFlow() == 'front-desk') {
                  if (this.createpage_flag == true) {
                    this.doc_id = this.docname_list[0].prov_id;
                  }

                  if (Doc_Helper.getClient_type() == 'New') {
                    this.doc_id = this.docname_list[0].prov_id;
                  }
                }

                if (Helper_Class.getInpatientFlow() == 'doctor') {
                  this.doc_id = this.userinfo.doc_reg_id;
                } 
              }
              this.Change_Specialization(this.spl_id);
              this.changedoctor(this.docname_list[0].prov_id);
            }
          },
          (error) => {
            this.toastr.error(Message_data.unableToFetchData);
          }
        );

    } else {
      for (var i = 0; i < this.spl_array.length; i++) {
        if (e == this.spl_array[i].spl_id) {
          var splname = this.spl_array[i].spl_name;
        }
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.doc_url, JSON.stringify({
            hptl_clinic_id: this.hospital_id,
            spl_name: splname,
          }),
          { headers: headers }
        )
        .subscribe(
          (data) => {
            console.log('dataobj ' + JSON.stringify({
              hptl_clinic_id: this.hospital_id,
              spl_name: splname,
            }));
            this.doctor_list = [];
            this.docname_list = [];
            var dataobj = JSON.parse(data['_body']);
            this.doctor_list = dataobj.doctors;
            if (this.doctor_list != undefined) {
              for (var i = 0; i < this.doctor_list.length; i++) {
                var docname;
                if (this.doctor_list[i].middle_name != undefined) {
                  docname =this.doctor_list[i].first_name +' ' +
                    this.doctor_list[i].middle_name +' ' +this.doctor_list[i].last_name;
                } else {
                  docname =this.doctor_list[i].first_name +' ' +this.doctor_list[i].last_name;
                }
                this.docname_list.push({
                  docname: docname,
                  prov_id: this.doctor_list[i].prov_id,
                });
              }
              
              if (Helper_Class.getInpatientFlow() == 'front-desk') {
                if (Doc_Helper.getClient_type() == 'New') {
                  this.doc_id = this.docname_list[0].prov_id;
                }
              }
            }
            this.Change_Specialization(this.spl_id);
            if (this.docname_list != undefined) {
              this.changedoctor(this.docname_list[0].prov_id)
            }
          },
          (error) => {
            this.toastr.error(Message_data.unableToFetchData);
          }
        );
    }
  }

  async getCovidSymptoms() {
    if (Master_Helper.getMasterCovidSymptoms() == undefined) {
      await this.masterData.getCovidSymptoms();
    }
    var obj = Master_Helper.getMasterCovidSymptoms();
    if (obj != undefined) {
      for (var i = 0; i < obj.covid_symptoms.length; i++) {
        this.covidSympList.push({
          symp_id: obj.covid_symptoms[i].covid_symp_id,
          symp_desc: obj.covid_symptoms[i].description,
        });
      }
    }
  }

  async getComorbidities() {
    if (Master_Helper.getMasterComorbidities() == undefined) {
      await this.masterData.getComorbidities();
    }
    var obj = Master_Helper.getMasterComorbidities();
    if (obj != undefined) {
      for (var i = 0; i < obj.comorbidities.length; i++) {
        this.comorbList.push({
          comorb_id: obj.comorbidities[i].comorbidity_id,
          comorb_desc: obj.comorbidities[i].comorbidity,
        });
      }
    }
  }

  covidtypeSelect(e) {
    this.covid_flag = this.covid_type == 'yes' ? true : false;
  }

  allergyChange(e) {
    if (this.allergy == 'yes') {
      this.allergyFlag = true;
    } else {
      this.allergyFlag = false;
      this.drugDetailsFlag = false;
      this.engDetailsFlag = false;
      this.foodDetailsFlag = false;
    }
  }


  symptom_click(e: any, data) {
    if (e == true) {
      if (this.covidSympList.length != 0) {
        for (var i = 0; i < this.covidSympList.length; i++) {
          if (this.covidSympList[i].symp_id == data) {
            this.covidSympList[i].checked = true;
            this.finalcovidsymp.push(this.covidSympList[i].symp_id);
          }
        }
      }
    } else {
      if (this.covidSympList.length != 0) {
        for (var i = 0; i < this.covidSympList.length; i++) {
          if (this.covidSympList[i].symp_id == data) {
            this.covidSympList[i].checked = false;
          }
        }
      }

      if (this.finalcovidsymp.length != 0) {
        for (var j = 0; j < this.finalcovidsymp.length; j++) {
          if (data == this.finalcovidsymp[j]) {
            this.finalcovidsymp.splice(j, 1);
          }
        }
      }
    }
  }

  comorb_click(e: any, data) {
    if (e == true) {
      if (this.comorbList.length != 0) {
        for (var i = 0; i < this.comorbList.length; i++) {
          if (this.comorbList[i].comorb_id == data) {
            this.comorbList[i].checked = true;
            this.finalcomorbList.push(this.comorbList[i].comorb_id);
          }
        }
      }
    } else {
      if (this.comorbList.length != 0) {
        for (var i = 0; i < this.comorbList.length; i++) {
          if (this.comorbList[i].comorb_id == data) {
            this.comorbList[i].checked = false;
          }
        }
      }

      if (this.finalcomorbList.length != 0) {
        for (var j = 0; j < this.finalcomorbList.length; j++) {
          if (data == this.finalcomorbList[j]) {
            this.finalcomorbList.splice(j, 1);
          }
        }
      }
    }
  }

  Change_Specialization(e) {
    this.spl_id = e;
    for (var i = 0; i < this.spl_array.length; i++) {
      if (this.spl_array[i].spl_id == this.spl_id) {
        this.spl = this.spl_array[i].spl_name;
        this.spl_id = this.spl_array[i].spl_id;
      }
    }
  }

  getSalutations() {
    if (Master_Helper.getMasterSalutation() == undefined) {
      this.masterData.getSalutations();
    }
    var salutation = Master_Helper.getMasterSalutation();
    if (salutation != undefined) {
      for (var i = 0; i < salutation.salutations.length; i++) {
        this.clnt_sal_list.push({
          sal_id: salutation.salutations[i].salutation_id,
          sal_desc: salutation.salutations[i].salutation_desc,
        });
      }
    }
    this.clnt_sal = this.clnt_sal_list[0].sal_id;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "gen/sal", { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(data['_body']);
        if (obj != undefined) {
          for (var i = 0; i < obj.salutations.length; i++) {
            this.clnt_sal_list.push({
              sal_id: obj.salutations[i].salutation_id,
              sal_desc: obj.salutations[i].salutation_desc,
            });
          }
          this.clnt_sal = "select";
        }
      });
    this.changeSalutation(this.clnt_sal);
  }

  openFabButton() {
    this.fabButtonOpened = this.fabButtonOpened == false ? true : false;
  }

  getHospitalDetails() {
    this.h_location = Master_Helper.getMasterHospital().location;
    this.h_address1 = Master_Helper.getMasterHospital().address1;
    this.h_address2 = Master_Helper.getMasterHospital().address2;
    this.h_city = Master_Helper.getMasterHospital().city;
    this.h_state = Master_Helper.getMasterHospital().state;
    this.h_country = Master_Helper.getMasterHospital().country;
    this.h_zipcode = Master_Helper.getMasterHospital().zipcode;
    this.h_city_id = Master_Helper.getMasterHospital().city_id;
    this.h_state_id = Master_Helper.getMasterHospital().state_id;
    this.h_country_id = Master_Helper.getMasterHospital().country_id;
  }

  userTypeSelect(e) {
    this.Client_type = e;
    if (this.Client_type == 'New') {
      this.userTypeFlag = true;
      this.existUserFlag = false;

      this.user_type = true;
      this.new_user = false;
      this.exist_user = true;
      this.clnt_gender = 'Male';
      this.search_hide = true;
      this.mobile_type = false;
      this.dob_flag = false;
      this.clnt_gender = 'Male';
      this.dob_flag = false;
      this.clnt_fname = null;
      this.clnt_mname = null;
      this.clnt_lname = null;
      this.clnt_age = '';
      this.clnt_gender = '';
      this.clnt_blood = '';
      this.clnt_blood_id = '';
      this.clnt_fg_name = '';
      this.clnt_income = '';
      this.clnt_occupation = '';
      this.clnt_address1 = 'NA';
      this.clnt_address2 = null;

      this.clnt_cont_number = null;
      this.clnt_symptom = '';
      this.clnt_dob = null;

      this.height_txt = '';
      this.hmeasure = 'cms';
      this.weight_txt = '';
      this.wmeasure = 'kgs';
      this.bmi_txt = '';
      this.temp_txt = '';
      this.bmi_txt;
      this.encheight;
      this.encweight;
      this.chief_comp = '';
      this.pres_ill = '';
      this.med_hist = '';
      this.family_hist = '';
      this.physical_exam = '';
      this.refer_txt = '';
      this.covid_type = 'no';
      this.scan_02 = '25';
      this.rtpcr_result = 'Positive';
      this.covid_flag = false;
      this.observList = [];
      this.BP_01 = '';
      this.BP_02 = '';
      this.spo_txt = '';
      this.resp_txt = '';
      this.clnt_symptom = '';
      this.disable_age = false;
      this.disable_dob = false;
      for (var i = 0; i < this.comorbList.length; i++) {
        this.comorbList[i].checked = false;
      }
      for (var i = 0; i < this.covidSympList.length; i++) {
        this.covidSympList[i].checked = false;
      }

      this.clnt_sal = this.clnt_sal_list[0].sal_id;
      this.idProofType = [];
      this.idProofNo = '';
      this.idProofLabel = '';
      this.idProofDesc = '';
      this.idProofNoFlag = false;
      this.idProofId = '';
      this.mlcCase = 'No';
      this.mlcCaseNo = '';
      this.mlcCaseNoFlag = false;
      this.modeOfPayment;
      this.admDisReason;
      this.unit = '';
      this.client_reg_id="0";

    } else {
      this.userTypeFlag = false;
      this.existUserFlag = true;

      this.user_type = false;
      this.new_user = true;
      this.exist_user = false;
      this.search_hide = false;
      this.mobile_type = true;
      this.dob_flag = true;
      this.clnt_fname = null;
      this.clnt_mname = null;
      this.clnt_lname = null;
      this.clnt_cont_number = null;
      this.clnt_age = '';
      this.clnt_gender = '';
      this.clnt_blood = '';
      this.clnt_blood_id = '';
      this.clnt_fg_name = '';
      this.clnt_income = '';
      this.clnt_occupation = '';
      this.clnt_address1 = 'NA';
      this.clnt_address2 = null;
      this.height_txt = '';
      this.hmeasure = 'cms';
      this.weight_txt = '';
      this.wmeasure = 'kgs';
      this.bmi_txt = '';
      this.temp_txt = '';
      this.bmi_txt;
      this.encheight;
      this.encweight;
      this.pres_ill = '';
      this.med_hist = '';
      this.family_hist = '';
      this.physical_exam = '';
      this.covid_type = 'no';
      this.scan_02 = '25';
      this.rtpcr_result = 'Positive';
      this.covid_flag = false;
      this.observList = [];
      this.user_type = true;
      this.new_user = true;
      this.exist_user = false;
      this.dob_flag = true;
      this.BP_01 = '';
      this.BP_02 = '';
      this.spo_txt = '';
      this.resp_txt = '';
      this.refer_txt = '';
      this.clnt_symptom = '';
      this.clnt_dob = null;

      this.disable_age = false;
      this.disable_dob = false;
      this.clnt_location_id = this.hospital_details.location;
      this.clnt_city_id = this.hospital_details.city;
      this.clnt_state_id = this.hospital_details.state;
      this.clnt_country_id = this.hospital_details.country;

      for (var i = 0; i < this.comorbList.length; i++) {
        this.comorbList[i].checked = false;
      }
      for (var i = 0; i < this.covidSympList.length; i++) {
        this.covidSympList[i].checked = false;
      }
      this.idProofType = [];
      this.idProofNo = '';
      this.idProofLabel = '';
      this.idProofDesc = '';
      this.idProofNoFlag = false;
      this.idProofId = '';
      this.mlcCase = 'No';
      this.mlcCaseNo = '';
      this.mlcCaseNoFlag = false;
      this.modeOfPayment;
      this.admDisReason;
      this.unit = '';
    }
  }

  fname_toUpper() {
    if (this.clnt_fname != undefined) {
      this.clnt_fname = this.clnt_fname.toLocaleUpperCase();
    }
  }

  mname_toUpper() {
    if (this.clnt_mname != undefined) {
      this.clnt_mname = this.clnt_mname.toLocaleUpperCase();
    }
  }

  lname_toUpper() {
    if (this.clnt_lname != undefined) {
      this.clnt_lname = this.clnt_lname.toLocaleUpperCase();
    }
  }

  validateMRNNumber() {
    var flag = true;
    if (this.mr_no != undefined && this.mr_no.length > 8) {
      if(this.inpatientlist != undefined)
      for (let i = 0; i < this.inpatientlist.length; i++) {
        if (this.mr_no == this.inpatientlist[i].patient_id) {
          flag = false
          break;
        }
      }
    }

    if (this.mr_no != undefined && this.mr_no.length > 8) {
      if (flag != false) {
        this.getUserDetails();
      } else {
        this.toastr.error("Already admitted as in-paitent")
        this.mr_no = '';
      }
    }
  }

  getUserDetails() {
    var numberflag = true;
    var name;
    if (this.clnt_cont_number != undefined && this.clnt_cont_number != null && this.clnt_cont_number != '')
      for (let i = 0; i < this.inpatientlist.length; i++) {
        if (this.clnt_cont_number == this.inpatientlist[i].mobilenumber) {
          numberflag = false
          break;
        }
      }

    if (this.clnt_mname != '' && this.clnt_mname != undefined) {
      name = this.clnt_fname + "  " + this.clnt_mname + "  " + this.clnt_lname
      for (let i = 0; i < this.inpatientlist.length; i++) {
        if (name == this.inpatientlist[i].patient_namevalue) {
          numberflag = false
          break;
        }
      }

    } else if (this.clnt_fname != '' && this.clnt_fname != undefined && this.clnt_lname != '' && this.clnt_lname != undefined) {
      name = this.clnt_fname + "  " + this.clnt_lname
      for (let i = 0; i < this.inpatientlist.length; i++) {
        if (name == this.inpatientlist[i].patient_namevalue) {
          numberflag = false
          break;
        }
      }

    } else if (this.clnt_fname != '' && this.clnt_fname != undefined) {
      name = this.clnt_fname
      for (let i = 0; i < this.inpatientlist.length; i++) {
        if (name == this.inpatientlist[i].patient_namevalue) {
          numberflag = false
          break;
        }
      }

    } else {
      name = this.clnt_fname
      if(this.inpatientlist != undefined) {
        for (let i = 0; i < this.inpatientlist.length; i++) {
          if (name == this.inpatientlist[i].patient_namevalue) {
            numberflag = false
            break;
          }
        }
      }
    }

    if (numberflag == true) {
      var fname, mname, lname, mnumber, mmrno;
      this.inpatientId = undefined;
      if (this.clnt_fname != undefined && this.clnt_fname != undefined &&
        this.clnt_fname != null) {
        fname = encrypt_decript.Encript(this.clnt_fname).toString();
      }

      if (this.clnt_mname != '' && this.clnt_mname != undefined &&
        this.clnt_mname != null) {
        mname = encrypt_decript.Encript(this.clnt_mname).toString();
      }

      if (this.clnt_lname != undefined && this.clnt_lname != undefined &&
        this.clnt_lname != null) {
        lname = encrypt_decript.Encript(this.clnt_lname).toString();
      }

      if (this.clnt_cont_number != null) {
        mnumber = encrypt_decript.Encript(this.clnt_cont_number).toString();
      }

      var senddata;
      if (this.nurse_flow == true) {
        senddata = JSON.stringify({
          inpat_id: Doc_Helper.getInpatientId(),
          mobile: mnumber,
          client_reg_id: this.client_reg_id,
          fname: fname,
          mname: mname,
          lname: lname,
          country: 'IN',
          mr_no: this.mr_no,
          is_doc: 1,
          hptl_id: this.hospital_id,
          pat_type:'emergency'
        });

      } else {
        senddata = JSON.stringify({
          inpat_id: Doc_Helper.getInpatientId(),
          mobile: mnumber,
          fname: fname,
          mname: mname,
          lname: lname,
          country: 'IN',
          hptl_id: this.hospital_id,
          type: "Existing",
          is_doc: 1,
          mr_no: this.mr_no,
          pat_type:'emergency'
        });
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.clnt_url, senddata, { headers: headers }).subscribe(
        (response) => {
          var data = JSON.parse(response['_body']);
          console.log("CLIENT DETAILS ===== " + JSON.stringify(data))
          // Newly inserted
          if (data.diabetics != undefined) {
            if (data.diabetics == "yes") {
              this.ynopt10 = true;
              this.diabflag = true;
              this.diabdetials = data.diabetic_note;
              //
              this.diabetes = data.diabetics
            } else {
              this.ynopt10 = false;
              this.diabflag = false;
            }
          }

          if (data.hypertension != undefined) {
            if (data.hypertension == "yes") {
              this.ynopt11 = true;
              this.hyperflag = true;
              this.hype_details = data.hypertension_note;
              //
              this.hyper = data.hypertension
            } else {
              this.ynopt11 = false;
              this.hyperflag = false;
            }
          }

          if (data.cad != undefined) {
            if (data.cad == "yes") {
              this.ynopt13 = true;
              this.cadflag = true;
              this.caddetails = data.cad_note;
              this.cadvalue = data.cad

            } else {
              this.ynopt13 = false;
              this.cadflag = false;
            }

          } else {
            this.ynopt13 = false;
            this.cadflag = false;
          }
          //ckddetails
          if (data.ckd != undefined) {
            if (data.ckd == "yes") {
              this.ynopt12 = true;
              this.ckdflag = true;
              this.ckddetails = data.CKD_note;
              this.ckdvalue = data.ckd

            } else {
              this.ynopt12 = false;
              this.ckdflag = false;
            }

          } else {
            this.ynopt12 = false;
            this.ckdflag = false;
          }

          if (data.cvd != undefined) {
            if (data.cvd == "yes") {
              this.ynopt14 = true;
              this.cvdflag = true;
              this.cvd_details = data.cvd_note;
              //

              this.cvdvalue = data.cvd
            } else {
              this.ynopt14 = false;
              this.cvdflag = false;
            }

          } else {
            this.ynopt14 = false;
            this.cvdflag = false;
          }

          if (data.tuberculosis != undefined) {
            if (data.tuberculosis == "yes") {
              this.ynopt15 = true;
              this.tuberflag = true;
              this.tuber_details = data.tb_note;
              //
              this.tuber = data.tuberculosis
            } else {
              this.ynopt15 = false;
              this.tuberflag = false;
            }
          }

          if (data.ba != undefined) {
            if (data.ba == "yes") {
              this.ynopt16 = true;
              this.baflag = true;
              this.ba_details = data.ba_note;
              //
              this.ba_details = data.ba_details
            } else {
              this.ynopt16 = false;
              this.baflag = false;
            }
          }

          if (data.ptb != undefined) {
            if (data.ptb == "yes") {
              this.ynopt17 = true;
              this.ptbflag = true;
              this.ptb_details = data.ptb_note;
              //
              this.ptb = data.ptb
            } else {
              this.ynopt17 = false;
              this.ptbflag = false;
            }
          }

          if (data.copd != undefined) {
            if (data.copd == "yes") {
              this.ynopt18 = true;
              this.copdflag = true;
              this.copd_details = data.copd_note;
              //
              this.copd = data.copd
            } else {
              this.ynopt18 = false;
              this.copdflag = false;
            }
          }

          if (data.apd != undefined) {
            if (data.apd == "yes") {
              this.ynopt19 = true;
              this.apdflag = true;
              this.apd_details = data.apd_note;
              this.apd = data.apd

            } else {
              this.ynopt19 = false;
              this.apdflag = false;
            }
          }

          if (data.seizure != undefined) {
            if (data.seizure == "yes") {
              this.ynopt20 = true;
              this.seizureflag = true;
              this.seizure_details = data.seizure_note;
              //
              this.seizure = data.seizure
            } else {
              this.ynopt20 = false;
              this.seizureflag = false;
            }
          }

          if (data.thyroid != undefined) {
            if (data.thyroid == "yes") {
              this.ynopt21 = true;
              this.thyroidflag = true;
              this.thyroid_details = data.thyroid_note;
              //
              this.thyroid = data.thyroid
            } else {
              this.ynopt21 = false;
              this.thyroidflag = false;
            }
          }

          if (data.jaundice != undefined) {
            if (data.jaundice == "yes") {
              this.ynopt22 = true;
              this.jaundiceflag = true;
              this.jaundice_details = data.jaundice_note;
              //
              this.jaundice = data.jaundice
            } else {
              this.ynopt22 = false;
              this.jaundiceflag = false;
            }
          }

          if (data.surgical != undefined) {
            if (data.surgical == "yes") {
              this.ynopt23 = true;
              this.surgicalflag = true;
              this.surgical_details = data.surgical_note;
              //
              this.surgical = data.surgical
            } else {
              this.ynopt23 = false;
              this.surgicalflag = false;
            }
          }

          if (data.others != undefined) {
            if (data.others == "yes") {
              this.ynopt24 = true;
              this.otherflag = true;
              this.Others_details = data.other_note;
              //
              this.othervalue = data.others
            } else {
              this.ynopt24 = false;
              this.otherflag = false;
            }
          }
          if (data.pain_scale != undefined) {
            if (this.painLevels.length != 0) {
              for (var j = 0; j < this.painLevels.length; j++) {
                if (this.painLevels[j].number == data.pain_scale) {
                  this.painLevels[j].selected = true;
                  this.selectedPainScaleLevel = this.painLevels[j].number;
                }
              }
            }
          }

          this.user_details = data;
          this.doc_reg_id = data.doc_reg_id;
          if (data.advance != undefined) {
            this.advance = "0.00";
            if(data.advance != 'null') {
              this.tot_coll_adv = data.advance
              this.show_adv_info = true;
            } 
          }

          if (data.advance_list != undefined) {
            for (var l = 0; l < data.advance_list.length; l++) {
              this.advanceList.push({
                sno: l + 1,
                created_date_time: Date_Formate(data.advance_list[l].created_date) + ' ' + Time_Formate(data.advance_list[l].created_time),
                "advance_id": data.advance_list[l].advance_id,
                "amount": data.advance_list[l].amount,
                "balance": data.advance_list[l].balance,
                "payment_desc": data.advance_list[l].payment_desc,
                "created_by": data.advance_list[l].created_by
              })
            }
          }

          this.vitals_List = [];
          this.previousvisits = []
          this.readonlyFlag = false;
          if (data.med_history != undefined) {
            if (data.med_history.length != 0) {
              for (let i = 0; i < data.med_history.length; i++) {
                var time;
                if(data.med_history[i].time_duration != undefined) {
                  time = data.med_history[i].time_duration.split('s')[0]
                } else {
                  time=''
                }
                
                this.listProducts.push({
                  drug_name: data.med_history[i].product_name,
                  every_six: data.med_history[i].morning + ' - ' + data.med_history[i].afternoon + ' - ' + data.med_history[i].evening + ' - ' + data.med_history[i].night,
                  morning: data.med_history[i].morning,
                  afternoon: data.med_history[i].afternoon,
                  night: data.med_history[i].night,
                  evening: data.med_history[i].evening,
                  drug_intake: data.med_history[i].time_duration,
                  dure_txt_table: time
                })
              }
              this.Medicine_table = false
            }
          }

          if (data.vital_log != undefined) {
            this.vitalsFlag = true;
            for (var i = 0; i < data.vital_log.length; i++) {
              var dateval = data.vital_log[i].date.split("-");
              if (data.vital_log[i].weight != "" || data.vital_log[i].weight != undefined) {
                if (this.BP_01 != undefined && this.BP_02 != undefined) {
                  this.bpvalue = this.BP_01 + " / " + this.BP_02
                } else if (this.BP_01 != undefined) {
                  this.bpvalue = this.BP_01
                } else if (this.BP_02 != undefined) {
                  this.bpvalue = this.BP_02
                }

                Helper_Class.setvitalloginfo(data.vital_log);
                if (data.vital_log[i].bp != "null" && data.vital_log[i].bp != undefined) {
                  var is_bpAbnormal: boolean;
                  var bloodpressuresplit = data.vital_log[i].bp.split("/")
                  this.systole = bloodpressuresplit[0].trim();

                  if (bloodpressuresplit[1] != null) {
                    var check = bloodpressuresplit[1].split(" ")
                  }
                  this.diastole = bloodpressuresplit[1];

                  if (parseInt(bloodpressuresplit[0]) > 160 || parseInt(bloodpressuresplit[0]) < 110 || parseInt(bloodpressuresplit[1]) > 90 || parseInt(bloodpressuresplit[1]) < 70) {
                    is_bpAbnormal = true
                  } else {
                    is_bpAbnormal = false
                  }

                  this.bloodpressureky = data.vital_log[i].bp + " " + "mmHg"
                }

                if (data.vital_log[i].temparature != "null" && data.vital_log[i].temparature != undefined) {
                  var temp_split = data.vital_log[i].temparature.split(' ')
                  var is_tempAbnormal: boolean = false;

                  if (parseInt(temp_split[0]) > 100 || parseInt(temp_split[0]) < 96) {
                    is_tempAbnormal = true;
                  }
                }
                var tempval;
                if (data.vital_log[i].temparature != undefined) {
                  var tval = data.vital_log[i].temparature.split(" ");
                  tempval = tval[0];
                }
                this.vitals_List.push({
                  sNo: i + 1,
                  height: data.vital_log[i].height + " " + data.vital_log[i].hmeasure,
                  weight: data.vital_log[i].weight + " " + data.vital_log[i].wmeasure,
                  bmi: data.vital_log[i].bmi,
                  bmr: data.vital_log[i].bmr,


                  temperature: tempval,
                  bloodPressure: data.vital_log[i].bp,
                  bloodPressurekey: this.bloodpressureky,
                  SPO2: data.vital_log[i].spo2,
                  pulse: data.vital_log[i].pulse,
                  RR: data.vital_log[i].rrtxt,
                  Cvs: data.vital_log[i].cvs,
                  Rs: data.vital_log[i].rs,
                  Cns: data.vital_log[i].cns,
                  ABD: data.vital_log[i].abd,
                  bpsys: this.systole,
                  bpdia: this.diastole,
                  PR: data.vital_log[i].pr_txt,
                  cbg_txt: data.vital_log[i].cbg,
                  date: dateval[2] + "-" + dateval[1] + "-" + dateval[0],

                  time: Time_Formate(data.vital_log[i].time),
                  is_bpAbnormal: is_bpAbnormal,
                  is_tempAbnormal: is_tempAbnormal
                })
              }
            }

            if (this.vitals_List.length != 0) {
              this.spo2array = [];
              this.pulse_xaxis = [];
              this.pulsearray = [];
              this.spo2_xaxis = [];
              this.systolearray = [];
              this.diastolearray = [];
              this.bparray_xaxis = [];
              for (var i = 0; i < this.vitals_List.length; i++) {
                if (this.vitals_List[i].SPO2 != undefined && this.vitals_List[i].SPO2 != "") {
                  this.spo2_xaxis.push(this.vitals_List[i].date + " " + (this.vitals_List[i].time));
                  this.spo2array.push(this.vitals_List[i].SPO2);
                }
                if (this.vitals_List[i].PR != undefined && this.vitals_List[i].PR != "") {
                  this.pulse_xaxis.push(this.vitals_List[i].date + " " + (this.vitals_List[i].time));
                  this.pulsearray.push(this.vitals_List[i].PR);
                }

                if (this.vitals_List[i].bpsys != undefined && this.vitals_List[i].bpdia != undefined) {
                  this.systolearray.push(this.vitals_List[i].bpsys);;
                  this.diastolearray.push(this.vitals_List[i].bpdia);
                  this.bparray_xaxis.push(this.vitals_List[i].date + " " + (this.vitals_List[i].time));
                }
              }

              if (this.spo2_xaxis.length != 0 && this.spo2array.length != 0) {
                this.spo2graphflag = true;
                setTimeout(() => {
                  this.linechart_spo2(this.spo2_xaxis, this.spo2array, "spo2")
                }, 100);

              } else {
                this.spo2graphflag = false;
              }

              if (this.pulse_xaxis.length != 0 && this.pulsearray.length != 0) {
                this.pulseflag = true;
                setTimeout(() => {
                  this.linechartpulse(this.pulse_xaxis, this.pulsearray, "pulse")
                }, 200);

              } else {
                this.pulseflag = false;
              }

              if (this.systolearray.length != 0 && this.diastolearray.length != 0) {
                this.bpgraphflag = true;
                setTimeout(() => {
                  this.linechartsys(this.systolearray, this.diastolearray, this.bparray_xaxis)
                }, 300);
              } else {
                this.bpgraphflag = false;
              }
            }
          }

          if (data.prev_visits != undefined) {
            this.previousflag = true;
            for (var k = 0; k < data.prev_visits.length; k++) {
              if (data.prev_visits[k].visit_date != undefined) {
                this.previousvisits.push({
                  visit_date: Date_Formate(data.prev_visits[k].visit_date),
                  doc_name: data.prev_visits[k].doc_name,
                  visit_purpose: data.prev_visits[k].visit_purpose != undefined ? data.prev_visits[k].visit_purpose : "",
                  specialization_name: data.prev_visits[k].specialization_name,
                  app_type: data.prev_visits[k].app_type
                })
              }
            }
          }
          
          if (data.doc_qualification != undefined) {
            this.doc_qualification = data.doc_qualification;
          }
          if (data.anaesthesia_types != undefined) {
            this.anaest_types = data.anaesthesia_types;
          }
          if (data.mobile != undefined) {
            this.mobile_no = encrypt_decript.Decript(data.mobile);
          }
          if (data.consult_charge_id != undefined) {
            this.chargetype = data.consult_charge_id;
          }
          if (data.aadhar_no != undefined) {
            this.idproofcheck = encrypt_decript.Decript(data.aadhar_no);
          }

          if (data.glucose_charge_id != undefined) {
            this.glucose_charge_id = data.glucose_charge_id;
          }

          if (data.surgery_time != undefined && data.surgery_time != " ") {
            var hr_min = Time_Formate(data.surgery_time);
            var checkvalue = hr_min.split(":")
            var checkvalue1 = checkvalue[1].split(" ")
            this.surgery_time1 = checkvalue[0];
            this.surgery_time2 = checkvalue1[0];
            this.surgery_time3 = checkvalue1[1];
            this.surgery_time_full = this.surgery_time1 + ':' + this.surgery_time2 + ' ' + this.surgery_time3;
          }

          if (data.doctor_visits != undefined) {
            var doc_visitng = data.doctor_visits;
            for (var i = 0; i < doc_visitng.length; i++) {
              var billable = doc_visitng[i].is_billable == '1' ? 'Yes' : 'No';
              var docName;
              if (doc_visitng[i].middle_name != undefined) {
                docName =doc_visitng[i].first_name +' ' +
                  doc_visitng[i].middle_name +' ' +doc_visitng[i].last_name;
              } else {
                if (doc_visitng[i].last_name != undefined) {
                  docName =doc_visitng[i].first_name + ' ' + doc_visitng[i].last_name;
                } else {
                  docName = doc_visitng[i].first_name;
                }
              }

              this.visitignArrray.push({
                visiting_doc_name: docName,
                remarks: doc_visitng[i].remarks,
                billable: billable,
                date: Date_Formate(doc_visitng[i].created_date),
                time: this.tConvert(doc_visitng[i].created_time),
              });
            }
          }

          if (data.client_reg_id != undefined) {
            this.nursenotesarray = [];
            var fullname, username;
            if (data.nursing_notes != undefined) {
              for (var i = 0; i < data.nursing_notes.length; i++) {
                if (data.nursing_notes[i].middle_name != undefined) {
                  if (data.nursing_notes[i].last_name != undefined) {
                    username = data.nursing_notes[i].first_name + " " + data.nursing_notes[i].middle_name + " ";
                  } else {
                    username = data.nursing_notes[i].first_name + " " + data.nursing_notes[i].middle_name + " " + data.nursing_notes[i].last_name;
                  }
                } else {
                  if (data.nursing_notes[i].last_name != undefined) {
                    username = data.nursing_notes[i].first_name + " " + data.nursing_notes[i].last_name
                  } else {
                    username = data.nursing_notes[i].first_name
                  }
                }
                if (this.userinfo.user_type == "doctor") {
                  fullname = "Dr" + " " + username
                } else {
                  fullname = username
                }

                this.nursenotesarray.push({
                  nursing_notes_id: data.nursing_notes[i].nursing_notes_id,
                  name: fullname,
                  nursing_notes: data.nursing_notes[i].nursing_notes,
                  inpatient_id: data.inpat_id
                })
              }
            }

            this.mr_no = data.patient_hosp_id;
            this.mrno = data.mrno;
            this.doc_id = data.doc_reg_id;
            this.life_style_id = data.life_style_id;
            this.client_reg_id = data.client_reg_id;
            Doc_Helper.setClient_id(this.client_reg_id);

            if (data.barcode != undefined) {
              this.barcode_url = ipaddress.Ip_with_img_address + data.barcode;
              this.barcode = data.barcode
              this.barcode_flag = true;
            }

            Doc_Helper.setRel_id('1');
            Doc_Helper.setClient_gender(encrypt_decript.Decript(data.gender));
            this.inpatientId = data.admission_id;
            this.admission_id = data.admission_id;
            var data1 = {
              admission_id: this.inpatientId,
            }

            Doc_Helper.setAdmission_id(null);
            Doc_Helper.setAdmission_id(data1);
            this.clnt_sal = data.salutation;
            this.clnt_saldesc = data.salutation_desc;
            if (data.profile_image != undefined) {
              if (data.profile_image == "default") {
                this.profileImg = "../../../assets/img/default.jpg";

              } else {
                if (data.profile_image != "") {
                  var image = ipaddress.Ip_with_img_address + data.profile_image
                  this.profileImg = ipaddress.Ip_with_img_address + data.profile_image;
                }
              }
            }

            if (this.inpatientId != undefined) {
              this.update_btn = false;
              this.Confirm_btn = true;
              this.printFlag = true;

            } else {
              this.update_btn = true;
              this.Confirm_btn = false;
              this.printFlag = false;
            }
            this.app_id = data.app_id;
            Doc_Helper.setApp_id(data.app_id)
            this.clnt_fname = encrypt_decript.Decript(data.first_name);

            if (data.middle_name != null) {
              this.clnt_mname = encrypt_decript.Decript(data.middle_name);
            }
            this.inpat_id = data.inpat_id

            this.clnt_lname = encrypt_decript.Decript(data.last_name);
            if (this.clnt_mname != undefined) {
              Doc_Helper.setClient_name(
                this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname
              );
              this.patient_name = this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname;

            } else {
              Doc_Helper.setClient_name(this.clnt_fname + ' ' + this.clnt_lname);
              this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
            }

            if (data.admission_date != undefined) {
              this.admission_date = data.admission_date;
              this.adm_date = Date_Formate(data.admission_date);
            }

            if (data.admission_time != undefined) {
              this.admission_time = Time_Formate(data.admission_time);
              var timePrint1 = tConv24(data.admission_time);
              var adm_time = timePrint1.split(' ');
              var time1 = adm_time[0];
              var hr_min1 = time1.split(':');
              this.admission_time3 = adm_time[1];
              this.admission_time1 = hr_min1[0];
              this.admission_time2 = hr_min1[1];
              this.admission_time = this.admission_time;
              this.f_time1 = hr_min1[0];
              this.to_time1 = hr_min1[1];
              this.sesionselction1 = adm_time[1];
            }

            if (data.spl_id != undefined) {
              Helper_Class.setspl_id(data.spl_id);
              this.spl_id = data.spl_id;
              this.getDoctorList(this.spl_id);
              for (var i = 0; i < this.spl_array.length; i++) {
                if (this.spl_id == this.spl_array[i].spl_id) {
                  this.spl = this.spl_array[i].spl_name;
                }
              }
            }

            if (data.dr_middle_name != undefined) {
              this.doctor_name = "Dr." +data.dr_first_name +' ' +
                data.dr_middle_name +' ' +data.dr_last_name;
              this.doctornamevalue = data.dr_first_name +' ' +
                data.dr_middle_name +' ' +data.dr_last_name;

            } else {
              this.doctor_name = "Dr." + data.dr_first_name + ' ' + data.dr_last_name;
              this.doctornamevalue = data.dr_first_name + ' ' + data.dr_last_name;
            }
            this.is_consultent;

            if (data.dob != undefined && data.dob != '' && data.dob != null && data.dob != 'null') {
              var ddata = data.dob.split('-');
              this.clnt_dob = data.dob;
              if (this.spl_id == '10') {
                this.OnDateChangeDOB(this.clnt_dob);
              }
            } else {
              this.disable_dob = true;
            }

            if (data.age != undefined && data.age != '' && data.age != null) {
              this.clnt_age = data.age;
            } else {
              this.clnt_age = '';
            }

            if (data.marital_status != undefined) {
              if (data.marital_status != "null") {
                this.clnt_marital = data.marital_status;
                this.clnt_maritalvalue = data.marital_status;
              }
            }
            //clnt_visitpurpose
            if (data.clnt_visitpurpose != undefined) {
              this.clnt_visitpurpose = data.clnt_visitpurpose;
            }
            Doc_Helper.setClient_age(this.clnt_age);

            this.pre_hosp = data.prev_hosp;
            this.preHospFlag = data.prev_hosp == 'yes' ? true : false;
            this.pre_hospflag = data.prev_hosp == 'yes' ? true : false;
            this.pre_hospital_details =data.prev_hosp_det != undefined ? data.prev_hosp_det : '';
            this.allergyFlag = data.allergy == 'yes' ? true : false;
            this.allergy = data.allergy;
            this.ynopt2 = data.allergy == 'yes' ? true : false;
            if (data.gender != undefined && data.gender != null && data.gender != "") {
              this.clnt_gender = encrypt_decript.Decript(data.gender);
              this.gender = this.clnt_gender[0];
              this.Gender_data = this.clnt_gender;
              this.Age_data = encrypt_decript.Decript(data.age);
              if (this.gender != undefined && this.gender[0] == "T") {
                this.gender = "Tgen";
              }
            }

            this.clnt_blood = data.blood_desc;
            this.clnt_blood_id = data.blood_group;
            if (data.guardian != undefined) {
              this.clnt_fg_name = data.guardian;
            }

            if (data.emerg_contact != undefined) {
              this.emer_contact = encrypt_decript.Decript(data.emerg_contact);
            }

            if (data.hosp_rate_card != undefined) {
              if (this.RateCardArray.length != 0) {
                var data_hosp_rate_card = this.RateCardArray.filter(department1 => department1.rate_card_id == data.hosp_rate_card)
                console.log("mm ==" + JSON.stringify(data_hosp_rate_card))
                this.hospitalratecard = data_hosp_rate_card[0].rate_card_id
              }
            }

            if (data.department_id != undefined) {
              this.dept_desc = data.department_desc;
              this.department_id = data.department_id;
              if (this.departmentList.length != 0) {
                var check = this.departmentList.filter(department => department.department_id == this.department_id)
                this.department = check[0].department_id
              }
            }

            if (data.ward_id != undefined) {
              if (data.ward_id == "null") {
                this.print_wardbed = false;
                this.wardName = " ";

              } else {
                this.print_wardbed = true;
                this.ward_id = data.ward_id;
                this.wardName = data.ward_name;
                this.getBedList(this.ward_id);
                this.changeglucoselevel(this.gluselect)
              }
            }

            if (data.bed_no != 'null' && data.bed_no != null &&
              data.bed_no != '' && data.bed_no != undefined
            ) {
              this.send_bed_no = data.bed_no;
              this.bed_no = data.bed_no;
              this.bed_novalue = data.bed_no;
              this.allocatedbedflag = false;

            } else {
              this.allocatedbedflag = true;
            }

            if (data.pharma_rate_card != undefined) {
              if (this.discountDatasArray.length != 0) {
                var data_pharma_rate_card = this.discountDatasArray.filter(department => department.disc_type_id == data.pharma_rate_card)
                this.pharmaratecrad = data_pharma_rate_card[0].disc_type_id
              }
            }

            if (data.family_income != null) {
              this.clnt_income = encrypt_decript.Decript(data.family_income);
            }

            if (data.id_proof_id != null) {
              var res = this.idProofTypeList.filter(
                (o) => o.proof_id == data.id_proof_id
              );
              if (res.length != 0) {
                this.idProofLabel = res[0].description;
                this.idProofNoFlag = true;
                this.idProofType = {
                  proof_id: res[0].proof_id,
                  description: res[0].description,
                };
                this.changeIdProofType(this.idProofType);
              }
            }

            if (data.id_proof_det != null) {
              this.idProofNo = data.id_proof_det;
            }

            if (data.admission_id != null) {
              this.inpatId = data.admission_id;
            }
            this.clnt_income = data.income != null ? data.income : '';
            this.mlcCase = data.mlc_type != null ? data.mlc_type : 'No';
            this.modeOfPayment =data.payment_mode != null ? data.payment_mode : '';
            if (data.payment_mode != null && data.payment_mode == 'Insurance') {
              this.insure_flag = false;
              this.modeOfPaymentvalue = data.payment_mode;
              this.modeOfPaymentvalue = data.payment_mode
              this.insure_flagheader = false;
              this.insuranceName = data.insuranceName;
              this.insCardNo = data.insCardNo;
              this.policyNumber = data.policyNumber;
              this.ccnNumber = data.ccnNumber;
              this.tpa_name = data.tpa_name;
              //bill_inc_pharma
              this.tpanamevalue = data.tpa_name;
              this.bill_inc_pharma = data.bill_inc_pharma;
              this.billPayment = data.bill_inc_pharma;
              this.insuranceNamevalue = data.insuranceName;
              this.insCardNovalue = data.insCardNo;
              this.policyNumbervalue = data.policyNumber;
              this.ccnNumbervalue = data.ccnNumber;

              if (data.approved_amount != undefined) {
                this.approved_amountflag = true
                this.approved_amountvalue = data.approved_amount;

              } else {
                this.approved_amountflag = false;
              }
              this.approved_amount = data.approved_amount

            } else if (data.payment_mode != 'Insurance') {
              this.modeOfPaymentvalue = data.payment_mode
              this.insure_flagheader = true;
            }

            if (data.payment_mode == 'Insurance') {
              this.billtypeflag = false;
              this.bill_inc_pharmavalue = data.bill_inc_pharma

            } else {
              this.billtypeflag = true;
              this.bill_inc_pharmavalue = data.bill_inc_pharma
            }

            this.bill_inc_pharmavalue = data.bill_inc_pharma
            this.billPayment = this.billPayment;
            this.paymentTypeChange(data.payment_mode);
            this.changepayment(data.bill_inc_pharma)
            this.admDisReason = data.admission_reason != null ? data.admission_reason : '';
            this.disReason = data.discharge_reason != null ? data.discharge_reason : '';
            this.unit = data.unit != null ? data.unit : '';

            if (data.mlc_details != null) {
              this.mlcCaseNoFlag = this.mlcCase == 'Yes' ? true : false;
              this.mlcCaseNo = data.mlc_details;
            }

            if (data.height != undefined) {
              this.height_txt_case = data.height;
              this.hmeasure_case = data.hmeasure;
              this.height_txt = data.height;
              this.hmeasure = data.hmeasure;
            }

            if (data.weight != undefined) {
              this.weight_txt_case = data.weight;
              this.wmeasure_case = data.wmeasure;
              this.bmi_txt_case = data.bmi;
              this.bmr_txt_case = data.bmr;
              this.weight_txt = data.weight;
              this.wmeasure = data.wmeasure;
              this.bmi_txt = data.bmi;
              this.bmr = data.bmr;
            }

            if (data.temparature != undefined) {
              this.temperature_case = data.temparature;
            }
            if (data.pulse != undefined) {
              this.pulse_case = data.pulse;
            }
            if (data.bp != undefined) {
              this.bloodPressure_case = data.bp;
            }
            if (data.spo2 != undefined) {
              this.SPO2_case = data.spo2;
            }
            if (data.rrtxt != undefined) {
              this.RR_case = data.rrtxt;
            }
            if (data.cvs != undefined) {
              this.Cvs_case = data.cvs;
            }
            if (data.rs != undefined) {
              this.Rs_case = data.rs;
            }
            if (data.cns != undefined) {
              this.Cns_case = data.cns;
            }
            if (data.cvs != undefined) {
              this.Cvs_case = data.cvs;
            }
            if (data.abd != undefined) {
              this.ABD_case = data.abd;
            }
            if (data.pr_txt != undefined) {
              this.PR_case = data.pr_txt;
            }
            if (data.cbg != undefined) {
              this.cbg_txt_case = data.cbg;
            }
            if (data.cvs != undefined) {
              this.Cvs_case = data.cvs;
            }

            this.bpsys_case = this.systole
            this.bpdia_case = this.diastole
            this.inp_inv = data.inp_inv;

            this.chief_comp = data.complaints;
            if (data.complaints != undefined) {
              Helper_Class.setAssessComplaint(data.complaints);
            }
            if (data.provisional_diagnosis != undefined) {
              Helper_Class.setAssessProvDiag(data.provisional_diagnosis);
            }
            this.pres_ill = data.present_illness;
            this.med_hist = data.medical_history;
            this.family_hist = data.family_history;
            this.prov_diag = data.provisional_diagnosis;
            
            if (data.family_history != undefined) {
              this.family_hist_data = JSON.parse(data.family_history);
            }

            this.physical_exam = data.physical_exam;
            this.gen_exam = data.general_exam;
            this.local_exam = data.local_examination;
            this.line_mgmt = data.line_management;
            this.diet_rec = data.diet_details;
            this.app_id = data.app_id;
            this.admission_id = data.admission_id;

            if (this.admission_id == undefined) {
              this.admission_id = data.inpatient_id
            }
            this.bp_txt = data.bp;
            this.clnt_symptom = data.symptoms;
            if (this.clnt_symptom != undefined) {
              this.clnt_symptom = `<p>${this.clnt_symptom}</p>`;
            }
            this.clnt_blood = data.blood_group;
            this.covid_type = data.is_covid;
            this.covid_duration = data.covid_dur;

            if (this.covid_type == 'yes') {
              this.covid_flag = true;

            } else {
              this.covid_type = 'no';
              this.covid_flag = false;
            }

            this.finalcovidsymp = [];
            this.finalcomorbList = [];
            if (data.covid_symp != undefined) {
              this.finalcovidsymp = data.covid_symp;
            }
            if (data.covid_comorb != undefined) {
              this.finalcomorbList = data.covid_comorb;
            }
            if (data.covid_observ != undefined) {
              this.observList = data.covid_observ;
              this.observetable = false;
            }

            if (this.finalcovidsymp != undefined && this.finalcovidsymp.length != 0) {
              if (this.covidSympList.length != 0) {
                for (var i = 0; i < this.covidSympList.length; i++) {
                  for (var j = 0; j < this.finalcovidsymp.length; j++) {
                    if (this.covidSympList[i].symp_id == this.finalcovidsymp[j]) {
                      this.covidSympList[i].checked = true;
                      if (this.coviedSytemmsText == '') {
                        this.coviedSytemmsText = this.covidSympList[i].symp_desc;
                      } else {
                        this.coviedSytemmsText = this.coviedSytemmsText + ', ' + this.covidSympList[i].symp_desc;
                      }
                    }
                  }
                }
              }
            }

            if (this.finalcomorbList != undefined && this.finalcomorbList.length != 0) {
              if (this.comorbList.length != 0) {
                for (var i = 0; i < this.comorbList.length; i++) {
                  for (var j = 0; j < this.finalcomorbList.length; j++) {
                    if (this.comorbList[i].comorb_id == this.finalcomorbList[j]) {
                      this.comorbList[i].checked = true;
                      if (this.comorb_desc == '') {
                        this.comorb_desc = this.comorbList[i].comorb_desc;
                      } else {
                        this.comorb_desc = this.comorb_desc + ', ' + this.comorbList[i].comorb_desc;
                      }
                    }
                  }
                }
              }
            }

            if (data.ward_id != undefined) {
              for (var i = 0; i < this.ward_list.length; i++) {
                if (data.ward_id == this.ward_list[i].ward_id) {
                  this.wardName = this.ward_list[i].ward_name;
                }
              }
            }

            if (data.discharge_date != undefined) {
              this.discharge_date = data.discharge_date;
              this.disPrint = Date_Formate(this.discharge_date);
            }

            if (data.discharge_time != undefined && data.discharge_time != ":00") {
              this.timePrint = tConv24(data.discharge_time);
              var discharge_time = this.timePrint.split(' ');
              var time = discharge_time[0];
              var hr_mincheck = time.split(':');

              this.discharge_time3 = discharge_time[1];
              this.discharge_time1 = hr_mincheck[0];
              this.discharge_time2 = hr_mincheck[1];
            }
            this.req_surgery = data.req_surgery;
            if (this.req_surgery != undefined && this.req_surgery == 'Yes') {
              this.req_sur_flag = false;
              this.ot_bookingFlag = true;
              if (this.surgeon_list.length == 0) {
                this.getSurgeons();
              }

            } else {
              this.ot_bookingFlag = false;
            }

            if (data.surgery_date != undefined) {
              this.surgery_date = data.surgery_date;
            }

            if (data.surgeon != undefined) {
              this.surgery_proc = data.surgery_proc;
              this.surg_doc = data.surgeon.split(',');
              if (this.surgeon_list.length != 0 && data.surgeon != undefined) {
                var surgdoc = data.surgeon.split(',');
                for (var i = 0; i < surgdoc.length; i++) {
                  this.surg_doc.push(surgdoc[i]);
                }
              }
            }

            if (data.consultant != undefined) {
              this.consult_doc = data.consultant.split(',');
              if (this.surgeon_list.length != 0 && data.consultant != undefined) {
                var consultdoc = data.consultant.split(',');
                for (var i = 0; i < consultdoc.length; i++) {
                  this.consult_doc.push(consultdoc[i]);
                }
              }
            }

            if (data.anaest_doc != undefined) {
              this.anaest_doc = data.anaest_doc.split(',');
              if (
                this.anaest_doctors.length != 0 &&
                data.anaest_doc != undefined
              ) {
                var andoc = data.anaest_doc.split(',');
                for (var i = 0; i < andoc.length; i++) {
                  this.anaest_doc.push(andoc[i]);
                }
              }
            }

            if (data.ref_by != undefined) {
              Doc_Helper.setRefer_by(data.ref_by);
            }

            Doc_Helper.setApp_id(this.app_id);
            if (this.inpatientId != undefined) {
              this.update_btn = false;
              this.Confirm_btn = true;
              this.printFlag = true;
            } else {
              this.update_btn = true;
              this.Confirm_btn = false;
              this.printFlag = false;
            }

            if (data.occupation != null) {
              this.clnt_occupation = encrypt_decript.Decript(data.occupation);
            }

            if (data.address1 != null) {
              this.clnt_address1 = encrypt_decript.Decript(data.address1);
            }

            if (data.address2 != null) {
              this.clnt_address2 = encrypt_decript.Decript(data.address2);
            }

            this.clnt_location = data.location_desc;
            this.clnt_city = data.city_desc;
            this.clnt_state = data.state_desc;
            this.clnt_country = data.country_desc;

            this.clnt_location_id = data.location;
            this.clnt_city_id = data.city;
            this.clnt_state_id = data.state;
            this.clnt_country_id = data.country;

            if (data.zipcode != undefined) {
              this.clnt_zipcode = encrypt_decript.Decript(data.zipcode);
              this.clnt_zipcodevalue = (data.zipcode)
            }

            if (data.mobile != undefined) {
              this.clnt_cont_number = encrypt_decript.Decript(data.mobile);
            }

            var addval;
            if (data.address2 != undefined) {

              addval = this.clnt_address1 + ',' + this.clnt_address2;
            } else {
              addval = this.clnt_address1;
            }

            this.hosp_addr = addval;
            this.hosp_csz =this.clnt_location +', ' +this.clnt_city +' - ' +this.clnt_state +'  ' +this.clnt_zipcode;
            this.hosp_count = this.clnt_location;

            if (data.telephone != undefined) {
              this.hosp_tel = data.telephone;
            } else {
              this.hosp_tel = '-';
            }

            var hospital;
            if (data.hosp_address2 != undefined) {
              hospital = data.hosp_address1 + ',' + data.hosp_address2;
            } else {
              hospital = data.hosp_address1;
            }
            this.hospitalAddress = hospital;
            this.Hospital_location =data.hosp_loc_desc +', ' +
              data.hosp_city_desc +' - ' +data.hosp_state_desc +'  ' +data.hosp_zipcode;

            if (data.drug_details != undefined && data.drug_details != '') {
              this.allergyType('drug', 'yes');
              this.drug_value = 'yes';
              this.drug_details = data.drug_details;
              this.drugdetailsFlag = true;
              this.ynopt3 = true;

            } else {
              this.allergyType('drug', 'no');
              this.drugdetailsFlag = false;
              this.drug_value = 'no';
              this.ynopt3 = false;
            }

            if (data.env_details != undefined && data.env_details != '') {
              this.allergyType('evn', 'yes');
              this.env_value = 'yes';
              this.env_details = data.env_details;
              this.envDetailsFlag = true;
              this.ynopt4 = true;

            } else {
              this.allergyType('evn', 'no');
              this.envDetailsFlag = false;
              this.env_value = 'no';
              this.ynopt4 = false;
            }

            if (data.food_details != undefined && data.food_details != '') {
              this.allergyType('food', 'yes');
              this.food_value = 'yes';
              this.food_details = data.food_details;
              this.envDetailsFlag = true;
              this.ynopt5 = true;

            } else {
              this.allergyType('food', 'no');
              this.envDetailsFlag = false;
              this.food_value = 'no';
              this.ynopt5 = false;
            }

            this.doc_id = data.doc_reg_id;

            if (
              data.height == '' &&
              data.weight == '' &&
              data.bmi == '' &&
              data.temparature == '' &&
              data.bp == '' &&
              data.bp == '/' &&
              data.is_covid == 'no'
            ) {
              this.vitalsFlag = false;
            }

            if (this.med_hist == '' && this.family_hist == '' 
              && this.physical_exam == '' && this.pre_hospital_details == '' 
              && this.allergyFlag == false) {
              this.observationFlag = false;
            }

            this.medication = data.take_med;
            if (data.take_med != undefined && data.take_med == 'yes') {
              this.medication_flag = false;
            }
            this.med_details = data.med_details;
            this.inp_smoke = data.smoking;
            this.inp_alcohol = data.alcohol;
            this.anaesttype = data.anaesttype;
            this.anaest_procedure = data.anaest_proc;
            this.anaest_effect = data.anaest_effect;
            this.surg_diagosis = data.discharge_diagnosis;
            this.anaest_findings = data.anaest_findings;

            if (data.surgery_id != undefined) {
              this.surgery_id = data.surgery_id;
            }

            this.surgery_name = data.surgery_desc;
            this.surgery_name1 = data.surgery_desc;
            this.tpa_name = data.tpa_name;

          } else {
            if (data.client_reg_id == undefined)
              this.toastr.error(Message_data.noDataFound);
          }
          this.getBedDetails();
          this.getRetrivalothercharges();
          this.getreadings("183");
          this.changeglucoselevel(this.gluselect)
        },
        (error) => {
          this.toastr.error(Message_data.unableToFetchData);
        }
      );

    } else {
      this.clnt_fname = '';
      this.clnt_mname = '';
      this.clnt_lname = '';
      this.clnt_cont_number = '';
      this.mr_no = '';
      this.toastr.error("Already admitted as in-paitent")
    }
    // this.datalist()
  }

  getSurgeons() {
    var send_data = {
      hospital_id: this.hospital_id,
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp + 'search/docbyhns', JSON.stringify(send_data), {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          if (obj != null) {
            console.log("THE DOCTOR LIST ======= "+JSON.stringify(obj))
            this.surgeon_list = [];
            this.surgeon_list1 = [];
            for (var i = 0; i < obj.doctors.length; i++) {
              var docname;
              if (obj.doctors[i].middle_name != undefined) {
                docname =
                  obj.doctors[i].first_name +
                  ' ' +
                  obj.doctors[i].middle_name +
                  ' ' +
                  obj.doctors[i].last_name;
              } else {
                docname =
                  obj.doctors[i].first_name + ' ' + obj.doctors[i].last_name;
              }
              this.surgeon_list.push({
                docname: docname,
                prov_id: obj.doctors[i].prov_id,
              });
              this.surgeon_list1.push({
                docname: docname,
                prov_id: obj.doctors[i].prov_id,
              });
            }
            console.log("SURGEONS ========= "+JSON.stringify(this.surgeon_list))
          }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  changedoctor(id) {
   
  }

  clearFields() {
    if (this.surgery_name == "") {
      this.surgery_id = "0";
      this.surgery_name = "";
    }
  }

  surgeryChange(data) {
    if (data.length > 2) {
      var send_data = {
        spl_id: this.spl_id,
        surgery_desc: data
      };
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(ipaddress.getIp + 'mas/gsbn', JSON.stringify(send_data), {
          headers: headers,
        })
        .subscribe(
          (response) => {
            var obj = response.json();
            console.log("surg--" + JSON.stringify(obj))

            if (obj != null) {
              this.surgery_list = [];
              for (var i = 0; i < obj.surgeries.length; i++) {
                this.surgery_list.push({
                  surgery_id: obj.surgeries[i].surgery_id,
                  surgery_name: obj.surgeries[i].surgery_desc,
                });
              }
            }
          },
          (error) => {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        );
    }
  }

  tConvert(time) {
    let hour = time.split(':')[0];
    let min = time.split(':')[1];
    let part = hour > 12 ? 'pm' : 'am';

    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;

    return `${hour}:${min} ${part}`;
  }

  getCurrentDate() {
    this.CurrentDatetime = Master_Helper.getMasterCurrentDate().current_date;
    this.currentDatevalue = (Master_Helper.getMasterCurrentDate().current_date);
    this.currentTime = Master_Helper.getMasterCurrentDate().current_time;
    this.currentDate = Master_Helper.getMasterCurrentDate().current_date;
    this.admission_date = Master_Helper.getMasterCurrentDate().current_date;
    this.f_date = this.currentDate;
    this.t_date = this.currentDate;
    // this.f_date = Date_Formate(this.currentDate);
    // this.t_date = this.currentDate;
    var time1 = this.currentTime_charge.split(":")
    var time2 = time1[1].split(" ");
    var time3 = time2[1]
    this.f_time1 = time1[0]
    this.to_time1 = time2[0]
    this.sesionselction1 = time3
    this.f_time = time1[0]
    this.to_time = time2[0]
    this.sesionselction = time3
    this.get_date =
      Master_Helper.getMasterCurrentDate().current_date.split('-');
    this.currentYear = this.get_date[0];
    Helper_Class.setKey(
      Master_Helper.getMasterCurrentDate().provider +
      Master_Helper.getMasterCurrentDate().clientid +
      Master_Helper.getMasterCurrentDate().messageid
    );
    Helper_Class.setIV(Master_Helper.getMasterCurrentDate().random);
    var check1 = Time_Formate(this.currentTime);
    var check2 = check1.split(":")
    var check4 = check2[1].split(" ")
    var check3 = check1.split(" ")
    var currenttime = Master_Helper.getMasterCurrentDate().current_time.split(":");
    console.log("obj currenttime" + JSON.stringify(currenttime[0]))
    if (currenttime[0] >= 3 && currenttime[0] < 12) {
      this.visitSession = "Morning";
    } else if (currenttime[0] >= 12 && currenttime[0] < 16) {
      this.visitSession = "Afternoon";
    } else if (currenttime[0] >= 16 && currenttime[0] < 19) {
      this.visitSession = "Evening";
    } else if (currenttime[0] >= 19 && currenttime[0] < 24) {
      this.visitSession = "Evening";
    }
    this.getVisitingSessionArray = [{ "description": this.visitSession }];
    this.admission_time1 = check2[0];
    this.admission_time2 = check4[0];
    this.admission_time3 = check3[1];
    this.getSpecializations();
  }
  getCurrentDate1() {
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            get_date = obj.current_date.split('-');
            this.currentDate = obj.current_date;

            // this.f_date = this.currentDate;
            console.log("time format" + JSON.stringify(obj.current_time))

          }
        },
        error => {

        }
      )
  }

  getDepartment(hospital_id) {
    this.departmentList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.https.post(ipaddress.getIp.toString() + 'gen/gdbh',
      {
        hptl_clinic_id: hospital_id,
        type: "hospital"
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("department list" + JSON.stringify(obj.departments))
          for (var i = 0; i < obj.departments.length; i++) {
            this.departmentList.push({
              department_id: obj.departments[i].department_id,
              description: obj.departments[i].description,
            });
          }
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  getCurrentToken() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'appointment/dtoken',
        JSON.stringify({
          doc_reg_id: this.user_id,
        }),
        { headers: headers }
      )
      .subscribe((response) => {
        var obj = response.json();
        if (obj != undefined) {
          this.tokenumber = obj.tokem;
        }
      });
  }

  create_admission() {
    Doc_Helper.setInpatientId(null);
    var create_flag = true;
    if (this.clnt_symptom != '' && this.clnt_symptom != undefined && this.clnt_symptom != null) {
      var new_symptoms: any = [];
      new_symptoms = this.filterNew(this.clnt_symptom, this.newsymptoms);
      var new_symptoms: any = [];

    } else {
      this.clnt_symptom = '';
    }

    if (this.Client_type == 'New') {
      if (this.clnt_cont_number == undefined || this.clnt_fname == undefined 
        || this.clnt_lname == undefined || this.clnt_age == undefined 
        || this.clnt_gender == undefined || this.clnt_location_id == undefined 
        || this.spl_id == undefined) {
        create_flag = false;
        this.toastr.error(Message_data.mandatory);
      }

      if (this.require_flag == true) {
        if (this.clnt_age == '') {
          create_flag = false;
        }
      }

      if (this.disable_dob == false) {
        if (this.clnt_dob == null) {
          create_flag = false;
        }
      }
    } else {
      if (this.clnt_cont_number == undefined || this.spl_id == undefined) {
        create_flag = false;
      }

      if (this.client_reg_id == '0') {
        create_flag = false;
      }
    }

    if (this.clnt_age == '') {
      create_flag = false;
    }
    if (this.clnt_gender != undefined) {
      this.clnt_gender = this.clnt_gender[0];
      if (this.clnt_gender[0] == "T")
        this.clnt_gender = "Tgen";
    }

    if (this.clnt_sal == 'select') {
      this.toastr.error(Message_data.sltSalutation);
      create_flag = false;
    }

    if (this.clnt_age == '') {
      this.toastr.error(Message_data.ageMandatory);
      create_flag = false;
    }

    if (this.idProofType != '') {
      if (this.idProofNo == undefined) {
        create_flag = false;
        this.toastr.error(Message_data.sltIdproof);
      }
    } else {
      this.idProofId = undefined;
      this.idProofNo = undefined;
    }

    if (this.modeOfPayment == 'select') {
      this.modeOfPayment = '';
    }
    if (this.modeOfPayment == 'Insurance') {
      if (this.insuranceName == undefined) {
        create_flag = false;
      }
    }

    if (this.Client_type != 'New' && this.client_reg_id == '0') {
      this.toastr.error('Search details for existing patient');
    } else if (create_flag == false) {
      this.toastr.error(Message_data.mandatory);
    }

    if (this.admDisReason == 'select') {
      this.admDisReason = '';
    }

    if (create_flag == true) {
      this.patient_name = null;
      if (this.clnt_mname != undefined && this.clnt_mname != null) {
        if (this.clnt_mname != '') {
          this.patient_name =
            this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname;
        } else {
          this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
        }
      } else {
        this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
      }
      Doc_Helper.setClient_name(this.patient_name);
      Doc_Helper.setClient_gender(this.gender);
      if (this.clnt_age != '') {
        if (this.clnt_age == '0') {
          this.clnt_age = '';
        }
        Doc_Helper.setClient_age(this.clnt_age);
      } else {
        Doc_Helper.setClient_age('');
      }
      var get_date = Date_Formate(this.currentDate);
      var curr_datetime = new Date(
        Date_Formate(get_date) + ' ' + this.currentTime
      );
      var curr_session;
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        curr_session = 'Morning';
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        curr_session = 'Afternoon';
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 22) {
        curr_session = 'Evening';
      }

      curr_session = 'Evening';
      var apptype;
      if (this.App_type == 'New') {
        apptype = '1';
      } else {
        apptype = '2';
      }

      var midname;
      if (this.clnt_mname != undefined) {
        midname = encrypt_decript.Encript(this.clnt_mname).toString();
      }

      var addr2;
      if (this.clnt_address2 != undefined) {
        addr2 = encrypt_decript.Encript(this.clnt_address2).toString();
      } else {
        addr2 = undefined;
      }

      var address: any = [];
      address.push({
        address1: encrypt_decript.Encript(this.clnt_address1).toString(),
        address2: addr2,
        location: this.clnt_location_id,
        city: this.clnt_city_id,
        state: this.clnt_state_id,
        country: this.clnt_country_id,
        zipcode: encrypt_decript.Encript(this.clnt_zipcode).toString(),
        type: 'pres',
      });

      var bpval;
      if (this.BP_01 != null) {
        bpval = this.BP_01 + '/' + this.BP_02;
      }

      var discharge_time;
      if (this.discharge_time1 != undefined &&
        this.discharge_time2 != undefined) {
        discharge_time = this.discharge_time1 + ':' + this.discharge_time2;

      } else {
        discharge_time = '';
      }

      var surgery_time;
      if (this.surgery_time1 != undefined && this.surgery_time2 != undefined) {
        surgery_time = ConvertTimeformat(
          '',
          this.surgery_time1 +
          ':' +
          this.surgery_time2 +
          ' ' +
          this.surgery_time3
        );
        this.surgery_time_full = this.surgery_time1 + ':' + this.surgery_time2 + ' ' + this.surgery_time3;
      } else {
        surgery_time = '';
      }

      var adm_time;
      if (this.admission_time1 != undefined &&
        this.admission_time2 != undefined) {
        adm_time = ConvertTimeformat('', this.admission_time1 + ':' + this.admission_time2 + ' ' + this.admission_time3);
        this.admission_time_full = this.admission_time1 + ':' + this.admission_time2 + ' ' + this.admission_time3;
      } else {
        adm_time = '';
      }

      var user_id = this.getUserId();
      if (user_id == undefined) {
        this.toastr.error("Enter Admission Doctor");
        create_flag = false;
      }
      this.refer_txt = this.refer_txt != '' ? this.refer_txt : undefined;
      if (create_flag == true) {
        var save_data = JSON.stringify({
          prov_id: user_id,
          client_id: this.client_reg_id,
          relation_id: '1',
          salutation: this.clnt_sal,
          first_name: encrypt_decript.Encript(this.clnt_fname).toString(),
          middle_name: midname,
          last_name: encrypt_decript.Encript(this.clnt_lname).toString(),
          age: this.clnt_age,
          dob: this.clnt_dob,
          gender: encrypt_decript.Encript(this.clnt_gender).toString(),
          mem_mobile: encrypt_decript.Encript(this.clnt_cont_number).toString(),
          medicare_id: '1',
          specialization_id: this.spl_id,
          hptl_id: this.hospital_id,
          date: this.CurrentDatetime,
          time: '1',
          token: this.tokenumber,
          session: encrypt_decript.Encript(curr_session).toString(),
          symptoms: this.clnt_symptom,
          newsymptoms: new_symptoms,
          app_type: '1',
          complaints: this.chief_comp,
          present_illness: this.pres_ill,
          medical_history: this.med_hist,
          family_history: this.family_hist,
          physical_exam: this.physical_exam,
          home_care: this.homecarevalue,
          blood_group: this.clnt_blood,
          occupation: this.clnt_occupation,
          income: this.clnt_income,
          guardian: this.clnt_fg_name,
          citizenship: encrypt_decript.Encript('Indian').toString(),
          nationality: encrypt_decript.Encript('Indian').toString(),
          address: address,
          emergency_contact: (this.emer_contact),
          ward_id: this.ward_id,
          bed_no: this.send_bed_no,
          flag: 'mobile',
          ref_by: this.refer_txt,
          covid_symp: this.finalcovidsymp,
          covid_comorb: this.finalcomorbList,
          covid_observ: this.observList,
          is_covid: this.covid_type,
          covid_dur: this.covid_duration,
          location: this.h_location,
          address1: this.h_address1,
          address2: this.h_address2,
          city: this.h_city,
          state: this.h_state,
          country: this.h_country,
          zipcode: this.h_zipcode,
          refer_by: this.refer_txt,
          discharge_date: this.discharge_date,
          discharge_time: discharge_time,
          marital_status: this.clnt_marital, // single or married
          clnt_visitpurpose: this.clnt_visitpurpose,
          prev_hosp: this.pre_hosp, // yes or no
          prev_hosp_det: this.pre_hospital_details,
          allergy: this.allergy, // yes or no
          drug_details: this.drug_details,
          env_details: this.env_details,
          food_details: this.food_details,
          id_proof_id: this.idProofId,
          id_proof_det: this.idProofNo,
          unit: this.unit,
          mlc_type: this.mlcCase,
          mlc_details: this.mlcCaseNo,
          payment_mode: this.modeOfPayment,
          admission_reason: this.admDisReason,
          discharge_reason: this.disReason,
          surgery_date: this.surgery_date,
          surgery_time: surgery_time,
          admission_date: this.admission_date,
          admission_time: adm_time,
          surgery_proc: this.surgery_proc,
          req_surgery: this.req_surgery,
          surgeon: this.surg_doc,
          consultant: this.consult_doc,
          take_med: this.medication,
          med_details: this.med_details,
          smoking: this.inp_smoke,
          alcohol: this.inp_alcohol,
          anaest_doc: this.anaest_doc,
          anaesttype: this.anaesttype,
          anaest_proc: this.anaest_procedure,
          anaest_effect: this.anaest_effect,
          surg_diagosis: this.surg_diagosis,
          inp_inv: this.inp_inv,
          anaest_findings: this.anaest_findings,
          surgery_id: this.surgery_id,
          surgery_desc: this.surgery_name,
          insuranceName: this.insuranceName,
          tpa_name: this.tpa_name,
          insCardNo: this.insCardNo,
          policyNumber: this.policyNumber,
          approved_amount: this.approved_amount,
          ccnNumber: this.ccnNumber,
          //added
          department_id: this.department,
          profile_image: this.profileImg,
          bill_inc_pharma: this.billPayment,
          bill_pay_type: this.billPayment,
          pharma_rate_card: this.pharmaratecrad,
          hosp_rate_card: this.hospitalratecard,
          family_comorb:this.family_hist_data,
          adm_type:"emergency"
        });

        console.log("SaveDats--------->" + save_data);
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'inpat/nadm', save_data, {
          headers: headers,
        })
          .subscribe((response) => {
            var obj = response.json();

            if (obj.amission_id != undefined) {
              this.inpatId = obj.inpat_id;
              this.inpatientId = obj.uh_id;
              this.cdRef.detectChanges();
              if (this.inpatId != undefined) {
                setTimeout(() => {
                  this.printIdCard();
                  if (Helper_Class.getInpatientFlow() == 'front-desk') {
                    this.frontservice.sendMessage('emergencylist');
                  } else {
                    this.messageservice.sendMessage('emergencylist');
                  }
                }, 2000);
              }
              this.toastr.success(Message_data.addmissionSavedSuccess);
              Doc_Helper.setApp_id(this.app_id);
              var data = {
                admission_id: obj.amission_id,
                flag: "edit"
              }
              Doc_Helper.setAdmission_id(null);
              Doc_Helper.setAdmission_id(data);

              if (this.Client_type == 'New') {
                this.client_reg_id = obj.client_id;
                Doc_Helper.setClient_id(obj.client_id);
                Doc_Helper.setRel_id('1');

                this.Client_type = 'Existing';
              }

              this.update_btn = false;
              this.Confirm_btn = true;
            } else {
              this.toastr.error(obj.result);
            }
          });
      }
    }
  }

  //update appointment
  update_admission() {
    var create_flag = true;
    if (this.clnt_sal == 'select') {
      create_flag = false;
    }

    if (this.idProofDesc != undefined &&
      this.idProofDesc != null && this.idProofDesc.length != 0) {
      if (this.idProofNo == '' && this.idProofNo == undefined 
        && this.idProofNo == null) {
        create_flag = false;
      }
    }

    if (this.modeOfPayment == 'select') {
      this.modeOfPayment = '';
    }

    if (this.modeOfPayment == 'Insurance') {
      if (this.insuranceName == undefined) {
        create_flag = false;
      }
      if (this.policyNumber == undefined) {
        create_flag = false;
      }

      if (create_flag == false) {
        this.toastr.error(Message_data.mandatory);
      }
    }

    if (this.admDisReason == 'select') {
      this.admDisReason = '';
    }

    if (this.send_bed_no == undefined || this.send_bed_no == '') {
      create_flag = false;
    }
    if (this.userinfo.user_type != "Billing") {
      if (this.req_surgery == 'Yes') {
        if (
          this.surgery_date == undefined ||
          this.surgery_time1 == undefined ||
          this.surgery_time2 == undefined ||
          this.surgery_time3 == undefined
        ) {
          create_flag = false;
          this.toastr.error('Enter surgery date and time');
        }
      }
    }

    if (create_flag == false) {
      if (this.clnt_sal == 'select') {
        this.toastr.error(Message_data.sltSalutation);
      } else if (this.send_bed_no == undefined || this.send_bed_no == '') {
        this.toastr.error('Allot bed for update');
      }

    } else {
      var bpval;
      if (this.BP_01 != null) {
        bpval = this.BP_01 + '/' + this.BP_02;
      }

      var midname;
      if (this.clnt_mname != undefined) {
        midname = encrypt_decript.Encript(this.clnt_mname).toString();
      }

      var addr2;
      if (this.clnt_address2 != undefined) {
        addr2 = encrypt_decript.Encript(this.clnt_address2).toString();
      } else {
        addr2 = undefined;
      }

      var get_date = Date_Formate(this.currentDate);

      var curr_datetime = new Date(
        Date_Formate(get_date) + ' ' + this.currentTime
      );
      var curr_session;
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        curr_session = 'Morning';
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        curr_session = 'Afternoon';
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 22) {
        curr_session = 'Evening';
      }

      curr_session = 'Evening';
      if (this.clnt_address1 != null && this.clnt_address1 != undefined) {
        var address1 = this.clnt_address1;
      }
      if (addr2 != null && addr2 != undefined) {
        var address2 = addr2;
      }
      var address: any = [];
      address.push({
        address1: encrypt_decript.Encript(this.clnt_address1).toString(),
        address2: addr2,
        location: this.clnt_location_id,
        city: this.clnt_city_id,
        state: this.clnt_state_id,
        country: this.clnt_country_id,
        zipcode: encrypt_decript.Encript(this.clnt_zipcode).toString(),
        type: 'pres',
      });

      var discharge_time;

      if (this.discharge_time1 != undefined &&
        this.discharge_time2 != undefined) {
        if (this.discharge_time2 != "00") {
          discharge_time = ConvertTimeformat(
            '',
            this.discharge_time1 +
            ':' +
            this.discharge_time2 +
            ' ' +
            this.discharge_time3
          );
        }

      } else {
        discharge_time = '';
      }

      var surgery_time;

      if (this.surgery_time1 != "") {
        if (this.surgery_time1 != undefined && this.surgery_time2 != undefined) {
          surgery_time = ConvertTimeformat(
            '',
            this.surgery_time1 +
            ':' +
            this.surgery_time2 +
            ' ' +
            this.surgery_time3
          );
        } 
      }

      var adm_time;
      if (
        this.admission_time1 != undefined &&
        this.admission_time2 != undefined
      ) {
        adm_time = ConvertTimeformat(
          '',
          this.admission_time1 +
          ':' +
          this.admission_time2 +
          ' ' +
          this.admission_time3
        );
        this.admission_time_full = this.admission_time1 + ':' + this.admission_time2 + ' ' + this.admission_time3;
      } else {
        adm_time = undefined;
      }

      var midname;
      if (this.clnt_mname != undefined) {
        midname = encrypt_decript.Encript(this.clnt_mname).toString();
      }
      if (this.anaest_doc.length != 0) {
        var anaedoc: any = [];
        for (var i = 0; i < this.anaest_doc.length; i++) {
          anaedoc.push(this.anaest_doc[i]);
        }
      }
      anaedoc = this.removeDuplicates(anaedoc);

      if (this.surg_doc.length != 0) {
        var surdoc: any = [];
        for (var i = 0; i < this.surg_doc.length; i++) {
          surdoc.push(this.surg_doc[i]);
        }
      }
      surdoc = this.removeDuplicates(surdoc);

      if (this.consult_doc.length != 0) {
        var consultdoc: any = [];
        for (var i = 0; i < this.consult_doc.length; i++) {
          consultdoc.push(this.consult_doc[i]);
        }
      }
      consultdoc = this.removeDuplicates(consultdoc);

      var advance_amt;
      if(this.advance != '0.00') {
        advance_amt = this.advance;
      }

      var user_id = this.getUserId();
      var send_data;

      if (this.nurse_flow == true) {
        send_data = {
          prov_id: this.doc_id,
          specialization_id: this.spl_id,
          life_style_id: this.life_style_id,
          complaints: this.chief_comp,
          present_illness: this.pres_ill,
          medical_history: this.med_hist,
          family_history: this.family_hist,
          physical_exam: this.physical_exam,
          admission_id: this.inpatientId,
          discharge_date: this.discharge_date,
          discharge_time: discharge_time,
          ward_id: this.ward_id,
          bed_no: this.send_bed_no,
          marital_status: this.clnt_marital, // single or married
          clnt_visitpurpose: this.clnt_visitpurpose,
          prev_hosp: this.pre_hosp, // yes or no
          prev_hosp_det: this.pre_hospital_details,
          allergy: this.allergy, // yes or no
          drug_details: this.drug_details,
          env_details: this.env_details,
          food_details: this.food_details,
          created_by: this.userinfo.user_id,
          creator_type: this.userinfo.user_type,
          surgery_date: this.surgery_date,
          surgery_time: surgery_time,
          admission_date: this.admission_date,
          admission_time: adm_time,
          surgery_proc: this.surgery_proc,
          req_surgery: this.req_surgery,
          surgeon: surdoc,
          consultant: consultdoc,
          first_name: encrypt_decript.Encript(this.clnt_fname).toString(),
          middle_name: midname,
          last_name: encrypt_decript.Encript(this.clnt_lname).toString(),
          client_reg_id: this.client_reg_id,
          take_med: this.medication,
          med_details: this.med_details,
          smoking: this.inp_smoke,
          alcohol: this.inp_alcohol,
          anaest_doc: anaedoc,
          anaesttype: this.anaesttype,
          anaest_proc: this.anaest_procedure,
          anaest_effect: this.anaest_effect,
          surg_diagosis: this.surg_diagosis,
          inp_inv: this.inp_inv,
          anaest_findings: this.anaest_findings,
          surgery_id: this.surgery_id,
          surgery_list: this.surgery_list,
          surg_doc: this.surg_doc,
          surgery_name: this.surgery_name,
          insuranceName: this.insuranceName,
          tpa_name: this.tpa_name,
          insCardNo: this.insCardNo,
          policyNumber: this.policyNumber,
          approved_amount: this.approved_amount,
          ccnNumber: this.ccnNumber,
          department_id: this.department,
          bill_inc_pharma: this.billPayment,
          bill_pay_type: this.billPayment, payment_mode: this.modeOfPayment, discharge_reason: this.disReason,
          pharma_rate_card: this.pharmaratecrad,
          hosp_rate_card: this.hospitalratecard,
          pain_scale: this.selectedPainScaleLevel,
          adv_amt: advance_amt,
          pay_type: this.payType,
          card_no: this.cardNumber,
          card_holder_name: this.cardHolderName,
          transaction_no: this.transactionId,
          bank_name: this.bankName,
          app_id: this.app_id,
          hptl_id: this.hospital_id,
          createdby_txt: this.userinfo.first_name + " " + this.userinfo.last_name,
          adm_type:"emergency",
          family_comorb:this.family_hist_data,
        };

      } else {
        send_data = {
          prov_id: this.doc_id,
          specialization_id: this.spl_id,
          life_style_id: this.life_style_id,
          complaints: this.chief_comp,
          present_illness: this.pres_ill,
          medical_history: this.med_hist,
          family_history: this.family_hist,
          physical_exam: this.physical_exam,
          admission_id: this.inpatientId,
          discharge_date: this.discharge_date,
          discharge_time: discharge_time,
          ward_id: this.ward_id,
          bed_no: this.send_bed_no,
          marital_status: this.clnt_marital, // single or married
          clnt_visitpurpose: this.clnt_visitpurpose,
          prev_hosp: this.pre_hosp, // yes or no
          prev_hosp_det: this.pre_hospital_details,
          allergy: this.allergy, // yes or no
          drug_details: this.drug_details,
          env_details: this.env_details,
          food_details: this.food_details,
          id_proof_id: this.idProofId,
          id_proof_det: this.idProofNo,
          unit: this.unit,
          mlc_type: this.mlcCase,
          mlc_details: this.mlcCaseNo,
          payment_mode: this.modeOfPayment,
          admission_reason: this.admDisReason,
          discharge_reason: this.disReason,
          surgery_date: this.surgery_date,
          surgery_time: surgery_time,
          admission_date: this.admission_date,
          admission_time: adm_time,
          surgery_proc: this.surgery_proc,
          req_surgery: this.req_surgery,
          consultant: consultdoc,
          surgeon: this.removeDuplicates(surdoc),
          first_name: encrypt_decript.Encript(this.clnt_fname).toString(),
          middle_name: midname,
          last_name: encrypt_decript.Encript(this.clnt_lname).toString(),
          client_reg_id: this.client_reg_id,
          take_med: this.medication,
          med_details: this.med_details,
          smoking: this.inp_smoke,
          alcohol: this.inp_alcohol,
          anaest_doc: this.removeDuplicates(anaedoc),
          anaesttype: this.anaesttype,
          anaest_proc: this.anaest_procedure,
          anaest_effect: this.anaest_effect,
          surg_diagosis: this.surg_diagosis,
          inp_inv: this.inp_inv,
          anaest_findings: this.anaest_findings,
          surgery_id: this.surgery_id,
          surgery_list: this.surgery_list,
          surg_doc: this.surg_doc,
          surgery_name: this.surgery_name,
          insuranceName: this.insuranceName,
          tpa_name: this.tpa_name,
          insCardNo: this.insCardNo,
          policyNumber: this.policyNumber,
          ccnNumber: this.ccnNumber,
          approved_amount: this.approved_amount,
          created_by: this.userinfo.user_id,
          department_id: this.department,
          bill_inc_pharma: this.billPayment,
          bill_pay_type: this.billPayment,
          pharma_rate_card: this.pharmaratecrad,
          hosp_rate_card: this.hospitalratecard,
          pain_scale: this.selectedPainScaleLevel,
          adv_amt: advance_amt,
          pay_type: this.payType,
          card_no: this.cardNumber,
          card_holder_name: this.cardHolderName,
          transaction_no: this.transactionId,
          bank_name: this.bankName,
          app_id: this.app_id,
          hptl_id: this.hospital_id,
          createdby_txt: this.userinfo.first_name + " " + this.userinfo.last_name,
          adm_type:"emergency",
          family_comorb:this.family_hist_data,
        };
      }

      if (this.advance != undefined && this.advance != '0.00') {
        send_data.advance = this.advance;
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'inpat/updaadm/',
          JSON.stringify(send_data),{ headers: headers }
        ).subscribe((response) => {
          var obj = response.json();
          console.log(obj);
          if (this.clnt_symptom != undefined) {
            this.clnt_symptom = `<p>${this.clnt_symptom}</p>`;
          }

          if (obj.key == '1') {
            this.toastr.success(obj.result);
            var inpat = {
              prov_id: this.user_id,
              client_id: this.client_reg_id,
              life_style_id: this.life_style_id,
              relation_id: '1',
              app_id: this.app_id,
              salutation: this.clnt_sal, inpatient_id: this.inpatId,
              first_name: encrypt_decript.Encript(this.clnt_fname).toString(),
              middle_name: midname,
              last_name: encrypt_decript.Encript(this.clnt_lname).toString(),
              age: this.clnt_age,
              dob: this.clnt_dob,
              gender: encrypt_decript.Encript(this.clnt_gender).toString(),
              mem_mobile: encrypt_decript.Encript(this.clnt_cont_number).toString(),
              medicare_id: '1',
              specialization_id: this.spl_id,
              hptl_id: this.hospital_id,
              date: this.CurrentDatetime,
              Appoint_Date: Date_Formate(this.CurrentDatetime),
              time: '1',
              token: this.tokenumber,
              session: encrypt_decript.Encript(curr_session).toString(),
              symptoms: this.clnt_symptom,
              app_type: '2',

              complaints: this.chief_comp,
              present_illness: this.pres_ill,
              medical_history: this.med_hist,
              family_history: this.family_hist,
              physical_exam: this.physical_exam,
              admission_id: this.inpatientId,
              home_care: this.homecarevalue,
              blood_group: this.clnt_blood,
              occupation: this.clnt_occupation,
              income: this.clnt_income,
              guardian: this.clnt_fg_name,
              citizenship: encrypt_decript.Encript('Indian').toString(),
              nationality: encrypt_decript.Encript('Indian').toString(),
              address: address,
              address1: address1,
              address2: address2,
              emergency_contact: (this.emer_contact),
              ward_id: this.ward_id,
              bed_no: this.bed_no,
              flag: 'mobile',
              refer_by: this.refer_txt,
              covid_symp: this.finalcovidsymp,
              covid_comorb: this.finalcomorbList,
              covid_observ: this.observList,
              is_covid: this.covid_type,
              covid_dur: this.covid_duration,
              location: this.clnt_location,
              city: this.clnt_city,
              state: this.clnt_state,
              country: this.clnt_country,
              zipcode: this.clnt_zipcode,
              surgery_date: this.surgery_date,
              surgery_time: surgery_time,
              admission_date: this.admission_date,
              admission_time: adm_time, pat_id: this.mr_no, mobile: this.mobile_no,
              barcode: this.barcode
            };

            Doc_Helper.setClient_Info(inpat);
            if (Helper_Class.getInpatientFlow() == 'front-desk') {
              this.frontservice.sendMessage('emergencylist');
            } else if (Helper_Class.getInpatientFlow() == 'nurse') {
              this.frontservice.sendMessage('emergencylist');
            } else {
              this.messageservice.sendMessage('emergencylist');
            }
          } else {
            this.toastr.error(obj.result);
          }
        });
    }
  }

  Write_prescription() {
    if (this.clnt_mname != undefined && this.clnt_mname != null) {
      if (this.clnt_mname != '') {
        this.patient_name =
          this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname;
      } else {
        this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
      }

    } else {
      this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
    }
    var bpval;
    if (this.BP_01 != null) {
      bpval = this.BP_01 + '/' + this.BP_02;
    }

    var inpat_pres: any = [];
    inpat_pres = {
      prov_id: this.user_id,
      client_id: this.client_reg_id,
      relation_id: '1',
      inpatient_id: this.inpatId,
      Gender_data: this.clnt_gender,
      client_name: this.patient_name,
      Age_data: this.clnt_age,
      app_id: this.app_id,
      Client_id: this.client_reg_id,
      rel_id: '1',
      sub_id: '',
      Case_Clnt_dob: this.clnt_dob,
      spl_id: this.spl_id,
      hptl_clinic_id: this.hospital_id,
      mobile: this.clnt_cont_number,
      spl: this.spl,
      temparature: this.temp_txt,
      height: this.height_txt,
      height_measure: this.hmeasure,
      weight: this.weight_txt,
      weight_measure: this.wmeasure,
      bmi: this.bmi_txt,
      bp: bpval,
      symptoms: this.clnt_symptom,
      complaints: this.clnt_symptom,
      medicare_name: this.treatement_type,
      Appoint_Date: Date_Formate(this.CurrentDatetime),
      addmed: 'write',
      AppFlow: 'InpatMedPres',
      covid_symp: this.finalcovidsymp,
      covid_comorb: this.finalcomorbList,
      covid_observ: this.observList,
      is_covid: this.covid_type,
      covid_dur: this.covid_duration,
      location: this.h_location,
      address1: this.h_address1,
      address2: this.h_address2,
      city: this.h_city,
      state: this.h_state,
      country: this.h_country,
      zipcode: this.h_zipcode,
      refer_by: this.refer_txt,
      country_id: this.h_country_id,
      state_id: this.h_state_id,
      city_id: this.h_city_id,
      user_type: this.userinfo.user_type,
      salutation: this.clnt_saldesc,
      doctor_name: this.doctornamevalue,
      clientaddress1: this.clnt_address1,
      clientaddress2: this.clnt_address2,
      clientlocation: this.clnt_location,
      clientcity: this.clnt_city,
      clientstate: this.clnt_state,
      clientcountry: this.clnt_country,
      clientcountry_id: this.clnt_country_id,
      clientstate_id: this.clnt_state_id,
      clientcity_id: this.clnt_city_id,
      clientzipcode: this.clnt_zipcode,
      allergy: this.allergy, // yes or no
      drug_details: this.drug_details,
      env_details: this.env_details,
      food_details: this.food_details,
      pat_id: this.mrno,
      doc_id: this.doc_id,
      barcode: this.barcode,
      doc_app_id: this.app_id,
      ward: this.wardName,
      bedno: this.bed_novalue,
      doc_reg_id: this.doc_reg_id,

      doc_qualification: this.doc_qualification
      // blood_group:this.blood_group,
    };
    Doc_Helper.setClient_Info(undefined);
    Doc_Helper.setClient_Info(inpat_pres);

    console.log("Write prescription data ========== " + JSON.stringify(Doc_Helper.getClient_Info()))
    Doc_Helper.setDiagAppflow('medical');
    this.messageservice.sendMessage('docpresnocase');
  }

  Write_prescription_forgyno() {
    if (this.clnt_mname != undefined && this.clnt_mname != null) {
      if (this.clnt_mname != '') {
        this.patient_name =
          this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname;
      } else {
        this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
      }
    } else {
      this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
    }
    var bpval;
    if (this.BP_01 != null) {
      bpval = this.BP_01 + '/' + this.BP_02;
    }
    // if(this.clnt_symptom != undefined){
    //   this.clnt_symptom = `<p>${this.clnt_symptom}</p>`;
    // }
    var inpat_pres: any = [];
    inpat_pres = {
      prov_id: this.user_id,
      client_id: this.client_reg_id,
      relation_id: '1',
      salutation: this.clnt_sal,
      Gender_data: this.clnt_gender,
      client_name: this.patient_name,
      Age_data: this.clnt_age,
      app_id: this.app_id,
      Client_id: this.client_reg_id,
      rel_id: '1',
      sub_id: '',
      Case_Clnt_dob: this.clnt_dob,
      spl_id: this.spl_id,
      hptl_clinic_id: this.hospital_id,
      mobile: this.clnt_cont_number,
      spl: this.spl,
      temparature: this.temp_txt,
      height: this.height_txt,
      height_measure: this.hmeasure,
      weight: this.weight_txt,
      weight_measure: this.wmeasure,
      bmi: this.bmi_txt,
      bp: bpval,
      symptoms: this.clnt_symptom,
      complaints: this.clnt_symptom,
      medicare_name: this.treatement_type,
      Appoint_Date: Date_Formate(this.CurrentDatetime),
      addmed: 'write',
      AppFlow: 'InpatMedPres',
      // ref_by:this.refer_txt,
      covid_symp: this.finalcovidsymp,
      covid_comorb: this.finalcomorbList,
      covid_observ: this.observList,
      is_covid: this.covid_type,
      covid_dur: this.covid_duration,
      location: this.h_location,
      address1: this.h_address1,
      address2: this.h_address2,
      city: this.h_city,
      state: this.h_state,
      country: this.h_country,
      zipcode: this.h_zipcode,
      refer_by: this.refer_txt, pat_id: this.mrno,

      doc_qualification: this.doc_qualification
    };
    Doc_Helper.setGynoMedPres(inpat_pres);
  }

  validateMobile() {
    if (this.clnt_cont_number.length != 10) {
      this.toastr.error('Enter valid mobile number');
      this.clnt_cont_number = undefined;
    }
  }

  // zipcode_change() {
  //   if (this.clnt_zipcode.length > 4) {
  //     this.clnt_location_list = [];
  //     var headers = new Headers();
  //     headers.append('Content-Type', 'application/json');
  //     this.http
  //       .post(
  //         this.zipcode_url,
  //         {
  //           zipcode: this.clnt_zipcode,
  //         },
  //         { headers: headers }
  //       )
  //       .subscribe(
  //         (data) => {
  //           var obj = data.json();
  //           if (obj.locations != null && obj.locations.length != 0) {
  //             if (obj.locations.length == 1) {
  //               this.clnt_location_id = obj.locations[0].location_id;
  //               this.clnt_location = obj.locations[0].description;
  //               this.clnt_zipcode = obj.locations[0].pincode;
  //               this.getCities(this.clnt_location, 0);
  //             } else {
  //               this.clnt_location_list = obj.locations;
  //               console.log('../location' + JSON.stringify(this.clnt_location_list));
  //             }
  //           }
  //         },
  //         (error) => {
  //           this.toastr.error(
  //             'Unable to fetch location details, please try later'
  //           );
  //         }
  //       );
  //   } else {
  //     this.clnt_location_list = [];
  //   }
  // }

  // getCities(state, flag) {
  //   this.clnt_city_list = [];
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http
  //     .post(
  //       this.cityUrl,
  //       { loc_name: this.clnt_location, zipcode: this.clnt_zipcode },
  //       { headers: headers }
  //     )
  //     .subscribe((data) => {
  //       var dataval = data.json();
  //       if (dataval.cities != null) {
  //         this.clnt_city_list = dataval.cities;
  //         if (this.clnt_city_list.length == 1) {
  //           for (var i = 0; i < this.clnt_city_list.length; i++) {
  //             if (this.clnt_city_list[i].city_id == this.clnt_city_id) {
  //               this.clnt_city_id = this.clnt_city_list[i].city_id;
  //               this.clnt_city = this.clnt_city_list[i].city_desc;
  //               this.getStates(this.clnt_city_id, 0);

  //               break;
  //             } else {
  //               this.clnt_city_id = this.clnt_city_list[0].city_id;
  //               this.clnt_city = this.clnt_city_list[0].city_desc;
  //               this.getStates(this.clnt_city_id, 0);
  //             }
  //           }
  //         } else {
  //           this.clnt_city_id = this.clnt_city_list[0].city_id;
  //           this.clnt_city = this.clnt_city_list[0].city_desc;
  //           this.getStates(this.clnt_city_id, 0);
  //         }
  //       }
  //     });
  // }

  // changeCity(clnt_city, flag) {
  //   this.clnt_city_list = [];
  //   for (var i = 0; i < this.clnt_city_list.length; i++) {
  //     if (this.clnt_city_list[i].city_desc == clnt_city) {
  //       this.clnt_city_id = this.clnt_city_list[i].district_id;
  //       this.clnt_city = this.clnt_city_list[i].city_desc;
  //       this.getStates(this.clnt_city_id, 0);
  //     }
  //   }
  // }

  // getLocations(city) {
  //   this.clnt_location_list = [];
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http
  //     .post(this.locationUrl, { city_id: city }, { headers: headers })
  //     .subscribe((data) => {
  //       var dataval = data.json();
  //       if (dataval.locations != null && dataval.locations.length != 0) {
  //         this.clnt_location_list = dataval.locations;
  //         for (var i = 0; i < this.clnt_location_list.length; i++) {
  //           if (
  //             this.clnt_location_list[i].location_id == this.clnt_location_id
  //           ) {
  //             this.clnt_location_id = this.clnt_location_list[i].location_id;
  //             this.clnt_location = this.clnt_location_list[i].description;
  //             this.clnt_zipcode = this.clnt_location_list[i].pincode;
  //             this.clnt_location_list = [];
  //             break;
  //           }
  //         }
  //       }
  //     });
  // }

  // changeLocation(locat_desc) {
  //   this.clnt_location = locat_desc;
  //   this.clnt_location_list = [];

  //   if (this.clnt_location != null && this.clnt_location.length > 2) {
  //     var headers = new Headers();
  //     headers.append('Content-Type', 'application/json');
  //     this.http
  //       .post(
  //         this.locationNameByUrl,
  //         {
  //           name: this.clnt_location,
  //           //city_id: this.clntCityId,
  //         },
  //         { headers: headers }
  //       )
  //       .subscribe(
  //         (data) => {
  //           var dataval = data.json();
  //           if (dataval.locations != null && dataval.locations.length != 0)
  //             this.clnt_location_list = dataval.locations;
  //         },
  //         (error) => {
  //           this.toastr.error(Message_data.unableToFetchData);
  //         }
  //       );
  //   } else {
  //     this.clnt_location_list = [];
  //   }
  // }

  // selectLocation(location) {
  //   this.clnt_location = location;
  //   for (var i = 0; i < this.clnt_location_list.length; i++) {
  //     if (this.clnt_location_list[i].location_id == location.location_id) {
  //       this.clnt_location_id = this.clnt_location_list[i].location_id;
  //       this.clnt_location = this.clnt_location_list[i].description;
  //       this.clnt_zipcode = this.clnt_location_list[i].pincode;
  //     }
  //   }
  //   this.clnt_location_list = [];
  //   this.getCities(this.clnt_location, '0');
  //   if (this.Client_type == 'Existing') {
  //     this.existUserFlag = true;
  //   }
  // }

  // getStates(city, flag) {
  //   this.clnt_state_list = [];
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http
  //     .post(this.stateUrl, { city_id: city }, { headers: headers })
  //     .subscribe((data) => {
  //       var dataval = data.json();
  //       if (dataval.states != null) {
  //         this.clnt_state_list = dataval.states;
  //         if (this.clnt_state_list.length == 1) {
  //           for (var i = 0; i < this.clnt_state_list.length; i++) {
  //             if (this.clnt_state_list[i].state_id == this.clnt_state_id) {
  //               this.clnt_state_id = this.clnt_state_list[i].state_id;
  //               this.clnt_state = this.clnt_state_list[i].state_desc;
  //               this.getCountries(this.clnt_state_id, '0');
  //               break;
  //             } else {
  //               this.clnt_state_id = this.clnt_state_list[0].state_id;
  //               this.clnt_state = this.clnt_state_list[0].state_desc;
  //               this.getCountries(this.clnt_state_id, '0');
  //             }
  //           }
  //         } else {
  //           this.clnt_state_id = this.clnt_state_list[0].state_id;
  //           this.clnt_state = this.clnt_state_list[0].state_desc;
  //           this.getCountries(this.clnt_state_id, '0');
  //         }
  //       }
  //     });
  // }

  // changeState(clnt_state, flag) {
  //   for (var i = 0; i < this.clnt_state_list.length; i++) {
  //     if (this.clnt_state_list[i].stage_desc == clnt_state) {
  //       this.clnt_state_id = this.clnt_state_list[i].state_id;
  //       this.clnt_state = this.clnt_state_list[i].stage_desc;
  //       this.getCountries(this.clnt_state_id, '0');
  //     }
  //   }
  // }

  // getCountries(stateid, flag) {
  //   this.clnt_country_list = [];
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http
  //     .post(this.countryUrl, { state_id: stateid }, { headers: headers })
  //     .subscribe(
  //       (data) => {
  //         this.clnt_country_list = [];
  //         var dataval = data.json();
  //         if (dataval.countries != null) {
  //           this.clnt_country_list = dataval.countries;
  //           for (var c = 0; c < this.clnt_country_list.length; c++) {
  //             if (
  //               this.clnt_country_list[c].country_id == this.clnt_country_id
  //             ) {
  //               this.clnt_country = this.clnt_country_list[c].country_desc;
  //               this.clnt_country_id = this.clnt_country_list[c].country_id;
  //               //this.getStates(this.clntCountryId, flag);
  //               break;
  //             }
  //           }
  //         }
  //       },
  //       (error) => {
  //         this.toastr.error(Message_data.unableToFetchData);
  //       }
  //     );
  // }

  // callback...
  myCallbackFunction = function (_params) {
    return new Promise((resolve, reject) => {
      resolve(_params);
    });
  };

  goToPatientlist() {
    if (Helper_Class.getInpatientFlow() == 'front-desk') {
      this.frontservice.sendMessage('emergencylist');
      // if (Doc_Helper.getFlowName() == 'emergencylist') {
      //   if (Doc_Helper.getFlowName() == 'emergencylist') {
      //     this.frontservice.sendMessage('emergencylist');
      //   }
      // } else if (Doc_Helper.getFlowName() == 'ward') {
      //   this.messageservice.sendMessage('warddetails');
      // }
      // else {
      //   this.frontservice.sendMessage('emergencylist');
      // }
    } else if (Helper_Class.getInpatientFlow() == 'nurse') {
      this.nurseservice.sendMessage('emergencylist');
     
    } else if (Helper_Class.getInpatientFlow() == 'Billing') {
      this.nurseservice.sendMessage('emergencylist');
      // if (Doc_Helper.getFlowName() == 'ward') {
      //   this.messageservice.sendMessage('warddetails');

      // } else {
      //   this.nurseservice.sendMessage('Patients');
      // }
    }
    else {
      this.messageservice.sendMessage('emergencylist');
    }
    //Patients
  }

  goToConcentForm() {
    this.messageservice.sendMessage('concent_form');
    var object = {
      hospital_details: this.hospital_details,
      user_details: this.user_details
    };
    Helper_Class.setConcentForm(object);
  }

  calories_data() {
    if (
      (this.height_txt != undefined && this.height_txt.length != 0) ||
      (this.weight_txt != undefined && this.weight_txt.length != 0) ||
      (this.chief_comp != undefined && this.chief_comp.length != 0) ||
      (this.family_hist != undefined && this.family_hist.length != 0) ||
      (this.pres_ill != undefined && this.pres_ill.length != 0) ||
      (this.med_hist != undefined && this.med_hist.length != 0) ||
      (this.physical_exam != undefined && this.physical_exam.length != 0)
    ) {
      if (this.app_id == undefined) {
        if (this.createpage_flag == false) {
          this.Confirm_btn = true;
        }
      }

      if (this.height_txt != undefined && this.height_txt.length != 0) {
        this.encheight = encrypt_decript.Encript(this.height_txt.toString());
      }
      if (this.weight_txt != undefined && this.weight_txt.length != 0) {
        this.encweight = encrypt_decript.Encript(this.weight_txt.toString());
      }
    } else {
      this.Confirm_btn = true;
    }

    if (
      this.height_txt == undefined ||
      this.height_txt == '' ||
      this.weight_txt == undefined ||
      this.weight_txt == ''
    ) {
      this.bmi_txt = '';
    } else {
      if (this.height_txt != undefined) {
        if (this.hmeasure == 'cms') {
          this.height_txt = this.height_txt.toString().replace(/[^0-9]/g, '');
        } else {
          this.height_txt = this.height_txt
            .toString()
            .replace('/[^-0-9.]/g', '');
          if (this.height_txt.indexOf('.') !== -1) {
            if (this.height_txt.split('.').length > 2) {
              this.height_txt = this.height_txt.height.slice(0, -1);
            }
          }
        }

        if (this.hmeasure == 'cms') {
          if (parseFloat(this.height_txt) > 300) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        } else {
          if (parseFloat(this.height_txt) > 100) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        }
      }

      if (this.wmeasure == "kgs" && this.hmeasure == "inch") {
        var pound = this.weight_txt * 2.20462;
        var Height_txt = parseFloat(this.height_txt)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi_txt = Math.round(to);
        if (this.Gender_data == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.Age_data) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.Gender_data == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
        }
      } else if (this.wmeasure == "kgs" && this.hmeasure == "cms") {
        var meter = parseFloat(this.height_txt) / 100.00;
        var to = parseFloat(this.weight_txt) / (meter * meter);
        this.bmi_txt = Math.round(to);
        if (this.Gender_data == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.Age_data) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.Gender_data == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
        }
      } else if (this.wmeasure == "lbs" && this.hmeasure == "inch") {
        var Height_txt = parseFloat(this.height_txt);
        var dat = this.weight_txt / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi_txt = Math.round(to);
        if (this.Gender_data == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.Age_data) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.Gender_data == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
        }
      } else if (this.wmeasure == "lbs" && this.hmeasure == "cms") {
        var Height_txt = parseFloat(this.height_txt);
        var inch = Height_txt * 0.393701;
        var dat = this.weight_txt / (inch * inch);
        var to = dat * 703;
        this.bmi_txt = Math.round(to);
        if (this.Gender_data == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.Age_data) + 5;
          this.bmr = Math.round(get_bmr);
        } else if (this.Gender_data == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.Age_data) - 161;
          this.bmr = Math.round(get_bmr);
        }
      }
    }
  }

  dischargeSummary() {
    if (this.clnt_mname != undefined && this.clnt_mname != null) {
      if (this.clnt_mname != '') {
        this.patient_name =
          this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname;
      } else {
        this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
      }
    } else {
      this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
    }
    var bpval;
    if (this.BP_01 != null) {
      bpval = this.BP_01 + '/' + this.BP_02;
    }

    var inpat_pres = {
      prov_id: this.user_id,
      client_id: this.client_reg_id,
      relation_id: '1',
      salutation: this.clnt_sal,
      Gender_data: this.clnt_gender,
      client_name: this.patient_name,
      Age_data: this.clnt_age,
      app_id: this.app_id,
      Client_id: this.client_reg_id,
      rel_id: '1',
      sub_id: '',
      Case_Clnt_dob: this.clnt_dob,
      spl_id: this.spl_id,
      hptl_clinic_id: this.hospital_id,
      mobile: this.mobile_no,
      spl: this.spl,
      temparature: this.temp_txt,
      height: this.height_txt,
      height_measure: this.hmeasure,
      weight: this.weight_txt,
      weight_measure: this.wmeasure,
      bmi: this.bmi_txt,
      bp: bpval,
      symptoms: this.clnt_symptom,
      complaints: this.clnt_symptom,
      medicare_name: this.treatement_type,
      Appoint_Date: Date_Formate(this.CurrentDatetime),
      addmed: 'write',
      AppFlow: 'InpatMedPres',
      // ref_by:this.refer_txt,
      covid_symp: this.finalcovidsymp,
      covid_comorb: this.finalcomorbList,
      covid_observ: this.observList,
      is_covid: this.covid_type,
      covid_dur: this.covid_duration,
      location: this.h_location,
      address1: this.h_address1,
      address2: this.h_address2,
      city: this.h_city,
      state: this.h_state,
      country: this.h_country,
      zipcode: this.h_zipcode,
      refer_by: this.refer_txt,
      barcode: this.barcode,
      flag: 'inpat', 
      pat_id: this.mr_no, 
      inpatient_id: this.inpatId, 
      doc_reg_id: this.doc_reg_id,
      doc_qualification: this.doc_qualification,
      chief_comp: this.chief_comp,
      pres_ill: this.pres_ill,
      med_hist: this.med_hist,
      gen_exam: this.gen_exam,
      line_mgmt: this.line_mgmt,
      inp_inv: this.inp_inv,
      country_id: this.clnt_country_id,
      state_id: this.h_state_id,
      city_id: this.h_city_id,
      pat_type:"emergency"
    };

    Doc_Helper.setClient_Info(inpat_pres);
    Doc_Helper.setMobile_no(encrypt_decript.Encript(this.mobile_no));
    this.messageservice.sendMessage('inPatientDS');
    Helper_Class.set_med_pres_id(null);
    Helper_Class.set_diag_pres_id(null);
  }

  view_prescription() {
    const dialogRef5 = this.dialog.open(InpatMedprescViewComponent, {
      width: '700px',
      height: '300px',
      data: { inpatient_id: this.admission_id },
    });

    dialogRef5.afterClosed().subscribe((result) => { });
  }

  getReferralData(e) {
    this.Refered_doctor = [];
    this.Refered_name = [];
    this.refer_txt = e.target.value.toString();
    if (this.refer_txt != '' && this.refer_txt.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp.toString() + 'search/docbyhns/',
          JSON.stringify({
            doc_name: this.refer_txt,
            hospital_id: this.hospital_id,
          }),
          { headers: headers }
        )
        .subscribe(
          (response) => {
            var obj = response.json();
            this.Refered_name = obj.doctors;

            if (this.Refered_name != undefined) {
              for (var i = 0; i < this.Refered_name.length; i++) {
                var docname;
                if (this.Refered_name[i].middle_name != undefined) {
                  docname =
                    this.Refered_name[i].first_name +
                    ' ' +
                    this.Refered_name[i].middle_name +
                    ' ' +
                    this.Refered_name[i].last_name;
                } else {
                  docname =
                    this.Refered_name[i].first_name +
                    ' ' +
                    this.Refered_name[i].last_name;
                }
                this.Refered_doctor.push(docname);
              }
            }
            var name = this.refer_txt;
            this.Refered_doctor = this.Refered_doctor.filter(
              function (el) {
                return el.toLowerCase().indexOf(name.toLowerCase()) > -1;
              }.bind(this)
            );
          },
          (error) => {
            this.toastr.error(Message_data.defaultErr);
          }
        );
    } else {
      this.Refered_doctor = [];
    }
  }

  getVisitingDoc(e) {
    this.visiting_doctor = [];
    this.docName = [];
    this.visiting_doc_name = e.target.value.toString();
    if (this.visiting_doc_name != '' && this.visiting_doc_name.length > 2) {
      var sendData = {
        doc_name: this.visiting_doc_name,
        hospital_id: this.hospital_id,
      };
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp.toString() + 'search/docbyhns/',
          JSON.stringify(sendData),
          { headers: headers }
        )
        .subscribe(
          (response) => {
            var obj = response.json();
            this.docName = obj.doctors;
            if (this.docName != undefined) {
              for (var i = 0; i < this.docName.length; i++) {
                var docname;
                if (this.docName[i].middle_name != undefined) {
                  docname =
                    this.docName[i].first_name +
                    ' ' +
                    this.docName[i].middle_name +
                    ' ' +
                    this.docName[i].last_name;

                } else {
                  docname =
                    this.docName[i].first_name +
                    ' ' +
                    this.docName[i].last_name;
                }
                this.visiting_doctor.push({
                  name: docname,
                  id: this.docName[i].prov_id,
                  type: this.docName[i].doctor_type,
                });
                console.log(this.visiting_doctor);
              }
            } else {
              this.visiting_doctor = [];
            }
          },
          (error) => {
            this.toastr.error(Message_data.defaultErr);
          }
        );
    }
  }

  Select_refer_doctor(item) {
    this.refer_txt = item;
    this.Refered_doctor = [];
  }

  Select_visiting_doctor(item) {
    for (var i = 0; i < this.visiting_doctor.length; i++) {
      if (this.visiting_doctor[i].id == item) {

        this.is_consultent = this.visiting_doctor[i].type != 'doctor' ? '1' : '0';
        this.visiting_doc_id = this.visiting_doctor[i].id;
      }
    }
  }

  getChargeTypes1(e) {
    console.log("hhthis.hospital_id" + this.userinfo.hospitals[0].hptl_clinic_id)
    this.chargeList = [];
    console.log("obj valu" + JSON.stringify(this.userinfo.hospitals[0].hptl_clinic_id));
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.visiting_doc_name = e.target.value.toString();
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/chgtype',
        JSON.stringify({
          hptl_clinic_id: this.userinfo.hospitals[0].hptl_clinic_id,
          provider: "doctor",
          type: "in-patient",
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          this.chargeList = [];
          if (obj.charges != undefined && obj.charges.length != 0) {
            this.chargeList = obj.charges;
            console.log("obj valu" + JSON.stringify(this.chargeList));
            console.log("this.chargeList.length valu" + JSON.stringify(this.chargeList.length));
            //charge_id

          }
        },
        (error) => {
          this.toastr.error(Message_data.network);
        }
      );
  }
  addChargeType(chargeTypes) {
    this.is_charge_id = "1";
  }

  public delet_id;
  public deletedItem: any;
  deletecharge(data) {
    for (var i = 0; i < this.selectedchargelistvalue.length; i++) {
      if (data.chargename === this.selectedchargelistvalue[i].chargename) {
        this.deletedItem = this.selectedchargelistvalue[i];
        this.selectedchargelistvalue.splice(i, 1);
        this.delet_id = data
        console.log("this.selectedchargelistvalue" + JSON.stringify(this.deletedItem))

      }
    }
    // if(bill_charge_id)
    var sendadata = {
      inpatient_id: Doc_Helper.getInpatientId(),
      country: ipaddress.country_code,
      created_by: this.userinfo.user_type,
      inpatient_service_id: this.deletedItem.service_id,
      start_time: this.deletedItem.new_starttime,
      end_time: this.deletedItem.new_endtime,
      quantity: this.quantity,
      from_date: this.f_date,
      to_date: this.t_date,
      status: 0,
      pat_type:"emergency"
    }

    console.log("********senddata**********" + JSON.stringify(sendadata))

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'inpat/sost',
        JSON.stringify(sendadata),
        { headers: headers }
      )
      .subscribe((data) => {
        console.log("check data ===" + JSON.stringify(data))
        var obj = data.json();
        console.log(obj);
        if (obj != undefined) {
          if (obj.key == '1') {
            this.toastr.success(obj.result);
            var selectedCharge = this.chargeList.find(charge => charge.charge_id === this.chargeid);
            var selectedCharge1 = this.chargeList.filter(charge => charge.charge_id === this.chargeid);
            console.log("check selectedCharge ===" + JSON.stringify(selectedCharge))
            this.chargedescription = selectedCharge1[0].charge_desc;
            // console.log("check filter ==="+JSON.stringify(selectedCharge1))

            if (selectedCharge) {
              // Push the description of the selected charge into the selectedchargelistvalue array
              // this.selectedchargelistvalue.push(selectedCharge.description);
            }
            this.selectedchargelistvalue.push({
              chargename: this.chargedescription,
              starttime: Time_Formate(this.data1),
              endtime: Time_Formate(this.data2),
              quantity: this.quantity,
              charge_id: this.chargeid,
              from_date: this.f_date,
              to_date: this.t_date
            });
            console.log("add adtehj" + JSON.stringify(this.selectedchargelistvalue) + this.data1 + this.data2)
            this.quantity = 1;
            this.f_time = "";
            this.to_time = "";
            this.sesionselction1 = "";
            this.sesionselction = "";
            this.f_time1 = "";
            this.to_time1 = ""

            // this.getRetrivalothercharges();
          } else {
            this.toastr.error('Unable to update visit details, try later');
          }
        }
      });

  }

  getRetrivalothercharges() {
    var sentdata = {
      inpatient_id: Doc_Helper.getInpatientId()
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'inpat/gost',
        JSON.stringify(sentdata),
        { headers: headers }
      )
      .subscribe((data) => {

        var newarray = [];
        this.selectedchargelistvalue = [];
        var obj = JSON.parse(data['_body']);
        console.log("BILLING CHARGES LOG =========== " + JSON.stringify(obj.bill_charges))
        if (obj.bill_charges != undefined && obj.bill_charges.length != 0) {
          for (var i = 0; i < obj.bill_charges.length; i++) {

            if (obj.bill_charges[i].from_date != undefined) {
              obj.bill_charges[i].from_date = Date_Formate(obj.bill_charges[i].from_date)
            }
            if (obj.bill_charges[i].to_date != undefined) {
              obj.bill_charges[i].to_date = Date_Formate(obj.bill_charges[i].to_date)
            }


            this.selectedchargelistvalue.push({
              chargename: obj.bill_charges[i].charge_desc,
              starttime: Time_Formate(obj.bill_charges[i].start_time),
              endtime: Time_Formate(obj.bill_charges[i].end_time),
              created_date: Date_Formate(obj.bill_charges[i].created_date),
              created_time: Time_Formate(obj.bill_charges[i].created_time),
              quantity: obj.bill_charges[i].quantity,
              bill_charge_id: obj.bill_charges[i].bill_charge_id,
              new_starttime: (obj.bill_charges[i].start_time),
              new_endtime: (obj.bill_charges[i].end_time),
              service_id: obj.bill_charges[i].service_id,
              from_date: obj.bill_charges[i].from_date,
              to_date: obj.bill_charges[i].to_date
            });
          }
        }

      });
  }

  addVisiting() {
    this.getCurrentDate()
    var visitflag = true;
    if (this.visiting_doc_name == undefined) {
      visitflag = false;
      this.toastr.error("Enter Doctor visitings mandatory field")
    } if (visitflag == true) {
      console.log(this.visiting_doc_id);
      var providername;
      if (this.userinfo.middle_name != undefined) {
        providername = this.userinfo.first_name + ' ' + this.userinfo.middle_name + ' ' + this.userinfo.last_name;
      } else {
        if (this.userinfo.last_name != undefined) {
          providername = this.userinfo.first_name + ' ' + this.userinfo.last_name;
        } else {
          providername = this.userinfo.first_name;
        }
      }
      var billable = this.doc_billable == '0' ? 'No' : 'Yes';
      var currentTime_moment = moment().format('hh:mm a');
      //var currentTime_moment = moment().format('hh:mm A');
      this.visitignArrray.push({
        doc_reg_id: this.visiting_doc_id,
        visiting_doc_name: this.visiting_doc_name,
        remarks: this.visiting_remarks,
        billable: billable,
        date: Date_Formate(this.CurrentDatetime),
        time: currentTime_moment,
        pat_type:"emergency"
      });

      var visitingsend = {
        inpatient_id: this.inpatientId,
        doc_reg_id: this.visiting_doc_id,
        is_consultant: this.is_consultent,
        is_billable: this.doc_billable,
        charge_id: this.chargetype,
        remarks: this.visiting_remarks,
        created_by: providername,
        hosp_clinic_id: this.hospital_id,
        pat_type:"emergency"
      };

      console.log("visitingsend = " + JSON.stringify(visitingsend) + " visitingnArray = " + JSON.stringify(this.visitignArrray));
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp.toString() + 'inpat/sdv',
          JSON.stringify(visitingsend),
          { headers: headers }
        )
        .subscribe((data) => {
          var obj = data.json();
          console.log(obj);
          if (obj != undefined) {
            if (obj.key == '1') {
              this.toastr.success('Successfully updated visit details');
            } else {
              this.toastr.error('Unable to update visit details, try later');
            }
          }
        });
    }
  }

  OnDateChangeDOB(e) {
    this.clnt_dob = e;
    if (e != null) {
      this.disable_age = true;
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp + 'adm/ddif',
        JSON.stringify({
          dobdate: Date_Formate(this.clnt_dob),
          curdate: Date_Formate(this.CurrentDatetime),
          spl: this.spl_id,
        }),
        { headers: headers }
      )
      .subscribe((data) => {
        var obj = data.json();
        if (obj != undefined) {
          this.clnt_age = obj.value;
        }
      });
  }

  checkchar(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  getSymptoms() {
    var searchString;
    if (this.clnt_symptom.indexOf(',') > -1) {
      var dataval = this.clnt_symptom.split(',');
      searchString = dataval[dataval.length - 1].trim();
    } else {
      searchString = this.clnt_symptom;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp.toString() + 'adm/gsymbn/',
          JSON.stringify({
            symp_name: searchString,
          }),
          { headers: headers }
        )
        .subscribe(
          (response) => {
            var obj = JSON.parse(response['_body']);
            if (
              obj.symptoms != null &&
              obj.symptoms != undefined &&
              obj.symptoms != '' &&
              obj.symptoms.length != 0
            ) {
              this.newsymptoms_flag = false;
              this.symptoms_list = [];
              this.symptomlist = [];
              this.symptom_data = [];
              this.symptoms_list = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.symptom_data.push(obj.symptoms[i].description);
              }
              this.symptomlist = this.symptom_data.filter(
                function (el) {
                  return (
                    el.toLowerCase().indexOf(searchString.toLowerCase()) > -1
                  );
                }.bind(this)
              );
            } else {
              this.symptomlist = [];
              this.newsymptoms_flag = true;
            }
          },
          (error) => { }
        );
    }
  }

  select_symptom(data) {
    this.newsymptoms.push(data);
    for (var j = 0; j < this.symptoms_list.length; j++) {
      if (this.symptoms_list[j].description == data) {
        this.selected_symptoms.push(data);
        break;
      }
    }

    if (this.clnt_symptom.indexOf(',') > -1) {
      var dataval = this.clnt_symptom.split(',');
      dataval.pop();
      this.clnt_symptom = dataval.join(',');
      this.clnt_symptom = this.clnt_symptom + ',' + data;
    } else {
      this.clnt_symptom = data;
    }
    this.symptomlist = [];
  }

  ChangeAge() {
    if (this.clnt_age != '') {
      this.disable_dob = true;
      this.clnt_dob = null;
    } else {
      this.disable_dob = false;
    }
    this.nodob = true;
    var dateval = this.currentDate.split('-');
    this.clnt_dob = parseInt(dateval[0]) - parseInt(this.clnt_age) + '-01-01';
    this.disable_dob = false;
  }
  isReadonly() { return true; }
  changeSalutation(e) {
    if (e == '1' || e == '6') this.clnt_gender = 'Male';
    else if (e == '2' || e == '3' || e == '7') this.clnt_gender = 'Female';
    else if (e == '4') {
      this.clnt_gender = 'Female';
      this.clnt_marital = 'Married';
    } else if (e == '5') this.clnt_gender = 'Transgender';
    else {
      this.clnt_gender = '';
    }
  }

  filterNew(event, newEvent) {
    if (event.trim() != '' && event != undefined && event != null) {
      var new_symptoms: any = [];
      if (event.indexOf(',') > -1) {
        var dataval1 = event.trim().split(',');
        var dataval = dataval1.sort();
        if (newEvent.length != 0) {
          new_symptoms = dataval.filter((val) => !newEvent.includes(val));
        } else {
          for (var i = 0; i < dataval.length; i++) {
            if (
              dataval[i].trim() != '' &&
              dataval[i].trim() != undefined &&
              dataval[i].trim() != null
            ) {
              new_symptoms.push(dataval[i].trim());
            }
          }
        }
      } else {
        var dataval1 = event.trim().split(',');
        var dataval = dataval1.sort();
        if (newEvent.length != 0) {
          new_symptoms = dataval.filter((val) => !newEvent.includes(val));
        } else {
          for (var i = 0; i < dataval.length; i++) {
            if (
              dataval[i].trim() != '' &&
              dataval[i].trim() != undefined &&
              dataval[i].trim() != null
            ) {
              new_symptoms.push(dataval[i].trim());
            }
          }
        }
      }
      return new_symptoms;
    }
  }

  getDoctorBothModule() {
    var send_data = {
      doc_reg_id: this.doc_id,
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/gdm', JSON.stringify(send_data), {
        headers: headers,
      })
      .subscribe((response) => {
        var obj = response.json();
        if (obj.both_modules != null && obj.both_modules != undefined) {
          for (var i = 0; i < obj.both_modules.length; i++) {
            this.module_data = [];
            obj.both_modules[i].module_id;
            this.module_data.push(obj.both_modules[i].module_id);
            this.both_modul_list.push(this.module_data);
          }
        }
        if (obj.home_modules != null) {
          for (var j = 0; j < obj.home_modules.length; j++) {
            this.module_data = [];
            this.module_data.push(obj.home_modules[j].module_id);
            this.both_modul_list.push(this.module_data);
          }
        }
        if (obj.menudrawer_modules != null) {
          for (var k = 0; k < obj.menudrawer_modules.length; k++) {
            this.module_data = [];
            this.module_data.push(obj.menudrawer_modules[k].module_id);
            this.both_modul_list.push(this.module_data);
          }
        }
        if (obj.submenudrawer_modules != null) {
          for (var k = 0; k < obj.submenudrawer_modules.length; k++) {
            this.module_data = [];
            this.module_data.push(obj.submenudrawer_modules[k].module_id);
            this.submenu_drawer_modul_list.push(this.module_data);
          }
        }
        Doc_Helper.setCasesheet_flag('1');
        //   Doc_Helper.setModuleList(this.both_modul_list);
        for (var i = 0; i < this.both_modul_list.length; i++) {
          if (this.both_modul_list[i] == '29') {
            Doc_Helper.setCasesheet_flag('1');
          } else if (this.both_modul_list[i] == '30') {
            Doc_Helper.setCasesumm_flag('1');
            // localStorage.setItem("casesumm_flag","1");
          }
        }
        console.log("check -obj--" + JSON.stringify(obj))
        console.log("check ---" + JSON.stringify(this.both_modul_list))
      });
  }

  Casesheet() {
    if (Helper_Class.getInpatientFlow() == 'nurse') {
      this.getDoctorBothModule();
    }
    if (this.clnt_mname != undefined && this.clnt_mname != null) {
      if (this.clnt_mname != '') {
        this.patient_name =
          this.clnt_fname + ' ' + this.clnt_mname + ' ' + this.clnt_lname;
      } else {
        this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
      }
    } else {
      this.patient_name = this.clnt_fname + ' ' + this.clnt_lname;
    }

    this.BP_01 = this.BP_01 != null ? this.BP_01 : '00';
    this.BP_02 = this.BP_02 != null ? this.BP_02 : '00';

    var user_id = this.getUserId();
    if (this.surgery_id != undefined) {
      for (var i = 0; i < this.surgery_list.length; i++) {
        if (this.surgery_id == this.surgery_list[i].surgery_id) {
          this.surgery_name = this.surgery_list[i].surgery_name;
          break;
        }
      }
    }

    var data = Helper_Class.getvitalloginfo();
    console.log("check datadatadata ---" + JSON.stringify(data))
    var walkinViewpres = {
      user_id: user_id,
      Age_data: this.clnt_age,
      Gender_data: this.clnt_gender,
      app_id: this.app_id,
      client_name: this.patient_name,
      //image: list.profile_image,
      sub_id: '',
      Client_id: this.client_reg_id,
      life_style_id: this.life_style_id,
      rel_id: "1",
      relation_id: "1",
      Case_Clnt_dob: this.clnt_dob,
      relationship_name: this.relation_name,
      hptl_clinic_id: this.hospital_id,
      spl_id: this.spl_id,
      spl: this.spl,
      //  mobile:list.contact,
      symptoms: this.clnt_symptom,
      complaints: this.clnt_symptom,
      //   Appoint_Date: list.lastvisit,
      medicare_name: this.treatement_type,
      //inpatient_id: this.admission_id,
      address1: this.h_address1,
      address2: this.h_address2,
      location: this.h_location,
      city: this.h_city,
      state: this.h_state,
      country: this.h_country,
      zipcode: this.h_zipcode,
      hptl_name: Doc_Helper.getHospital_name(),
      // height: this.height_txt,
      // weight: this.weight_txt,
      // height_measure: this.hmeasure,
      // weight_measure: this.wmeasure,
      // temparature: this.temp_txt,
      // bp: this.BP_01 + '/' + this.BP_02,
      // bmi: this.bmi_txt,
      // bmr: this.bmr,
      // pulse: this.pulse_txt,
      // rr:this.rr_txt,
      // spo2:this.spo_txt,
      // cns:this.cns_txt,
      // abd:this.abd_txt,
      // cvs:this.cvs_txt,     
      // rs:this.rs_text,    
      // resp_rate:this.rr_txt,
      // pr:this.pr_txt,
      // cbg:this.cbg_txt,
      //new
      height: this.height_txt_case,
      weight: this.weight_txt_case,
      height_measure: this.hmeasure_case,
      weight_measure: this.wmeasure_case,
      temparature: this.temperature_case,
      bp: this.bloodPressure_case,
      bmi: this.bmi_txt_case,
      bmr: this.bmr_txt_case,
      pulse: this.pulse_case,
      rr: this.RR_case,
      spo2: this.SPO2_case,
      cns: this.Cns_case,
      abd: this.ABD_case,
      cvs: this.Cvs_case,
      rs: this.Rs_case,
      resp_rate: this.RR_case,
      pr: this.PR_case,
      cbg: this.cbg_txt_case,
      patient_id: this.admission_id,
      AppFlow: 'Inpatient',
      country_id: this.h_country_id,
      state_id: this.h_state_id,
      city_id: this.h_city_id, inpatientnuber: this.inpat_id,
      surgery_date: this.surgery_date,
      surgery_time: this.surgery_time,
      admission_date: this.admission_date,
      admission_time: this.admission_time,
      ward_id: this.ward_id,
      bed_no: this.send_bed_no,
      wardlist: this.wardName,
      surgery_id: this.surgery_id,
      surgery_list: this.surgery_list,
      surgery_proc: this.surgery_proc,
      surg_doc: this.surg_doc,
      surgery_name: this.surgery_name,
      // spo2: this.spo_txt,
      // cvs: this.cvs_txt,
      // rs: this.rstxt,
      // cns: this.cns_txt,
      // abd: this.abd_txt,
      salutation: this.clnt_saldesc,
      clientaddress1: this.clnt_address1,
      clientaddress2: this.clnt_address2,
      clientlocation: this.clnt_location,
      clientcity: this.clnt_city,
      clientstate: this.clnt_state,
      clientcountry: this.clnt_country,
      clientcountry_id: this.clnt_country_id,
      clientstate_id: this.clnt_state_id,
      clientcity_id: this.clnt_city_id,
      clientzipcode: this.clnt_zipcodevalue,
      doctor_name: this.doctornamevalue,
      pat_id: this.mrno, inpatient_id: this.inpatId, mobile: this.mobile_no,
      barcode: this.barcode,
      allergy: this.allergy, // yes or no
      drug_details: this.drug_details,
      env_details: this.env_details,
      food_details: this.food_details,
      ward: this.wardName,
      bedno: this.bed_novalue,
      pre_hosp: this.pre_hosp,
      pre_hospflag: this.pre_hospflag,
      pre_hospital_details: this.pre_hospital_details,
      diab_reading: Helper_Class.getdiabrading_inpatient(),

      doc_qualification: this.doc_qualification
      //  blood_group:this.blood_group,
    };

    Doc_Helper.setCasesheet_flag('1');
    Doc_Helper.setClient_Info(walkinViewpres);
    console.log("check this.clnt_address1 ---" + JSON.stringify(this.clnt_address1))
    console.log("check data ---" + JSON.stringify(Doc_Helper.getClient_Info()))
    this.check_specialization();
    // this.messageservice.sendMessage("medprescription");
  }

  check_specialization() {
    this.getASGDET(this.client_reg_id, '1', '');
    var oflag: boolean = false;
    var oflag: boolean = false;
    this.Gynecology_flag = false;
    Helper_Class.setapp_flow('0');
    this.Is_specilization = this.spl;
    if (this.Is_specilization == 'Gynecology') {
      this.Gynecology_flag = true;
      oflag = true;
    } else if (this.Is_specilization == 'Pediatrics') {
      this.Pediatrics_flag = true;
      oflag = true;
    } else if (this.Is_specilization == 'Cardiology') {
      this.Cardio_flag = true;
      oflag = true;
    } else if (this.Is_specilization == 'Nephrology') {
      this.Nephrology_flag = true;
      oflag = true;
    } else if (this.Is_specilization == 'Dental') {
      this.Dental_flag = true;
      oflag = true;
    } else if (this.Is_specilization == 'Diabetes') {
      this.Diabetes_flag = true;
      oflag = true;
    } else {
      if (oflag == false) {
        this.Others_flag = true;
      }
    }

    if (this.Gynecology_flag == true) {
      this.Get_gynaretrieval_data();
    }
    if (this.Gynecology_flag == false) {
      this.getpatientcasesheetDetails();
    }
  }

  getASGDET(Client_id, rel_id, sub_id) {
    var sen_pass;
    if (sub_id != null && sub_id != undefined && sub_id != '') {
      sen_pass = JSON.stringify({
        client_reg_id: Client_id,
        relation_id: "1",
        sub_rel_id: sub_id,
        country: ipaddress.country_code,
      });
    } else {
      sen_pass = JSON.stringify({
        client_reg_id: Client_id,
        relation_id: "1",
        country: ipaddress.country_code,
      });
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'gen/asgdet', sen_pass, {
        headers: headers,
      })
      .subscribe((response) => {
        var obj = response.json();
        Helper_Class.setAsg(obj);
      });
  }

  Get_gynaretrieval_data() {
    Helper_Class.setgynaRet(undefined);
    Helper_Class.setRet(undefined);

    var user_id = this.getUserId();
    var sendata = JSON.stringify({
      doc_reg_id: user_id,
      client_reg_id: Doc_Helper.getClient_id(),
      relation_id: "1",
      country: ipaddress.country_code,
    });

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'goi/chist', sendata, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          if (obj['key'] != 0) {
            if (obj != null && obj != undefined && obj != '') {
              Helper_Class.setgynaRet(obj);
              Helper_Class.setRet(obj);
              if (this.userinfo.user_type == 'nurse') {
                this.nurseservice.sendMessage('casesheet_list');
              } else {
                this.messageservice.sendMessage('casesheet_list');
              }
            }
          } else {
            this.getPatientCaseSheetFields(
              Doc_Helper.getClient_Info().hptl_clinic_id,
              Doc_Helper.getClient_Info().spl_id,
              Doc_Helper.getClient_Info().doc_app_id
            );
          }
        },
        (error) => { }
      );
  }

  getpatientcasesheetDetails() {
    Helper_Class.setRet(null);
    Helper_Class.setpediaRet(null);

    var user_id = this.getUserId();
    var send_data = JSON.stringify({
      doc_reg_id: user_id,
      client_reg_id: Doc_Helper.getClient_id(),
      relation_id: "1",
      country: 'IN',
    });

    if (this.Pediatrics_flag == true) {
      this.spl_url = 'pedia/chist';
    } else if (this.Cardio_flag == true) {
      this.spl_url = 'cardio/chist';
    } else if (this.Nephrology_flag == true) {
      this.spl_url = 'neph/chist';
    } else if (this.Dental_flag == true) {
      this.spl_url = 'dental/perdet/';
    } else if (this.Diabetes_flag == true) {
      this.spl_url = 'diab/chist';
    } else {
      this.spl_url = 'other/chist';
    }
    console.log("spl_url = " + this.spl_url)
    var headers = new Headers();

    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + this.spl_url, send_data, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log("get patient details case sheet = " + JSON.stringify(obj))
          if (obj['key'] != 0) {
            if (obj != null || obj != '' || obj != undefined) {
              Helper_Class.setRet(obj);
              if (this.Pediatrics_flag == true) {
                Helper_Class.setpediaRet(obj);
              }
              if (this.Nephrology_flag == true) {
                Helper_nephro_casesheet.nephro_get_ret = obj;
              }
              if (this.Dental_flag == true) {
                Helper_Class.Set_den_ret1(obj);
                this.Dental_Retrieval_2();
              }
              if (this.Dental_flag == false && this.Nephrology_flag == false) {
                if (Helper_Class.getInpatientFlow() == 'nurse') {
                  this.nurseservice.sendMessage('casesheet_list');
                } else {
                  this.messageservice.sendMessage('casesheet_list');
                }
              } else {
                this.getPatientCaseSheetFields(
                  this.hospital_id,
                  this.spl_id,
                  Doc_Helper.getApp_id()
                );
              }
            }
          } else {
            if (Doc_Helper.getCasesheet_flag() == '1') {
              this.getPatientCaseSheetFields(
                this.hospital_id,
                this.spl_id,
                Doc_Helper.getApp_id()
              );
            } else {
              this.getPatientCaseSheetFields(
                this.hospital_id,
                this.spl_id,
                Doc_Helper.getApp_id()
              );
            }
          }
        }, //response
        (error) => { }
      );
  }

  Dental_Retrieval_2() {
    var sen_pass = JSON.stringify({
      client_reg_id: Doc_Helper.getClient_id(),
      relation_id: "1",
      country: ipaddress.country_code,
      den_case_hist_id: Helper_Class.Get_den_ret1().den_case_hist_id,
    });

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'dental/patdet/', sen_pass, {
        headers: headers,
      })
      .subscribe((response) => {
        var obj = response.json();
        Helper_Class.Set_den_ret2(obj);
      });
  }

  getPatientCaseSheetFields(hptl_id, spl_id, doc_app_id) {
    var getFields = JSON.stringify({
      hosp_id: hptl_id,
      spl_id: spl_id,
    });

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/gpfhsp/', getFields, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();

          Helper_Class.setHopitalfieldsRet(obj.pages);
          this.get_case_sheet_data(Doc_Helper.getApp_id());
        },
        (error) => {
          this.toastr.error(Message_data.defaultErr);
        }
      );
  }

  get_case_sheet_data(doc_app_id) {
    Doc_Helper.setDischarge_Summary(undefined);
    var user_id = this.getUserId();

    var sen_pass = JSON.stringify({
      doc_reg_id: user_id,
      client_reg_id: Doc_Helper.getClient_id(),
      relation_id: "1",
      country: ipaddress.country_code,
    });

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'gen/cbook', sen_pass, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();

          if (obj['key'] != 0) {
            if (
              obj != null &&
              obj.length != 0 &&
              obj != undefined &&
              obj != ''
            ) {
              Doc_Helper.setDischarge_Summary('S');
              Helper_Class.setcasesumret(obj);
            } else {
              Helper_Class.setcasesumret(undefined);
            }
          } else {
            Helper_Class.setcasesumret(undefined);
          }

          if (this.Gynecology_flag == true) {
            const dialogRef = this.dialog.open(
              GynaObestricPopSelectionComponent,
              {
                width: '280px',
                height: '182px',
              }
            );
          } else {
            if (Helper_Class.getInpatientFlow() == 'nurse') {
              this.nurseservice.sendMessage('casesheet');
            } else {
              this.messageservice.sendMessage('casesheet');
            }
          }
        },
        (error) => {
          //this.toastr.error("Internet is too slow or no connection, try again");
        }
      );
  }

  changeGender(e) {
    if (this.clnt_age != undefined && this.clnt_age != null && this.clnt_age != '') {
      if (e == 'Male') {
        if (this.clnt_age <= 15)
          this.clnt_sal = '6';
        else
          this.clnt_sal = '1';

      } else if (e == 'Female') {
        if (this.clnt_age <= 15)
          this.clnt_sal = '7';
        else
          this.clnt_sal = '2';

      } else if (e == 'Transgender')
        this.clnt_sal = '5';
      else {
        this.clnt_sal = '';
      }
    } else {
      if (e == 'Male') {
        this.clnt_sal = '1';
      } else if (e == 'Female') {
        this.clnt_sal = '2';
      } else if (e == 'Transgender') this.clnt_sal = '5';
      else {
        this.clnt_sal = '';
      }
    }
  }

  print_area() {
    let printContents, popupWin;
    printContents = document.getElementById('inpatientInfoPrint')!.innerHTML;
    popupWin = window.open(
      '',
      '_blank',
      'top=20,left=10,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <link rel="stylesheet" media="screen" href="">
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">

        <style>
          @page { size: auto;  margin: 0mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          *{
            font-size:11px !important;
          }
          table.table td {
            padding: 0px 15px !important;
            border: 1px solid #000 !important;
            height: 30px !important;
            font-size: 12px !important;
          }
          .table th {
            height: 25px;
            color: #000;
            padding: 8px 0px;
            background: #fff;
            border: 1px solid #000 !important;
          }
          .align_left{
            width: 50%;
            padding-left: 10px;
          }
          .pRow{
            padding-left: 20px;
          }
          .rowCover{
            display: flex;
            flex-direction: column;
            width: 100%;
            padding:10px !important
          }
          .vitalLable{
           
            
            justify-content: space-between;
            align-items: center;
           
          }
        </style>
      </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }


  printIdCard() {
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == "template") {
      printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else {
      printContents = this.idprint.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    }
    popupWin = window.open(
      '',
      '_blank',
      'top=20,left=10,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`
              <head>
                <title>Inpatient</title>
                <link rel="stylesheet" media="screen" href="">
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
                <style>
                @page { size: auto;  margin: 0mm; }
                .table_class {
                  background-color: rgb(108, 180, 248);
                }
                
                </style>
              </head>
                  <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
                </html>`);
    popupWin.document.close();
  }
  getBedList(ward_id) {
    this.bedList = [];
    this.show_bed_details = this.bedList.length != 0 ? true : false;
    this.bed_no = undefined;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp + 'inpat/gbw',
        JSON.stringify({
          hptl_clinic_id: this.hospital_id,
          ward_id: ward_id,
        }),
        { headers: headers }
      )
      .subscribe((data) => {
        var obj = data.json();
        var flag = this.isEmptyObject(obj);
        console.log('falg --> ' + flag);
        if (obj != undefined && obj != null && obj != '' && !flag) {
          console.log('BedList ==> ' + JSON.stringify(obj));

          if (obj.bed_details.length != 0) {
            this.show_bed_details = obj.bed_details != 0 ? true : false;
            for (var i = 0; i < obj.bed_details.length; i++) {
              var pat_name,
                fontWeight = '',
                flag: boolean;
              pat_name =

                obj.bed_details[i].name == 'Vacant' ? 'red' : 'darkgreen';
              fontWeight = obj.bed_details[i].name == 'Vacant' ? '600' : '600';
              flag = obj.bed_details[i].name == 'Vacant' ? true : false;
              this.bedList.push({
                bed_no:
                  obj.bed_details[i].bed_no != undefined
                    ? obj.bed_details[i].bed_no
                    : '--',
                pat_name: obj.bed_details[i].name,
                bed:
                  obj.bed_details[i].bed_no + ' - ' + obj.bed_details[i].name,
                bed_img: '../../../assets/ui_icons/bed_icon.svg',
                color: pat_name,
                fontWeight: fontWeight,
                flag: flag,
              });
            }
          }
        } else {
          this.show_bed_details = this.bedList.length != 0 ? true : false;
        }
        console.log('this.bedList ==> ' + JSON.stringify(this.bedList));
      });
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  changeBed(e) {
    if (e.flag == true) {
      this.bed_no = e.bed_no;
      this.send_bed_no = e.bed_no;
    } else {
      this.toastr.error('Please select vacant bed');
    }
  }

  changestroke(e) {
    this.stroke1 = e;
    if (e == false) {
      this.doc_billable = '0';
    } else {
      this.doc_billable = '1';
    }
  }

  view_Medtracker() {
    Helper_Class.setapp_flowinpa('Inpatient')
    this.frontservice.sendMessage('medicineTracker');
  }

  getUserId() {
    var user_id = "";
    if (
      Helper_Class.getInpatientFlow() == 'front-desk' ||
      Helper_Class.getInpatientFlow() == 'nurse' || Helper_Class.getInpatientFlow() == 'Billing'
    ) {
      user_id = this.doc_id;

    } else {
      user_id = this.user_id;
    }
    return user_id;
  }

  change_type(id, value) {
    if (value == 'yes') {
      this.ynopt1 = true;
      this.Client_type = 'New';
      this.userTypeSelect('New');
    } else {
      this.ynopt1 = false;
      this.Client_type = 'Existing';
      this.userTypeSelect('Existing');
    }
  }
  
  changeSmoking(id,value) {
    if(value =='yes') {
      this.ynoptsmoke = true;
      this.inp_smoke = 'yes';
    } else {
      this.ynoptsmoke = false;
      this.inp_smoke = 'no';
    }
  }

  changeAlcohol(value) {
    if(value =='yes') {
      this.ynoptalc = true;
      this.inp_alcohol = 'yes';
    } else {
      this.ynoptalc = false;
      this.inp_alcohol = 'no';
    }
  }

  changeAllery(type, id, value) {
    if (type == 'main') {
      if (value == 'yes') {
        this.ynopt2 = true;
        this.allergy = 'yes';
        this.ynopt3 = false;
        this.ynopt4 = false;
        this.ynopt5 = false;
        this.drugDetailsFlag = false;
        this.foodDetailsFlag = false;
        this.engDetailsFlag = false;
        this.allergyChange('New');
      } else {
        this.drugDetailsFlag = false;
        this.foodDetailsFlag = false;
        this.engDetailsFlag = false;
        this.ynopt2 = false;
        this.allergy = 'no';
        this.ynopt3 = false;
        this.ynopt4 = false;
        this.ynopt5 = false;
        this.drug_details = "";
        this.env_details = "";
        this.food_details = "";
        this.allergyChange('Existing');
      }
    } else if (type == 'drug') {
      if (value == 'yes') {
        this.ynopt3 = true;
        this.drug_value = 'yes';
        this.drugDetailsFlag = true;
        this.allergyType('drug', 'Yes');
      } else {
        this.ynopt3 = false;
        this.drug_value = 'no';
        this.drugDetailsFlag = false;
        this.allergyType('drug', 'No');
      }
    } else if (type == 'evn') {
      if (value == 'yes') {
        this.ynopt4 = true;
        this.env_value = 'yes';
        this.engDetailsFlag = true;
        this.allergyType('evn', 'Yes');
      } else {
        this.ynopt4 = false;
        this.engDetailsFlag = false;
        this.env_value = 'no';
        this.allergyType('evn', 'No');
      }
    } else if (type == 'Diabetes') {
      if (value == 'yes') {
        this.ynopt2 = true;
        this.diabetes = 'yes';
        this.allergyType('Diabetes', 'Yes');
      } else {
        this.ynopt2 = false;
        this.diabetes = 'no';
        this.allergyType('Diabetes', 'No');
      }
    } else if (type == 'hyper') {
      if (value == 'yes') {
        this.ynopt3 = true;
        this.hyper = 'yes';
        this.allergyType('hyper', 'Yes');
      } else {
        this.ynopt3 = false;
        this.hyper = 'no';
        this.allergyType('hyper', 'No');
      }
    } else if (type == 'cad') {
      if (value == 'yes') {
        this.ynopt4 = true;
        this.cadvalue = 'yes';
        this.allergyType('cad', 'Yes');
      } else {
        this.ynopt4 = false;
        this.cadvalue = 'no';
        this.allergyType('cad', 'No');
      }
    } else if (type == 'cvd') {
      if (value == 'yes') {
        this.ynopt5 = true;
        this.cvdvalue = 'yes';
        this.allergyType('cvd', 'Yes');
      } else {
        this.ynopt5 = false;
        this.cvdvalue = 'no';
        this.allergyType('cvd', 'No');
      }
    }
    else if (type == 'tuber') {
      if (value == 'yes') {
        this.ynopt15 = true;
        this.tuber = 'yes';
        this.allergyType('tuber', 'Yes');
      } else {
        this.ynopt15 = false;
        this.tuber = 'no';
        this.allergyType('tuber', 'No');
      }
    }
    else if (type == 'CKD') {
      if (value == 'yes') {
        this.ynopt12 = true;
        this.ckdvalue = 'yes';
        this.allergyType('CKD', 'Yes');
      } else {
        this.ynopt12 = false;
        this.ckdvalue = 'no';
        this.allergyType('CKD', 'No');
      }
    } else if (type == 'Others') {
      if (value == 'yes') {
        this.ynopt16 = true;
        this.othervalue = 'yes';
        this.allergyType('others', 'Yes');
      } else {
        this.ynopt16 = false;
        this.othervalue = 'no';
        this.allergyType('others', 'No');
      }
    } else {
      if (value == 'yes') {
        this.ynopt5 = true;
        this.food_value = 'yes';
        this.foodDetailsFlag = true;
        this.allergyType('food', 'Yes');
      } else {
        this.ynopt5 = false;
        this.food_value = 'no';
        this.foodDetailsFlag = false;
        this.allergyType('food', 'No');
      }
    }
  }

  allergyType(type, value) {
    if (type == 'drug') {
      this.drugDetailsFlag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'evn') {
      this.engDetailsFlag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'Diabetes') {
      this.diabflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'hyper') {
      this.hyperflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'cad') {
      this.cadflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'cvd') {
      this.cvdflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'tuber') {
      this.tuberflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'evn') {
      this.engDetailsFlag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'CKD') {
      this.ckdflag = value.toLowerCase() == 'yes' ? true : false;
    } else if (type == 'Others') {
      this.ckdflag = value.toLowerCase() == 'yes' ? true : false;
    } else {
      this.foodDetailsFlag = value.toLowerCase() == 'yes' ? true : false;
    }
  }

  async getIdProofType() {
    if (Master_Helper.getMasterIdProofType() == undefined) {
      await this.masterData.getIdProofType();
    }
    var obj = Master_Helper.getMasterIdProofType();
    if (obj != undefined) {
      console.log('id proof ==> ' + JSON.stringify(obj));
      for (var i = 0; i < obj.id_proofs.length; i++) {
        this.idProofTypeList.push({
          proof_id: obj.id_proofs[i].proof_id,
          description: obj.id_proofs[i].description,
        });
      }
    }
  }

  compareById(object1: any, object2: any) {
    console.log('object1 ==> ' + JSON.stringify(object1));
    console.log('object2 ==> ' + JSON.stringify(object2));
    return object1 && object2 && object1.proof_id === object2.proof_id;
  }

  changeIdProofType(event) {
    this.idProofNoFlag = true;
    this.idProofId = event.proof_id;
    this.idProofDesc = event.description;
    this.idProofLabel = this.idProofDesc;

    if (this.idProofId == "4") {
      this.prooflength = "12";
      this.idProofNo = this.idproofcheck

    } else {
      this.prooflength = "15";
      this.idProofNo = ""
    }
  }

  getBedDetails() {
    if (Doc_Helper.getInpatientId() != undefined) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp + 'inpat/gbdinp',
          JSON.stringify({
            hptl_clinic_id: this.hospital_id,
            inpatient_id: Doc_Helper.getInpatientId(),
          }),
          { headers: headers }
        )
        .subscribe((data) => {
          this.bedOccList = [];
          var obj = data.json();
          if (obj.bed_details != undefined && obj.bed_details.length != 0) {
            for (var i = 0; i < obj.bed_details.length; i++) {
              var bdate = obj.bed_details[i].occup_date.split('-');
              this.bedOccList.push({
                ward: obj.bed_details[i].ward_name,
                bedno: obj.bed_details[i].bed_no,
                date: bdate[2] + '-' + bdate[1] + '-' + bdate[0],
                status: obj.bed_details[i].status,
              });
            }
          }
          this.show_bednos = this.bedOccList.length != 0 ? true : false;
        });
    }
  }

  removeDuplicates(arr) {
    var tmp: any = [];
    if (arr != undefined) {
      for (var i = 0; i < arr.length; i++) {
        if (tmp.indexOf(arr[i]) == -1) {
          tmp.push(arr[i]);
        }
      }
    }
    return tmp;
  }
  preHosp_change(value) {
    if (value == "no") {

      this.pre_hospflag = false;
    } else {
      this.isDisabled = true;
      this.pre_hospflag = true;
      this.pre_hospital_details = this.pre_hospital_details;
    }
  }

  diet_tracking() {
    this.messageservice.sendMessage('dietTracker');
  }

  addVitals() {
    var vital_flag = false;
    if (this.height_txt == "") {
      vital_flag = true;
      this.toastr.error("Enter Height")
    }
    if (this.weight_txt == "") {
      vital_flag = true;
      this.toastr.error("Enter Weight")
    }
    if (this.client_reg_id == "0") {
      vital_flag = true;
      this.toastr.error("USER SELECT")
    }

    if (this.createpage_flag == true) {
      if (this.clnt_fname == undefined) {
        this.toastr.error("Select patient")
        vital_flag = true;
      }

    } else {
      if (this.patient_name == undefined) {
        this.toastr.error("Select patient")
        vital_flag = true;
      }
    }
    if (this.vitals_List.length != 0) {
      this.saveVitals(vital_flag)

    } else {
      this.saveVitals(vital_flag)
    }
  }

  saveVitals(vital_flag) {
    var vital_flag1 = false; var databp, data_nounit;

    var is_bpAbn: boolean = false;
    var is_tempAbn: boolean = false;
    var is_spo2Abn: boolean = false;

    if (this.BP_01 != "" && this.BP_02 != "") {
      databp = this.BP_01 + " / " + this.BP_02 + " " + "mmHg";
      data_nounit = this.BP_01 + " / " + this.BP_02;

      if (parseInt(this.BP_01) > 160 || parseInt(this.BP_01) < 110 || parseInt(this.BP_02) > 90 || parseInt(this.BP_02) < 70) {
        is_bpAbn = true
      } else {
        is_bpAbn = false
      }
    }

    if (this.temp_txt != "" && this.temp_txt != undefined) {
      if (parseInt(this.temp_txt) > 100 || parseInt(this.temp_txt) < 96) {
        is_tempAbn = true;
      }
    }

    if (this.spo_txt != "" && this.spo_txt != undefined) {
      if (parseInt(this.spo_txt) < 95) {
        is_spo2Abn = true;
      }
    }

    this.currentTimeAMPM = moment().format("h:mm A");
    this.currentTime24Hr = moment().format("HH:mm");

    var send_data = {
      client_reg_id: this.client_reg_id,
      relation_id: "1",
      height: this.height_txt,
      height_measure: this.hmeasure,
      weight: this.weight_txt,
      weight_measure: this.wmeasure,
      bmi: this.bmi_txt,
      bmr: this.bmr,
      temparature: this.temp_txt + " " + this.temp_read,
      blood_pressure: data_nounit,
      rstxt: this.rs_text,
      rrtxt: this.rr_txt,
      pulse: this.pulse_txt,
      spo2: this.spo_txt,
      cns_txt: this.cns_txt,
      abd_txt: this.abd_txt,
      pr_txt: this.pr_txt,
      cvs: this.cvs_txt,
      cbg_txt: this.cbg_txt,
      date: (this.CurrentDatetime),
      time: this.currentTime24Hr,
      country: ipaddress.country_code.toString()
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'lifestyle/svt',
      JSON.stringify(send_data),
      { headers: headers }
    )
      .subscribe((data) => {
        if (this.spo2graph) {
          this.spo2graph.destroy();
        }
        if (this.bpgraph) {
          this.bpgraph.destroy();
        }
        if (this.pulsegrpah) {
          this.pulsegrpah.destroy();
        }
        this.bedOccList = [];
        var obj = data.json();
        this.bpvalue = "";
        this.currentTime = this.currentTimeAMPM
        if (obj.key == "1") {
          if (this.vitals_List.length != 0) {
            if (vital_flag == false) {
              var Sno = this.vitals_List.length + 1;
              if (this.BP_01 != "" && this.BP_02 != "") {
                this.bpvalue = this.BP_01 + " / " + this.BP_02
                this.bloodpressureky = this.BP_01 + " / " + this.BP_02 + " " + "mmHg"
              }
              else {
                this.bpvalue = " "
                this.bloodpressureky = " "
              }
              console.log('1 svt--' + JSON.stringify(obj))
              this.vitals_List.push({
                sNo: Sno,
                height: this.height_txt + " " + this.hmeasure,
                weight: this.weight_txt + " " + this.wmeasure,
                bmi: this.bmi_txt,
                bmr: this.bmr,
                temperature: this.temp_txt + " " + this.temp_read,
                pulse: this.pulse_txt,
                RR: this.rr_txt,
                bloodPressure: this.bpvalue,
                bpsys: this.BP_01,
                bpdia: this.BP_02,
                SPO2: this.spo_txt,
                Cns: this.cns_txt,
                ABD: this.abd_txt,
                PR: this.pr_txt,
                Cvs: this.cvs_txt,
                Rs: this.rs_text,
                cbg_txt: this.cbg_txt,
                bloodPressurekey: this.bloodpressureky,
                date: Date_Formate(this.CurrentDatetime),
                time: (this.currentTime),
                is_bpAbnormal: is_bpAbn,
                is_tempAbnormal: is_tempAbn,
                is_spo2Abnormal: is_spo2Abn
              })

            } else {
              console.log('2 *8--' + JSON.stringify(obj))
              if (vital_flag == false) {
                if (this.BP_01 != "" && this.BP_02 != "") {
                  this.bpvalue = this.BP_01 + " / " + this.BP_02 + " " + "mmHg"
                  this.bloodpressureky = this.BP_01 + " / " + this.BP_02 + " " + "mmHg"
                }
                else {
                  this.bpvalue = " "
                  this.bloodpressureky = " "
                }
                var Sno = this.vitals_List.length + 1;
                console.log('2 svt--' + JSON.stringify(obj))
                this.vitals_List.push({
                  sNo: Sno,
                  height: this.height_txt + " " + this.hmeasure,
                  weight: this.weight_txt + " " + this.wmeasure,
                  bmi: this.bmi_txt,
                  bmr: this.bmr,
                  temperature: this.temp_txt + " " + this.temp_read,
                  pulse: this.pulse_txt,
                  RR: this.rr_txt,
                  bloodPressure: this.bpvalue,
                  bpsys: this.BP_01,
                  bpdia: this.BP_02,
                  SPO2: this.spo_txt,
                  Cns: this.cns_txt,
                  ABD: this.abd_txt,
                  PR: this.pr_txt,
                  Cvs: this.cvs_txt,
                  Rs: this.rs_text,
                  cbg_txt: this.cbg_txt,
                  bloodPressurekey: this.bloodpressureky,
                  date: Date_Formate(this.CurrentDatetime),
                  time: (this.currentTime),
                  is_bpAbnormal: is_bpAbn,
                  is_tempAbnormal: is_tempAbn,
                  is_spo2Abnormal: is_spo2Abn
                })
              }
            }

          } else {
            if (vital_flag == false) {
              if (this.BP_01 != "" && this.BP_02 != "") {
                this.bpvalue = this.BP_01 + " / " + this.BP_02 + " " + "mmHg"
                this.bloodpressureky = this.BP_01 + " / " + this.BP_02 + " " + "mmHg"
              } else {
                this.bpvalue = " "
                this.bloodpressureky = " "
              }
              console.log('3 svt--' + JSON.stringify(obj))
              var Sno = this.vitals_List.length + 1;
              this.vitals_List.push({
                sNo: Sno,
                height: this.height_txt + " " + this.hmeasure,
                weight: this.weight_txt + " " + this.wmeasure,
                bmi: this.bmi_txt,
                bmr: this.bmr,
                temperature: this.temp_txt + " " + this.temp_read,
                pulse: this.pulse_txt,
                RR: this.rr_txt,
                bloodPressure: this.bpvalue,
                bpsys: this.BP_01,
                bpdia: this.BP_02,
                SPO2: this.spo_txt,
                Cns: this.cns_txt,
                ABD: this.abd_txt,
                PR: this.pr_txt,
                Cvs: this.cvs_txt,
                Rs: this.rs_text,
                cbg_txt: this.cbg_txt,
                bloodPressurekey: this.bloodpressureky,
                date: Date_Formate(this.CurrentDatetime),
                time: (this.currentTime),
                is_bpAbnormal: is_bpAbn,
                is_tempAbnormal: is_tempAbn,
                is_spo2Abnormal: is_spo2Abn
              })

            }
          }
          this.height_txt = "";
          this.weight_txt = "";
          this.bmi_txt = "";
          this.temp_txt = "";
          this.spo_txt = "";
          this.pulse_txt = "";
          this.cvs_txt = "";
          this.rstxt = "";
          this.cns_txt = "";
          this.abd_txt = "";
          this.rstxt = "";
          this.cns_txt = "";
          this.abd_txt = "";
          this.cns_txt = "";
          this.abd_txt = "";
          this.rr_txt = "";
          this.pr_txt = "";
          this.rs_text = "";
          this.cbg_txt = ""

          if (this.vitals_List.length != 0) {
            this.spo2array = [];
            this.pulse_xaxis = [];
            this.pulsearray = [];
            this.spo2_xaxis = [];
            this.systolearray = [];
            this.diastolearray = [];
            this.bparray_xaxis = [];
            for (var i = 0; i < this.vitals_List.length; i++) {
              if (this.vitals_List[i].SPO2 != undefined) {
                var datecheck = (this.vitals_List[i].date + " " + this.currentTimeAMPM)
                this.spo2_xaxis.push(datecheck);
                this.spo2array.push(this.vitals_List[i].SPO2);
              }
              if (this.vitals_List[i].PR != undefined) {
                var datecheck = (this.vitals_List[i].date + " " + this.currentTimeAMPM)
                this.pulse_xaxis.push(datecheck);
                this.pulsearray.push(this.vitals_List[i].PR);
              }

              if (this.vitals_List[i].bpsys != undefined && this.vitals_List[i].bpsys != undefined) {
                if (this.vitals_List[i].bpsys != "" && this.vitals_List[i].bpsys != "") {
                  var datecheck = (this.vitals_List[i].date + " " + this.currentTimeAMPM)
                  this.systolearray.push(this.vitals_List[i].bpsys);
                  this.diastolearray.push(this.vitals_List[i].bpdia);
                  this.bparray_xaxis.push(datecheck);
                }
              }
            }

            if (this.spo2_xaxis.length != 0 && this.spo2array.length != 0) {
              this.spo2graphflag = true;
              setTimeout(() => {
                this.linechart_spo2(this.spo2_xaxis, this.spo2array, "spo2")
              }, 100);

            } else {
              this.spo2graphflag = false;
            }
            if (this.pulse_xaxis.length != 0 && this.pulsearray.length != 0) {
              this.pulseflag = true;
              setTimeout(() => {
                this.linechartpulse(this.pulse_xaxis, this.pulsearray, "pulse")
              }, 200);
            }
            else {
              this.pulseflag = false;
            }
            if (this.systolearray.length != 0 && this.diastolearray.length != 0) {
              this.bpgraphflag = true;
              setTimeout(() => {
                this.linechartsys(this.systolearray, this.diastolearray, this.bparray_xaxis)
              }, 300);
            } else {
              this.bpgraphflag = false;
            }
          }

        } else {
          this.toastr.error("Error occured while saving data")
        }
      });
  }


  getOtBooking() {
    Helper_Class.set_Otdata("Inpat_flow")
    this.messageservice.sendMessage('OTBooking');
  }

  datalist() {
    var fname, mname, lname, mnumber;
    this.inpatientId = undefined;
    if (
      this.clnt_fname != undefined &&
      this.clnt_fname != undefined &&
      this.clnt_fname != null
    ) {
      fname = encrypt_decript.Encript(this.clnt_fname).toString();
    }
    if (
      this.clnt_mname != '' &&
      this.clnt_mname != undefined &&
      this.clnt_mname != null
    ) {
      mname = encrypt_decript.Encript(this.clnt_mname).toString();
    }
    if (
      this.clnt_lname != undefined &&
      this.clnt_lname != undefined &&
      this.clnt_lname != null
    ) {
      lname = encrypt_decript.Encript(this.clnt_lname).toString();
    }

    if (this.clnt_cont_number != null) {
      mnumber = encrypt_decript.Encript(this.clnt_cont_number).toString();
    }

    var senddata;
    if (this.nurse_flow == true) {
      senddata = JSON.stringify({
        inpat_id: Doc_Helper.getInpatientId(),
        mobile: mnumber,
        client_reg_id: this.client_reg_id,
        fname: fname,
        mname: mname,
        lname: lname,
        country: 'IN',
        nurse_flow: this.nurse_flow
      });

    } else {
      senddata = JSON.stringify({
        inpat_id: Doc_Helper.getInpatientId(),
        mobile: mnumber,
        fname: fname,
        mname: mname,
        lname: lname,
        country: 'IN',
        hptl_id: this.hospital_id,
        type: "Existing",
        is_doc: 1,
        nurse_flow: this.nurse_flow
      });
    }
    console.log("this.user_details", JSON.stringify(senddata));

  }

  diostol(value) {
    if (value > 90 || value < 70) {
      this.diocolorstyle = true;
    } else {
      this.diocolorstyle = false;
    }
  }


  sistol(value) {
    if (value > 160 || value < 110) {
      this.siscolorstyle = true;
    } else {

      this.siscolorstyle = false;
    }
  }

  spo2(value) {
    if (value < 95) {
      this.spo2colorstyle = true;
    } else {
      this.spo2colorstyle = false;
    }
  }

  tempcolor(value) {
    if (this.temp_read === '°C') {
      // Temperature is in Celsius
      // if (value > 32 || value < 21) { // Convert 90°F to 32°C and 70°F to 21°C
      //   this.colorstyle = true;
      // } else {
      //   this.colorstyle = false;
      // }
    } else if (this.temp_read === 'F') {
      // Temperature is in Fahrenheit
      if (value > 100 || value < 96) {
        this.colorstyle = true;
      } else {
        this.colorstyle = false;
      }
    }
  }

  settempcolor(temprature) {
    if (temprature > 100) {
      this.tempcolorstyle = true;
    } else {
      this.tempcolorstyle = false;
    }
  }

  reading_Graph() {
    var ctx = <HTMLCanvasElement>document.getElementById("Daygraph");
    var data = [
      { x: new Date('2022-02-27T10:00:00'), y: 10 },
      { x: new Date('2022-02-27T11:00:00'), y: 20 },
      { x: new Date('2022-02-27T12:00:00'), y: 15 },
      { x: new Date('2022-02-27T13:00:00'), y: 25 },
      { x: new Date('2022-02-27T14:00:00'), y: 30 },
      { x: new Date('2022-02-27T15:00:00'), y: 35 }
    ];

    this.options = {
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'hour',
            displayFormats: {
              hour: 'MMM D, hA'
            }
          },
          title: {
            display: true,
            text: 'Time'
          }
        },
        y: {
          title: {
            display: true,
            text: 'Value'
          }
        }
      }
    };

    // Then in your chart configuration
    var timeChart = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [{
          label: 'Time-based Data',
          data: data,
          borderColor: 'blue',
          fill: false
        }]
      },
      options: this.options
    });

  }

  getChargeTypes(e, value) {
    this.chargeList = [];
    var send_data;

    if (value == "chgtype") {
      this.charge_desc = e.target.value.toString();
      console.log('this.charge_desc' + JSON.stringify(this.charge_desc));
      if (this.charge_desc != undefined && this.charge_desc.length > 2) {
        send_data = {
          hptl_clinic_id: this.userinfo.hospitals[0].hptl_clinic_id,
          provider: "doctor",
          type: "in-patient",
          charge_name: this.charge_desc,
        }
        this.chargeList = [];
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'bill/gchgtypes/',
          JSON.stringify(send_data), { headers: headers })
          .subscribe(
            (response) => {
              var charge_base;
              var obj = JSON.parse(response['_body']);
              console.log("objobjobjobj chargetype if" + JSON.stringify(obj));
              this.chargeList = [];

              for (var i = 0; i < obj.charges.length; i++) {
                if (obj.charges[i].charge_base === "1")
                  charge_base = "Day";
                else if (obj.charges[i].charge_base === "2")
                  charge_base = "Half day";
                else if (obj.charges[i].charge_base === "3")
                  charge_base = "Hourly";
                else if (obj.charges[i].charge_base === "4")
                  charge_base = "Count";

                this.chargeList.push({
                  charge_id: obj.charges[i].charge_id,
                  charge_cat: obj.charges[i].charge_cat,
                  charge_desc: obj.charges[i].charge_desc,
                  charge_rate: obj.charges[i].amount,
                  department: obj.charges[i].department,
                  department_id: obj.charges[i].department_id,
                  ward_id: obj.charges[i].ward_id,
                  ward_name: obj.charges[i].ward_name,
                  charge_base: obj.charges[i].charge_base,
                });
              }
            },
            (error) => {
              this.toastr.error(Message_data.network);
            }
          );
      }

    } else {
      this.charge_desc = value;
      if (this.charge_desc != undefined) {
        send_data = {
          hptl_clinic_id: this.userinfo.hospitals[0].hptl_clinic_id,
          provider: "doctor",
          charge_name: 'consult',
          ward: this.ward_id
        }

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http
          .post(
            ipaddress.getIp.toString() + 'bill/gchgtypes/', JSON.stringify(send_data),
            { headers: headers }
          )
          .subscribe(
            (response) => {
              var charge_base;
              var obj = JSON.parse(response['_body']);
              this.doc_visit_chargeList = [];
              if (obj.charges != undefined) {
                for (var i = 0; i < obj.charges.length; i++) {
                  if (obj.charges[i].charge_base === "1")
                    charge_base = "Day";
                  else if (obj.charges[i].charge_base === "2")
                    charge_base = "Half day";
                  else if (obj.charges[i].charge_base === "3")
                    charge_base = "Hourly";
                  else if (obj.charges[i].charge_base === "4")
                    charge_base = "Count";
                  this.doc_visit_chargeList.push({
                    charge_id: obj.charges[i].charge_id,
                    charge_cat: obj.charges[i].charge_cat,
                    charge_desc: obj.charges[i].charge_desc,
                    charge_rate: obj.charges[i].amount,
                    department: obj.charges[i].department,
                    department_id: obj.charges[i].department_id,
                    ward_id: obj.charges[i].ward_id,
                    ward_name: obj.charges[i].ward_name,
                    charge_base: obj.charges[i].charge_base,
                  });
                }
              }

              this.glucose_charge_id = obj.glcharge_id;
            },
            (error) => {
              this.toastr.error(Message_data.network);
            }
          );
      }
    }
  }

  selectitem(list) {
    var timeflag = false
    this.datacheck = this.chargeList.filter(department => department.charge_id == list.charge_id)
    this.chargeid = this.datacheck[0].charge_id;
    this.chargedescription = this.datacheck[0].charge_desc;
    this.chargebase = list.charge_base;
    this.f_date = this.admission_date;
    this.fromdatetime = this.admission_date;

    if (this.chargebase == "1") {
      this.timerequired = false
      this.calculateTotalHours();
    } if (this.chargebase == "2") {
      this.timerequired = true
      if (this.f_time1 && this.to_time1 && this.sesionselction1 && this.f_time && this.to_time && this.sesionselction) {
        timeflag = true;
        
      } else {
        timeflag = false;
        this.toastr.error(Message_data.mandatory)
      }
      if (timeflag == true) {
        this.calculateTotalHours();
      }
    }

    if (this.chargebase == "3") {
      this.timerequired = true;
      if (this.f_time1 && this.to_time1 && this.sesionselction1 && this.f_time && this.to_time && this.sesionselction) {
        timeflag = true;
        
      } else {
        timeflag = false;
        this.toastr.error(Message_data.mandatory)
      }
      if (timeflag == true) {
        this.calculateTotalHours();
      }

    } else {
      this.timerequired = false;
      this.calculateTotalHours();
    }
  }

  OnDateChangeddate(e) {
    this.admission_date = e;
  }

  OnDateChangedfrom(e) {
    this.f_date = e;
    this.f_date_flag = true;
    var selectedDate = moment(this.f_date);
    var currentDate = moment(this.admission_date);
    this.startTime1 = moment(`${this.f_time1}:${this.to_time1} ${this.sesionselction1}`, 'h:mm A');
    const startTime = this.admission_time;
    const selectedTime = moment(this.startTime1, "h:mm A");
    const startTimeMoment = moment(this.admission_time, "h:mm A");

    if (selectedDate.isBefore(currentDate)) {
      this.toastr.error('From date cannot be before the admission date.');

    } else {
      console.log('Selected date is valid');
      this.calculateTotalHours();
    }
  }

  OnDateChangedto(e) {
    this.t_date = e;
    this.t_date_flag = true;
    var fromdate = moment(this.f_date, 'YYYY-MM-DD');
    var todate = moment(this.t_date, 'YYYY-MM-DD');
    if (todate.isBefore(fromdate)) {
      this.toastr.error("Selected to date is earlier than from date");
      this.t_date = ""

    } else {
      this.t_date = e;
      this.t_date_flag = true;
      this.calculateTotalHours();
    }
  }

  calculateTotalHours(): void {
    var changeformat_endtime: any;
    var changeformat_starttime: any;
    var starttimeperiod: any;
    var endtimeperiod: any;
    var fDate = moment(this.f_date, 'YYYY-MM-DD');
    var tDate = moment(this.t_date, 'YYYY-MM-DD');
    var today = moment();
    var flag = true;
    if (this.t_date == " ") {
      flag = false;
      this.toastr.error("Select to date")
    }

    if (this.t_date == " ") {
      flag = false;
      this.toastr.error("Select to date")
    }

    if (tDate.isBefore(fDate)) {
      this.toastr.error('Target date cannot be before the start date.');
      flag = false;
    } else if (tDate.isAfter(today)) {
      this.toastr.error('Target date cannot be a future date.');
      flag = false;
    }
    var selectedDate = moment(this.f_date);
    var currentDate = moment(this.admission_date);
    var time_check = moment(`${this.f_time1}:${this.to_time1} ${this.sesionselction1}`, 'h:mm A');
    if (selectedDate.isBefore(currentDate)) {
      this.toastr.error('Selected date is in the past');

    } else if (selectedDate.isSame(currentDate)) {
      const selectedTime = time_check;
      const startTimeMoment = moment(this.admission_time, "h:mm A");

      if (selectedTime.isBefore(startTimeMoment)) {
        this.toastr.error('Selected time must be after the Admission time');
        flag = false
      }
    }

    if (flag == true) {
      if (this.f_time1 && this.to_time1 && this.sesionselction1 && this.f_time && this.to_time && this.sesionselction) {
        this.startTime1 = moment(`${this.f_time1}:${this.to_time1} ${this.sesionselction1}`, 'h:mm A');
        this.endTime1 = moment(`${this.f_time}:${this.to_time} ${this.sesionselction}`, 'h:mm A');
        this.totalHours1 = this.endTime1.diff(this.startTime1, 'hours');
        this.startMoment = moment(this.startTime1, 'h:mm A');
        this.endMoment = moment(this.endTime1, 'h:mm A');
        this.duration = moment.duration(this.endMoment.diff(this.startMoment));
        console.log("KK" + this.startMoment + this.endMoment)

        if (this.datacheck != undefined)
          this.chargedescription = this.datacheck[0].description;
        var datacheck = this.datacheck;
        var converttmeformat_starttime = moment(this.f_time1 + ":" + this.to_time1 + " " + this.sesionselction1, 'h:mm A')
        changeformat_starttime = converttmeformat_starttime.format("HH:mm")
        var converttmeformat_endtime = moment(this.f_time + ":" + this.to_time + " " + this.sesionselction, 'h:mm A')
        changeformat_endtime = converttmeformat_endtime.format("HH:mm")
        this.data1 = changeformat_starttime;
        this.data2 = changeformat_endtime;
        var check = Time_Formate(changeformat_starttime)
        var check1 = check.split(":")
        this.f_time1 = check1[0];
        var endtime = Time_Formate(changeformat_endtime)
        var endtimevalue = endtime.split(":")
        this.f_time = endtimevalue[0];
        var startdate = this.f_date.split('-');
        this.dateval = moment(
          startdate[2] + '/' + startdate[1] + '/' + startdate[0] + ' ' + changeformat_starttime,
          'DD/MM/YYYY HH:mm'
        );

        var enddate = this.t_date.split('-');
        var curdateval = moment(
          enddate[2] + '/' + enddate[1] + '/' + enddate[0] + ' ' + changeformat_endtime,
          'DD/MM/YYYY HH:mm'
        );

        if (datacheck != undefined && datacheck.length != 0) {
          for (var i = 0; i < datacheck.length; i++) {
            if (datacheck[i].charge_base != undefined && datacheck[i].charge_base != "") {
              if (datacheck[i].charge_base === '1') {
                var hourdif = (moment(curdateval).diff(this.dateval, 'hours') / 24).toFixed(2).split('.');
                this.DayHour = parseInt(hourdif[0]);
                var remhour = Math.round(parseInt(hourdif[1]) / 24);
                if (remhour > 12) {
                  this.quantity = this.DayHour + 1;
                } else {
                  this.quantity = this.DayHour;
                }
                if (this.quantity == 0) {
                  this.quantity = 1
                }

              } else if (datacheck[i].charge_base === '2') {
                var hourdif = (moment(curdateval).diff(this.dateval, 'hours') / 12).toFixed(2).split('.');
                this.HalfDayHour = parseInt(hourdif[0]);
                var remhour = Math.round(parseInt(hourdif[1]) / 12);
                if (remhour > 6) {
                  this.quantity = this.HalfDayHour + 0.5;
                } else {
                  this.quantity = this.HalfDayHour;
                }
                if (this.quantity == 0) {
                  this.quantity = 1
                }

              } else if (datacheck[i].charge_base === '3') {
                var hourdif = (moment(curdateval, "DD/MM/YYYY HH:mm").diff(moment(this.dateval, "DD/MM/YYYY HH:mm"), 'minutes')).toFixed(2).split('.');
                var totalBulkMinutes = parseInt(hourdif[0]);
                this.HourlyHour = (totalBulkMinutes / 60);
                this.HourlyHour = Math.round(this.HourlyHour);
                var remainingMinutes = totalBulkMinutes % 60;
                if (remainingMinutes >= 5) {
                  this.quantity = this.HourlyHour + 1;
                } else {
                  this.quantity = this.HourlyHour;
                }
                
                if (this.quantity == 0) {
                  this.quantity = 1
                }

              } else if (datacheck[i].charge_base === '4') {
                this.quantity = 1;
              }

              if (this.quantity == 0) {
                this.quantity = 1
              }

            } else {
              this.quantity = 1;
            }
          }
        }

      } else {
        if (this.f_time1 && this.to_time1 && this.sesionselction1) {
          console.log("ok1")
          this.startTime1 = moment(`${this.f_time1}:${this.to_time1} ${this.sesionselction1}`, 'h:mm A');
          this.endTime1 = moment().format('HH:mm');
          var time = (Time_Formate(this.endTime1))
          var time1 = time.split(" ")
          this.sesionselction = time1[1]
          var time2 = time1[0].split(":")
          this.f_time = time2[0];
          this.to_time = time2[1];
          var converttmeformat_starttime = moment(this.f_time1 + ":" + this.to_time1 + " " + this.sesionselction1, 'h:mm A')
          changeformat_starttime = converttmeformat_starttime.format("HH:mm")
          changeformat_endtime = moment().format('HH:mm')
          starttimeperiod = this.startTime1
          endtimeperiod = moment().format('h:mm A')
          this.data1 = moment(this.f_time1 + ":" + this.to_time1 + " " + this.sesionselction1, 'h:mm A');
          this.data2 = endtimeperiod;
          var data_start = starttimeperiod.split(" ")
          var data = endtimeperiod.split(" ")
          var split_start = data_start[0].split(":")
          var split_end = data[0].split(":")
          this.f_time1 = split_start[0]
          this.to_time1 = split_start[1]
          this.sesionselction1 = data_start[1];
          this.f_time = split_end[0];
          this.to_time = split_end[1];
          this.sesionselction = data[1];

        } else if (this.f_time && this.to_time && this.sesionselction) {
          this.startTime1 = moment().format('HH:mm');
          this.endTime1 = moment(`${this.f_time}:${this.to_time} ${this.sesionselction}`, 'h:mm A');
          this.endTime1 = moment().format('HH:mm');
          changeformat_starttime = this.startTime1
          var converttmeformat_endtime = moment(this.f_time + ":" + this.to_time + " " + this.sesionselction, 'h:mm A')
          changeformat_endtime = converttmeformat_endtime.format("HH:mm")
          starttimeperiod = moment().format('h:mm A')
          endtimeperiod = moment(`${this.f_time}:${this.to_time} ${this.sesionselction}`, 'h:mm A')
          this.data1 = starttimeperiod;
          this.data2 = endtimeperiod;
          var data_start = starttimeperiod.split(" ")
          var data = endtimeperiod.split(" ")
          var split_start = data_start[0].split(":")
          var split_end = data[0].split(":")
          this.f_time1 = split_start[0]
          this.to_time1 = split_start[1]
          this.sesionselction1 = data_start[1];
          this.f_time = split_end[0];
          this.to_time = split_end[1];
          this.sesionselction = data[1];

        } else {
          this.startTime1 = moment().format('HH:mm');
          this.endTime1 = moment().format('HH:mm');
          changeformat_starttime = this.startTime1;
          changeformat_endtime = this.endTime1;
          starttimeperiod = moment().format('h:mm A')
          endtimeperiod = moment().format('h:mm A')
          this.data1 = starttimeperiod;
          this.data2 = endtimeperiod;
          var data_start = starttimeperiod.split(" ")
          var data = endtimeperiod.split(" ")
          var split_start = data_start[0].split(":")
          var split_end = data[0].split(":")
          this.f_time1 = split_start[0]
          this.to_time1 = split_start[1]
          this.sesionselction1 = data_start[1];
          this.f_time = split_end[0];
          this.to_time = split_end[1];
          this.sesionselction = data[1];
        }

        var startdate = this.f_date.split('-');
        this.dateval = moment(
          startdate[2] + '/' + startdate[1] + '/' + startdate[0] + ' ' + changeformat_starttime,
          'DD/MM/YYYY HH:mm'
        );

        var enddate = this.t_date.split('-');
        var curdateval = moment(
          enddate[2] + '/' + enddate[1] + '/' + enddate[0] + ' ' + changeformat_endtime, 'DD/MM/YYYY HH:mm'
        );

        if (this.datacheck != undefined)
          this.chargedescription = this.datacheck[0].description;

        var datacheck = this.datacheck;
        if (datacheck != undefined && datacheck.length != 0) {
          for (var i = 0; i < datacheck.length; i++) {
            if (datacheck[i].charge_base != undefined && datacheck[i].charge_base != "") {
              if (datacheck[i].charge_base === '1') {
                var hourdif = (moment(curdateval).diff(this.dateval, 'hours') / 24).toFixed(2).split('.');
                this.DayHour = parseInt(hourdif[0]);
                var remhour = Math.round(parseInt(hourdif[1]) / 24);
                if (remhour > 12) {
                  this.quantity = this.DayHour + 1;
                } else {
                  this.quantity = this.DayHour;
                }
                if (this.quantity == 0) {
                  this.quantity = 1
                }
                this.timerequired = false;

              } else if (datacheck[i].charge_base === '2') {
                var hourdif = (moment(curdateval).diff(this.dateval, 'hours') / 12).toFixed(2).split('.');
                this.HalfDayHour = parseInt(hourdif[0]);
                var remhour = Math.round(parseInt(hourdif[1]) / 12);
                if (remhour > 6) {
                  this.quantity = this.HalfDayHour + 0.5;
                } else {
                  this.quantity = this.HalfDayHour;
                }
                if (this.quantity == 0) {
                  this.quantity = 1
                }
                this.timerequired = true;

              } else if (datacheck[i].charge_base === '3') {
                var hourdif = (moment(curdateval, "DD/MM/YYYY HH:mm").diff(moment(this.dateval, "DD/MM/YYYY HH:mm"), 'minutes')).toFixed(2).split('.');
                var totalBulkMinutes = parseInt(hourdif[0]);
                this.HourlyHour = (totalBulkMinutes / 60);
                this.HourlyHour = Math.round(this.HourlyHour);
                var remainingMinutes = totalBulkMinutes % 60;
                if (remainingMinutes >= 5) {
                  this.quantity = this.HourlyHour + 1;
                } else {
                  this.quantity = this.HourlyHour;
                }
                if (this.quantity == 0) {
                  this.quantity = 1
                }
                this.timerequired = true;

              } else if (datacheck[i].charge_base === '4') {
                this.timerequired = false;
                this.quantity = 1;
              }
              if (this.quantity == 0) {
                this.quantity = 1
              }
            } else {
              this.quantity = 1;
            }
          }
        }
      }
    }
  }

  addothercharges() {
    var flag = true;
    if (this.chargeid == undefined) {
      this.toastr.error("Enter Chargetype");
      flag = false;
    } else {
      if (this.f_date == " ") {
        this.toastr.error("Enter from date");
        flag = false;
      }
      if (!this.t_date) {
        this.toastr.error("Enter to date");
        flag = false;
      }
    }
    if (this.chargeid != undefined && this.t_date != "") {
      if (flag == true) {
        var sendadata = {
          inpatient_id: Doc_Helper.getInpatientId(),
          country: ipaddress.country_code,
          created_by: this.userinfo.user_type,
          bill_charge_id: this.chargeid,
          start_time: this.data1,
          end_time: this.data2,
          quantity: this.quantity,
          from_date: this.f_date,
          to_date: this.t_date,
          pat_type:"emergency"
        }
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http
          .post(
            ipaddress.getIp.toString() + 'inpat/sost',
            JSON.stringify(sendadata),
            { headers: headers }
          )
          .subscribe((data) => {
            var obj = data.json();
            console.log(obj);
            if (obj != undefined) {
              if (obj.key == '1') {
                this.toastr.success(obj.result);
                this.getRetrivalothercharges();
                this.quantity = 1;
                this.f_time = "";
                this.to_time = "";
                this.sesionselction1 = "";
                this.sesionselction = "";
                this.f_time1 = "";
                this.to_time1 = ""
                this.chargetypevalue = ""
                this.chargeList = [];
                
              } else {
                this.toastr.error('Unable to update visit details, try later');
              }
            }
          });
      }
    }
  }

  convertToAmPm(time: string): string {
    // Check if the time already includes AM/PM
    if (time.includes('AM') || time.includes('PM')) {
      // If it does, return it as it is
      return time;
    } else {
      // Otherwise, convert it using moment.js
      return moment(time, 'HH:mm').format('hh:mm A');
    }
  }

  changeglucoselevel(select) {
    if (select != "Random") {
      this.randomflag = false;
      if (select == "beforefood") {
        this.beforefood_flag = true;
        this.Fasting_Halfhour = this.Fasting_Halfhour;
        if (this.glucose_charge_id == undefined) {
          this.getChargeTypes("Capillary blood glucose", 'CBG fasting')
        }

      } else {
        this.Postprandial = this.Postprandial;
        this.beforefood_flag = false;
        if (this.glucose_charge_id == undefined) {
          this.getChargeTypes("Capillary blood glucose", 'CBG postprandial')
        }
      }

    } else {
      this.randomflag = true;
      if (this.glucose_charge_id == undefined) {
        this.getChargeTypes("Capillary blood glucose", 'CBG random')
      }
    }
  }

  save_reading() {
    var providername;
    if (this.userinfo.middle_name != undefined) {
      providername = this.userinfo.first_name + ' ' + this.userinfo.middle_name + ' ' + this.userinfo.last_name;
    } else {
      if (this.userinfo.last_name != undefined) {
        providername = this.userinfo.first_name + ' ' + this.userinfo.last_name;
      } else {
        providername = this.userinfo.first_name;
      }
    }

    var flag = true; var senddata;
    if (this.randomflag == false) {
      if (this.beforefood_flag == true) {
        if (this.Fasting_Halfhour == "") {
          flag = false;
          this.toastr.error("Enter mandatory fields")
        }

      } else {
        if (this.Postprandial == "") {
          flag = false;
          this.toastr.error("Enter mandatory fields")
        }
      }

    } else {
      if (this.random == "") {
        flag = false;
        this.toastr.error("Enter mandatory fields")
      }
    }

    if (flag == true) {
      senddata = {
        client_reg_id: this.client_reg_id,
        created_date: this.currentDate,
        created_time: this.currentTime,
        session: this.visitSession,
        relation_id: "1",
        random: this.random,
        life_style_id: this.life_style_id,
        country: "IN",
        glu_charge_id: this.glucose_charge_id,
        inpatient_id: this.inpatientId,
        created_by: providername,
        quantity: "1"
      };

      if (this.randomflag == false) {
        if (this.beforefood_flag == true) {
          senddata.fasting = this.Fasting_Halfhour
        } else {
          senddata.twohours = this.Postprandial
        }
      } else {
        senddata.random = this.random
      }
      console.log("read data " + JSON.stringify(senddata))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json')
      this.http.post(ipaddress.getIp.toString() + 'lifestyle/sgr', JSON.stringify(senddata), {
        headers: headers
      }).subscribe((data) => {
        var obj = data.json();
        console.log("read data " + JSON.stringify(obj))
        if (obj.key == "1") {
          this.bloodgluarray.push({
            fasting: this.Fasting_Halfhour,
            onehour: this.Fasting_Onehour,
            onehalfhour: this.Fasting_Onehalfhour,
            twohours: this.Postprandial,
            HbA1c: this.HbA1c,
            created_date: this.currentDate,
            created_time: this.currentTime,
            session: this.visitSession,
          })
        }

        if (obj != undefined) {
          if (obj.key == '1') {
            this.toastr.success(obj.result);
            this.getreadings("0");
          } else {
            this.toastr.error(obj.result);
            this.bloodglucoseflag = false;
          }
        }
      })
    }
  }

  async getreadings(range) {
    var sen_pass;
    this.bloodglucoseflag = true;
    if (range = "183") {
      sen_pass = {
        life_style_id: this.life_style_id,
        interval: range,
        country: ipaddress.country_code,
      }
      this.diaburl = "lifestyle/ggr"

    } else {
      if (this.life_style_id != null) {
        sen_pass = {
          life_style_id: this.life_style_id
        }
        this.diaburl = "lifestyle/ggr"
      }
    }

    if (this.life_style_id != null) {
      this.xAxis = [];
      this.fastinglevel = [];
      this.postprandiallevel = [];
      this.randomlevel = []
      console.log("sen_pass" + JSON.stringify(sen_pass))
      var response = await this.serviceAPI.PostData(this.diaburl, sen_pass).toPromise();
      if (response) {
        var obj = JSON.parse(JSON.stringify(response));
        
        if (obj.inpatient_readings != undefined) {
          if (obj.inpatient_readings.length != 0) {
            for (var i = 0; i < obj.inpatient_readings.length; i++) {
              this.fastinglevel.push(obj.inpatient_readings[i].reading)
              this.postprandiallevel.push(obj.inpatient_readings[i].type)
              var cretaetime1 = moment(obj.inpatient_readings[i].created_time, 'HH:mm:ss').format('hh:mm A');
              console.log(cretaetime1); // Output: "09:50 AM"
              this.xAxis.push(
                Date_Formate(obj.inpatient_readings[i].created_date) + " " + cretaetime1
              )
            }
            Helper_Class.setdiabrading_inpatient(null);
            Helper_Class.setdiabrading_inpatient(obj.inpatient_readings);
            if (this.xAxis.length != 0) {
              this.bloodglu_graph();
            }
          }
        }
      }
    }
  }

  bloodglu_graph() {
    if (this.xAxis.length > 1 && this.fastinglevel.length > 1) {
      var ctx = <HTMLCanvasElement>document.getElementById("energyGraph");
      const datacount = this.fastinglevel.length;
      this.energyGraph = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.xAxis,
          datasets: [
            {
              data: this.fastinglevel,
              backgroundColor: "#01452c",
              borderColor: "#01452c",
              fill: false,
              borderWidth: 2,
              spanGaps: true,
              label: "Reading",
              datalabels: {
                anchor: 'end',
                align: 'start',
                formatter: (value: number) => value.toString(), // Display the value as text
                color: '#000', // Text color
              }
            }
          ]
        },
        options: {
          legend: {
            //display: this.showGraph,
          },
          responsive: true,
          scales: {
            yAxes: [{
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 100,
                stepValue: 5,
              }
            }],
            xAxes: [{
              ticks: {
                // display: this.showGraph,
                maxRotation: 20,
                minRotation: 20
              }
            }]
          },
          plugins: {
            datalabels: {
              display: true,
              color: '#000',
              font: {
                weight: 'bold',
                size: 10,
              },
              anchor: 'end',
              align: 'start'
            },
            tooltip: {
              callbacks: {
                label: function (chartInstance) {
                  const label = chartInstance.dataset.label || '';
                  const value = chartInstance.data; // get the value for the tooltip
                  return `${label}: ${value}`;
                },
                enabled: true,
              }
            }
          }
        }
      });

    } else {
      var ctx = <HTMLCanvasElement>document.getElementById("energyGraph");
      this.energyGraph = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.xAxis,
          datasets: [
            {
              data: this.fastinglevel,
              backgroundColor: "#01452c",
              borderColor: "#01452c",
              fill: false,
              borderWidth: 2,
              spanGaps: true,
              label: "Reading"

            }
          ]
        },
        options: {
          legend: {
            // display: this.showGraph,
          },
          responsive: true,
          scales: {
            yAxes: [{
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 100,
                stepValue: 5,
              }
            }],
            xAxes: [{
              barPercentage: 1,
              barThickness: 25,
              ticks: {
                // display: this.showGraph,
                maxRotation: 20,
                minRotation: 20
              }
            }]
          }
        }
      });
    }
  }

  glugraph(value) {
    if (value == true) {
      this.gluprnt_graph = true
    } else {
      this.gluprnt_graph = true;
    }
    this.grpah1()
  }

  grpah1() {
    if (
      (this.xAxis.length > 1 && this.fastinglevel.length > 1) ||
      (this.xAxis.length > 1 && this.postprandiallevel.length > 1) ||
      (this.xAxis.length > 1 && this.randomlevel.length > 1) ||
      (this.fastinglevel.length > 1 && this.postprandiallevel.length > 1) ||
      (this.fastinglevel.length > 1 && this.randomlevel.length > 1) ||
      (this.postprandiallevel.length > 1 && this.randomlevel.length > 1)
    ) {
      var ctx = <HTMLCanvasElement>document.getElementById("energyGraph1");
      this.energyGraph1 = new Chart(ctx, {
        type: 'line',
        labels: ["Before food", "After food"],
        data: {
          labels: this.xAxis,
          datasets: [
            {
              data: this.fastinglevel,
              backgroundColor: "#01452c",
              borderColor: "#01452c",
              fill: false,
              borderWidth: 2,
              spanGaps: true,
              label: "Fasting"
            }, {
              data: this.postprandiallevel,
              backgroundColor: "#ff8b00",
              borderColor: "#ff8b00",
              fill: false,
              borderWidth: 2,
              spanGaps: true,
              label: "Postprandial"
            },
            {
              data: this.randomlevel,
              backgroundColor: "#000080",
              borderColor: "#000080",
              fill: false,
              borderWidth: 2,
              spanGaps: true,
              label: "Random"
            }]
        },
        options: {
          legend: {
            //display: this.showGraph,
          },
          responsive: true,
          scales: {
            yAxes: [{
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 100,
                stepValue: 5,

              }
            }],
            xAxes: [{

              ticks: {
                // display: this.showGraph,
                maxRotation: 20,
                minRotation: 20
              }
            }]
          },
        }
      });

    } else {
      var ctx = <HTMLCanvasElement>document.getElementById("energyGraph1");
      this.energyGraph1 = new Chart(ctx, {
        type: 'bar',
        labels: ["Before food", "After food"],
        data: {
          labels: this.xAxis,
          datasets: [
            {
              data: this.fastinglevel,
              backgroundColor: "#01452c",
              borderColor: "#01452c",
              fill: false,
              borderWidth: 2,
              spanGaps: true,
              label: "Fasting"
            }, {
              data: this.postprandiallevel,
              backgroundColor: "#ff8b00",
              borderColor: "#ff8b00",
              fill: false,
              borderWidth: 2,
              spanGaps: true,
              label: "Postprandial"
            },
            {
              data: this.randomlevel,
              backgroundColor: "#000080",
              borderColor: "#000080",
              fill: false,
              borderWidth: 2,
              spanGaps: true,
              label: "Random"
            }]
        },
        options: {
          legend: {
            //display: this.showGraph,
          },
          responsive: true,
          scales: {
            yAxes: [{
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 100,
                stepValue: 5,

              }
            }],
            xAxes: [{
              barPercentage: 1,
              barThickness: 25,
              ticks: {
                // display: this.showGraph,
                maxRotation: 20,
                minRotation: 20
              }
            }]
          },
        }
      });
    }
    this.printgraphvalue()
  }

  printgraphvalue() {
    let printContents, popupWin;
    printContents = document.getElementById('printgraph').innerHTML;
    popupWin = window.open(
      '',
      '_blank',
      'top=20,left=10,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`
              <head>
                <title>Inpatient</title>
                <link rel="stylesheet" media="screen" href="">
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
                <style>
                @page { size: auto;  margin: 0mm; }
                .table_class {
                  background-color: rgb(108, 180, 248);
                }
                
                </style>
              </head>
                  <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
                </html>`);
    popupWin.document.close();
  }

  linechartpulse(xAxis, yaxis, value) {
    if (xAxis.length != 0 && yaxis.length != 0) {
      var ctx = <HTMLCanvasElement>document.getElementById("pulsegrpah");
      this.pulsegrpah = new Chart(ctx, {
        type: "line",
        labels: ["Pulse"],
        data: {
          labels: xAxis,
          datasets: [
            {
              data: yaxis,
              backgroundColor: "#01452c",
              borderColor: "#01452c",
              fill: false,
              borderWidth: 2,
              spanGaps: true,
              label: "Pulse"
            },
          ]
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [{
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 100,
                stepValue: 5,
              },
              scaleLabel: {
                display: true,
                labelString: 'Pulse'
              }
            }],
            xAxes: [{
              barPercentage: 1,
              barThickness: 25,
              ticks: {
                maxRotation: 50,
                minRotation: 50,
              },
              scaleLabel: {
                display: true,
                labelString: 'Date'
              }
            }]
          },
        }
      });
    }
  }

  linechart_spo2(xAxis, yaxis, value) {
    if (value != "pulse") {
      if (xAxis.length != 0 && yaxis.length != 0) {
        var ctx = <HTMLCanvasElement>document.getElementById("spo2graph");
        this.spo2graph = new Chart(ctx, {
          type: "line",
          labels: ["SPO2"],
          data: {
            labels: xAxis,
            datasets: [
              {
                data: yaxis,
                backgroundColor: "#01452c",
                borderColor: "#01452c",
                fill: false,
                borderWidth: 2,
                spanGaps: true,
                label: "Spo2"
              },
            ]
          },
          options: {
            responsive: true,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Spo2'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 25,
                ticks: {
                  maxRotation: 50,
                  minRotation: 50,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Date'
                }
              }]
            },
          }
        });
      }
    }
  }

  linechartsys(sys, dia, xAxis) {
    this.bpgraphflag = true;
    console.log("check sys" + JSON.stringify(sys));
    console.log("check sys" + JSON.stringify(dia));
    if (sys.length != 0 || dia.length != 0) {
      var ctx = <HTMLCanvasElement>document.getElementById("bpgraph");
      this.showGraph = true;
      this.bpgraph = new Chart(ctx, {
        type: 'line',
        labels: ["Systole", "Diastole"],
        data: {
          labels: xAxis,
          datasets: [
            {
              data: sys,
              backgroundColor: "#01452c",
              borderColor: "#01452c",
              fill: false,
              borderWidth: 2,
              spanGaps: true,
              label: "Systole"
            }, {
              data: dia,
              backgroundColor: "#ff8b00",
              borderColor: "#ff8b00",
              fill: false,
              borderWidth: 2,
              spanGaps: true,
              label: "Diastole"
            }]
        },
        options: {
          responsive: true,
          layout: {
            padding: {
              left: 50,
              right: 50,
              top: 20,
              bottom: 20
            }
          },
          scales: {
            yAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Blood Pressure' // Label for the y-axis
              },
              ticks: {
                beginAtZero: true,
                steps: 100,
                stepValue: 5
              }
            }],
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Date' // Label for the x-axis
              },
              ticks: {
                display: this.showGraph,
                maxRotation: 50,
                minRotation: 50
              }
            }]
          },
        }
      });
    }
  }

  compareByName(obj1, obj2) {
    return obj1.docname === obj2.docname;
  }

  dischargedetials() {
    if (moment(this.discharge_date).isAfter(this.admission_date)) {
      console.log('date2 is a future date compared to date1');
    } else if (moment(this.discharge_date).isSame(this.admission_date)) {

      // this.toastr.error("Date of Discharge should not be same as Date of Admission")
    } else {
      console.log('date2 is not a future date and is not the same as date1');

      this.toastr.error("Date of Discharge should not be lesser than Date of Admission")
    }
  }

  addnursingnotes() {
    var flag = true, username, saluatation, fullname, senddata, nusenotes_url;
    if (this.nursingnotes == "") {
      this.toastr.error("Enter nursing notes")
      flag = false
    }
    if (this.userinfo.middle_name != undefined) {
      if (this.userinfo.last_name != undefined) {
        username = this.userinfo.first_name + " " + this.userinfo.middle_name + " ";
      } else {
        username = this.userinfo.first_name + " " + this.userinfo.middle_name + " " + this.userinfo.last_name;
      }
    } else {
      if (this.userinfo.last_name != undefined) {
        username = this.userinfo.first_name + " " + this.userinfo.last_name
      } else {
        username = this.userinfo.first_name
      }

    }
    if (this.userinfo.user_type == "doctor") {
      fullname = "Dr" + " " + username
    } else {
      fullname = username
    }

    if (flag == true) {
      if (this.nursing_notes_id != "") {
        nusenotes_url = 'inpat/uinn'
        senddata = {
          inpatient_id: Doc_Helper.getInpatientId(), nur_reg_id: this.userinfo.user_id,
          country: ipaddress.country_code, nursing_notes: this.nursingnotes, nursing_notes_id: this.nursing_notes_id
        }
      } else {
        nusenotes_url = 'inpat/sinn'
        senddata = {
          inpatient_id: Doc_Helper.getInpatientId(), nur_reg_id: this.userinfo.user_id,
          country: ipaddress.country_code, nursing_notes: this.nursingnotes
        }
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + nusenotes_url,
        JSON.stringify(senddata),
        { headers: headers }
      )
        .subscribe((data) => {
          var obj = data.json();
          console.log("check nurseing notes--" + JSON.stringify(obj))
          if (obj.key == "1") {
            if (nusenotes_url == "inpat/sinn") {
              this.nursenotesarray.push({
                name: fullname,
                nursing_notes: this.nursingnotes,
                inpatient_id: Doc_Helper.getInpatientId(), nursing_notes_id: obj.nursing_notes_id
              })

            } else {
              this.nursenotesarray.push({
                name: fullname,
                nursing_notes: this.nursingnotes,
                inpatient_id: Doc_Helper.getInpatientId(), nursing_notes_id: this.nursing_notes_id
              })
            }

            this.toastr.success(obj.result)
            this.nursingnotes = "";
            
          } else {
            this.toastr.error("Error occured while saving data")
          }
        });
    }
  }

  editnurseing_notes(notes) {
    for (var i = 0; i < this.nursenotesarray.length; i++) {
      if (notes.nursing_notes_id == this.nursenotesarray[i].nursing_notes_id) {
        this.nursingnotes = this.nursenotesarray[i].nursing_notes
        this.nursing_notes_id = this.nursenotesarray[i].nursing_notes_id
        this.nursenotesarray.splice(i, 1);
      }
    }
  }

  RateCardData() {
    this.rate_card_disable = false;
    var requestData = {
      centre_id: this.hospital_id,
      center_type: 'hospital',
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'mas/grcd', requestData, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log("rate card response --" + JSON.stringify(obj));
          var RateCard = obj.rate_cards;
          console.log(RateCard);
          if (RateCard != undefined && RateCard.length != 0) {
            for (var i = 0; i < RateCard.length; i++) {
              this.RateCardArray.push({
                discount: RateCard[i].discount,
                rate_card_desc: RateCard[i].rate_card_desc,
                rate_card_id: RateCard[i].rate_card_id,
                default: RateCard[i].default,
              });
            }
            this.rate_card = this.RateCardArray[0].rate_card_id;
            for (var i = 0; i < this.RateCardArray.length; i++) {
              if (this.RateCardArray[i].inp_default != undefined && this.RateCardArray[i].inp_default == '1') {
                this.rate_card = this.RateCardArray[i].rate_card_id;
                this.rate_card_disable = true;
                break;
              }
            }
          }
        },
        (error) => {
          this.toastr.error('An error occurred');
        }
      );
  }

  getDiscTypes() {
    console.log("rate card this.pharma_id -&&&-" + JSON.stringify(this.pharma_id));
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.discURL, { center_id: this.pharma_id }, { headers: headers }).subscribe(
      data => {
        this.discountDatas = [];
        var distypes = data.json();
        this.discountDatasArray = distypes.disc_types;
        for (var i = 0; i < this.discountDatasArray.length; i++) {
          if (this.discountDatasArray[i].default_val == 1) {
            this.discType = this.discountDatasArray[i].disc_type_id;
            this.rateplaneflag = true
            break
          } else {
            this.discType = this.discountDatasArray[0].disc_type_id;
            this.rateplaneflag = true
          }
        }
      
      }, error => { });
  }

  changeratecard(id, value) {
    if (value == 'pharmacy') {
      this.pharmaratecrad = id;
    } else {
      this.hospitalratecard = id;
    }
  }

  spo2value(value) {
    if (value < 90) {
      this.spo2colorstyle = true;
    } else {
      this.spo2colorstyle = false;
    }
  }

  pulsevalue(value) {
    if (value < 60 || value > 100) {
      this.pulsecolorstyle = true;
    } else {
      this.pulsecolorstyle = false;
    }
  }

  rrvalue(value) {
    if (value < 14 || value > 20) {
      this.rrcolorstyle = true;
    } else {
      this.rrcolorstyle = false;
    }
  }

  cbgvalue(value) {
    if (value < 90 || value > 250) {
      this.cbgcolorstyle = true;
    } else {
      this.cbgcolorstyle = false;
    }
  }

  chnagetemp(e) {
    this.temp_read = e;
  }

  Medicine_name_change(e, type) {
    console.log("check e value " + JSON.stringify(e))
    if (this.new_med != true) {
      if (e.target.value.toString() != null && e.target.value.length.toString() > 2) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        console.log("check e value " + JSON.stringify({
          drug_category: this.userinfo.user_id,
          drug_name: e.target.value.toString(),
          type: type,
          pharmacy_id: this.pharma_id,
        }))
        this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/',
          {
            drug_category: this.userinfo.user_id,
            drug_name: e.target.value.toString(),
            type: type,
            pharmacy_id: this.pharma_id,
          },
          { headers: headers })
          .subscribe(
            response => {
              var obj = response.json();
              console.log("ressponse --" + JSON.stringify(obj))
              if (obj.med_details != null) {
                this.Medicinenamelist = [];
                this.Medicine_list = [];
                this.Medicne_data = [];
                this.medicinecolorarray = [];

                for (var i = 0; i < obj.med_details.length; i++) {
                  var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                  if (obj.med_details[i].show_intake != undefined) {
                    var default_quantity = obj.med_details[i].default_quantity;
                  }
                  this.Medicne_data.push({
                    name: medname, generic: obj.med_details[i].generic_name, quantity: obj.med_details[i].quantity, drug_id: obj.med_details[i].med_id, default_quantity: default_quantity, look_alike: obj.med_details[i].look_alike,
                    sound_alike: obj.med_details[i].sound_alike,
                    high_risk: obj.med_details[i].high_risk,
                    ward_only: obj.med_details[i].ward_only,
                  });

                  //  this.Medicne_data.push({ name: medname, generic: obj.med_details[i].generic_name });
                  this.Medicine_list.push({
                    med_id: obj.med_details[i].med_id,
                    med_name: obj.med_details[i].med_name,
                    med_type_id: obj.med_details[i].med_type_id,
                    type_name: obj.med_details[i].type_name,
                    short_name: obj.med_details[i].short_name,
                    short_form: obj.med_details[i].short_form,
                    show_short_form: obj.med_details[i].show_short_form,
                    show_intake: obj.med_details[i].show_intake,
                    medname: medname,
                    default_quantity: default_quantity,
                    look_alike: obj.med_details[i].look_alike,
                    sound_alike: obj.med_details[i].sound_alike,
                    high_risk: obj.med_details[i].high_risk,
                    ward_only: obj.med_details[i].ward_only,
                  })
                }

                this.Medicinenamelist = this.Medicne_data.filter(function (this: any, el) {
                  return el.toLowerCase().indexOf(this.medicine_nametxt.toLowerCase()) > -1;
                }.bind(this));

                // this.Med_addbtn = true;
              }
            },
            error => {

            }
          )
      } else {
        this.Medicinenamelist = [];
      }
    } 
  }

  select_medicine_item(item) {
    this.high_risk = item.high_risk;
    this.look_alike = item.look_alike;
    this.sound_alike = item.sound_alike;
    this.ward_only = item.ward_only;
    if (this.personalinfo.AppFlow == "Inpatient" || this.personalinfo.AppFlow == "InpatMedPres") {
      if (this.intake_txt != "SOS") {
        var matchingPrescription = this.viewpreslist_array.find((prescription) => {
          return prescription.medicines.some((medicine) => {
            return medicine.drug_id === item.drug_id.toString();
          });
        });

      } else {
        for (var i = 0; i < this.Medicine_list.length; i++) {
          if (this.Medicine_list[i].medname == item.name) {
            this.medicine_nametxt = this.Medicine_list[i].med_name;
            this.drug_typetxt = this.Medicine_list[i].med_type_id;
            this.medtypetxt = this.Medicine_list[i].type_name;
            this.ganericName = item.generic;
            this.short_form = this.Medicine_list[i].short_form;
            this.show = this.Medicine_list[i].show_short_form;
            this.show_intake = this.Medicine_list[i].show_intake;
            this.med_id = this.Medicine_list[i].med_id;
            this.short_name = this.Medicine_list[i].short_name;
            if (this.Medicine_list[i].default_quantity != undefined) {
              this.default_quantity = this.Medicine_list[i].default_quantity
            }
            if ((parseInt(this.drug_typetxt) == 5) || (parseInt(this.drug_typetxt) == 14) || (parseInt(this.drug_typetxt) == 75)) {
              this.mix_show = false;
            } else {
              this.mix_show = true;
            }
          }
        }
       
        if (this.show_intake == "1" || this.show_intake == 1) {
          this.getIntakeSession();
          this.intakesession = false
        } else {
          this.intakesession = true;
          this.quan_lable = false;
          this.morning_dis = false;
          this.mor_id = true;
          this.after_id = true;
          this.eve_id = true;
          this.intake_show = true;
          this.dure_show = true;

          this.eve_eventxt = true;
          this.night_eventxt = true;
          this.daydur_show = true;

          this.mor_lable = true;
          this.after_eventxt = true;
          this.morning_req = true;
          this.morn_eventxt = false;
        }
        this.Medicne_data = [];
        this.Medicinenamelist = [];
      }

      for (var i = 0; i < this.Medicine_list.length; i++) {
        if (this.Medicine_list[i].medname == item.name) {
          this.medicine_nametxt = this.Medicine_list[i].med_name;
          this.drug_typetxt = this.Medicine_list[i].med_type_id;
          this.medtypetxt = this.Medicine_list[i].type_name;
          this.ganericName = item.generic;
          this.short_form = this.Medicine_list[i].short_form;
          this.show = this.Medicine_list[i].show_short_form;
          this.show_intake = this.Medicine_list[i].show_intake;
          this.med_id = this.Medicine_list[i].med_id;
          this.short_name = this.Medicine_list[i].short_name;
          this.default_quantity = this.Medicine_list[i].default_quantity;
          if ((parseInt(this.drug_typetxt) == 5) || (parseInt(this.drug_typetxt) == 14) || (parseInt(this.drug_typetxt) == 75)) {
            this.mix_show = false;
          } else {
            this.mix_show = true;
          }
        }
      }
      
      if (this.show_intake == "1" || this.show_intake == 1) {
        this.getIntakeSession();
        this.intakesession = false

      } else {
        this.intakesession = true;
        this.quan_lable = false;
        this.morning_dis = false;
        this.mor_id = true;
        this.after_id = true;
        this.eve_id = true;
        this.intake_show = true;
        this.dure_show = true;
        this.eve_eventxt = true;
        this.night_eventxt = true;
        this.daydur_show = true;
        this.mor_lable = true;
        this.after_eventxt = true;
        this.morning_req = true;
        this.morn_eventxt = false;
      }

      this.Medicne_data = [];
      this.Medicinenamelist = [];

    } else {
      for (var i = 0; i < this.Medicine_list.length; i++) {
        if (this.Medicine_list[i].medname == item.name) {
          this.medicine_nametxt = this.Medicine_list[i].med_name;
          this.drug_typetxt = this.Medicine_list[i].med_type_id;
          this.medtypetxt = this.Medicine_list[i].type_name;
          this.ganericName = item.generic;
          this.short_form = this.Medicine_list[i].short_form;
          this.show = this.Medicine_list[i].show_short_form;
          this.show_intake = this.Medicine_list[i].show_intake;
          this.med_id = this.Medicine_list[i].med_id;
          this.short_name = this.Medicine_list[i].short_name;
          if ((parseInt(this.drug_typetxt) == 5) || (parseInt(this.drug_typetxt) == 14) || (parseInt(this.drug_typetxt) == 75)) {
            this.mix_show = false;
          } else {
            this.mix_show = true;
          }
        }
      }
      // this.sesssion_basechange();
      if (this.show_intake == "1" || this.show_intake == 1) {
        this.getIntakeSession();
        this.intakesession = false
      } else {
        this.intakesession = true;
        this.quan_lable = false;
        this.morning_dis = false;
        this.mor_id = true;
        this.after_id = true;
        this.eve_id = true;
        this.intake_show = true;
        this.dure_show = true;

        this.eve_eventxt = true;
        this.night_eventxt = true;
        this.daydur_show = true;

        this.mor_lable = true;
        this.after_eventxt = true;
        this.morning_req = true;
        this.morn_eventxt = false;
      }
      this.Medicne_data = [];
      this.Medicinenamelist = [];
    }

    this.medicinecolorarray = this.medicinecolorarray || [];

    let lookAlikeColor = item.look_alike !== "0" && item.look_alike !== undefined ? this.hospitalinfoarray[0].look_alike_color : '';
    let soundAlikeColor = item.sound_alike !== "0" && item.sound_alike !== undefined ? this.hospitalinfoarray[0].sound_alike_color : '';
    let highRiskColor = item.high_risk !== "0" && item.high_risk !== undefined ? this.hospitalinfoarray[0].high_risk_color : '';
    let wardOnlyColor = item.ward_only !== "0" && item.ward_only !== undefined ? this.hospitalinfoarray[0].ward_only_color : '';

    if (item.look_alike !== "0" && item.look_alike !== undefined) {
      this.medicinecolorarray.push({ type: 'look_alike', color: lookAlikeColor });
    }
    if (item.sound_alike !== "0" && item.sound_alike !== undefined) {
      this.medicinecolorarray.push({ type: 'sound_alike', color: soundAlikeColor });
    }
    if (item.high_risk !== "0" && item.high_risk !== undefined) {
      this.medicinecolorarray.push({ type: 'high_risk', color: highRiskColor });
    }
    if (item.ward_only !== "0" && item.ward_only !== undefined) {
      this.medicinecolorarray.push({ type: 'ward_only', color: wardOnlyColor });
    }

    this.Medicne_data = [];
    this.Medicinenamelist = [];
  }

  getIntakeSession() {
    var send_data = {
      product_id: this.med_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gis', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          var flag = this.isEmptyObject(obj);
          for (var i = 0; i < this.Get_Intake_array.length; i++) {
            if (obj.intake == this.Get_Intake_array[i].drug_int_id) {
              this.intake_txt = this.Get_Intake_array[i].description
              this.intake_id = this.Get_Intake_array[i].drug_int_id;
            }
          }
          this.sesssion_basechange();
        })
  }

  Get_Intake_session() {
    var obj = masterCSData_Helper.getMasterIntakeSession();
    if (obj != undefined) {
      this.Get_Intake_array = obj.drug_intake;
      this.intakemodes = obj.intake_mode;
      this.intake_txt = "Thrice a day";
    }
  }

  sesssion_basechange() {
    //intakesession
    if (this.show_intake == undefined) {
      this.toastr.error(Message_data.selectMedicine);
    }

    if (this.intake_txt == "Morning only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;
      this.morning_dis = false;
      this.afternoon_dis = true;
      this.even_dis = true;
      this.ngt_dis = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;

      this.morning_req = true;
      this.afternoon_req = false;
      this.evening_req = false;
      this.night_req = false;

      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }

      this.morn_eventxt = false;

    } else if (this.intake_txt == "Afternoon only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.afternoon_dis = false;
      this.morning_dis = true;
      this.even_dis = true;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Evening only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "0";
          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = true;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;

      this.morning_req = false;
      this.afternoon_req = false;
      this.evening_req = true;
      this.night_req = false;

      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Night only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.afternoon_dis = true;
      this.morning_dis = true;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = false;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning & Afternoon" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning & Evening" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = true;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = false;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = true;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = false;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Afternoon & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Afternoon & Evening" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    }
    else if (this.intake_txt == "Morning & Evening" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    }

    else if (this.intake_txt == "Morning,Afternoon & Evening" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Thrice a day" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Morning,Afternoon & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Morning,Afternoon,Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Four times a day" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Afternoon,Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = true;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = false;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if ((this.intake_txt == "Every 6 hours" || this.intake_txt == "Every 8 hours"
      || this.intake_txt == "Every 12 hours") && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = false;

      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Stat") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;
      this.intake_show = true;
      this.dure_show = true;
      this.intakesession = false;
      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = true;

      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.morn_eventxt = false;
      this.dure_txt = " ";
      this.daydur_txt = " ";
      // this.day_txt = "";
      this.afterfood_txt = ""

    } else if (this.intake_txt == "Once a day") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = false;
      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "SOS") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }

      } else {
        this.mor = "0";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";
      }
      this.dure_txt = " ";
      this.daydur_txt = " ";
      // this.day_txt = "";
      this.afterfood_txt = ""
      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = false;

      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week"
      || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week" || this.intake_txt == "Once a month") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }
      this.afternoon_dis = false;
      this.morning_dis = false;
      this.mor_id = false;
      this.mor_lable = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;
      this.even_dis = false;
      this.ngt_dis = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.evening_req = true;
      this.afternoon_req = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    }
    if (this.intake_txt != "Stat") {
      this.daydur_txt = "day(s)"
      this.afterfood_txt = "After food";
      this.dure_txt = "30";
    }
    for (var i = 0; i < this.Get_Intake_array.length; i++) {
      if (this.intake_txt == this.Get_Intake_array[i].description) {
        // this.intake_txt = this.Get_Intake_array[i].description
        this.intake_id = this.Get_Intake_array[i].drug_int_id;
        break;
      }
    }

    if (this.intake_txt == "Once a week"
      || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week") {
      this.daydur_txt = "week(s)";
    } else if (this.intake_txt == "Once a month") {
      this.daydur_txt = "month(s)";
    } else {
      this.daydur_txt = "day(s)";
    }
    this.intake_change();
  }
  public intakeOption: any = [];
  public language_key;
  printLanguageflag: boolean = true;
  public language: string = 'English';
  public mixtype: any = [];
  intake_change = function (this: any) {
    if (this.afterfood_txt == "After food" || this.afterfood_txt == "Before food") {
      this.dure_show = false;
      this.dure_require = true;
      this.dure_txt = "30";
    } else {
      this.dure_show = true;
      this.dure_require = false;
      this.dure_txt = undefined;
    }
  }

  languageChange() {
    if (this.language_key != "English") {
      this.printLanguageflag = true;
    } else {
      this.printLanguageflag = false;
    }
    if (this.language == "English") {
      this.translate.use('english');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Tamil") {
      this.translate.use('tamil');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")
      // this.mixtype.push("15ml தண்ணீருடன்")
      // this.mixtype.push("100ml தண்ணீருடன்")
      // this.mixtype.push("தயிருடன்")

    } else if (this.language == "Telugu") {
      this.translate.use('telugu');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Malayalam") {
      this.translate.use('malayalam');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")
    } else if (this.language == "Kannada") {
      this.translate.use('kannada');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Oriya") {
      this.translate.use('oriya');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")
    } else if (this.language == "Bengali") {
      this.translate.use('bangla');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Hindi") {
      this.translate.use('hindi');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")
    }
    if (this.language_key != "English") {
      this.printLanguageflag = true;
    } else {
      this.printLanguageflag = false;
    }
  }

  Duration() {
    var duration = this.day_txt + " " + this.daydur_txt;
    return duration
  }

  Medicalpres_add() {
    if (this.saveredirect_diagnosis == false && this.saveredirect_medical == false) {
      this.printerflag = false;
    } else {
      if (this.saveredirect_diagnosis == true && this.saveredirect_medical == false) {
        this.printerflag = true;
      }
      if (this.saveredirect_diagnosis == false && this.saveredirect_medical == true) {
        this.printerflag = true;
      } else {
        this.printerflag = true;
      }
    }
    this.high_riskcolor = "";
    this.sound_alikecolor = "";
    this.look_alikecolor = "";
    this.ward_onlycolor = "";
    
    if (this.high_risk != undefined) {
      this.high_risk = this.high_risk;
      this.high_riskcolor = this.hospitalinfoarray[0].high_risk_color;

    } else {
      this.high_risk = ""
      this.high_riskcolor = ""
    }

    if (this.sound_alike != undefined) {
      this.sound_alike = this.sound_alike;
      this.sound_alikecolor = this.hospitalinfoarray[0].sound_alike_color;
    } else {
      this.sound_alike = "";
      this.sound_alikecolor = "";
    }

    if (this.look_alike != undefined) {
      this.look_alike = this.look_alike;
      this.look_alikecolor = this.hospitalinfoarray[0].look_alike_color;
    } else {
      this.look_alike = "";
      this.look_alikecolor = "";
    }

    if (this.ward_only != undefined) {
      this.ward_only = this.ward_only;
      this.ward_onlycolor = this.hospitalinfoarray[0].ward_only_color;
    } else {
      this.ward_only = "";
      this.ward_onlycolor = "";
    }

    if (this.ganericName != undefined) {
      this.genericnameflag = true;
    }
    if (this.default_quantity != undefined) {
      this.default_quantity = this.default_quantity
    }
   
    //this.intakesession = true;
    var flag = false;
    if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
      flag = false;
    } 
    this.found = this.listProducts.some(item => item.drug_id === this.med_id && item.intake === this.intake_id);

    if (this.new_med == true) {
      if (this.medtypetxt == undefined || this.medtypetxt == "" || this.medtypetxt == " ") {
        this.toastr.error(Message_data.enterMedType);
        flag = true;
        this.new_med = true;
        this.newmed_flag = false;
      }
    }

    if (this.medicine_nametxt == undefined || this.medicine_nametxt == "") {
      this.toastr.error(Message_data.enterMedName);
      flag = true;
    } else if (this.intakesession != true) {
      if (this.intake_txt == undefined || this.intake_txt == "") {
        this.toastr.error(Message_data.enterIntkSess);
        flag = true;
      }
    }

    if (this.show_intake == "1") {
      if (this.intake_txt != undefined && this.intake_txt == "Morning only" && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon only" && (this.aft == undefined || this.aft == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Evening only" && (this.eve == undefined || this.eve == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Night only" && (this.ngt == undefined || this.ngt == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning & Afternoon" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning & Evening" &&
        ((this.mor == undefined || this.mor == "") || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon & Evening" &&
        ((this.aft == undefined || this.aft == "") || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning & Evening" &&
        ((this.mor == undefined || this.mor == "") || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Evening & Night" &&
        ((this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      }
      else if (this.intake_txt != undefined && this.intake_txt == "Afternoon & Night" &&
        ((this.aft == undefined || this.aft == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning,Afternoon & Evening" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning,Afternoon & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning,Afternoon,Evening & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon,Evening & Night" &&
        ((this.aft == undefined || this.aft == "") || (this.eve == undefined || this.eve == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Evening & Night" &&
        ((this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Thrice a day" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Four times a day" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && (this.intake_txt == "Every 6 hours" || this.intake_txt == "Every 8 hours"
        || this.intake_txt == "Every 12 hours" || this.intake_txt == "Once a day" || this.intake_txt == "Stat")
        && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterIntake);
        flag = true;
      } else if (this.intake_txt == "Stat" && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterIntake);
        flag = true;
      } else if (this.intake_txt != undefined && (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week"
        || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week") && (this.mor == "0" && this.aft == "0" && this.eve == "0" && this.ngt == "0")) {
        this.toastr.error(Message_data.enterIntake);
        flag = true;
      }
    }

    if (flag == false) {
      this.saveflag = true;
      this.printFlag = true;
      this.print_view_flag = false;

      for (var i = 0; i < this.Medical_prescription_array.length; i++) {
        if (this.Medical_prescription_array[i].med_name == this.medtypetxt) {
          this.drug_typetxt = this.Medical_prescription_array[i].med_code;
        }
      }

      for (var i = 0; i < this.Get_Intake_array.length; i++) {
        if (this.intake_txt == this.Get_Intake_array[i].description) {
          this.intake_id = this.Get_Intake_array[i].drug_int_id;
        }
      }

      this.Medicine_table = false;
      if (this.med_id == null) {
        this.med_id = "0";
      }

      var aftfood;
      console.log("this.show.this.show --" + this.show)
      console.log("this.show_intake -intakesession-" + this.show_intake + this.intake_txt + this.intakesession)
      console.log("this.short_form --" + this.short_form)
      console.log("this.short_form **--" + this.short_form);

      if (this.show == "1") {
        if (this.intakesession == true && this.intake_txt == "") {
          this.dure_txt = "1"
        } else {
          this.dure_txt = this.dure_txt
        }
        var morntxt = parseFloat(this.mor) != 0 ? this.mor : "0";
        var afttxt = parseFloat(this.aft) != 0 ? this.aft : "0";
        var evetxt = parseFloat(this.eve) != 0 ? this.eve : "0";
        var ngttxt = parseFloat(this.ngt) != 0 ? this.ngt : "0";

        if (this.show_intake == "0") {
          this.intake_id = "1"
          if (this.intakesession == true && this.intake_txt == "") {
            this.every_six = morntxt
            this.mor = morntxt;
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.every_six = this.intake_txt;
          }

        } else {
          var morning = " ", afternoon = " ", evening = " ", night = " ";
          if (this.intake_txt == "Morning only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          } else if (this.intake_txt == "Afternoon only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          } else if (this.intake_txt == "Evening only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Night only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Morning & Afternoon") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          } else if (this.intake_txt == "Morning & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Afternoon & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Evening & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Morning & Evening") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Afternoon & Evening") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Thrice a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Four times a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Every 6 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 6 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 8 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 8 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 12 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 12 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          } else if (this.intake_txt == "SOS") {
            if (this.drug_typetxt == "1" || this.drug_typetxt == "7" || this.drug_typetxt == "10"
              || this.drug_typetxt == "23" || this.drug_typetxt == "30") {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;
            } else {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;

            }

          } else if (this.intake_txt == "Once a week") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }
            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Once in two weeks") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }
            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Twice a week") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }
            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Alternative day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }
            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          } else if (this.intake_txt == "Stat") {
            this.every_six = morntxt + " " + this.short_form + " - Stat";
            this.param_four = true;

          } else if (this.intake_txt == "Once a month") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }
            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          } else {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }
            this.every_six = morning + afternoon + evening + night; this.param_four = false;
          }
        }
        console.log("seeeeeeeeedure_txteeeeeee" + this.dure_txt)

        if ((this.intake_txt != "Stat") && (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ")) {
          if (this.intake_txt == "SOS") {
            if (this.dure_txt != " ") {
              this.dure_write = this.dure_txt + " " + "mins ";
            } else {
              this.dure_write = "";
              this.dure_flag = true;
            }

          } else {
            console.log("else **" + this.intake_txt + this.dure_txt)
            if (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ") {
              if (this.intakesession == true && this.intake_txt == "") {
                this.dure_txt = "1"
              } else {
                this.dure_write = this.dure_txt + " " + "mins ";
              }

            }
          }
        } else {
          console.log("else (((((" + this.intake_txt + this.dure_txt)
          this.dure_write = "";
          this.dure_flag = true;
        }
        if (this.afterfood_txt == undefined) {
          this.afterfood_txt = "";
        }

        var frequency;
        if (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week" || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week" || this.intake_txt == "Once a month") {
          frequency = this.intake_txt;
        } else {
          if (this.intake_txt == "Stat" || this.intake_txt == "SOS") {
            frequency = "";
          } else {
            if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
              frequency = "";
            } else {
              if (this.intakesession == true && this.intake_txt == "") {
                frequency = "";
              } else {
                frequency = "Daily";
              }

            }
          }
        }

        if (this.intake_txt == "Stat") {
          period = "";
          // this.day_txt = "";
        } else {
          var period
          if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
            period = "day";

          } else if (this.day_txt != "1" && this.daydur_txt == "day(s)") {
            period = "days";

          } else if (this.day_txt == "1" && this.daydur_txt == "week(s)") {
            period = "week";

          } else if (this.day_txt != "1" && this.daydur_txt == "week(s)") {
            period = "weeks";

          } else if (this.day_txt == "1" && this.daydur_txt == "month(s)") {
            period = "month";

          } else if (this.day_txt != "1" && this.daydur_txt == "month(s)") {
            period = "months";
          } else {
            if (this.intakesession == true && this.intake_txt == "") {
              period = "";
            } else {
              period = "";
            }
            // period = "";
          }
        }

        if (this.intake_txt != "Stat" && (this.afterfood_txt != undefined && this.afterfood_txt != 'Select')) {
          aftfood = this.afterfood_txt;
        } else {
          aftfood = "";
        }
        if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
          if (this.day_txt != "") {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              genericname: this.ganericName,
              days: this.day_txt,
              period: period,

              drug_intake: this.dure_write + " " + aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              // remarks: this.remark_txt,
              frequency: frequency,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              mixval: this.mixing_txt,
              param_four: this.param_four,
              default_quantity: this.default_quantity,
              intake_mode: this.intake_mode,
              highrisk: this.high_risk,
              soundalike: this.sound_alike,
              lookalike: this.look_alike,
              ward: this.ward_only,
              high_riskcolor: this.high_riskcolor,
              sound_alikecolor: this.sound_alikecolor,
              look_alikecolor: this.look_alikecolor,
              ward_onlycolor: this.ward_onlycolor,

              status: "1"
            });

          } else {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              genericname: this.ganericName,
              // days: this.day_txt,
              // period: period,

              drug_intake: this.dure_write + " " + aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              remarks: this.remark_txt,
              frequency: frequency,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              mixval: this.mixing_txt, param_four: this.param_four,
              intake_mode: this.intake_mode,
              highrisk: this.highrisk,
              soundalike: this.soundalike,
              lookalike: this.lookalike,
              ward: this.ward,
              high_riskcolor: this.high_riskcolor,
              sound_alikecolor: this.sound_alikecolor,
              look_alikecolor: this.look_alikecolor,
              ward_onlycolor: this.ward_onlycolor,

              status: "1"
            });
          }

        } else {
          if (this.intakesession == true && this.intake_txt == "") {
            this.dure_txt = "1"
          } else {
            this.dure_txt = this.dure_txt
          }
          this.listProducts.push({
            med_typetxt: this.medtypetxt,
            drug_name: this.medicine_nametxt,
            genericname: this.ganericName,
            days: this.day_txt,
            period: period,

            drug_intake: this.dure_write + " " + aftfood,
            intake: this.intake_id,
            every_six: this.every_six,
            dure_txt_table: this.dure_write,
            time_duration: this.dure_txt,
            morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
            afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
            evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
            night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
            morning: this.mor,
            afternoon: this.aft,
            evening: this.eve,
            night: this.ngt,
            drug_id: this.med_id,
            drug_type_id: this.drug_typetxt,
            remarks: this.remark_txt,
            frequency: frequency,
            show_intake: this.show_intake,
            rowid: this.rowid,
            short_name: this.short_name,
            mixval: this.mixing_txt, param_four: this.param_four,
            default_quantity: this.default_quantity,
            intake_mode: this.intake_mode,
            highrisk: this.high_risk,
            soundalike: this.sound_alike,
            lookalike: this.look_alike,
            ward: this.ward_only,
            high_riskcolor: this.high_riskcolor,
            sound_alikecolor: this.sound_alikecolor,
            look_alikecolor: this.look_alikecolor,
            ward_onlycolor: this.ward_onlycolor,

            status: "1"
            // this.high_risk=this.Medicine_list[0].high_risk;
            // this.look_alike=this.Medicine_list[0].look_alike;
            // this.sound_alike=this.Medicine_list[0].sound_alike;
            // this.ward_only=this.Medicine_list[0].ward_only;
          });
        }
      } else {
        if (this.intakesession == true && this.intake_txt == "") {
          this.dure_txt = "1"

        } else {
          this.dure_txt = this.dure_txt
        }
        var morntxt = parseFloat(this.mor) != 0 ? this.mor : "0";
        var afttxt = parseFloat(this.aft) != 0 ? this.aft : "0";
        var evetxt = parseFloat(this.eve) != 0 ? this.eve : "0";
        var ngttxt = parseFloat(this.ngt) != 0 ? this.ngt : "0";

        if (this.show_intake == "0") {
          this.intake_id = "1"
          if (this.intakesession == true) {
            this.intake_txt = ""
            this.every_six = morntxt
            this.mor = morntxt;
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
          if (this.intakesession == true && this.intake_txt == "") {
            this.every_six = morntxt
          } else {
            this.every_six = this.intake_txt;
          }
        } else {
          var morning = " ", afternoon = " ", evening = " ", night = " ";

          if (this.intake_txt == "Morning only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Afternoon only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Night only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Morning & Afternoon") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Morning & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Afternoon & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Thrice a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Four times a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Every 6 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 6 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 8 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 8 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 12 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 12 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          } else if (this.intake_txt == "SOS") {
            if (this.drug_typetxt == "1" || this.drug_typetxt == "7" || this.drug_typetxt == "10"
              || this.drug_typetxt == "23" || this.drug_typetxt == "30") {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;
            } else {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;

            }

          } else if (this.intake_txt == "Once a week") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Once in two weeks") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Twice a week") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Alternative day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          } else if (this.intake_txt == "Stat") {
            this.every_six = morntxt + " " + this.short_form + " - Stat";
            this.param_four = true;

          } else if (this.intake_txt == "Once a month") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          } else if (this.intake_txt == "Evening only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "") {
            this.every_six = morntxt + " " + this.short_form;
            this.param_four = true;
            // this.dure_txt="";

          } else {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
          }
        }
        if ((this.intake_txt != "Stat") && (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ")) {
          if (this.intake_txt == "SOS") {
            if (this.dure_txt != " ") {
              this.dure_write = this.dure_txt + " " + "mins ";
            } else {
              this.dure_write = "";
              this.dure_flag = true;
            }

          } else {
            console.log("else **" + this.intake_txt + this.dure_txt)
            if (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ") {
              if (this.intakesession == true && this.intake_txt == "") {
                this.dure_write = "";
              } else {
                this.dure_write = this.dure_txt + " " + "mins ";
              }

            }
          }
        } else {
          console.log("else (((((" + this.intake_txt + this.dure_txt)
          this.dure_write = "";
          this.dure_flag = true;
        }

        var frequency;
        if (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week" || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week" || this.intake_txt == "Once a month") {
          frequency = this.intake_txt;
        } else {
          if (this.intakesession == true && this.intake_txt == "") {
            frequency = ""
          } else {
            if (this.intake_txt == "Stat" || this.intake_txt == "SOS") {
              frequency = "";
            } else {
              if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
                frequency = "";
              } else {
                if (this.intakesession == true && this.intake_txt == "") {
                  frequency = ""
                } else {
                  frequency = "Daily";

                }
              }
            }
          }
        }

        var period
        if (this.intakesession == true && this.intake_txt == "") {
          period = "";
        } else {
          if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
            period = "day";
          } else if (this.day_txt != "1" && this.daydur_txt == "day(s)") {
            period = "days";
          } else if (this.day_txt == "1" && this.daydur_txt == "week(s)") {
            period = "week";
          } else if (this.day_txt != "1" && this.daydur_txt == "week(s)") {
            period = "weeks";
          } else if (this.day_txt == "1" && this.daydur_txt == "month(s)") {
            period = "month";
          } else if (this.day_txt != "1" && this.daydur_txt == "month(s)") {
            period = "months";
          } else {
            if (this.intakesession == true && this.intake_txt == "") {
              period = "";
            } else {
              period = "";
            }
          }
        }
        if (this.intakesession == true && this.intake_txt == "") {
          aftfood = ""
        } else {
          if (this.afterfood_txt != undefined && this.afterfood_txt != 'Select') {
            if (this.intakesession == true && this.intake_txt == "") {
              aftfood = ""
            } else {
              aftfood = this.afterfood_txt;
            }
          } else {
            aftfood = "";
          }
        }

        if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
          if (this.day_txt !== "") {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              genericname: this.ganericName,
              days: this.day_txt,
              period: period,

              drug_intake: this.dure_write + " " + aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              frequency: frequency,
              remarks: this.remark_txt,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              param_four: this.param_four,
              default_quantity: this.default_quantity,
              intake_mode: this.intake_mode,
              highrisk: this.high_risk,
              soundalike: this.sound_alike,
              lookalike: this.look_alike,
              ward: this.ward_only,
              high_riskcolor: this.high_riskcolor,
              sound_alikecolor: this.sound_alikecolor,
              look_alikecolor: this.look_alikecolor,
              ward_onlycolor: this.ward_onlycolor,

              status: "1"
            });
          } else {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              // days: this.day_txt,
              // period: period,

              drug_intake: this.dure_write + " " + aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              frequency: frequency,
              remarks: this.remark_txt,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              param_four: this.param_four,
              default_quantity: this.default_quantity,
              intake_mode: this.intake_mode,
              highrisk: this.high_risk,
              soundalike: this.sound_alike,
              lookalike: this.look_alike,
              ward: this.ward_only,
              high_riskcolor: this.high_riskcolor,
              sound_alikecolor: this.sound_alikecolor,
              look_alikecolor: this.look_alikecolor,
              ward_onlycolor: this.ward_onlycolor,

              status: "1"
            });
          }

        } else {
          if (this.day_txt !== "") {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              genericname: this.ganericName,
              days: this.day_txt,
              period: period,

              drug_intake: this.dure_write + " " + aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              frequency: frequency,
              remarks: this.remark_txt,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              param_four: this.param_four,
              default_quantity: this.default_quantity,
              intake_mode: this.intake_mode,
              highrisk: this.high_risk,
              soundalike: this.sound_alike,
              lookalike: this.look_alike,
              ward: this.ward_only,
              high_riskcolor: this.high_riskcolor,
              sound_alikecolor: this.sound_alikecolor,
              look_alikecolor: this.look_alikecolor,
              ward_onlycolor: this.ward_onlycolor,

              status: "1"
            });
          } else {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              // days: this.day_txt,
              // period: period,

              drug_intake: this.dure_write + " " + aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              frequency: frequency,
              remarks: this.remark_txt,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              param_four: this.param_four,
              default_quantity: this.default_quantity,
              intake_mode: this.intake_mode,
              highrisk: this.high_risk,
              soundalike: this.sound_alike,
              lookalike: this.look_alike,
              ward: this.ward_only,
              high_riskcolor: this.high_riskcolor,
              sound_alikecolor: this.sound_alikecolor,
              look_alikecolor: this.look_alikecolor,
              ward_onlycolor: this.ward_onlycolor,

              status: "1"
            });
          }
        }
      }


      console.log("list product::::::" + JSON.stringify(this.listProducts))
      this.highRiskPresent = this.listProducts.some(drug => drug.highrisk === "1");
      this.medicinecolorarray = [];
      //Resets the form 
      this.med_typetxt = "";
      this.medicine_nametxt = "";
      this.short_name = "";
      this.short_form = "";
      this.newmed_flag = true;
      this.new_med = false;
      this.print_med = true;
      this.mixing_txt = "";
      this.ganericName = "";
      // this.day_txt = "";
      this.remark_txt = "";
      // this.dure_txt=""
      //}
      this.medicineaddbtn = false; // Add button
      console.log("MED LIST " + JSON.stringify(this.listProducts))
    }

    var flag: boolean;
    if (this.subtestuname.length != 0) {
      flag = true;
    } else if (this.subtestfname.length != 0) {
      flag = true;
    } else if (this.subtestname.length != 0) {
      flag = true;
    } else if (this.subtestsname.length != 0) {
      flag = true;
    } else if (this.subtestxname.length != 0) {
      flag = true;
    } else if (this.subtesturname.length != 0) {
      flag = true;
    } else if (this.subtestbname.length != 0) {
      flag = true;
    } else if (this.subtestcname.length != 0) {
      flag = true;
    } else {
      flag = false;
    }

    if (this.listProducts.length != 0) {
      this.print_med = true;
    }
    if (flag == true && this.listProducts.length != 0) {
      this.print_medidiag = true;
    } else {
      this.print_medidiag = false;
    }

  }

  savemed() {
    if (this.listProducts.length != 0 && this.listProducts != undefined) {
      for (var i = 0; i < this.listProducts.length; i++) {
        if (this.listProducts[i].status == "1") {
          this.saveproducts.push({
            product_name: this.listProducts[i].drug_name,
            product_id: this.listProducts[i].drug_id,
            morning: this.listProducts[i].morning,
            afternoon: this.listProducts[i].afternoon,
            evening: this.listProducts[i].evening,
            night: this.listProducts[i].night,
            intake: this.listProducts[i].intake,
            time_duration: this.listProducts[i].drug_intake,
          })
        }
      }
    }
    var send_data = {
      // product_id: this.med_id
      inpatient_id: this.inpatId,
      created_by: this.docName,
      medicines: this.saveproducts,
      country: ipaddress.country_code,
      pat_type:"emergency"
    }
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/siom', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.key == '1') {
            this.saveproducts = [];
            this.toastr.success("Medicine saved successfully")

          } else {
            this.toastr.error("Error in save medicine")
          }
        })
  }

  public selectedPainScaleLevel;
  handlePainScaleClick(number: number) {
    this.painLevels.forEach(level => {
      level.selected = level.number === number;
      this.selectedPainScaleLevel = number;
    });
  }

  changeInsuranceName() {
    this.InsNameList = [];

    var headers = new Headers();
    if (this.insuranceName != null && this.insuranceName != undefined && this.insuranceName.length > 2) {
      var send_data = {
        insname: this.insuranceName
      }
      
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/gibn/', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            var InsNameList = [];
            this.InsNameList = obj.insurers;

            if(this.InsNameList.length ==0) {
              this.toastr.error("No insurer found, try with different name")
              this.insuranceName = "";
            }
          },
          error => {
          }
        )
    }
  }

  selectInsuranceName(insname) {
    for(var i=0;i<this.InsNameList.length;i++) {
      if(this.InsNameList[i].insurer_desc == insname) {
        this.insuranceName = insname;
        this.tpa_name = insname;
        
      } else {
        this.insuranceName = "";
      }
    }
    
  }

  RelationChange() {
    this.clearData();
  }

  getRelations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.get(ipaddress.getIp + "usercontroller/getrelation/", { headers: headers }).subscribe(
      data => {
        this.relation_datas = [];
        for (var b = 0; b < data.json().relation.length; b++) {
          var obj = data.json().relation[b];
          this.relation_datas.push({
            relation_name: obj.relationship_name,
            relation_id: obj.relationship_id
          });
          this.relation = this.relation_datas[0];
        }

      }, error => { });
  }

  compareById_2(object1: any, object2: any) {
    return object1 && object2 && object1.relation_id === object2.relation_id;
  }

  clearData() {
    this.togg_diab1 = false;
    this.changediab(false);

    this.togg_hyper1 = false;
    this.changehypertension(false);

    this.togg_tuber1 = false;
    this.changetuber(false);

    this.togg_CKD = false;
    this.changeCKD(false);

    this.togg_CAD = false;
    this.changeCAD(false);

    this.togg_CVD = false;
    this.changeCVD(false);

    this.togg_BA = false;
    this.changeBA(false);

    this.togg_COPD = false;
    this.changeCOPD(false);

    this.togg_APD = false;
    this.changeAPD(false);

    this.togg_Seizure = false;
    this.changeSeizure(false);

    this.togg_Thyroid = false;
    this.changeThyroid(false);

    this.togg_Jaundice = false;
    this.changeJaundice(false);

    this.togg_Surgical = false;
    this.changeSurgical(false);

    this.togg_Other = false;
    this.changeOther(false);

    this.diab_details = ""
    this.CKD_details = ""
    this.CAD_details = ""
    this.CVD_details = ""
    this.BA_details = ""
    this.TB_details = ""
    this.COPD_details = ""
    this.APD_details = ""
    this.Seizure_details = ""
    this.Thyroid_details = ""
    this.hyper_details = ""
    this.Jaundice_details = ""
    this.Surgical_details = ""
    this.Other_details = ""
  }

  deletList(action) {
    var index = this.family_hist_data.findIndex(x => x.relation_id == action.relation_id);
    this.family_hist_data.splice(index, 1);
  }

  editList(action) {
    var index = this.family_hist_data.findIndex(x => x.relation_id == action.relation_id);
    console.log(action)

    this.relation = {
      relation_name: action.relationship_name,
      relation_id: action.relation_id
    }

    if (action.diabetics == "Yes") {
      this.changediab(true);
      this.diab_details = action.diabetic_note;
    } else {
      this.changediab(false);
    }

    if (action.hypertension == "Yes") {
      this.changehypertension(true);
      this.hyper_details = action.hypertension_note;
    } else {
      this.changehypertension(false);
    }

    if (action.CKD == "Yes") {
      this.changeCKD(true);
      this.CKD_details = action.CKD_note;
    } else {
      this.changeCKD(false);
    }

    if (action.CAD == "Yes") {
      this.changeCAD(true);
      this.CAD_details = action.cad_note;
    } else {
      this.changeCAD(false);
    }

    if (action.CVD == "Yes") {
      this.changeCVD(true);
      this.CVD_details = action.cvd_note;
    } else {
      this.changeCVD(false);
    }

    if (action.tuberculosis == "Yes") {
      this.changetuber(true);
      this.TB_details = action.tb_note;
    } else {
      this.changetuber(false);
    }

    if (action.BA == "Yes") {
      this.changeBA(true);
      this.BA_details = action.ba_note;
    } else {
      this.changeBA(false);
    }

    if (action.copd == "Yes") {
      this.changeCOPD(true);
      this.COPD_details = action.copd_note;
    } else {
      this.changeCOPD(false);
    }

    if (action.apd == "Yes") {
      this.changeAPD(true);
      this.APD_details = action.apd_note;
    } else {
      this.changeAPD(false);
    }

    if (action.seizure == "Yes") {
      this.changeSeizure(true);
      this.Seizure_details = action.seizure_note;
    } else {
      this.changeSeizure(false);
    }

    if (action.surgical == "Yes") {
      this.changeSurgical(true);
      this.Surgical_details = action.surgical_note;
    } else {
      this.changeSurgical(false);
    }

    if (action.thyroid == "Yes") {
      this.changeThyroid(true);
      this.Thyroid_details = action.thyroid_note;
    } else {
      this.changeThyroid(false);
    }

    if (action.jaundice == "Yes") {
      this.changeJaundice(true);
      this.Jaundice_details = action.jaundice_note;
    } else {
      this.changeJaundice(false);
    }

    if (action.others == "Yes") {
      this.changeOther(true);
      this.Other_details = action.other_note;
    } else {
      this.changeOther(false);
    }

    this.family_hist_data.splice(index, 1);
  }

  changediab(e) {
    this.togg_diab1 = e;
    this.togg_diab = e == false ? "No" : "Yes";
  }

  changehypertension(e) {
    this.togg_hyper1 = e;
    this.togg_hyper = e == false ? "No" : "Yes";
  }

  changetuber(e) {
    this.togg_tuber1 = e;
    this.togg_tuber = e == false ? "No" : "Yes";
  }

  // new
  changeCKD(e) {
    this.togg_CKD = e;
    this.togg_CKD_text = e == false ? "No" : "Yes";
  }

  changeCAD(e) {
    this.togg_CAD = e;
    this.togg_CAD_text = e == false ? "No" : "Yes";
  }

  changeCVD(e) {
    this.togg_CVD = e;
    this.togg_CVD_text = e == false ? "No" : "Yes";
  }

  changeBA(e) {
    this.togg_BA = e;
    this.togg_BA_text = e == false ? "No" : "Yes";
  }

  changeCOPD(e) {
    this.togg_COPD = e;
    this.togg_COPD_text = e == false ? "No" : "Yes";
  }

  changeAPD(e) {
    this.togg_APD = e;
    this.togg_APD_text = e == false ? "No" : "Yes";
  }

  changeSeizure(e) {
    this.togg_Seizure = e;
    this.togg_Seizure_text = e == false ? "No" : "Yes";
  }

  changeThyroid(e) {
    this.togg_Thyroid = e;
    this.togg_Thyroid_text = e == false ? "No" : "Yes";
  }

  changeJaundice(e) {
    this.togg_Jaundice = e;
    this.togg_Jaundice_text = e == false ? "No" : "Yes";
  }

  changeSurgical(e) {
    this.togg_Surgical = e;
    this.togg_Surgical_text = e == false ? "No" : "Yes";
  }

  changeOther(e) {
    this.togg_Other = e;
    this.togg_Other_text = e == false ? "No" : "Yes";
  }

  addFamilyDetails() {
    if (this.family_other != "") {
      var others = this.family_other;
    }
    var checkRet = [];
    if (this.family_hist_data.length != 0)
      checkRet = this.family_hist_data.filter(o => o.relation_id === this.relation.relation_id);

    if (checkRet.length == 0) {
      var toast_text, flag: boolean = true;
      if (this.togg_diab != "No" && this.diab_details == "") {
        flag = false
        toast_text = "Please enter diabetics details"
      }
      if (this.togg_hyper != "No" && this.hyper_details == "") {
        flag = false
        toast_text = "Please enter hypertension details"
      }
      if (this.togg_tuber != "No" && this.TB_details == "") {
        flag = false
        toast_text = "Please enter tuberculosis details"
      }
      if (this.togg_CKD_text != "No" && this.CKD_details == "") {
        flag = false
        toast_text = "Please enter CKD details"
      }
      if (this.togg_CAD_text != "No" && this.CAD_details == "") {
        flag = false
        toast_text = "Please enter CKD details"
      }
      if (this.togg_CVD_text != "No" && this.CVD_details == "") {
        flag = false
        toast_text = "Please enter CVD details"
      }
      if (this.togg_BA_text != "No" && this.BA_details == "") {
        flag = false
        toast_text = "Please enter BA details"
      }
      if (this.togg_COPD_text != "No" && this.COPD_details == "") {
        flag = false
        toast_text = "Please enter COPD details"
      }
      if (this.togg_APD_text != "No" && this.APD_details == "") {
        flag = false
        toast_text = "Please enter APD details"
      }
      if (this.togg_Seizure_text != "No" && this.Seizure_details == "") {
        flag = false
        toast_text = "Please enter Seizure details"
      }
      if (this.togg_Thyroid_text != "No" && this.Thyroid_details == "") {
        flag = false
        toast_text = "Please enter Seizure details"
      }
      if (this.togg_Jaundice_text != "No" && this.Jaundice_details == "") {
        flag = false
        toast_text = "Please enter jaundice details"
      }
      if (this.togg_Surgical_text != "No" && this.Surgical_details == "") {
        flag = false
        toast_text = "Please enter Surgery details"
      }
      if (this.togg_Other_text != "No" && this.Other_details == "") {
        flag = false
        toast_text = "Please enter Other details"
      }

      if (flag == true) {
        if ((this.togg_diab != "No" && this.diab_details != "") || (this.togg_hyper != "No" && this.hyper_details != "") || (this.togg_tuber != "No" && this.TB_details != "") || (this.togg_CKD_text != "No" && this.CKD_details != "") || (this.togg_CAD_text != "No" && this.CAD_details != "") || (this.togg_CVD_text != "No" && this.CVD_details != "") || (this.togg_BA_text != "No" && this.BA_details != "") || (this.togg_COPD_text != "No" && this.COPD_details != "") || (this.togg_APD_text != "No" && this.APD_details != "") || (this.togg_Seizure_text != "No" && this.Seizure_details != "") || (this.togg_Thyroid_text != "No" && this.Thyroid_details != "") || (this.togg_Jaundice_text != "No" && this.Jaundice_details != "") || (this.togg_Surgical_text != "No" && this.Surgical_details != "") || (this.togg_Other_text != "No" && this.Other_details != "")) {
          this.family_hist_data.push({
            relation_id: this.relation.relation_id,
            relationship_name: this.relation.relation_name,

            diabetics: this.togg_diab,
            CKD: this.togg_CKD_text,
            CAD: this.togg_CAD_text,
            CVD: this.togg_CVD_text,
            ba_txt: this.togg_BA_text,
            tuberculosis: this.togg_tuber,
            copd: this.togg_COPD_text,
            apd: this.togg_APD_text,
            seizure: this.togg_Seizure_text,
            thyroid: this.togg_Thyroid_text,
            hypertension: this.togg_hyper,
            jaundice: this.togg_Jaundice_text,
            surgical: this.togg_Surgical_text,
            others: this.togg_Other_text,

            diabetic_note: this.diab_details,
            CKD_note: this.CKD_details,
            cad_note: this.CAD_details,
            cvd_note: this.CVD_details,
            ba_note: this.BA_details,
            tb_note: this.TB_details,
            copd_note: this.COPD_details,
            apd_note: this.APD_details,
            seizure_note: this.Seizure_details,
            thyroid_note: this.Thyroid_details,
            hypertension_note: this.hyper_details,
            jaundice_note: this.Jaundice_details,
            surgical_note: this.Surgical_details,
            other_note: this.Other_details,
          });
          this.clearData();
        } else {
          this.toastr.error("Select and enter diesease details")
        }
      } else {
        this.toastr.error(toast_text, null, { timeOut: 3000 });
      }
    } else {
      this.toastr.error(Message_data.alrdyExist);
    }
  }

  zipcode_change() {
    if (this.clnt_zipcode.length > 4) {
      this.clnt_location_list = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          this.zipcode_url,
          {
            zipcode: this.clnt_zipcode,
          },
          { headers: headers }
        )
        .subscribe(
          (data) => {
            var obj = data.json();
            if (obj.locations != null && obj.locations.length != 0) {
              if (obj.locations.length == 1) {
                this.clnt_location_id = obj.locations[0].location_id;
                this.clnt_location = obj.locations[0].description;
                this.clnt_zipcode = obj.locations[0].pincode;
                this.getCities(this.clnt_location, 0);
              } else {
                this.clnt_location_list = obj.locations;
                console.log('../location' + JSON.stringify(this.clnt_location_list));
              }
            }
          },
          (error) => {
            this.toastr.error(
              'Unable to fetch location details, please try later'
            );
          }
        );
    } else {
      this.clnt_location_list = [];
    }
  }

  getCities(state, flag) {
    this.clnt_city_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        this.cityUrl,
        { loc_name: this.clnt_location, zipcode: this.clnt_zipcode },
        { headers: headers }
      )
      .subscribe((data) => {
        var dataval = data.json();
        if (dataval.cities != null) {
          this.clnt_city_list = dataval.cities;
          if (this.clnt_city_list.length == 1) {
            for (var i = 0; i < this.clnt_city_list.length; i++) {
              if (this.clnt_city_list[i].city_id == this.clnt_city_id) {
                this.clnt_city_id = this.clnt_city_list[i].city_id;
                this.clnt_city = this.clnt_city_list[i].city_desc;
                this.getStates(this.clnt_city_id, 0);

                break;
              } else {
                this.clnt_city_id = this.clnt_city_list[0].city_id;
                this.clnt_city = this.clnt_city_list[0].city_desc;
                this.getStates(this.clnt_city_id, 0);
              }
            }
          } else {
            this.clnt_city_id = this.clnt_city_list[0].city_id;
            this.clnt_city = this.clnt_city_list[0].city_desc;
            this.getStates(this.clnt_city_id, 0);
          }
        }
      });
  }

  changeCity(clnt_city, flag) {
    this.clnt_city_list = [];
    for (var i = 0; i < this.clnt_city_list.length; i++) {
      if (this.clnt_city_list[i].city_desc == clnt_city) {
        this.clnt_city_id = this.clnt_city_list[i].district_id;
        this.clnt_city = this.clnt_city_list[i].city_desc;
        this.getStates(this.clnt_city_id, 0);
      }
    }
  }

  getLocations(city) {
    this.clnt_location_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(this.locationUrl, { city_id: city }, { headers: headers })
      .subscribe((data) => {
        var dataval = data.json();
        if (dataval.locations != null && dataval.locations.length != 0) {
          this.clnt_location_list = dataval.locations;
          for (var i = 0; i < this.clnt_location_list.length; i++) {
            if (
              this.clnt_location_list[i].location_id == this.clnt_location_id
            ) {
              this.clnt_location_id = this.clnt_location_list[i].location_id;
              this.clnt_location = this.clnt_location_list[i].description;
              this.clnt_zipcode = this.clnt_location_list[i].pincode;
              this.clnt_location_list = [];
              break;
            }
          }
        }
      });
  }

  changeLocation(locat_desc) {
    this.clnt_location = locat_desc;
    this.clnt_location_list = [];

    if (this.clnt_location != null && this.clnt_location.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          this.locationNameByUrl,
          {
            name: this.clnt_location,
            //city_id: this.clntCityId,
          },
          { headers: headers }
        )
        .subscribe(
          (data) => {
            var dataval = data.json();
            if (dataval.locations != null && dataval.locations.length != 0)
              this.clnt_location_list = dataval.locations;
          },
          (error) => {
            this.toastr.error(Message_data.unableToFetchData);
          }
        );
    } else {
      this.clnt_location_list = [];
    }
  }

  selectLocation(location) {
    this.clnt_location = location;
    for (var i = 0; i < this.clnt_location_list.length; i++) {
      if (this.clnt_location_list[i].location_id == location.location_id) {
        this.clnt_location_id = this.clnt_location_list[i].location_id;
        this.clnt_location = this.clnt_location_list[i].description;
        this.clnt_zipcode = this.clnt_location_list[i].pincode;
      }
    }
    this.clnt_location_list = [];
    this.getCities(this.clnt_location, '0');
    if (this.Client_type == 'Existing') {
      this.existUserFlag = true;
    }
  }

  getStates(city, flag) {
    this.clnt_state_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(this.stateUrl, { city_id: city }, { headers: headers })
      .subscribe((data) => {
        var dataval = data.json();
        if (dataval.states != null) {
          this.clnt_state_list = dataval.states;
          if (this.clnt_state_list.length == 1) {
            for (var i = 0; i < this.clnt_state_list.length; i++) {
              if (this.clnt_state_list[i].state_id == this.clnt_state_id) {
                this.clnt_state_id = this.clnt_state_list[i].state_id;
                this.clnt_state = this.clnt_state_list[i].state_desc;
                this.getCountries(this.clnt_state_id, '0');
                break;
              } else {
                this.clnt_state_id = this.clnt_state_list[0].state_id;
                this.clnt_state = this.clnt_state_list[0].state_desc;
                this.getCountries(this.clnt_state_id, '0');
              }
            }
          } else {
            this.clnt_state_id = this.clnt_state_list[0].state_id;
            this.clnt_state = this.clnt_state_list[0].state_desc;
            this.getCountries(this.clnt_state_id, '0');
          }
        }
      });
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.clnt_state_list.length; i++) {
      if (this.clnt_state_list[i].stage_desc == clnt_state) {
        this.clnt_state_id = this.clnt_state_list[i].state_id;
        this.clnt_state = this.clnt_state_list[i].stage_desc;
        this.getCountries(this.clnt_state_id, '0');
      }
    }
  }

  getCountries(stateid, flag) {
    this.clnt_country_list = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(this.countryUrl, { state_id: stateid }, { headers: headers })
      .subscribe(
        (data) => {
          this.clnt_country_list = [];
          var dataval = data.json();
          if (dataval.countries != null) {
            this.clnt_country_list = dataval.countries;
            for (var c = 0; c < this.clnt_country_list.length; c++) {
              if (
                this.clnt_country_list[c].country_id == this.clnt_country_id
              ) {
                this.clnt_country = this.clnt_country_list[c].country_desc;
                this.clnt_country_id = this.clnt_country_list[c].country_id;
                //this.getStates(this.clntCountryId, flag);
                break;
              }
            }
          }
        },
        (error) => {
          this.toastr.error(Message_data.unableToFetchData);
        }
      );
  }

  send_data() {

  }

  changetype(value){
    if(value == 'yes') {
      this.ynoptapp1=true;
      this.ward_type='Normal';
    } else {
      this.ynoptapp1=false;
      this.ward_type='icu';
    }
  }

  showAdmType(data) {
    if(data =='admitted') {
      this.wardflag=false;
      this.dischargeflag=true;
      this.emerg_type="admitted";

    } else if(data =='discharged') {
      this.wardflag=true;
      this.dischargeflag=false;
      this.emerg_type="discharged";
    } else {
      this.wardflag=true;
      this.dischargeflag=true;
      this.emerg_type="observation";
    }
  }
}
